import { EntityEndpoint } from "enums/EntityEndpoint";
import { ResourceType } from "enums/ResourceType";
import { ActivityTemplateJSONAModel } from "interfaces/jsona-models/ActivityTemplateJSONAModel";
import { ActivityTemplateSelector } from "selectors/ActivityTemplateSelector";
import { AppApiActionHandler } from "./AppApiActionHandler";

class ActivityTemplateActionHandler extends AppApiActionHandler<ActivityTemplateJSONAModel> {
  constructor() {
    super(
      ResourceType.ActivityTemplate,
      EntityEndpoint.ActivityTemplate,
      new ActivityTemplateSelector()
    );
  }
}

export const activityTemplateActionHandler = new ActivityTemplateActionHandler();
