import { SearchOutlined } from "@ant-design/icons/lib";
import { FuzzySearchType } from "@bornfight/aardvark";
import { JSONAModel } from "@bornfight/aardvark/dist/interfaces/JSONAModel";
import { CustomParam } from "@bornfight/aardvark/dist/services/JsonApiQuery/interfaces/CustomParam";
import { FilterConfig } from "@bornfight/aardvark/dist/services/JsonApiQuery/interfaces/FilterConfig";
import { JsonApiQueryConfig } from "@bornfight/aardvark/dist/services/JsonApiQuery/interfaces/JsonApiQueryConfig";
import { SortConfig } from "@bornfight/aardvark/dist/services/JsonApiQuery/interfaces/SortConfig";
import { Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import { ColumnType, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import autobind from "autobind-decorator";
import { DatePicker } from "common/DatePicker/DatePicker";
import { ResourceSelect } from "common/ResourceSelect/ResourceSelect";
import { ColumnFilterType } from "common/ResourceTable/enums/ColumnFilterType";
import { ResourceTableDataRecord } from "common/ResourceTable/interfaces/ResourceTableDataRecord";
import { ResourceTableProps } from "common/ResourceTable/interfaces/ResourceTableProps";
import { ResourceTableState } from "common/ResourceTable/interfaces/ResourceTableState";
import { TableColumnData } from "common/ResourceTable/interfaces/TableColumnData";
import { TableColumnSelectFilter } from "common/ResourceTable/interfaces/TableColumnSelectFilter";
import { TableColumnTextFilter } from "common/ResourceTable/interfaces/TableColumnTextFilter";
import { TextFilter } from "common/ResourceTable/TextFilter";
import { DateTimeFormat } from "enums/DateTimeFormat";
import isEqual from "lodash.isequal";
import moment from "moment";
import * as React from "react";
import { apiActionHandlers } from "utils/apiActionHandlers";
import { AppJsonApiQuery } from "utils/AppJsonApiQuery";
import { ResourceCollectionProvider } from "../ResourceCollectionProvider/ResourceCollectionProvider";

interface Refs {
  [key: string]: any;
}

export class ResourceTable<
  R extends ResourceTableDataRecord,
  E extends JSONAModel
> extends React.Component<ResourceTableProps<R, E>, ResourceTableState> {
  public static defaultProps = {
    pageSize: 10,
    defaultIdSortOrder: "desc",
  };

  constructor(props: ResourceTableProps<R, E>) {
    super(props);
    this.configRefs(props);

    const initialPage = this.props.currentPage || 1;

    const pagination = {
      pageSize: this.props.pageSize,
      pageNumber: initialPage,
    };
    const propCustomParams = this.getPropJsonApiQueryConfig().customParams;
    let customParamsWithOrder: CustomParam[] = [];
    if (propCustomParams) {
      customParamsWithOrder = [...propCustomParams];
    }

    customParamsWithOrder.filter((param) => {
      return !param.name.includes("order[");
    });

    const initialSortConfigCustomParam = this.getInitialSortConfig(props);
    if (initialSortConfigCustomParam) {
      customParamsWithOrder = [...customParamsWithOrder, initialSortConfigCustomParam];
    }

    this.state = {
      currentPage: initialPage,
      jsonApiQuery: new AppJsonApiQuery({
        ...this.getPropJsonApiQueryConfig(),
        paginationConfig: pagination,
        customParams: customParamsWithOrder,
      }),
    };
  }

  public componentDidUpdate(
    prevProps: Readonly<ResourceTableProps<R, E>>,
    prevState: Readonly<ResourceTableState>
  ): void {
    const prevPropsJsonApiQuery = prevProps.jsonApiQuery?.getInitialConfig();
    const currentPropsJsonApiQuery = this.getPropJsonApiQueryConfig();
    /**
     * perform new network request on json api prop change
     */
    if (!isEqual(prevPropsJsonApiQuery, currentPropsJsonApiQuery)) {
      const propCustomParams = this.getPropJsonApiQueryConfig().customParams;
      let customParamsWithOrder: CustomParam[] = [];
      if (propCustomParams) {
        customParamsWithOrder = [...propCustomParams];
      }

      customParamsWithOrder = customParamsWithOrder.filter((param) => {
        return !param.name.includes("order[");
      });

      const initialSortConfigCustomParam = this.getInitialSortConfig(this.props);

      if (initialSortConfigCustomParam) {
        customParamsWithOrder = [...customParamsWithOrder, initialSortConfigCustomParam];
      }

      this.setState({
        jsonApiQuery: new AppJsonApiQuery({
          ...this.getPropJsonApiQueryConfig(),
          customParams: customParamsWithOrder,
        }),
      });
    }
  }

  public render() {
    const {
      apiActionHandler,
      transformToDataSource,
      columnData,
      onCollectionRender,
      ...antdTableProps
    } = this.props;

    const columns = this.createColumns(columnData);
    return (
      <ResourceCollectionProvider
        apiActionHandler={apiActionHandler}
        jsonApiQuery={this.state.jsonApiQuery}
        render={(injectedProps) => {
          const collection = injectedProps.collection as E[];
          const dataSource = transformToDataSource(collection);

          if (onCollectionRender) {
            onCollectionRender(collection);
          }

          const tablePagination: TablePaginationConfig = {
            total: injectedProps.count,
            current: this.state.currentPage,
            // on change is triggered after the initial load,
            // so it immediately calls another api call if meta is present.
            onChange: this.onPageChange,
            showSizeChanger: true,
            onShowSizeChange: this.onShowSizeChange,
          };

          return (
            <Table
              loading={injectedProps.loading}
              dataSource={dataSource}
              pagination={tablePagination}
              columns={columns}
              data-test={"resource-table"}
              rowClassName={(record) => `resource-table-record-${record.key}`}
              onChange={(pagination, filters, sorter) => {
                this.updateSortConfig(sorter as SorterResult<R>);
              }}
              {...antdTableProps}
            />
          );
        }}
      />
    );
  }

  private getSortConfig(): SortConfig | undefined {
    if (
      this.props.jsonApiQuery &&
      this.props.jsonApiQuery.getInitialConfig() &&
      this.props.jsonApiQuery.getInitialConfig().sortKeyName
    ) {
      return {
        value: this.props.jsonApiQuery.getInitialConfig().sortKeyName as string,
        order: this.props.defaultIdSortOrder,
      };
    }
    return;
  }
  private transformSortConfigToCustomParam(sortConfig: SortConfig): CustomParam {
    return {
      name: `order[${sortConfig.value}]`,
      value: sortConfig.order || "",
    };
  }

  private getInitialSortConfig(props: ResourceTableProps<R, E>): CustomParam | undefined {
    const firstSortedColumn = props.columnData.find((column) => {
      return (
        column.sorter === true &&
        column.sorterField !== "" &&
        (column.defaultSortOrder !== undefined || column.sortOrder !== undefined)
      );
    });
    if (firstSortedColumn && firstSortedColumn?.sortOrder) {
      return this.transformSortConfigToCustomParam({
        value: firstSortedColumn.sorterField as string,
        order: firstSortedColumn.sortOrder,
      });
    }
    if (firstSortedColumn && firstSortedColumn?.defaultSortOrder) {
      return this.transformSortConfigToCustomParam({
        value: firstSortedColumn.sorterField as string,
        order: firstSortedColumn.defaultSortOrder,
      });
    }
    return undefined;
  }

  private updateSortConfig(sorterResult: SorterResult<R>) {
    let sortConfig: SortConfig | undefined;
    if (sorterResult.column) {
      sortConfig = {
        // inferred type because column is modified
        value: (sorterResult.column as TableColumnData<R>).sorterField as string,
        order: sorterResult.order || undefined,
      };
    }
    let orderCustomParam: CustomParam | undefined;
    if (sortConfig) {
      orderCustomParam = this.transformSortConfigToCustomParam(sortConfig);
    }

    this.setState((state) => {
      const initialConfig = state.jsonApiQuery.getInitialConfig();
      let customParamsWithOrder: CustomParam[];

      customParamsWithOrder =
        initialConfig?.customParams?.filter((param) => {
          return !param.name.includes("order[");
        }) || [];

      if (orderCustomParam) {
        customParamsWithOrder = [...customParamsWithOrder, orderCustomParam];
      }

      return {
        jsonApiQuery: new AppJsonApiQuery({
          ...initialConfig,
          customParams: customParamsWithOrder,
        }),
      };
    });
  }

  private configRefs(props: ResourceTableProps<R, E>) {
    // tslint:disable-next-line:no-for-each-push because also assigning refs
    props.columnData.forEach((column) => {
      (this as Refs)[this.getRefName(column.dataIndex)] = null;
    });
  }

  @autobind
  private createColumns(columnData: Array<TableColumnData<R>>): Array<ColumnProps<R>> {
    return columnData.map((column) => {
      const { filter, sorter, ...rest } = column;

      const expandedColumn: Omit<ColumnProps<R>, "onHeaderCell"> & {
        onHeaderCell: (col: ColumnType<R>) => {};
      } = {
        ...rest,
        sorter,
        onHeaderCell: (col) => {
          return {
            "data-test": `resource-table-th-${col.dataIndex}`,
          };
        },
      };

      if (filter?.type === ColumnFilterType.Text) {
        const newFilter = {
          ...filter,
          fuzzySearchType: filter.fuzzySearchType ?? FuzzySearchType.Contains,
        };
        return {
          ...expandedColumn,
          ...this.createTextFilteredColumn({
            filter: newFilter,
            ...rest,
          }),
        };
      }

      if (filter?.type === ColumnFilterType.Select) {
        return {
          ...expandedColumn,
          ...this.createSelectFilteredColumn({ filter, ...rest }),
        };
      }

      if (filter?.type === ColumnFilterType.Date) {
        return {
          ...expandedColumn,
          ...this.createDateFilteredColumn({ filter, ...rest }),
        };
      }

      if (filter?.type === ColumnFilterType.Year) {
        return {
          ...expandedColumn,
          ...this.createDateFilteredColumn({ filter, ...rest }),
        };
      }

      return expandedColumn;
    });
  }

  private createFilterBase(column: TableColumnData<R>): ColumnProps<R> {
    const { filter, dataIndex, title, sorter, ...rest } = column;
    const refName = this.getRefName(dataIndex);
    return {
      ...rest,
      title,
      dataIndex,
      onFilterDropdownVisibleChange: (visible) => {
        this.handleFilterDropdownVisibleChange(refName, visible);
      },
      filterIcon: (filtered) => {
        return this.renderFilterIcon(dataIndex, filtered);
      },
    };
  }

  // tslint:disable-next-line:cognitive-complexity
  private createTextFilteredColumn(column: TableColumnData<R>): ColumnProps<R> {
    const filterBase = this.createFilterBase(column);
    const { dataIndex, title } = column;
    const refName = this.getRefName(dataIndex);

    return {
      ...filterBase,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        const value = (selectedKeys && selectedKeys[0]) || "";
        return (
          <TextFilter
            ref={(node) => {
              (this as Refs)[refName] = node;
            }}
            dataIndex={dataIndex}
            title={title}
            value={value}
            onChange={(e) =>
              setSelectedKeys && setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              if (confirm) {
                confirm();
              }
              if (column.filter) {
                this.updateFilter(
                  column.filter.field,
                  value.toString(),
                  (column.filter as TableColumnTextFilter).fuzzySearchType
                );
              }
            }}
            onClear={() => {
              if (clearFilters) {
                clearFilters();
              }
              this.updateFilter(column.filter!.field, "");
            }}
          />
        );
      },
    };
  }

  private createSelectFilteredColumn(column: TableColumnData<R>): ColumnProps<R> {
    const filterBase = this.createFilterBase(column);
    const { filter, dataIndex, title } = column;
    const refName = this.getRefName(dataIndex);
    const actionHandler =
      apiActionHandlers[(filter as TableColumnSelectFilter).resourceType as string];
    return {
      ...filterBase,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        const value = (selectedKeys && selectedKeys[0]) || "";
        return (
          <ResourceSelect
            style={{ width: 200 }}
            forwardedRef={(node) => {
              (this as Refs)[refName] = node;
            }}
            value={value as string}
            placeholder={title}
            allowClear={true}
            apiActionHandler={actionHandler}
            optionValueAttributeName={"name"}
            onChange={(newValue) => {
              if (setSelectedKeys) {
                const newKeys = newValue !== undefined ? [newValue] : [];
                setSelectedKeys(newKeys);
              }

              if (confirm) {
                confirm();
              }

              this.updateFilter(
                (filter as TableColumnSelectFilter).field,
                newValue && newValue.toString()
              );
            }}
          />
        );
      },
    };
  }

  private createDateFilteredColumn(column: TableColumnData<R>): ColumnProps<R> {
    const filterBase = this.createFilterBase(column);
    const { filter, dataIndex, title } = column;
    const refName = this.getRefName(dataIndex);
    return {
      ...filterBase,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        const selectedKeysValue = (selectedKeys && selectedKeys[0]) || undefined;

        const value: moment.Moment | undefined = selectedKeysValue
          ? moment(selectedKeysValue)
          : undefined;

        const pickerProps: { picker: "date" | "year"; format: DateTimeFormat } = {
          picker: column.filter?.type === ColumnFilterType.Year ? "year" : "date",
          format:
            column.filter?.type === ColumnFilterType.Year
              ? DateTimeFormat.Year
              : DateTimeFormat.YearMonthDayDashed,
        };

        return (
          <DatePicker
            data-test={`resource-table-filter-date-picker-${dataIndex}`}
            style={{ width: 200 }}
            ref={(node) => {
              (this as Refs)[refName] = node;
            }}
            value={value}
            placeholder={title}
            dropdownClassName={`resource-table-filter-date-picker-dropdown-${dataIndex}`}
            allowClear={true}
            defaultOpen={true}
            disabledDate={(current) => {
              if (this.props.disableDateFilterPastDates) {
                return current < moment().startOf("day");
              }
              return false;
            }}
            onChange={(newValue) => {
              if (this.props.onDateFilterColumnChange) {
                this.props.onDateFilterColumnChange(newValue, column);
              }
              if (setSelectedKeys) {
                const newKeys = newValue !== null ? [newValue.toISOString(true)] : [];
                setSelectedKeys(newKeys);
              }

              if (confirm) {
                confirm();
              }

              this.updateFilter(
                (filter as TableColumnSelectFilter).field,
                newValue && newValue.format(DateTimeFormat.YearMonthDayDashed)
              );
            }}
            {...pickerProps}
          />
        );
      },
    };
  }

  private renderFilterIcon(dataIndex: string, filtered: boolean) {
    // color from antd default theme
    const color = filtered ? "#1890ff" : undefined;
    return <SearchOutlined data-test={`resource-table-filter-${dataIndex}`} style={{ color }} />;
  }

  private handleFilterDropdownVisibleChange(refName: string, visible: boolean) {
    if (visible) {
      setTimeout(() => {
        const element = (this as Refs)[refName];
        if (!element) {
          return;
        }

        // here should probably focus() be called, but currently focus scrolls to top of the screen
        // @see reproduction example https://codesandbox.io/s/amazing-https-ugd4p?file=/index.css
        if (element.select) {
          element.select();
          return;
        }
      });
    }
  }

  /**
   *
   * @param name
   * @param value - can be 0 (e.g. index), therefore types are specific
   */
  private updateFilter(
    name: string,
    value: string | undefined | null,
    fuzzySearch?: FuzzySearchType
  ) {
    this.setState((state) => {
      const initialConfig = state.jsonApiQuery.getInitialConfig();
      const filterConfigsWithoutCurrent = initialConfig.filters
        ? initialConfig.filters.filter((filterConfig: FilterConfig) => filterConfig.name !== name)
        : [];
      const filters = [...filterConfigsWithoutCurrent];

      if (value !== undefined && value !== "" && value !== null) {
        const currentFilter: FilterConfig = {
          name,
          value,
          fuzzySearch,
        };
        filters.push(currentFilter);
      }

      const customParams: CustomParam[] = filters.map((f) => ({
        name: f.name,
        value: f.value,
      }));

      return {
        jsonApiQuery: new AppJsonApiQuery({
          ...initialConfig,
          customParams,
        }),
      };
    });
  }

  private getRefName(dataIndex: string) {
    return `ref-${dataIndex}`;
  }

  @autobind
  private onPageChange(page: number, pageSize: number) {
    const { currentPage } = this.state;
    if (currentPage === page) {
      return;
    }

    this.setState({
      currentPage: page,
      jsonApiQuery: new AppJsonApiQuery({
        ...this.state.jsonApiQuery.getInitialConfig(),
        paginationConfig: {
          pageSize: pageSize,
          pageNumber: page,
        },
      }),
    });
  }

  @autobind
  private onShowSizeChange(current: number, size: number) {
    this.setState({
      jsonApiQuery: new AppJsonApiQuery({
        ...this.state.jsonApiQuery.getInitialConfig(),
        paginationConfig: {
          pageNumber: current,
          pageSize: size,
        },
      }),
    });
  }

  private getPropJsonApiQueryConfig(): JsonApiQueryConfig {
    let propJsonApiQueryConfig: JsonApiQueryConfig = {};
    if (this.props.jsonApiQuery !== undefined) {
      propJsonApiQueryConfig = this.props.jsonApiQuery.getInitialConfig();
    }
    return propJsonApiQueryConfig;
  }
}
