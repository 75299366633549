// todo: replace this with real instances
import { ApiActionHandler } from "@bornfight/aardvark";
import { JSONAModel } from "@bornfight/aardvark/dist/interfaces/JSONAModel";
import { patientActionHandler } from "handlers/PatientActionHandler";
import { programActionHandler } from "handlers/ProgramActionHandler";
import { programTemplateActionHandler } from "handlers/ProgramTemplateActionHandler";
import { stepActionHandler } from "handlers/StepActionHandler";
import { stepTemplateActionHandler } from "handlers/StepTemplateActionHandler";
import { ResourceType } from "enums/ResourceType";
import { activityActionHandler } from "handlers/ActivityActionHandler";

export const apiActionHandlers: {
  [actionHandlerIndex: string]: ApiActionHandler<JSONAModel>;
} = {
  [ResourceType.Activity]: activityActionHandler,
  [ResourceType.Step]: stepActionHandler,
  [ResourceType.StepTemplate]: stepTemplateActionHandler,
  [ResourceType.Patient]: patientActionHandler,
  [ResourceType.Program]: programActionHandler,
  [ResourceType.ProgramTemplate]: programTemplateActionHandler,
};
