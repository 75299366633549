import { AuthEndpoint } from "enums/AuthEndpoint";
import { AuthProvider } from "locot";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { RouteView } from "root/routing/RouteView";
import "./App.scss";
import { AxiosInterceptor } from "app/configureAxiosInterceptors";
import { AppConfigProvider } from "utils/AppConfigProvider";
import { apiSaga } from "../app/store";

function App() {
  return (
    <div>
      <AppConfigProvider>
        <AuthProvider
          loginEndpoint={AuthEndpoint.Login}
          logoutEndpoint={AuthEndpoint.Logout}
          registerEndpoint={AuthEndpoint.Refresh}
        >
          <Router>
            <AxiosInterceptor axios={apiSaga.apiService.httpAdapter}>
              <RouteView />
            </AxiosInterceptor>
          </Router>
        </AuthProvider>
      </AppConfigProvider>
    </div>
  );
}

export default App;
