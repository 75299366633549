import { EntityEndpoint } from "enums/EntityEndpoint";
import { ResourceType } from "enums/ResourceType";
import { QuestionnaireJSONAModel } from "interfaces/jsona-models/QuestionnaireJSONAModel";
import { QuestionnaireSelector } from "selectors/QuestionnaireSelector";
import { AppApiActionHandler } from "./AppApiActionHandler";

class QuestionnaireActionHandler extends AppApiActionHandler<QuestionnaireJSONAModel> {
  constructor() {
    super(ResourceType.Questionnaire, EntityEndpoint.Questionnaire, new QuestionnaireSelector());
  }
}

export const questionnaireActionHandler = new QuestionnaireActionHandler();
