import { Select } from "antd";
import autobind from "autobind-decorator";
import { ResourceSelectProps } from "common/ResourceSelect/ResourceSelectProps";
import objectPath from "object-path";
import * as React from "react";
import { ResourceCollectionProvider } from "../ResourceCollectionProvider/ResourceCollectionProvider";
import { JSONAModel } from "@bornfight/aardvark/dist/interfaces/JSONAModel";

const { Option } = Select;

export class ResourceSelect<T extends JSONAModel> extends React.Component<ResourceSelectProps<T>> {
  public static defaultProps = {
    optionKeyAttributeName: "id",
  };

  public render() {
    const {
      apiActionHandler,
      onChange,
      optionValueAttributeName,
      optionKeyAttributeName,
      forwardedRef,
      ...selectProps
    } = this.props;

    return (
      <ResourceCollectionProvider
        apiActionHandler={apiActionHandler}
        render={(injectedProps) => {
          const collection = injectedProps.collection;

          // showAction attribute on Select autofocuses it on open
          // @see https://github.com/ant-design/ant-design/issues/14503#issuecomment-533478034
          return (
            <Select<string>
              ref={forwardedRef}
              loading={injectedProps.loading}
              style={{ width: "100%" }}
              onChange={onChange}
              showSearch={true}
              data-test={`resource-select`}
              showAction={["focus", "click"]}
              {...selectProps}
            >
              {this.getSelectChoices(collection)}
            </Select>
          );
        }}
      />
    );
  }

  @autobind
  private getSelectChoices(collection: T[]) {
    return collection.map((record: JSONAModel & { [key: string]: any }) => {
      const { optionValueAttributeName, optionKeyAttributeName, nestedRecordData } = this.props;

      if (nestedRecordData === true) {
        return (
          <Option
            key={objectPath.get(record, optionKeyAttributeName)}
            value={record.id}
            title={objectPath.get(record, optionValueAttributeName)}
            data-test={`resource-select-${record.type}-${record.id}`}
          >
            {objectPath.get(record, optionValueAttributeName)}
          </Option>
        );
      }

      return (
        <Option
          key={record[optionKeyAttributeName]}
          value={record.id}
          title={record[optionValueAttributeName]}
          data-test={`resource-select-${record.type}-${record.id}`}
        >
          {record[optionValueAttributeName]}
        </Option>
      );
    });
  }
}
