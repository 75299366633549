import { ExclamationCircleOutlined, SettingOutlined } from "@ant-design/icons/lib";
import { FuzzySearchType, usePatch } from "@bornfight/aardvark";
import { Button, Dropdown, Menu, Modal, Skeleton } from "antd";
import { ModalExtended } from "common/ModalExtended/ModalExtended";
import { ColumnFilterType } from "common/ResourceTable/enums/ColumnFilterType";
import { TableColumnData } from "common/ResourceTable/interfaces/TableColumnData";
import { ResourceTable } from "common/ResourceTable/ResourceTable";
import { TextContent } from "common/TextContent/TextContent";
import { ResourceType } from "enums/ResourceType";
import { AdminAddAdminForm } from "features/admin/components/AdminAddAdminForm/AdminAddAdminForm";
import styles from "features/admin/components/AdminAdminsTable/AdminAdminsTable.module.scss";
import { AdminAdminsTableColumnDataIndex } from "features/admin/components/AdminAdminsTable/enums/AdminAdminsTableColumnDataIndex";
import { AdminAdminsTableColumnTitle } from "features/admin/components/AdminAdminsTable/enums/AdminAdminsTableColumnTitle";
import { AdminAdminsTableRecord } from "features/admin/components/AdminAdminsTable/interfaces/AdminAdminsTableRecord";
import { UserRole } from "features/authentication/enums/UserRole";
import { administratorActionHandler } from "handlers/AdministratorActionHandler";
import { AdministratorJSONAModel } from "interfaces/jsona-models/AdministratorJSONAModel";
import * as React from "react";
import { FunctionComponent, useMemo, useState } from "react";
import { AppJsonApiQuery } from "utils/AppJsonApiQuery";
import { removeOverflowOnDropdownClose } from "utils/removeOverflowOnDropdownClose";

const { confirm } = Modal;
const confirmDeactivateAdminModal = (onOk: () => void, adminFullName: string) => {
  confirm({
    title: `Želite li zbilja deaktivirati administratora: ${adminFullName} ?`,
    icon: <ExclamationCircleOutlined />,
    onOk() {
      onOk();
    },
  });
};

export const AdminAdminsTable: FunctionComponent = () => {
  const [addAdminVisible, setAddAdminVisible] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [currentAdmin, setCurrentAdmin] = useState<AdministratorJSONAModel | undefined>(undefined);
  const [adminLoading, setAdminLoading] = useState<boolean>(false);
  const { update: editAdmin, loading: editAdminLoading } = usePatch(administratorActionHandler);
  const administratorJsonApiQuery = useMemo(() => {
    return new AppJsonApiQuery({});
  }, []);

  const renderOptionsColumn = (optionsData: AdministratorJSONAModel) => {
    const disabled = !optionsData.active;

    const menu = (
      <Menu>
        <Menu.Item
          key={"editAdministrator"}
          disabled={disabled}
          onClick={async () => {
            await setCurrentAdmin(optionsData);
            await setIsEditing(true);
            setAddAdminVisible(true);
          }}
        >
          Uredi
        </Menu.Item>
        <Menu.Item
          key={"deactivateAdministrator"}
          disabled={disabled}
          onClick={() => {
            const handleEditAdmin = () => {
              editAdmin(optionsData.id, {
                model: {
                  type: ResourceType.Administrator,
                  id: optionsData.id,
                  active: false,
                },
                includeNames: [],
              });
            };
            confirmDeactivateAdminModal(
              handleEditAdmin,
              `${optionsData.firstName} ${optionsData.lastName}`
            );
          }}
        >
          Deaktiviraj
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown overlay={menu} trigger={["click"]} onOpenChange={removeOverflowOnDropdownClose}>
        <div onClick={(e) => e.preventDefault()} className={styles.menuItemWidth}>
          <TextContent>
            <span className={styles.cogwheel}>
              <SettingOutlined />
            </span>
          </TextContent>
        </div>
      </Dropdown>
    );
  };

  const translateRole = (role: UserRole) => {
    switch (role) {
      case UserRole.User:
        return "Korisnik";
      case UserRole.Admin:
        return "Administrator";
    }
  };

  const renderRoleColumn = (roles: UserRole[]) => {
    if (roles.length > 1) {
      return roles.map((role, index, arr) => {
        const translatedRole = translateRole(role);
        if (arr.length - 1 === index) {
          // if last item in array don't show comma
          return <span key={index}>{translatedRole}</span>;
        } else {
          return <span key={index}>{translatedRole}, </span>;
        }
      });
    }
    return <span>{translateRole(roles[0])}</span>;
  };

  const dataSource = (collection: AdministratorJSONAModel[]): AdminAdminsTableRecord[] => {
    return (
      collection.map((admin, index) => {
        return {
          key: admin.id,
          [AdminAdminsTableColumnDataIndex.FirstName]: admin.firstName,
          [AdminAdminsTableColumnDataIndex.LastName]: admin.lastName,
          [AdminAdminsTableColumnDataIndex.Role]: admin.roles,
          [AdminAdminsTableColumnDataIndex.Email]: admin.email,
          [AdminAdminsTableColumnDataIndex.Active]: admin.active,
          [AdminAdminsTableColumnDataIndex.Options]: admin,
        };
      }) || []
    );
  };
  const columns: TableColumnData<AdminAdminsTableRecord>[] = [
    {
      title: AdminAdminsTableColumnTitle.FirstName,
      dataIndex: AdminAdminsTableColumnDataIndex.FirstName,
      key: AdminAdminsTableColumnDataIndex.FirstName,
    },
    {
      title: AdminAdminsTableColumnTitle.LastName,
      dataIndex: AdminAdminsTableColumnDataIndex.LastName,
      key: AdminAdminsTableColumnDataIndex.LastName,
      filter: {
        type: ColumnFilterType.Text,
        field: "lastName",
        fuzzySearchType: FuzzySearchType.StartWith,
      },
    },
    {
      title: AdminAdminsTableColumnTitle.Role,
      dataIndex: AdminAdminsTableColumnDataIndex.Role,
      key: AdminAdminsTableColumnDataIndex.Role,
      render: (roles: UserRole[]) => {
        return renderRoleColumn(roles);
      },
    },
    {
      title: AdminAdminsTableColumnTitle.Email,
      dataIndex: AdminAdminsTableColumnDataIndex.Email,
      key: AdminAdminsTableColumnDataIndex.Email,
    },
    {
      title: AdminAdminsTableColumnTitle.Active,
      dataIndex: AdminAdminsTableColumnDataIndex.Active,
      key: AdminAdminsTableColumnDataIndex.Active,
      render: (active: boolean) => {
        if (active) {
          return <span>Da</span>;
        }
        return <span>Ne</span>;
      },
    },
    {
      title: AdminAdminsTableColumnTitle.Options,
      dataIndex: AdminAdminsTableColumnDataIndex.Options,
      key: AdminAdminsTableColumnDataIndex.Options,
      render: (optionsData: AdministratorJSONAModel) => {
        return renderOptionsColumn(optionsData);
      },
    },
  ];
  return (
    <>
      <div className={styles.tableHeader}>
        <span className={styles.tableTitle}>Administratori</span>
        <Button type={"primary"} onClick={() => setAddAdminVisible(true)}>
          Novi administrator
        </Button>
      </div>
      {adminLoading || editAdminLoading ? (
        <Skeleton />
      ) : (
        <ResourceTable<AdminAdminsTableRecord, AdministratorJSONAModel>
          columnData={columns}
          transformToDataSource={dataSource}
          className={styles.table}
          apiActionHandler={administratorActionHandler}
          jsonApiQuery={administratorJsonApiQuery}
        />
      )}
      <ModalExtended
        title={isEditing ? "Uredi Administratora" : "Dodaj novog Administratora"}
        open={addAdminVisible}
        onCancel={() => {
          setIsEditing(false);
          setCurrentAdmin(undefined);
          setAddAdminVisible(false);
        }}
      >
        <AdminAddAdminForm
          closeModal={() => setAddAdminVisible(false)}
          currentAdmin={currentAdmin}
          isEditing={isEditing}
          setAdminLoading={setAdminLoading}
        />
      </ModalExtended>
    </>
  );
};
