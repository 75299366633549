import { useGet, useGetAll } from "@bornfight/aardvark";
import { Button, Modal } from "antd";
import { TextContent } from "common/TextContent/TextContent";
import { Questionnaire } from "features/patients/components/Questionnaire/Questionnaire";
import { StepQuestionnaireProps } from "features/patients/components/Steps/interfaces/StepQuestionnaireProps";
import { questionActionHandler } from "handlers/QuestionActionHandler";
import { questionnaireActionHandler } from "handlers/QuestionnaireActionHandler";
import * as React from "react";
import { FunctionComponent, useMemo, useState } from "react";
import { AppJsonApiQuery } from "utils/AppJsonApiQuery";
import { getUuidFromIri } from "utils/getUuidFromIri";
import { useDeepCompareMemo } from "use-deep-compare";

export const StepQuestionnaire: FunctionComponent<StepQuestionnaireProps> = (props) => {
  const { step, stepOrder } = props;
  const { stepTemplate } = step;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const completed = step.completed || Boolean(props?.isActivityCompleted);
  const disabled = props.disabledActivity;

  const questionnaireId = useMemo(() => {
    if (step && step.stepTemplate && step.stepTemplate.questionnaire) {
      return step.stepTemplate.questionnaire?.id;
    }
    return "";
  }, [step]);

  const includes = useMemo(() => {
    return ["questions"];
  }, []);

  // gets questionnaire.questions
  const { record: questionnaire } = useGet(questionnaireActionHandler, questionnaireId, includes);

  const questionIds = useMemo(() => {
    if (!questionnaire) {
      return [];
    }
    return questionnaire?.questions.map((question) => {
      return getUuidFromIri(question.id);
    });
  }, [questionnaire]);

  const questionIncludes = useMemo(() => {
    return ["answers", "questionnaireSubmission"];
  }, []);

  const questionQuery = useDeepCompareMemo(() => {
    const customParams = questionIds.map((id) => {
      return {
        name: "uuid[]",
        value: id,
      };
    });
    return new AppJsonApiQuery({
      customParams,
      includes: questionIncludes,
    });
  }, [questionIds, questionIncludes]);

  // gets question.answers
  useGetAll(questionActionHandler, questionQuery);

  return (
    <>
      <div>
        <TextContent
          strong={true}
          fontSize={16}
        >{`${stepOrder}. ${stepTemplate.name}`}</TextContent>
        <TextContent>
          <p>{stepTemplate.description}</p>
        </TextContent>
        <Button
          disabled={disabled && !completed}
          type={"primary"}
          onClick={() => setIsOpen(!isOpen)}
        >
          {completed ? "Pogledaj odgovore" : "Upitnik"}
        </Button>
      </div>
      <Modal
        title="Upitnik"
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        afterClose={() => document.body.style.removeProperty("overflow")}
        width={960}
        maskClosable={false}
        destroyOnClose={true}
        footer={null}
      >
        <Questionnaire
          completed={completed}
          data={questionnaire}
          closeModal={() => setIsOpen(false)}
          stepUuid={getUuidFromIri(step.id)}
          step={step}
          getActivities={props.getActivities}
        />
      </Modal>
    </>
  );
};
