import { useGetAllControlled } from "@bornfight/aardvark";
import { Divider, Skeleton } from "antd";
import { StepWrap } from "common/StepWrap/StepWrap";
import { ActivityStatus } from "enums/ActivityStatus";
import { StepType } from "enums/entitySpecifics/StepType";
import { ActivityFooter } from "features/patients/components/Activity/components/ActivityFooter";
import { ActivityHeader } from "features/patients/components/Activity/components/ActivityHeader";
import { ActivityProps } from "features/patients/components/Activity/interfaces/ActivityProps";
import { StepInstruction } from "features/patients/components/Steps/StepInstruction";
import { StepConversation } from "features/patients/components/Steps/StepConversation";
import { StepQuestionnaire } from "features/patients/components/Steps/StepQuestionnaire";
import { PatientProgramActivityRouteParams } from "features/patients/interfaces/PatientProgramActivityRouteParams";
import { stepTemplateActionHandler } from "handlers/StepTemplateActionHandler";
import { ActivityJSONAModel } from "interfaces/jsona-models/ActivityJSONAModel";
import { StepJSONAModel } from "interfaces/jsona-models/StepJSONAModel";
import * as React from "react";
import { FunctionComponent, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { AppJsonApiQuery } from "utils/AppJsonApiQuery";
import { getUuidFromIri } from "utils/getUuidFromIri";
import { useDeepCompareEffect, useDeepCompareMemo } from "use-deep-compare";
import { getIriFromUuid } from "utils/getIriFromUuid";

export const Activity: FunctionComponent<ActivityProps> = (props) => {
  const params = useParams<PatientProgramActivityRouteParams>();

  // rerender on url change
  useEffect(() => {}, [params.activityId]);

  const activityId = params.activityId;

  const currentActivity: ActivityJSONAModel | undefined = useMemo(() => {
    return props?.activities?.find((activity) => {
      return activity.uuid === activityId;
    });
  }, [activityId, props]);
  const isCurrentActivityInteractive = currentActivity?.status === ActivityStatus.Interactive;

  const stepTemplateIds = useMemo(() => {
    if (!currentActivity) {
      return [];
    }
    return currentActivity?.steps.map((step) => {
      return getUuidFromIri(step?.stepTemplate?.id);
    });
  }, [currentActivity]);

  const stepTemplateQuery = useDeepCompareMemo(() => {
    const customParams = stepTemplateIds.map((id) => {
      return {
        name: "uuid[]",
        value: id,
      };
    });
    return new AppJsonApiQuery({
      customParams,
      includes: ["questionnaire", "instruction", "conversation"],
    });
  }, [stepTemplateIds]);

  const { getAll, loading: stepTemplateLoading } = useGetAllControlled(
    stepTemplateActionHandler,
    stepTemplateQuery
  );

  useDeepCompareEffect(() => {
    if (stepTemplateIds.length === 0) {
      return;
    }
    getAll();
  }, [getAll, stepTemplateIds]);

  const steps: StepJSONAModel[] = currentActivity?.steps || [];
  const patientId = getIriFromUuid("patients", params.patientId);

  const stepsRenderer = (steps: StepJSONAModel[], disabledActivity: boolean) => {
    if (stepTemplateLoading) {
      return <Skeleton />;
    }
    return steps.map((step, index) => {
      const isActivityCompleted = currentActivity?.success;
      const stepProps = {
        step,
        stepOrder: index + 1,
        disabledActivity,
        isActivityCompleted,
      };
      switch (step.stepTemplate.stepType) {
        case StepType.Conversation:
          return (
            <StepWrap key={index}>
              {currentActivity && patientId && (
                <StepConversation {...stepProps} activity={currentActivity} patientId={patientId} />
              )}
            </StepWrap>
          );
        case StepType.Questionnaire:
          return (
            <StepWrap key={index}>
              <StepQuestionnaire {...stepProps} getActivities={props.getActivities} />
            </StepWrap>
          );
        case StepType.Instruction:
          return (
            <StepWrap key={index}>
              <StepInstruction {...stepProps} />
            </StepWrap>
          );
        default: {
          return null;
        }
      }
    });
  };

  const isCurrentActivityLastActivity = () => {
    if (props && props.activities && currentActivity) {
      const lastActivity = props.activities[props.activities?.length - 1];

      if (currentActivity.id === lastActivity.id) return true;
    }
    return false;
  };

  return (
    <div>
      {stepTemplateLoading ? (
        <Skeleton />
      ) : (
        <>
          <ActivityHeader
            activity={currentActivity}
            disabledActivity={!isCurrentActivityInteractive}
            getActivities={props.getActivities}
          />
          <Divider />
          {stepsRenderer(steps, !isCurrentActivityInteractive)}
          <ActivityFooter
            activity={currentActivity}
            disabledActivity={!isCurrentActivityInteractive}
            isLastActivity={isCurrentActivityLastActivity()}
            getActivities={props.getActivities}
          />
        </>
      )}
    </div>
  );
};
