import { EntityEndpoint } from "enums/EntityEndpoint";
import { ResourceType } from "enums/ResourceType";
import { PharmacistJSONAModel } from "interfaces/jsona-models/PharmacistJSONAModel";
import { PharmacistSelector } from "selectors/PharmacistSelector";
import { AppApiActionHandler } from "./AppApiActionHandler";

class PharmacistActionHandler extends AppApiActionHandler<PharmacistJSONAModel> {
  constructor() {
    super(ResourceType.Pharmacist, EntityEndpoint.Pharmacist, new PharmacistSelector());
  }
}

export const pharmacistActionHandler = new PharmacistActionHandler();
