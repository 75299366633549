import { ErrorMessageProps } from "common/ErrorMessage/interfaces/ErrorMessageProps";
import * as React from "react";
import { FunctionComponent } from "react";

export const ErrorMessage: FunctionComponent<ErrorMessageProps> = (props) => {
  return (
    <span
      style={{
        marginTop: 10,
        marginBottom: 10,
        color: "#ed2733",
      }}
    >
      {props.error}
    </span>
  );
};
