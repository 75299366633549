import * as React from "react";
import { FunctionComponent } from "react";
import placeholderImage from "static/placeholderImage.png";
import styles from "./TextImageBlock.module.scss";
import { TextContent } from "common/TextContent/TextContent";
import { TextImageBlockProps } from "../interfaces/TextImageBlockProps";

export const TextImageBlock: FunctionComponent<TextImageBlockProps> = (props) => {
  return (
    <div className={styles.wrapper}>
      <img src={placeholderImage} alt="pharmacist" />
      <div className={styles.textWrapper}>
        <TextContent fontSize={30}>
          <div className={styles.title}>{props.title}</div>
        </TextContent>
        <TextContent fontSize={14}>
          <div className={styles.subtitle}>{props.subtitle}</div>
        </TextContent>
        <TextContent fontSize={14}>
          <div className={styles.text}>{props.text}</div>
        </TextContent>
      </div>
    </div>
  );
};
