import { AddMedicineProps } from "features/patients/components/AddMedicine/AddMedicineProps";
import { AddExistingMedicineForm } from "features/patients/components/AddMedicine/components/AddExistingMedicineForm";
import { AddNewMedicineForm } from "features/patients/components/AddMedicine/components/AddNewMedicineForm";
import * as React from "react";
import { FunctionComponent, useState } from "react";

export const AddMedicine: FunctionComponent<AddMedicineProps> = (props) => {
  const [viewAddForm, setViewAddForm] = useState<boolean>(false);
  const renderAddNewMedicineForm = () => {
    return (
      <div>
        <AddNewMedicineForm setViewAddForm={setViewAddForm} closeModal={props.closeModal} />
      </div>
    );
  };

  const renderAddExistingMedicineForm = () => {
    return (
      <div>
        <AddExistingMedicineForm
          setViewAddForm={setViewAddForm}
          setPatientMedicineTableLoading={props.setPatientMedicineTableLoading}
          closeModal={props.closeModal}
          therapy={props.therapy}
        />
      </div>
    );
  };

  return <div>{viewAddForm ? renderAddNewMedicineForm() : renderAddExistingMedicineForm()}</div>;
};
