import { Button } from "antd";
import { PageHeader } from "common/PageHeader/PageHeader";
import { PageWrap } from "common/PageWrap/PageWrap";
import { Space } from "common/Space/Space";
import { RouteUrl } from "enums/RouteUrl";
import { ActivityTable } from "features/activities/components/ActivityTable";
import { ActivityTableType } from "features/activities/interfaces/ActivityTableType";
import * as React from "react";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

export const ActivitiesScreen: FunctionComponent = (props) => {
  const acitivitesArchiveButton = (
    <Button type={"ghost"}>
      <Link to={RouteUrl.ArchivedActivities}>Arhiva aktivnosti</Link>
    </Button>
  );
  return (
    <>
      <PageHeader title={"Aktivnosti"} rightComponent={acitivitesArchiveButton} />
      <PageWrap coverWhole={true}>
        <Space size={32} direction={"vertical"}>
          <ActivityTable
            key={ActivityTableType.Expired}
            type={ActivityTableType.Expired}
            title={"Prekoračen rok"}
          />
          <ActivityTable
            key={ActivityTableType.Today}
            type={ActivityTableType.Today}
            title={"Danas"}
          />
          <ActivityTable
            key={ActivityTableType.Future}
            type={ActivityTableType.Future}
            title={"Sve nadolazeće"}
            disableDateFilterPastDates={true}
          />
        </Space>
      </PageWrap>
    </>
  );
};
