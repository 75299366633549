import { SettingOutlined } from "@ant-design/icons/lib";
import { useDelete, useGetAllControlled } from "@bornfight/aardvark";
import { Button, Dropdown, Menu, Table } from "antd";
import { ModalExtended } from "common/ModalExtended/ModalExtended";
import { TextContent } from "common/TextContent/TextContent";
import { AdminAddAnswerForm } from "features/admin/components/AdminAddAnswerForm/AdminAddAnswerForm";
import { AdminAnswersTableColumnDataIndex } from "features/admin/components/AdminAnswersTable/enums/AdminAnswersTableColumnDataIndex";
import { AdminAnswersTableColumnTitle } from "features/admin/components/AdminAnswersTable/enums/AdminAnswersTableColumnTitle";
import { AdminAnswersTableProps } from "features/admin/components/AdminAnswersTable/interfaces/AdminAnswersTableProps";
import { AdminAnswersTableRecord } from "features/admin/components/AdminAnswersTable/interfaces/AdminAnswersTableRecord";
import { answerActionHandler } from "handlers/AnswerActionHandler";
import { questionActionHandler } from "handlers/QuestionActionHandler";
import { AnswerJSONAModel } from "interfaces/jsona-models/AnswerJSONAModel";
import * as React from "react";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useDeepCompareMemo } from "use-deep-compare";
import { AppJsonApiQuery } from "utils/AppJsonApiQuery";
import { getUuidFromIri } from "utils/getUuidFromIri";
import { removeOverflowOnDropdownClose } from "utils/removeOverflowOnDropdownClose";
import styles from "./AdminAnswersTable.module.scss";

export const AdminAnswersTable: FunctionComponent<AdminAnswersTableProps> = (props) => {
  const questionId = useMemo(() => {
    return getUuidFromIri(props?.currentQuestion?.id);
  }, [props]);

  const questionIncludes = useMemo(() => {
    return ["answers", "questionnaireSubmission"];
  }, []);

  const questionQuery = useDeepCompareMemo(() => {
    const customParams = [
      {
        name: "uuid[]",
        value: questionId,
      },
    ];
    return new AppJsonApiQuery({
      customParams,
      includes: questionIncludes,
    });
  }, [questionId, questionIncludes]);

  const { getAll: getQuestionAnswers, loading: getQuestionAnswersLoading } = useGetAllControlled(
    questionActionHandler,
    questionQuery
  );

  useEffect(() => {
    getQuestionAnswers();
  }, [getQuestionAnswers]);

  const [addAnswerVisible, setAddAnswerVisible] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [currentAnswer, setCurrentAnswer] = useState<AnswerJSONAModel | undefined>(undefined);

  const { deleteRecord: deleteAnswer, loading: deleteAnswerLoading } =
    useDelete(answerActionHandler);

  const handleDeleteAnswer = (questionId: string) => {
    deleteAnswer(questionId)
      .then(() => {
        getQuestionAnswers();
      })
      .catch((err) => console.log(err));
  };

  const isFirstQuestionnairePartOfProgram = useMemo(() => {
    return props?.currentQuestion?.questionnaires?.[0]?.partOfProgram;
  }, [props]);

  const renderOptionsColumn = (optionsData: AnswerJSONAModel) => {
    const menu = (
      <Menu>
        <Menu.Item
          key={"editAnswer"}
          disabled={isFirstQuestionnairePartOfProgram}
          onClick={async () => {
            await setIsEditing(true);
            await setCurrentAnswer(optionsData);
            setAddAnswerVisible(true);
          }}
        >
          Uredi
        </Menu.Item>
        <Menu.Item
          key={"deleteAnswer"}
          disabled={isFirstQuestionnairePartOfProgram}
          onClick={() => {
            handleDeleteAnswer(optionsData.id);
          }}
        >
          Izbriši
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown overlay={menu} trigger={["click"]} onOpenChange={removeOverflowOnDropdownClose}>
        <div onClick={(e) => e.preventDefault()} className={styles.menuItemWidth}>
          <TextContent>
            <span className={styles.cogwheel}>
              <SettingOutlined />
            </span>
          </TextContent>
        </div>
      </Dropdown>
    );
  };

  const dataSource: AdminAnswersTableRecord[] =
    props?.currentQuestion.answers?.map((answer, index) => {
      return {
        key: answer.id,
        [AdminAnswersTableColumnDataIndex.OrdinalNumber]: index + 1,
        [AdminAnswersTableColumnDataIndex.Answer]: answer.text,
        [AdminAnswersTableColumnDataIndex.Tip]: answer.explanation,
        [AdminAnswersTableColumnDataIndex.Options]: answer,
      };
    }) || [];
  const columns = [
    {
      title: AdminAnswersTableColumnTitle.OrdinalNumber,
      dataIndex: AdminAnswersTableColumnDataIndex.OrdinalNumber,
      key: AdminAnswersTableColumnDataIndex.OrdinalNumber,
    },
    {
      title: AdminAnswersTableColumnTitle.Answer,
      dataIndex: AdminAnswersTableColumnDataIndex.Answer,
      key: AdminAnswersTableColumnDataIndex.Answer,
    },
    {
      title: AdminAnswersTableColumnTitle.Tip,
      dataIndex: AdminAnswersTableColumnDataIndex.Tip,
      key: AdminAnswersTableColumnDataIndex.Tip,
    },
    {
      title: AdminAnswersTableColumnTitle.Options,
      dataIndex: AdminAnswersTableColumnDataIndex.Options,
      key: AdminAnswersTableColumnDataIndex.Options,
      render: (optionsData: AnswerJSONAModel) => {
        return renderOptionsColumn(optionsData);
      },
    },
  ];

  return (
    <>
      <div className={styles.tableHeader}>
        <span className={styles.tableTitle}>Odgovori:</span>
        <Button
          type={"primary"}
          onClick={() => setAddAnswerVisible(true)}
          disabled={isFirstQuestionnairePartOfProgram || getQuestionAnswersLoading}
        >
          Dodaj odgovor
        </Button>
      </div>
      <Table
        loading={deleteAnswerLoading || getQuestionAnswersLoading}
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        className={styles.table}
      />
      <ModalExtended
        title="Dodaj novi odgovor"
        open={addAnswerVisible}
        onCancel={() => {
          setIsEditing(false);
          setCurrentAnswer(undefined);
          setAddAnswerVisible(false);
        }}
      >
        <AdminAddAnswerForm
          closeModal={() => setAddAnswerVisible(false)}
          getQuestionAnswers={getQuestionAnswers}
          currentAnswer={currentAnswer}
          currentQuestion={props.currentQuestion}
          isEditing={isEditing}
        />
      </ModalExtended>
    </>
  );
};
