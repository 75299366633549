import { EntityEndpoint } from "enums/EntityEndpoint";
import { ResourceType } from "enums/ResourceType";
import { StepTemplateJSONAModel } from "interfaces/jsona-models/StepTemplateJSONAModel";
import { StepTemplateSelector } from "selectors/StepTemplateSelector";
import { AppApiActionHandler } from "./AppApiActionHandler";

class StepTemplateActionHandler extends AppApiActionHandler<StepTemplateJSONAModel> {
  constructor() {
    super(ResourceType.StepTemplate, EntityEndpoint.StepTemplate, new StepTemplateSelector());
  }
}

export const stepTemplateActionHandler = new StepTemplateActionHandler();
