import { EntityEndpoint } from "enums/EntityEndpoint";
import { ResourceType } from "enums/ResourceType";
import { ProgramTemplateJSONAModel } from "interfaces/jsona-models/ProgramTemplateJSONAModel";
import { ProgramTemplateSelector } from "selectors/ProgramTemplateSelector";
import { AppApiActionHandler } from "./AppApiActionHandler";

class ProgramTemplateActionHandler extends AppApiActionHandler<ProgramTemplateJSONAModel> {
  constructor() {
    super(
      ResourceType.ProgramTemplate,
      EntityEndpoint.ProgramTemplate,
      new ProgramTemplateSelector()
    );
  }
}

export const programTemplateActionHandler = new ProgramTemplateActionHandler();
