import { useGetAllControlled, useGetControlled, usePatch, usePost } from "@bornfight/aardvark";
import { Button, Form, Input, InputNumber, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { ResourceType } from "enums/ResourceType";
import { AdminAddProgramFormItemLabel } from "features/admin/components/AdminAddProgram/enums/AdminAddProgramFormItemLabel";
import { AdminAddProgramFormItemName } from "features/admin/components/AdminAddProgram/enums/AdminAddProgramFormItemName";
import { AdminAddProgramFormProps } from "features/admin/components/AdminAddProgram/interfaces/AdminAddProgramFormProps";
import { AdminAddProgramValues } from "features/admin/components/AdminAddProgram/interfaces/AdminAddProgramValues";
import { MedicineIdAndName } from "features/admin/components/AdminAddProgram/interfaces/MedicineIdAndName";
import { medicineActionHandler } from "handlers/MedicineActionHandler";
import { programTemplateActionHandler } from "handlers/ProgramTemplateActionHandler";
import { MedicineJSONAModel } from "interfaces/jsona-models/MedicineJSONAModel";
import { ProgramTemplateJSONAModel } from "interfaces/jsona-models/ProgramTemplateJSONAModel";
import * as React from "react";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useDeepCompareMemo } from "use-deep-compare";
import { AppJsonApiQuery } from "utils/AppJsonApiQuery";
import { removeOverflowOnDropdownClose } from "utils/removeOverflowOnDropdownClose";
import useDebounce from "utils/useDebounce";
import styles from "./AdminAddProgramForm.module.scss";

export const AdminAddProgramForm: FunctionComponent<AdminAddProgramFormProps> = (props) => {
  const { create: createProgramTemplate, loading: createProgTemplateLoading } = usePost(
    programTemplateActionHandler
  );

  const { update: editProgramTemplate, loading: editProgTemplateLoading } = usePatch(
    programTemplateActionHandler
  );

  let getSingleProgramTemplate: () => Promise<ProgramTemplateJSONAModel> | undefined;

  if (props.editingProgramTemplate) {
    const programTemplateId = useMemo(() => {
      return props.editingProgramTemplate?.id;
    }, [props.editingProgramTemplate?.id]);

    const { getSingle } = useGetControlled(
      programTemplateActionHandler,
      programTemplateId as string,
      ["activity_templates", "medicines"]
    );

    getSingleProgramTemplate = getSingle;
  }

  const [medicineName, setMedicineName] = useState<string>("");
  const debouncedSearchTerm = useDebounce(medicineName, 400);
  const customParams = useDeepCompareMemo(() => {
    return [{ name: "productName", value: debouncedSearchTerm }];
  }, [debouncedSearchTerm]);
  const medicineQuery = useDeepCompareMemo(() => {
    return new AppJsonApiQuery({
      customParams,
    });
  }, [debouncedSearchTerm]);
  const {
    getAll,
    collection: medicines,
    loading: medicinesLoading,
  } = useGetAllControlled(medicineActionHandler, medicineQuery);

  const [editingProgramMedicinesIds, setEditingProgramMedicinesIds] = useState<MedicineIdAndName[]>(
    []
  );
  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 2) {
      getAll();
    }
  }, [getAll, debouncedSearchTerm]);

  const currentEditingProgramMedicinesIds = useMemo(() => {
    return props?.editingProgramTemplate?.medicines.map((medicine) => {
      return {
        id: medicine.id,
        productName: medicine.productName,
      };
    });
  }, [props]);
  useEffect(() => {
    if (props.editMode) {
      if (
        currentEditingProgramMedicinesIds &&
        currentEditingProgramMedicinesIds.length > 0 &&
        currentEditingProgramMedicinesIds.length !== editingProgramMedicinesIds.length
      ) {
        setEditingProgramMedicinesIds(currentEditingProgramMedicinesIds);
      }
    }
  }, [currentEditingProgramMedicinesIds, editingProgramMedicinesIds.length, props.editMode]);
  const handleGetMedicines = (searchValue: string) => {
    setMedicineName(searchValue);
  };

  const renderMedicineOrHealthSupplementSelectItems = (medicines: MedicineJSONAModel[]) => {
    return medicines.map((medicine) => {
      return (
        <Select.Option key={medicine.id} value={medicine.id} title={medicine.productName}>
          {medicine.productName}
        </Select.Option>
      );
    });
  };

  const getInitialValues = (): AdminAddProgramValues => {
    const progTemplate = props.editingProgramTemplate;
    const medicineIds =
      progTemplate?.medicines.map((medicine) => {
        return medicine.productName;
      }) || [];
    return {
      [AdminAddProgramFormItemName.Name]: progTemplate?.name || "",
      [AdminAddProgramFormItemName.Description]: progTemplate?.description || "",
      [AdminAddProgramFormItemName.DropoutTimeInDays]: progTemplate?.dropoutThreshold || 0,
      [AdminAddProgramFormItemName.MedicineOrHealthSupplement]: medicineIds,
    };
  };

  const getAllMedicineIdObjects = (values: AdminAddProgramValues) => {
    const editingProgramMedicines = editingProgramMedicinesIds.map((medicineIdAndName) => {
      const editingProgramMedicine = values[
        AdminAddProgramFormItemName.MedicineOrHealthSupplement
      ].find((medicineName) => {
        return medicineName === medicineIdAndName.productName;
      });
      if (editingProgramMedicine) {
        return medicineIdAndName.id;
      }
      return undefined;
    });
    editingProgramMedicines.filter((medicineIds) => {
      return medicineIds !== undefined;
    });
    const newMedicineIds = values[AdminAddProgramFormItemName.MedicineOrHealthSupplement].filter(
      (medicineName) => {
        return medicineName.startsWith("/");
      }
    );
    const allMedicines = [...editingProgramMedicines, ...newMedicineIds];
    const allMedicinesFiltered: string[] = allMedicines.filter((medicine): medicine is string => {
      return medicine !== undefined;
    });
    return allMedicinesFiltered.map((medicineId) => {
      return {
        type: ResourceType.Medicine,
        id: medicineId,
      };
    });
  };

  const handleSubmit = (values: AdminAddProgramValues) => {
    if (props.editMode) {
      const allMedicineIdsObjects = getAllMedicineIdObjects(values);

      const editingProgTemplate = props.editingProgramTemplate;
      const dropoutThresholdNumber = Number(values[AdminAddProgramFormItemName.DropoutTimeInDays]);

      if (editingProgTemplate)
        editProgramTemplate(editingProgTemplate.id, {
          model: {
            type: ResourceType.ProgramTemplate,
            id: editingProgTemplate.id,
            name: values[AdminAddProgramFormItemName.Name],
            description: values[AdminAddProgramFormItemName.Description],
            dropoutThreshold: dropoutThresholdNumber,
            medicines: allMedicineIdsObjects as MedicineJSONAModel[],
          },
          includeNames: ["medicines"],
        }).then(() => {
          if (getSingleProgramTemplate) {
            getSingleProgramTemplate();

            if (props.closeModal) {
              props.closeModal();
            }
          }
        });
      return;
    }

    const medicineCollection = values[AdminAddProgramFormItemName.MedicineOrHealthSupplement].map(
      (medicineId) => {
        return {
          type: ResourceType.Medicine,
          id: medicineId,
        };
      }
    );

    createProgramTemplate({
      rawData: {
        data: {
          attributes: {
            name: values[AdminAddProgramFormItemName.Name],
            description: values[AdminAddProgramFormItemName.Description],
            dropoutThreshold: values[AdminAddProgramFormItemName.DropoutTimeInDays],
            enabled: true,
          },
          relationships: { medicines: medicineCollection },
        },
      },
    }).then(() => {
      if (props.fetchProgramTemplates) {
        props.fetchProgramTemplates();
        if (props.closeModal) {
          props.closeModal();
        }
      }
    });
  };

  return (
    <Form
      id={"addAdminProgramForm"}
      layout={"vertical"}
      onFinish={(values) => {
        handleSubmit(values as unknown as AdminAddProgramValues);
      }}
      initialValues={props.editMode ? getInitialValues() : undefined}
    >
      <Form.Item
        label={AdminAddProgramFormItemLabel.Name}
        name={AdminAddProgramFormItemName.Name}
        rules={[
          {
            required: true,
            message: "Ovo polje je obavezno.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={AdminAddProgramFormItemLabel.Description}
        name={AdminAddProgramFormItemName.Description}
        rules={[
          {
            required: true,
            message: "Ovo polje je obavezno.",
          },
        ]}
      >
        <TextArea rows={5} />
      </Form.Item>
      <Form.Item
        label={AdminAddProgramFormItemLabel.MedicineOrHealthSupplement}
        name={AdminAddProgramFormItemName.MedicineOrHealthSupplement}
        rules={[{ required: true, message: "Obavezno polje" }]}
      >
        <Select
          onDropdownVisibleChange={removeOverflowOnDropdownClose}
          getPopupContainer={() => {
            const editProgramForm = document.getElementById("addAdminProgramForm");
            if (!editProgramForm) {
              return document.body;
            }
            return editProgramForm;
          }}
          mode={"multiple"}
          optionFilterProp={"title"}
          showSearch={true}
          onSearch={handleGetMedicines}
          loading={medicinesLoading}
          showAction={["focus", "click"]}
        >
          {renderMedicineOrHealthSupplementSelectItems(medicines)}
        </Select>
      </Form.Item>
      <Form.Item
        label={AdminAddProgramFormItemLabel.DropoutTimeInDays}
        name={AdminAddProgramFormItemName.DropoutTimeInDays}
        rules={[
          {
            required: true,
            message: "Ovo polje mora sadržavati broj.",
            type: "number",
          },
        ]}
      >
        <InputNumber style={{ width: "100%" }} />
      </Form.Item>
      <div className={styles.submitWrap}>
        <Button
          type={"primary"}
          htmlType={"submit"}
          loading={createProgTemplateLoading || editProgTemplateLoading}
        >
          Spremi
        </Button>
      </div>
    </Form>
  );
};
