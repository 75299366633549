import { AxiosInstance } from "axios";
import { RouteUrl } from "enums/RouteUrl";
import { TokenData } from "features/authentication/interfaces/TokenData";
import jwt_decode from "jwt-decode";
import { useAuth } from "locot";
import moment from "moment";
import * as React from "react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const getTokenTimeToLive = (token: string | null): number => {
  if (token) {
    const tokenData: TokenData = jwt_decode(token);
    const tokenExpireDateTime = moment(new Date(tokenData.exp * 1000));
    const currentDateTime = moment();

    const secondsUntilExpiry = tokenExpireDateTime.diff(currentDateTime, "seconds");
    return secondsUntilExpiry;
  }
  return 0;
};

interface Interceptors {
  requestInterceptor: number;
  responseInterceptor: number;
}

export const configureAxiosInterceptors = (
  axiosInstance: AxiosInstance,
  {
    onUnauthorized,
    onRefresh,
  }: {
    onUnauthorized: () => void;
    onRefresh: () => Promise<any>;
  }
): Interceptors => {
  const requestInterceptor = axiosInstance.interceptors.request.use(async (config) => {
    const accessToken = localStorage.getItem("token");
    const tokenSecondsUntilExpiry = getTokenTimeToLive(accessToken);

    if (tokenSecondsUntilExpiry < 300 && tokenSecondsUntilExpiry > 10) {
      await onRefresh();
    }

    return {
      ...config,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/vnd.api+json",
        ...config.headers,
      },
    };
  });

  const responseInterceptor = axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.data?.code === 401 && onUnauthorized) {
        onUnauthorized();
      }
      return Promise.reject(error);
    }
  );

  return {
    requestInterceptor,
    responseInterceptor,
  };
};

interface AxiosInterceptorProps {
  axios: AxiosInstance;
}

interface ConfigureAxiosInterceptorsProviderProps {
  onUnauthorized: () => void;
  onRefresh: () => Promise<any>;
}

class ConfigureAxiosInterceptorsProvider extends React.Component<
  AxiosInterceptorProps & ConfigureAxiosInterceptorsProviderProps
> {
  private interceptors: Interceptors | undefined;
  constructor(props: AxiosInterceptorProps & ConfigureAxiosInterceptorsProviderProps) {
    super(props);
    this.ejectInterceptors();
  }

  componentDidMount() {
    this.ejectInterceptors();
    this.interceptors = configureAxiosInterceptors(this.props.axios, {
      onUnauthorized: () => {
        this.props.onUnauthorized();
      },
      onRefresh: () => {
        return this.props.onRefresh();
      },
    });
  }

  public render() {
    return <>{this.props.children}</>;
  }

  public componentWillUnmount() {
    this.ejectInterceptors();
  }

  private ejectInterceptors() {
    if (this.interceptors?.requestInterceptor) {
      this.props.axios.interceptors.request.eject(this.interceptors.requestInterceptor);
    }

    if (this.interceptors?.responseInterceptor) {
      this.props.axios.interceptors.response.eject(this.interceptors.responseInterceptor);
    }
  }
}

export const withAuth = (
  Component: React.ComponentType<AxiosInterceptorProps & ConfigureAxiosInterceptorsProviderProps>
) => {
  return (props: React.PropsWithChildren<AxiosInterceptorProps>) => {
    const navigate = useNavigate();
    const authContext = useAuth();

    const onUnauthorized = useCallback(async () => {
      await localStorage.clear();
      await authContext.logout();
      navigate(RouteUrl.Login);
    }, [authContext, history]);

    const onRefresh = useCallback(() => {
      return authContext.refresh();
    }, [authContext]);

    return <Component onUnauthorized={onUnauthorized} onRefresh={onRefresh} {...props} />;
  };
};

export const AxiosInterceptor = withAuth(ConfigureAxiosInterceptorsProvider);
