/**
 * Order is important
 */
export const defaultDateFormats = [
  "DD.MM.YYYY.",
  "DD.MM.YYYY",
  "DDMMYYYY",
  "DDMMYY",
  "D.M.YYYY",
  "DD.M.YYYY",
  "D.MM.YYYY",
];
