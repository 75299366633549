import { UserOutlined } from "@ant-design/icons/lib";
import { Dropdown, Menu } from "antd";
import { apiSaga } from "app/store";
import { TokenData } from "features/authentication/interfaces/TokenData";
import jwt_decode from "jwt-decode";
import { useAuth } from "locot";
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { removeOverflowOnDropdownClose } from "utils/removeOverflowOnDropdownClose";
import styles from "./UserStrip.module.scss";

export const UserStrip: FunctionComponent = () => {
  const [username, updateUsername] = useState("");
  const auth = useAuth();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const tokenData: TokenData = jwt_decode(token);
      updateUsername(tokenData.email);
    }
  }, []);

  const requestPasswordReset = () => {
    apiSaga.apiService.httpAdapter.post(
      `${process.env.REACT_APP_API_URL}users/request-reset`,
      {
        email: `${username}`,
      },
      { headers: { "Content-Type": "application/json\n" } }
    );
  };

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          requestPasswordReset();
        }}
      >
        <span>Zatraži novu lozinku</span>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          auth.logout();
        }}
      >
        <span>Odjavi se</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]} onOpenChange={removeOverflowOnDropdownClose}>
      <div className={styles.wrap}>
        <div className={styles["user-image-wrap"]}>
          <UserOutlined style={{ color: "white" }} />
        </div>
        <p>{username}</p>
      </div>
    </Dropdown>
  );
};
