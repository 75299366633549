import { Menu } from "antd";
import styles from "features/patients/components/ActivitiesMenu/ActivitiesMenu.module.scss";
import { PatientMenuItem } from "features/patients/components/PatientMenu/enums/PatientMenuItem";
import { PatientMenuProps } from "features/patients/components/PatientMenu/interfaces/PatientMenuProps";
import * as React from "react";
import { FunctionComponent } from "react";

const MenuItem = Menu.Item;

export const PatientMenu: FunctionComponent<PatientMenuProps> = (props) => {
  const menuItems = [PatientMenuItem.Info, PatientMenuItem.Programs];

  return (
    <Menu
      className={styles.menu}
      mode={"inline"}
      theme={"light"}
      selectedKeys={[props.selectedKey]}
    >
      {menuItems.map((menuItem) => {
        return (
          <MenuItem
            data-test={"patient-menu-item"}
            onClick={() => {
              props.setSelectedKey(menuItem);
            }}
            key={menuItem}
          >
            {menuItem}
          </MenuItem>
        );
      })}
    </Menu>
  );
};
