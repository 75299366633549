import { IntakeRegime } from "interfaces/jsona-models/TherapyJSONAModel";

export const intakeRegimeCollection = [
  IntakeRegime.OncePerDay,
  IntakeRegime.TwicePerDay,
  IntakeRegime.ThreeTimesPerDay,
  IntakeRegime.OncePerWeek,
  IntakeRegime.OncePerMonth,
  IntakeRegime.AsNeeded,
];
