import * as React from "react";
import { ChatMessageList } from "features/chat/ChatMessageList";
import { useGet } from "@bornfight/aardvark";
import { chatActionHandler } from "handlers/ChatActionHandler";
import { ChatMessageMapper } from "mappers/ChatMessageMapper";
import { useMemo } from "react";
import { Skeleton } from "antd";

interface Props {
  chatId: string;
}
const chatMessageMapper = new ChatMessageMapper();

export const FinishedConversation: React.FunctionComponent<
  React.HTMLAttributes<HTMLElement> & Props
> = ({ chatId }) => {
  const includes = useMemo(() => {
    return ["chatMessages"];
  }, []);

  const { record, loading } = useGet(chatActionHandler, chatId, includes);
  const messages = useMemo(() => {
    if (!record || !record.chatMessages) {
      return [];
    }

    return record?.chatMessages?.map(chatMessageMapper.fromChatMessageJSONAModel);
  }, [record]);

  if (loading) {
    return <Skeleton className={"js-test-finished-conversation-skeleton"} active={true} />;
  }
  return <ChatMessageList showBorderBottom={true} messages={messages} />;
};
