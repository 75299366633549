import { SettingOutlined } from "@ant-design/icons/lib";
import { useDelete } from "@bornfight/aardvark";
import { Button, Dropdown, Menu, Skeleton, Table } from "antd";
import { ModalExtended } from "common/ModalExtended/ModalExtended";
import { TextContent } from "common/TextContent/TextContent";
import { AdminAddQuestionForm } from "features/admin/components/AdminAddQuestionForm/AdminAddQuestionForm";
import { AdminAnswersTable } from "features/admin/components/AdminAnswersTable/AdminAnswersTable";
import { AdminQuestionsTableColumnDataIndex } from "features/admin/components/AdminQuestionsTable/enums/AdminQuestionsTableColumnDataIndex";
import { AdminQuestionsTableColumnTitle } from "features/admin/components/AdminQuestionsTable/enums/AdminQuestionsTableColumnTitle";
import { AdminQuestionsTableProps } from "features/admin/components/AdminQuestionsTable/interface/AdminQuestionsTableProps";
import { AdminQuestionsTableRecord } from "features/admin/components/AdminQuestionsTable/interface/AdminQuestionsTableRecord";
import { questionActionHandler } from "handlers/QuestionActionHandler";
import { QuestionJSONAModel } from "interfaces/jsona-models/QuestionJSONAModel";
import * as React from "react";
import { FunctionComponent, useMemo, useState } from "react";
import { removeOverflowOnDropdownClose } from "utils/removeOverflowOnDropdownClose";
import styles from "./AdminQuestionsTable.module.scss";

export const AdminQuestionsTable: FunctionComponent<AdminQuestionsTableProps> = (props) => {
  const [addQuestionVisible, setAddQuestionVisible] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [currentQuestion, setCurrentQuestion] = useState<QuestionJSONAModel | undefined>(undefined);

  const { deleteRecord: deleteQuestion, loading: deleteQuestionLoading } =
    useDelete(questionActionHandler);

  const handleDeleteQuestion = (questionId: string) => {
    deleteQuestion(questionId)
      .then(() => {
        props.getQuestionnaire();
      })
      .catch((err) => console.log(err));
  };

  const isFirstQuestionnairePartOfProgram = useMemo(() => {
    return props.partOfProgram;
  }, [props]);

  const renderOptionsColumn = (optionsData: QuestionJSONAModel) => {
    const menu = (
      <Menu>
        <Menu.Item
          disabled={isFirstQuestionnairePartOfProgram}
          key={"editQuestion"}
          onClick={async () => {
            await setIsEditing(true);
            await setCurrentQuestion(optionsData);
            setAddQuestionVisible(true);
          }}
        >
          Uredi
        </Menu.Item>
        <Menu.Item
          disabled={isFirstQuestionnairePartOfProgram}
          key={"deleteQuestion"}
          onClick={() => {
            handleDeleteQuestion(optionsData.id);
          }}
        >
          Izbriši
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown overlay={menu} trigger={["click"]} onOpenChange={removeOverflowOnDropdownClose}>
        <div onClick={(e) => e.preventDefault()} className={styles.menuItemWidth}>
          <TextContent>
            <span className={styles.cogwheel}>
              <SettingOutlined />
            </span>
          </TextContent>
        </div>
      </Dropdown>
    );
  };
  const dataSource: AdminQuestionsTableRecord[] =
    props?.questions?.map((question, index) => {
      return {
        key: question.id,
        [AdminQuestionsTableColumnDataIndex.OrdinalNumber]: index + 1,
        [AdminQuestionsTableColumnDataIndex.Question]: question?.text || "",
        [AdminQuestionsTableColumnDataIndex.Options]: question,
      };
    }) || [];
  const columns = [
    {
      title: AdminQuestionsTableColumnTitle.OrdinalNumber,
      dataIndex: AdminQuestionsTableColumnDataIndex.OrdinalNumber,
      key: AdminQuestionsTableColumnDataIndex.OrdinalNumber,
    },
    {
      title: AdminQuestionsTableColumnTitle.Question,
      dataIndex: AdminQuestionsTableColumnDataIndex.Question,
      key: AdminQuestionsTableColumnDataIndex.Question,
    },
    {
      title: AdminQuestionsTableColumnTitle.Options,
      dataIndex: AdminQuestionsTableColumnDataIndex.Options,
      key: AdminQuestionsTableColumnDataIndex.Options,
      render: (optionsData: QuestionJSONAModel) => {
        return renderOptionsColumn(optionsData);
      },
    },
  ];
  return (
    <>
      <div className={styles.tableHeader}>
        <span className={styles.tableTitle}>Pitanja</span>
        <Button
          type={"primary"}
          onClick={() => setAddQuestionVisible(true)}
          disabled={isFirstQuestionnairePartOfProgram}
        >
          Dodaj pitanje
        </Button>
      </div>
      {deleteQuestionLoading ? (
        <Skeleton />
      ) : (
        <Table
          pagination={false}
          dataSource={dataSource}
          columns={columns}
          className={styles.table}
          expandedRowRender={(row: AdminQuestionsTableRecord) => {
            return (
              <AdminAnswersTable
                currentQuestion={row[AdminQuestionsTableColumnDataIndex.Options]}
              />
            );
          }}
        />
      )}

      <ModalExtended
        title="Dodaj novo pitanje"
        open={addQuestionVisible}
        onCancel={() => {
          setIsEditing(false);
          setCurrentQuestion(undefined);
          setAddQuestionVisible(false);
        }}
      >
        <AdminAddQuestionForm
          closeModal={() => setAddQuestionVisible(false)}
          getQuestionnaire={props.getQuestionnaire}
          currentQuestion={currentQuestion}
          isEditing={isEditing}
        />
      </ModalExtended>
    </>
  );
};
