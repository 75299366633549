import { JSONAModel } from "@bornfight/aardvark/dist/interfaces/JSONAModel";
import { StepTemplateJSONAModel } from "interfaces/jsona-models/StepTemplateJSONAModel";
import { UuidAttribute } from "interfaces/UuidAttribute";

export type ActivityTemplateJSONAModel = JSONAModel<ActivityTemplate, Relationships>;

export enum RecommendedChannel {
  Survey = "survey",
  PhoneCall = "phone_call",
  Message = "message",
  VideoCall = "video_call",
  Live = "live",
}

interface ActivityTemplate extends UuidAttribute {
  name: string;
  dayOffset: number;
  recommendedChannels: RecommendedChannel[];
  programTemplate: string;
}

interface Relationships {
  stepTemplates: StepTemplateJSONAModel[];
}
