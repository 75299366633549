export enum PatientFormField {
  FirstName = "firstName",
  LastName = "lastName",
  MobilePhoneNumber = "mobilePhoneNumber",
  TelephoneNumber = "telephoneNumber",
  Birthday = "birthday",
  Gender = "gender",
  Height = "height",
  Weight = "weight",
  Smoking = "smoking",
  Alcohol = "alcohol",
  Allergies = "alergiesNote",
  DiseasesDiagnosis = "diseasesDiagnosisNote",
  GdprConsent = "gdprConsent",
}
