import { ModalExtended } from "common/ModalExtended/ModalExtended";
import { AdminAddProgramForm } from "features/admin/components/AdminAddProgram/AdminAddProgramForm";
import { AdminAddProgramModalProps } from "features/admin/components/AdminAddProgram/interfaces/AdminAddProgramModalProps";
import * as React from "react";
import { FunctionComponent } from "react";

export const AdminAddProgramModal: FunctionComponent<AdminAddProgramModalProps> = (props) => {
  return (
    <ModalExtended
      title="Novi program praćenja"
      open={props.visible}
      onCancel={() => {
        if (props.onClose) {
          props.onClose();
        }
        props.setVisible(false);
      }}
    >
      <AdminAddProgramForm
        editMode={props.editMode}
        editingProgramTemplate={props.editingProgramTemplate}
        fetchProgramTemplates={props.fetchProgramTemplates}
        closeModal={() => props.setVisible(false)}
      />
    </ModalExtended>
  );
};
