import { useGetAllControlled } from "@bornfight/aardvark";
import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { AdminAddProgramModal } from "features/admin/components/AdminAddProgram/AdminAddProgramModal";
import { AdminProgramOptionsMenu } from "features/admin/components/AdminProgramTable/AdminProgramOptionsMenu";
import { AdminProgramTableColumnDataIndex } from "features/admin/components/AdminProgramTable/enums/AdminProgramTableColumnDataIndex";
import { AdminProgramTableColumnTitle } from "features/admin/components/AdminProgramTable/enums/AdminProgramTableColumnTitle";
import { AdminProgramTableProps } from "features/admin/components/AdminProgramTable/interfaces/AdminProgramTableProps";
import { AdminProgramTableRecord } from "features/admin/components/AdminProgramTable/interfaces/AdminProgramTableRecord";
import { programTemplateActionHandler } from "handlers/ProgramTemplateActionHandler";
import { ProgramTemplateJSONAModel } from "interfaces/jsona-models/ProgramTemplateJSONAModel";
import * as React from "react";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { AppJsonApiQuery } from "utils/AppJsonApiQuery";
import styles from "./AdminProgramTable.module.scss";

export const AdminProgramTable: FunctionComponent<AdminProgramTableProps> = (props) => {
  const [addProgramVisible, setAddProgramVisible] = useState<boolean>(false);
  const [isEditingProgram, setIsEditingProgram] = useState<boolean>(false);
  const [editingProgramTemplate, setEditingProgramTemplate] = useState<
    ProgramTemplateJSONAModel | undefined
  >(undefined);

  const [tableLoading, setTableLoading] = useState<boolean>(props?.loading || true);

  const programTemplatesJsonApiQuery = useMemo(() => {
    const includes = ["programs", "activityTemplates", "medicines"];
    return new AppJsonApiQuery({
      includes,
      customParams: [],
    });
  }, []);
  let {
    collection: programTemplates,
    loading: programTemplatesLoading,
    getAll: getAllProgramTemplates,
  } = useGetAllControlled(programTemplateActionHandler, programTemplatesJsonApiQuery);

  useEffect(() => {
    if (!props.preventDataFetch) {
      getAllProgramTemplates();
    }
  }, [props.preventDataFetch, getAllProgramTemplates]);

  useEffect(() => {
    setTableLoading(programTemplatesLoading);
  }, [programTemplatesLoading]);

  programTemplates = useMemo(() => {
    return programTemplates;
  }, [programTemplates]);

  if (props.providedProgTemplateData) {
    programTemplates = [props.providedProgTemplateData];
  }

  const dataSource: AdminProgramTableRecord[] = programTemplates?.map((programTemplate) => {
    return {
      key: programTemplate.id,
      [AdminProgramTableColumnDataIndex.Name]: programTemplate.name,
      [AdminProgramTableColumnDataIndex.Description]: programTemplate.description || "Nema opisa",
      [AdminProgramTableColumnDataIndex.DropOut]: programTemplate.dropoutThreshold,
      [AdminProgramTableColumnDataIndex.Status]: programTemplate.enabled,
      [AdminProgramTableColumnDataIndex.Link]: programTemplate.uuid,
      [AdminProgramTableColumnDataIndex.Options]: programTemplate,
    };
  });
  const columns: ColumnsType<AdminProgramTableRecord> = [
    {
      title: AdminProgramTableColumnTitle.Name,
      dataIndex: AdminProgramTableColumnDataIndex.Name,
      key: AdminProgramTableColumnDataIndex.Name,
    },
    {
      title: AdminProgramTableColumnTitle.Description,
      dataIndex: AdminProgramTableColumnDataIndex.Description,
      key: AdminProgramTableColumnDataIndex.Description,
    },
    {
      title: AdminProgramTableColumnTitle.DropOut,
      dataIndex: AdminProgramTableColumnDataIndex.DropOut,
      key: AdminProgramTableColumnDataIndex.DropOut,
      render: (dropOutDays: number) => {
        if (dropOutDays > 1) {
          return `${dropOutDays} dana`;
        }
        return `${dropOutDays} dan`;
      },
    },
    {
      title: AdminProgramTableColumnTitle.Status,
      dataIndex: AdminProgramTableColumnDataIndex.Status,
      key: AdminProgramTableColumnDataIndex.Status,
      render: (enabled: boolean) => {
        return enabled ? "Aktivan" : "Neaktivan";
      },
    },
    props.showLink === true
      ? {
          title: AdminProgramTableColumnTitle.Link,
          dataIndex: AdminProgramTableColumnDataIndex.Link,
          key: AdminProgramTableColumnDataIndex.Link,
          render: (programTemplateUuid: string) => {
            return (
              <Link to={`/admin/program-templates/${programTemplateUuid}`}>Idi na Program</Link>
            );
          },
        }
      : {},
    {
      title: AdminProgramTableColumnTitle.Options,
      dataIndex: AdminProgramTableColumnDataIndex.Options,
      key: AdminProgramTableColumnDataIndex.Options,
      render: (optionsData: ProgramTemplateJSONAModel) => {
        return (
          <AdminProgramOptionsMenu
            optionsData={optionsData}
            setStateActions={{
              setAddProgramVisible,
              setIsEditingProgram,
              setTableLoading,
              setEditingProgramTemplate,
            }}
          />
        );
      },
    },
  ];

  const preventAddButtonRender = props.preventDataFetch;
  return (
    <>
      <div className={styles.tableHeader}>
        <span className={styles.tableTitle}>
          {props.title ? props.title : "Programi praćenja terapije"}
        </span>
        {preventAddButtonRender ? null : (
          <Button type={"primary"} onClick={() => setAddProgramVisible(true)}>
            Dodaj novi
          </Button>
        )}
      </div>
      <Table
        pagination={props.preventDataFetch ? false : undefined}
        loading={tableLoading}
        dataSource={dataSource}
        columns={columns}
        className={styles.table}
      />
      <AdminAddProgramModal
        visible={addProgramVisible}
        setVisible={setAddProgramVisible}
        onClose={() => {
          setIsEditingProgram(false);
        }}
        editMode={isEditingProgram}
        editingProgramTemplate={editingProgramTemplate}
        fetchProgramTemplates={getAllProgramTemplates}
      />
    </>
  );
};

AdminProgramTable.defaultProps = {
  ...AdminProgramTable.defaultProps,
  showLink: true,
};
