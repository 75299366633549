/**
 * Resource type enum keys must be singular!
 */
export enum ResourceType {
  Step = "step",
  StepTemplate = "stepTemplates",
  Activity = "activity",
  ActivityTemplate = "activityTemplate",
  Patient = "patient",
  ProgramComment = "programComment",
  Program = "program",
  ProgramTemplate = "programTemplate",
  Chat = "chat",
  ChatMessage = "chatMessage",
  Consent = "consent",
  Conversation = "conversation",
  PatientHealthData = "patientHealthData",
  Question = "question",
  Answer = "answer",
  Questionnaire = "questionnaire",
  Therapy = "therapy",
  Medicine = "medicine",
  User = "user",
  Instruction = "instruction",
  Administrator = "administrator",
  Pharmacist = "pharmacist",
  Pharmacy = "pharmacy",
}
