import { SettingOutlined } from "@ant-design/icons";
import { useGetControlled } from "@bornfight/aardvark";
import { Dropdown, Menu } from "antd";
import { apiSaga } from "app/store";
import { TextContent } from "common/TextContent/TextContent";
import styles from "features/admin/components/AdminProgramTable/AdminProgramTable.module.scss";
import { programTemplateActionHandler } from "handlers/ProgramTemplateActionHandler";
import { ProgramTemplateJSONAModel } from "interfaces/jsona-models/ProgramTemplateJSONAModel";
import * as React from "react";
import { Dispatch, FunctionComponent, SetStateAction, useEffect } from "react";
import { removeOverflowOnDropdownClose } from "utils/removeOverflowOnDropdownClose";

interface AdminProgramOptionsMenuProps {
  optionsData: ProgramTemplateJSONAModel;
  setStateActions: {
    setAddProgramVisible: Dispatch<SetStateAction<boolean>>;
    setIsEditingProgram: Dispatch<SetStateAction<boolean>>;
    setTableLoading: Dispatch<SetStateAction<boolean>>;
    setEditingProgramTemplate: Dispatch<SetStateAction<ProgramTemplateJSONAModel | undefined>>;
  };
}

export const AdminProgramOptionsMenu: FunctionComponent<AdminProgramOptionsMenuProps> = ({
  optionsData,
  setStateActions,
}) => {
  const { getSingle, loading } = useGetControlled(programTemplateActionHandler, optionsData.id, [
    "activity_templates",
    "medicines",
  ]);

  useEffect(() => {
    setStateActions.setTableLoading(false);
  }, [loading]);

  const editProgramTemplateEnabled = (progTemplateUuid: string, enabled: boolean) => {
    setStateActions.setTableLoading(true);

    apiSaga.apiService.httpAdapter
      .patch(
        `${process.env.REACT_APP_API_URL}program_templates/${progTemplateUuid}/toggle-availability`,
        {
          data: {
            attributes: {
              enabled,
            },
          },
        }
      )
      .then(() => {
        getSingle();
      });
  };

  const handleDeactivate = (editingProgramTemplate: ProgramTemplateJSONAModel) => {
    if (editingProgramTemplate) {
      editProgramTemplateEnabled(editingProgramTemplate.uuid, false);
      return;
    }
  };

  const handleActivate = (editingProgramTemplate: ProgramTemplateJSONAModel) => {
    if (editingProgramTemplate) {
      editProgramTemplateEnabled(editingProgramTemplate.uuid, true);
      return;
    }
  };

  const disabled = optionsData?.programs?.length > 0;
  const hasSingleActivityTemplate = optionsData?.activityTemplates?.length > 0;
  const hasSingleStepTemplate = optionsData?.activityTemplates?.some((activityTemplate) => {
    return activityTemplate?.stepTemplates?.length > 0;
  });
  const menu = (
    <Menu>
      <Menu.Item
        key={"editProgram"}
        disabled={disabled}
        onClick={async () => {
          await setStateActions.setIsEditingProgram(true);
          await setStateActions.setEditingProgramTemplate(optionsData);
          setStateActions.setAddProgramVisible(true);
        }}
      >
        Uredi
      </Menu.Item>
      {optionsData.enabled ? (
        <Menu.Item
          key={"deactivate"}
          onClick={() => {
            handleDeactivate(optionsData);
          }}
        >
          Deaktiviraj
        </Menu.Item>
      ) : (
        <Menu.Item
          key={"activate"}
          onClick={() => {
            handleActivate(optionsData);
          }}
          disabled={!hasSingleActivityTemplate || !hasSingleStepTemplate}
        >
          Aktiviraj
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]} onOpenChange={removeOverflowOnDropdownClose}>
      <div onClick={(e) => e.preventDefault()} className={styles.menuItemWidth}>
        <TextContent>
          <span className={styles.cogwheel}>
            <SettingOutlined />
          </span>
        </TextContent>
      </div>
    </Dropdown>
  );
};
