import { usePatch } from "@bornfight/aardvark";
import { Col, notification, Row } from "antd";
import { DatePicker } from "common/DatePicker/DatePicker";
import { TextContent } from "common/TextContent/TextContent";
import { DateTimeFormat } from "enums/DateTimeFormat";
import { ResourceType } from "enums/ResourceType";
import { ActivityHeaderProps } from "features/patients/components/Activity/interfaces/ActivityHeaderProps";
import { activityActionHandler } from "handlers/ActivityActionHandler";
import { RecommendedChannel } from "interfaces/jsona-models/ActivityTemplateJSONAModel";
import moment, { Moment } from "moment";
import * as React from "react";
import { FunctionComponent, useMemo } from "react";

const onDateChangeNotification = () => {
  notification["info"]({
    message: "Datum aktivnosti je promijenjen",
    description:
      "Ovom promjenom automatski se pomiču datumi svih budućih Aktivnosti za isti period.",
  });
};

export const ActivityHeader: FunctionComponent<ActivityHeaderProps> = (props) => {
  const activity = props.activity;
  const activityName = activity?.activityTemplate.name;
  const datePickerDefaultValue = useMemo(() => {
    if (props?.activity?.expectedDate) {
      return moment(props?.activity.expectedDate);
    }
    return;
  }, [props]);

  const { update: updateActivity, loading } = usePatch(activityActionHandler);

  const completed = activity?.success;
  const disabled = Boolean(activity?.actualDate) || props.disabledActivity;

  const renderChannelColumn = (channels: RecommendedChannel[] | undefined) => {
    if (channels === undefined) {
      return;
    }
    const translatedChannels = channels.map((channel) => {
      let channelName = "";
      switch (channel) {
        case RecommendedChannel.Survey:
          return (channelName = "Upitnik");
        case RecommendedChannel.PhoneCall:
          return (channelName = "Telefonski poziv");
        case RecommendedChannel.Message:
          return (channelName = "SMS");
        case RecommendedChannel.VideoCall:
          return (channelName = "Video poziv");
        case RecommendedChannel.Live:
          return (channelName = "Uživo");
      }
      return channelName;
    });
    const channelSpans = translatedChannels.map((translatedChannel, index, arr) => {
      if (arr.length - 1 === index) {
        // if last item in array don't show comma
        return <span key={index}>{translatedChannel}</span>;
      } else {
        return <span key={index}>{translatedChannel}, </span>;
      }
    });

    return <div>{channelSpans}</div>;
  };

  const handleDateChange = (dateString: string) => {
    const activityId = activity?.id || "";
    const data = {
      model: {
        type: ResourceType.Activity,
        id: activityId,
        expectedDate: dateString,
      },
    };
    if (activityId !== "") {
      updateActivity(activityId, {
        model: data.model,
        includeNames: [],
      }).then(() => {
        onDateChangeNotification();
        props.getActivities && props.getActivities();
      });
    }
  };

  return (
    <>
      <Row>
        <TextContent strong={true} fontSize={16}>
          <p>{activityName}</p>
        </TextContent>
      </Row>
      <Row>
        <Col span={12}>
          <TextContent>
            <div>Datum aktivnosti</div>
          </TextContent>
          {completed ? (
            <TextContent>
              {moment(activity?.actualDate).format(DateTimeFormat.ShortLocalized)}
            </TextContent>
          ) : (
            <DatePicker
              data-test={"activity-date-picker"}
              disabled={disabled || loading}
              disabledDate={(current) => {
                return current < moment().startOf("day");
              }}
              value={datePickerDefaultValue}
              onChange={(value: Moment | null) => {
                const stringDate = value?.format(DateTimeFormat.YearDayMonthTimeISO);
                if (stringDate) {
                  handleDateChange(stringDate);
                }
              }}
            />
          )}
        </Col>
        <Col span={12}>
          <TextContent>
            <div>Predloženi kanali aktivnosti</div>
            <div>{renderChannelColumn(activity?.activityTemplate.recommendedChannels)}</div>
          </TextContent>
        </Col>
      </Row>
    </>
  );
};
