import { EntityEndpoint } from "enums/EntityEndpoint";
import { ResourceType } from "enums/ResourceType";
import { ProgramJSONAModel } from "interfaces/jsona-models/ProgramJSONAModel";
import { ProgramSelector } from "selectors/ProgramSelector";
import { AppApiActionHandler } from "./AppApiActionHandler";

class ProgramActionHandler extends AppApiActionHandler<ProgramJSONAModel> {
  constructor() {
    super(ResourceType.Program, EntityEndpoint.Program, new ProgramSelector());
  }
}

export const programActionHandler = new ProgramActionHandler();
