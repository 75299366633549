import { PageHeader } from "common/PageHeader/PageHeader";
import { ProgramTemplates } from "features/programs/components/ProgramTemplates";
import * as React from "react";
import { FunctionComponent } from "react";

export const ProgramsScreen: FunctionComponent = () => {
  return (
    <div>
      <PageHeader title={"Programi"} />
      <ProgramTemplates />
    </div>
  );
};
