export enum PatientFormFieldName {
  FirstName = "Ime",
  LastName = "Prezime",
  MobilePhoneNumber = "Broj mobitela",
  TelephoneNumber = "Broj telefona",
  Birthday = "Godina rođenja",
  Gender = "Spol",
  Height = "Visina pacijenta",
  Weight = "Težina pacijenta",
  Smoking = "Pušenje",
  Alcohol = "Alkohol",
  Allergies = "Alergije",
  DiseasesDiagnosis = "Bolesti/Dijagnoze",
  GdprConsent = "GDPR privola",
}
