import "index.scss";

import { MomentLocale } from "enums/MomentLocale";
import moment from "moment";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "root/App";
import * as serviceWorker from "./serviceWorker";
import "moment/locale/hr";
import { store } from "app/store";
import * as Sentry from "@sentry/react";
import packageJson from "../package.json";
import { BrowserTracing } from "@sentry/tracing";

// only for production builds (all envs)
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://207e24f5baac489e9de36feef5852704@o48196.ingest.sentry.io/5419510",
    integrations: [new BrowserTracing()],
    release: "pliva-adherence-frontend@" + packageJson.version,
    tracesSampleRate: 0.1,
    environment: process.env.REACT_APP_ENVIRONMENT,
  });
}

if (process.env.NODE_ENV !== "production") {
  require("whatwg-fetch");
}

moment.locale(MomentLocale.Croatian);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
