import { Card } from "antd";
import * as React from "react";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { TextContent } from "../../../common/TextContent/TextContent";
import { IconCardProps } from "../interfaces/IconCardProps";
import styles from "./IconCard.module.scss";

const addPatientIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="46" height="44" fill="none" viewBox="0 0 46 44">
    <path
      fill="#3485FF"
      d="M36 0c-2.75 0-5 2.25-5 5s2.25 5 5 5 5-2.25 5-5-2.25-5-5-5zM6 2c-.35 0-.695.093-1 .268-.303.176-.555.428-.73.732H1c-.265 0-.52.105-.707.293C.105 3.48 0 3.735 0 4v8c0 6.279 4.853 11.439 11.002 11.95L11 24c0 2.493.12 5.939 1.928 8.865C14.736 35.792 18.29 38 24.443 38c2.751 0 4.945-.59 6.672-1.514.146-.047.279-.127.389-.234 1.587-.936 2.764-2.153 3.568-3.447C36.781 30.055 37 27.12 37 25.857v-2.002c1.716-.45 3-2.005 3-3.855 0-2.197-1.803-4-4-4-2.198 0-4 1.803-4 4 0 1.85 1.284 3.405 3 3.855v2.002c0 1.002-.224 3.637-1.627 5.893-.432.695-.983 1.355-1.662 1.95l-.262-4.997L28 27.323V21c0-4.43 3.57-8 8-8s8 3.57 8 8v6.322l-3.45 1.381L39.853 42h-7.7l-.203-3.68c-.6.33-1.249.61-1.949.85l.102 1.933v.075l.15 2.822h11.496l.733-13.916L46 28.678V21c0-5.511-4.489-10-10-10s-10 4.489-10 10v7.678l3.52 1.406.255 4.867C28.415 35.59 26.697 36 24.443 36c-5.698 0-8.363-1.838-9.814-4.186C13.179 29.467 13 26.412 13 24c0-.017 0-.034-.002-.05C19.148 23.438 24 18.278 24 12V4c0-.265-.105-.52-.293-.707C23.52 3.105 23.265 3 23 3h-3.271c-.176-.304-.428-.556-.731-.731C18.695 2.093 18.35 2 18 2c-.53 0-1.04.21-1.414.586C16.21 2.96 16 3.47 16 4c0 .53.21 1.04.586 1.414C16.96 5.79 17.47 6 18 6c.35 0 .695-.093 1-.268.303-.176.555-.428.73-.732H22v7c0 5.535-4.465 10-10 10S2 17.535 2 12V5h2.271c.176.304.428.556.731.731C5.305 5.907 5.65 6 6 6c.53 0 1.04-.21 1.414-.586C7.79 5.04 8 4.53 8 4c0-.53-.21-1.04-.586-1.414C7.04 2.21 6.53 2 6 2zm30 0c1.669 0 3 1.331 3 3s-1.331 3-3 3-3-1.331-3-3 1.331-3 3-3zm0 16c1.115 0 2 .885 2 2 0 1.084-.839 1.943-1.91 1.99-.035-.003-.07-.004-.106-.004-.025 0-.05.002-.076.004C34.837 21.942 34 21.084 34 20c0-1.115.884-2 2-2z"
    />
  </svg>
);

export const IconCard: FunctionComponent<IconCardProps> = (props) => {
  return (
    <Card className={styles.card}>
      <Link className={styles.cardContent} to={props.url}>
        <div className={styles.iconWrap}>{addPatientIcon}</div>
        <TextContent fontSize={14}>
          <span className={styles.text}>{props.text}</span>
        </TextContent>
      </Link>
    </Card>
  );
};
