import { EntityEndpoint } from "enums/EntityEndpoint";
import { ResourceType } from "enums/ResourceType";
import { AppApiActionHandler } from "./AppApiActionHandler";
import { ChatMessageJSONAModel } from "../interfaces/jsona-models/ChatMessageJSONAModel";
import { ChatMessageSelector } from "../selectors/ChatMessageSelector";

class ChatMessageActionHandler extends AppApiActionHandler<ChatMessageJSONAModel> {
  constructor() {
    super(ResourceType.ChatMessage, EntityEndpoint.ChatMessage, new ChatMessageSelector());
  }
}

export const chatMessageActionHandler = new ChatMessageActionHandler();
