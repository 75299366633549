import { SettingOutlined } from "@ant-design/icons";
import { useGetControlled } from "@bornfight/aardvark";
import { Dropdown, Menu, Table } from "antd";
import { ModalExtended } from "common/ModalExtended/ModalExtended";
import { Space } from "common/Space/Space";
import { TextContent } from "common/TextContent/TextContent";
import { AddMedicine } from "features/patients/components/AddMedicine/AddMedicine";
import { ProgramMainMedicinesTableColumnDataIndex } from "features/patients/components/ProgramMainMedicinesTable/enums/ProgramMainMedicinesTableColumnDataIndex";
import { ProgramMainMedicinesTableColumnTitle } from "features/patients/components/ProgramMainMedicinesTable/enums/ProgramMainMedicinesTableColumnTitle";
import { ProgramMainMedicinesTableProps } from "features/patients/components/ProgramMainMedicinesTable/interfaces/ProgramMainMedicinesTableProps";
import { ProgramMainMedicinesTableRecord } from "features/patients/components/ProgramMainMedicinesTable/interfaces/ProgramMainMedicinesTableRecord";
import styles from "features/patients/components/ProgramMainMedicinesTable/ProgramMainMedicinesTable.module.scss";
import { PatientProgramActivityRouteParams } from "features/patients/interfaces/PatientProgramActivityRouteParams";
import { programActionHandler } from "handlers/ProgramActionHandler";
import {
  IntakePartOfDay,
  IntakeRegime,
  TherapyJSONAModel,
} from "interfaces/jsona-models/TherapyJSONAModel";
import * as React from "react";
import { FunctionComponent, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { removeOverflowOnDropdownClose } from "utils/removeOverflowOnDropdownClose";
import { translateIntakePartOfDay } from "utils/translateIntakePartOfDay";
import { translateIntakeRegime } from "utils/translateIntakeRegime";

export const ProgramMainMedicinesTable: FunctionComponent<ProgramMainMedicinesTableProps> = (
  props
) => {
  const params = useParams<PatientProgramActivityRouteParams>();
  const [medicineModalOpened, setAddMedicineModalOpened] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [selectedTherapy, setSelectedTherapy] = useState<TherapyJSONAModel | undefined>(undefined);

  const programId = useMemo(() => {
    return params.programId;
  }, [params.programId]);
  const includes = useMemo(() => {
    return ["activities", "programTemplate", "programComments", "therapies"];
  }, []);
  const { getSingle: getProgram, loading: programLoading } = useGetControlled(
    programActionHandler,
    `/api/programs/${programId}`,
    includes
  );

  const renderOptionsColumn = (therapy: TherapyJSONAModel) => {
    const menu = (
      <Menu>
        <Menu.Item
          key={"editTherapy"}
          onClick={() => {
            setSelectedTherapy(therapy);
            setEditMode(true);
            setAddMedicineModalOpened(true);
          }}
        >
          Uredi
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown overlay={menu} trigger={["click"]} onOpenChange={removeOverflowOnDropdownClose}>
        <div onClick={(e) => e.preventDefault()}>
          <TextContent>
            <span className={styles.cogwheel}>
              <SettingOutlined />
            </span>
          </TextContent>
        </div>
      </Dropdown>
    );
  };

  const mainTherapies = useMemo(() => {
    if (props?.program?.therapies) {
      return props?.program?.therapies?.filter((therapy) => {
        return therapy.adherenceTherapy === true;
      });
    }
    return [];
  }, [props]);

  const dataSource: ProgramMainMedicinesTableRecord[] =
    mainTherapies?.map((therapy, index) => {
      return {
        key: therapy.uuid + index,
        [ProgramMainMedicinesTableColumnDataIndex.ProtectedDrugName]:
          therapy?.medicine?.productName,
        [ProgramMainMedicinesTableColumnDataIndex.Type]: therapy?.medicine?.medicineType,
        [ProgramMainMedicinesTableColumnDataIndex.Inn]: therapy?.medicine?.inn,
        [ProgramMainMedicinesTableColumnDataIndex.UsageRegime]: therapy?.intakeRegime,
        [ProgramMainMedicinesTableColumnDataIndex.UsageDayPeriod]: therapy?.intakePartOfDay,
        [ProgramMainMedicinesTableColumnDataIndex.Option]: therapy,
      };
    }) || [];
  const columns = [
    {
      title: ProgramMainMedicinesTableColumnTitle.ProtectedDrugName,
      key: ProgramMainMedicinesTableColumnDataIndex.ProtectedDrugName,
      dataIndex: ProgramMainMedicinesTableColumnDataIndex.ProtectedDrugName,
    },
    {
      title: ProgramMainMedicinesTableColumnTitle.Type,
      key: ProgramMainMedicinesTableColumnDataIndex.Type,
      dataIndex: ProgramMainMedicinesTableColumnDataIndex.Type,
    },
    {
      title: ProgramMainMedicinesTableColumnTitle.Inn,
      key: ProgramMainMedicinesTableColumnDataIndex.Inn,
      dataIndex: ProgramMainMedicinesTableColumnDataIndex.Inn,
    },
    {
      title: ProgramMainMedicinesTableColumnTitle.UsageRegime,
      key: ProgramMainMedicinesTableColumnDataIndex.UsageRegime,
      dataIndex: ProgramMainMedicinesTableColumnDataIndex.UsageRegime,
      render: (usageRegime: IntakeRegime) => translateIntakeRegime(usageRegime),
    },
    {
      title: ProgramMainMedicinesTableColumnTitle.UsageDayPeriod,
      key: ProgramMainMedicinesTableColumnDataIndex.UsageDayPeriod,
      dataIndex: ProgramMainMedicinesTableColumnDataIndex.UsageDayPeriod,
      render: (intakePartOfDay: IntakePartOfDay) => translateIntakePartOfDay(intakePartOfDay),
    },
    {
      title: ProgramMainMedicinesTableColumnTitle.Option,
      key: ProgramMainMedicinesTableColumnDataIndex.Option,
      dataIndex: ProgramMainMedicinesTableColumnDataIndex.Option,
      render: renderOptionsColumn,
    },
  ];
  return (
    <>
      <div>
        <Space size={10} direction={"vertical"}>
          <div className={styles.header}>
            <TextContent strong={true}>Lijek u praćenju</TextContent>
          </div>
          <Table dataSource={dataSource} columns={columns} loading={loading || programLoading} />
        </Space>
      </div>
      <ModalExtended
        title="Dodaj ostale lijekove i dodatke prehrani"
        open={medicineModalOpened}
        onCancel={() => setAddMedicineModalOpened(false)}
        afterClose={() => {
          setEditMode(false);
          setSelectedTherapy(undefined);
          setAddMedicineModalOpened(false);
          getProgram();
        }}
      >
        <AddMedicine
          setPatientMedicineTableLoading={setLoading}
          closeModal={setAddMedicineModalOpened}
          addExistingEditMode={editMode}
          therapy={selectedTherapy}
        />
      </ModalExtended>
    </>
  );
};
