import { usePost } from "@bornfight/aardvark";
import { JSONAModel } from "@bornfight/aardvark/dist/interfaces/JSONAModel";
import { Button, Form, Input, Select } from "antd";
import { ResourceType } from "enums/ResourceType";
import { AddNewMedicineFormProps } from "features/patients/components/AddMedicine/components/interfaces/AddNewMedicineFormProps";
import { AddNewMedicineFormValues } from "features/patients/components/AddMedicine/components/interfaces/AddNewMedicineFormValues";
import { intakePartOfDayCollection } from "features/patients/components/AddMedicine/constants/IntakePartOfDayCollection";
import { intakeRegimeCollection } from "features/patients/components/AddMedicine/constants/IntakeRegimeCollection";
import { AddNewMedicineFieldLabel } from "features/patients/components/AddMedicine/enums/AddNewMedicineFieldLabel";
import { AddNewMedicineFieldName } from "features/patients/components/AddMedicine/enums/AddNewMedicineFieldName";
import { PatientProgramActivityRouteParams } from "features/patients/interfaces/PatientProgramActivityRouteParams";
import { medicineActionHandler } from "handlers/MedicineActionHandler";
import { therapyActionHandler } from "handlers/TherapyActionHandler";
import * as React from "react";
import { FunctionComponent, useState } from "react";
import { useParams } from "react-router-dom";
import { removeOverflowOnDropdownClose } from "utils/removeOverflowOnDropdownClose";
import { translateIntakePartOfDay } from "utils/translateIntakePartOfDay";
import { translateIntakeRegime } from "utils/translateIntakeRegime";
import styles from "./AddNewMedicineForm.module.scss";

export const AddNewMedicineForm: FunctionComponent<AddNewMedicineFormProps> = (props) => {
  const params = useParams<PatientProgramActivityRouteParams>();
  const [loading, setLoading] = useState<boolean>(false);

  const renderUsageDayPeriod = () => {
    return intakePartOfDayCollection.map((intakePartOfDay, index) => {
      const translatedIntakePart = translateIntakePartOfDay(intakePartOfDay);

      return (
        <Select.Option key={index} value={intakePartOfDay}>
          {translatedIntakePart}
        </Select.Option>
      );
    });
  };

  const renderIntakeRegimentOptions = () => {
    return intakeRegimeCollection.map((intakeRegime, index) => {
      const translatedIntakeRegime = translateIntakeRegime(intakeRegime);

      return (
        <Select.Option key={index} value={intakeRegime}>
          {translatedIntakeRegime}
        </Select.Option>
      );
    });
  };

  const { create: createMedicine } = usePost(medicineActionHandler);
  const { create: createTherapy } = usePost(therapyActionHandler);

  const handleAddNewMedicine = async (values: AddNewMedicineFormValues) => {
    setLoading(true);
    createMedicine({
      rawData: {
        data: {
          attributes: {
            productName: values[AddNewMedicineFieldName.Name],
            medicineType: values[AddNewMedicineFieldName.Type],
            inn: values[AddNewMedicineFieldName.Inn],
          },
        },
      },
    })
      .then((response: JSONAModel) => {
        const programId = `/api/programs/${params.programId}`;
        createTherapy({
          rawData: {
            data: {
              attributes: {
                intakeRegime: values[AddNewMedicineFieldName.IntakeRegime],
                intakePartOfDay: values[AddNewMedicineFieldName.IntakePartOfDay],
              },
              relationships: {
                medicine: {
                  type: ResourceType.Medicine,
                  id: response.id,
                },
                program: {
                  type: ResourceType.Program,
                  id: programId,
                },
              },
            },
          },
        });
      })
      .then(() => {
        setLoading(false);
        props.closeModal(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        props.closeModal(false);
      });
  };

  return (
    <div>
      <Form
        layout={"vertical"}
        onFinish={(values) => {
          handleAddNewMedicine(values as AddNewMedicineFormValues);
        }}
      >
        <Form.Item
          label={AddNewMedicineFieldLabel.Name}
          name={AddNewMedicineFieldName.Name}
          rules={[{ required: true, message: "Obavezno polje" }]}
        >
          <Input placeholder={"Upiši naziv"} />
        </Form.Item>
        <Form.Item
          label={AddNewMedicineFieldLabel.Type}
          name={AddNewMedicineFieldName.Type}
          rules={[{ required: true, message: "Obavezno polje" }]}
        >
          <Input placeholder={"Upiši vrstu"} />
        </Form.Item>
        <Form.Item
          label={AddNewMedicineFieldLabel.Inn}
          name={AddNewMedicineFieldName.Inn}
          rules={[{ required: true, message: "Obavezno polje" }]}
        >
          <Input placeholder={"Upiši INN"} />
        </Form.Item>
        <Form.Item
          label={AddNewMedicineFieldLabel.IntakeRegime}
          name={AddNewMedicineFieldName.IntakeRegime}
          rules={[{ required: true, message: "Obavezno polje" }]}
        >
          <Select onDropdownVisibleChange={removeOverflowOnDropdownClose}>
            {renderIntakeRegimentOptions()}
          </Select>
        </Form.Item>
        <Form.Item
          label={AddNewMedicineFieldLabel.IntakePartOfDay}
          name={AddNewMedicineFieldName.IntakePartOfDay}
          rules={[{ required: true, message: "Obavezno polje" }]}
        >
          <Select onDropdownVisibleChange={removeOverflowOnDropdownClose}>
            {renderUsageDayPeriod()}
          </Select>
        </Form.Item>
        <div className={styles["button-wrap"]}>
          <Button
            htmlType={"button"}
            type={"ghost"}
            onClick={() => props.setViewAddForm(false)}
            className={styles.quit}
          >
            Odustani
          </Button>
          <Button htmlType={"submit"} type={"primary"} loading={loading}>
            Dodaj
          </Button>
        </div>
      </Form>
    </div>
  );
};
