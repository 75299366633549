import { PageWrap } from "common/PageWrap/PageWrap";
import { ReportQuestionnairesTable } from "features/admin/components/Reports/ReportQuestionnairesTable/ReportQuestionnairesTable";
import { ReportsTable } from "features/admin/components/Reports/ReportsTable/ReportsTable";
import pageCover from "features/admin/PageCoverStyle.module.scss";
import * as React from "react";
import { FunctionComponent } from "react";

export const AdminReportsScreen: FunctionComponent = () => {
  return (
    <div className={pageCover.wrap}>
      <PageWrap>
        <ReportsTable />
      </PageWrap>
      <PageWrap>
        <ReportQuestionnairesTable />
      </PageWrap>
    </div>
  );
};
