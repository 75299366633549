import { ModalExtended } from "common/ModalExtended/ModalExtended";
import { ChatInput } from "features/chat/ChatInput";
import { ChatMessage, ChatMessageList } from "features/chat/ChatMessageList";
import { PusherChannel } from "features/chat/enums/PusherChannel";
import { ChatProps } from "features/chat/interfaces/ChatProps";
import { ChatMessageMapper } from "mappers/ChatMessageMapper";
import Pusher from "pusher-js";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { ChatFooter } from "./ChatFooter";
import { usePrompt } from "utils/usePrompt";

const chatMessageMapper = new ChatMessageMapper();

const sortMessages = (messages: ChatMessage[]) => {
  return messages.sort((a, b) => {
    let aDate = a.createdAt;
    let bDate = b.createdAt;

    if (typeof aDate === "string") {
      aDate = new Date(a?.createdAt as string);
    }
    if (typeof bDate === "string") {
      bDate = new Date(b?.createdAt as string);
    }
    return (aDate as Date)?.getTime() - (bDate as Date)?.getTime();
  });
};

export const Chat: React.FunctionComponent<ChatProps> = (props) => {
  const [sessionActive, setSessionActive] = useState(true);
  const [messages, setMessages] = useState<ChatMessage[]>([]);

  const accessToken = localStorage.getItem("token");
  const pusherRef = useRef<Pusher>();

  useEffect(() => {
    const beforeUnloadListener = (event: BeforeUnloadEvent) =>
      sessionActive && event.preventDefault();
    addEventListener("beforeunload", beforeUnloadListener);

    return () => {
      removeEventListener("beforeunload", beforeUnloadListener);
    };
  }, []);

  const chatChannel = `private-chat-${props.chatUuid}`;
  const pusherKey = `${process.env["REACT_APP_PUSHER_KEY"]}`;

  useEffect(() => {
    pusherRef.current = new Pusher(pusherKey, {
      cluster: "eu",
      authEndpoint: props.url,
      auth: {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    });

    const channel = pusherRef.current?.subscribe(chatChannel);
    channel.bind(PusherChannel.MessageReceived, (data: ChatMessage) => {
      const modifyNewMessageData: ChatMessage = {
        ...data,
        type: "inbound",
      };
      setMessages((prevState) => [...prevState, modifyNewMessageData]);
      return;
    });

    return () => {
      pusherRef?.current?.disconnect();
    };
  });

  const handleSessionEnd = () => {
    setSessionActive(false);
    pusherRef.current?.disconnect();
    pusherRef.current?.unsubscribe(PusherChannel.MessageReceived);
  };

  usePrompt("Aktivna sesija je u tijeku. Želite li otići s trenutne stranice?", sessionActive);

  return (
    <React.Fragment>
      <ModalExtended
        wrapProps={{
          "data-test": "chat-modal",
        }}
        closable={false}
        width={550}
        destroyOnClose={true}
        title={"SMS komunikacija"}
        open={props.isActive}
        footer={
          <ChatFooter
            chatUuid={props.chatUuid}
            sessionActive={sessionActive}
            onEndSessionClick={handleSessionEnd}
            onChatClose={props.onChatClose}
          />
        }
      >
        <div>
          <p>
            Pokretanjem SMS komunikacije potrebno je odraditi svu komunikaciju u toj sesiji.
            Završavanjem sesije nije moguće ponovno komuniciranje sa pacijentom u ovom koraku.
          </p>
        </div>
        <div>
          <ChatMessageList showBorderBottom={false} messages={messages} />
          <ChatInput
            disabled={!sessionActive}
            chatUuid={props.chatUuid}
            defaultMessage={props.defaultMessage}
            onSuccessfulMessageSend={(message) => {
              setMessages((prevState) => {
                const messages = [
                  ...prevState,
                  chatMessageMapper.fromChatMessageJSONAModel(message),
                ];

                return sortMessages(messages);
              });
            }}
          />
        </div>
      </ModalExtended>
    </React.Fragment>
  );
};
