import { WarningOutlined } from "@ant-design/icons/lib";
import { FuzzySearchType, useGetAllControlled } from "@bornfight/aardvark";
import { CustomParam } from "@bornfight/aardvark/dist/services/JsonApiQuery/interfaces/CustomParam";
import { Tooltip } from "antd";
import { ColumnFilterType } from "common/ResourceTable/enums/ColumnFilterType";
import { TableColumnData } from "common/ResourceTable/interfaces/TableColumnData";
import { ResourceTable } from "common/ResourceTable/ResourceTable";
import { Space } from "common/Space/Space";
import { TextContent } from "common/TextContent/TextContent";
import { ActivityStatus } from "enums/ActivityStatus";
import { DateTimeFormat } from "enums/DateTimeFormat";
import { FontSize } from "enums/FontSize";
import { RouteUrl } from "enums/RouteUrl";
import { ActivityTableColumnDataIndex } from "features/activities/enums/ActivityTableColumnDataIndex";
import { ActivityTableColumnTitle } from "features/activities/enums/ActivityTableColumnTitle";
import { ActivityTableProps } from "features/activities/interfaces/ActivityTableProps";
import { ActivityTableRecord } from "features/activities/interfaces/ActivityTableRecord";
import { ActivityTableType } from "features/activities/interfaces/ActivityTableType";
import { PatientProgramActivityRouteParams } from "features/patients/interfaces/PatientProgramActivityRouteParams";
import { activityActionHandler } from "handlers/ActivityActionHandler";
import { programActionHandler } from "handlers/ProgramActionHandler";
import { ActivityJSONAModel } from "interfaces/jsona-models/ActivityJSONAModel";
import { RecommendedChannel } from "interfaces/jsona-models/ActivityTemplateJSONAModel";
import isEqual from "lodash.isequal";
import moment, { Moment } from "moment";
import * as React from "react";
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { getUuidFromIri } from "utils/getUuidFromIri";
import { SkeletonInput } from "common/SkeletonInput/SkeletonInput";
import { patientActionHandler } from "handlers/PatientActionHandler";
import { AppJsonApiQuery } from "utils/AppJsonApiQuery";
import styles from "./ActivityTable.module.scss";
import { ProgramStatus } from "interfaces/jsona-models/ProgramJSONAModel";

const today = moment().startOf("day");

const renderDateColumn = (
  dateString: Moment,
  tableType: ActivityTableType,
  dropOutTreshHold?: number
) => {
  if (tableType === ActivityTableType.Expired) {
    return (
      <Tooltip
        title={`Prekid programa: ${dateString
          .add(dropOutTreshHold, "d")
          .format(DateTimeFormat.ShortLocalized)}`}
        trigger={["click"]}
      >
        <TextContent type={"danger"}>
          <WarningOutlined />{" "}
          {dateString.subtract(dropOutTreshHold, "d").format(DateTimeFormat.ShortLocalized)}
        </TextContent>
      </Tooltip>
    );
  }
  return <TextContent>{dateString.format(DateTimeFormat.ShortLocalized)}</TextContent>;
};

const renderChannelColumn = (channels: RecommendedChannel[] | undefined) => {
  if (channels === undefined) {
    return;
  }
  const translatedChannels = channels.map((channel) => {
    let channelName = "";
    switch (channel) {
      case RecommendedChannel.Survey:
        return (channelName = "Upitnik");
      case RecommendedChannel.PhoneCall:
        return (channelName = "Telefonski poziv");
      case RecommendedChannel.Message:
        return (channelName = "SMS");
      case RecommendedChannel.VideoCall:
        return (channelName = "Video poziv");
      case RecommendedChannel.Live:
        return (channelName = "Uživo");
    }
    return channelName;
  });
  const channelSpans = translatedChannels.map((translatedChannel, index, arr) => {
    if (arr.length - 1 === index) {
      // if last item in array don't show comma
      return <span key={index}>{translatedChannel}</span>;
    } else {
      return <span key={index}>{translatedChannel}, </span>;
    }
  });

  return <div>{channelSpans}</div>;
};

const getDateColumn = (tableType: ActivityTableType): TableColumnData<ActivityTableRecord> => {
  if (tableType === ActivityTableType.Future) {
    return {
      title: ActivityTableColumnTitle.Date,
      dataIndex: ActivityTableColumnDataIndex.Date,
      filter: {
        type: ColumnFilterType.Date,
        field: "expectedDate",
      },
      render: (data: { date: Moment; dropoutThreshhold: number | undefined }) => {
        return renderDateColumn(data.date, tableType);
      },
      sorter: true,
      sorterField: "expectedDate",
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend", null],
    };
  }
  return {
    title: ActivityTableColumnTitle.Date,
    dataIndex: ActivityTableColumnDataIndex.Date,
    render: (data: { date: Moment; dropoutThreshhold: number | undefined }) => {
      return renderDateColumn(data.date, tableType, data.dropoutThreshhold);
    },
  };
};

export const ActivityTable: FunctionComponent<ActivityTableProps> = (props) => {
  const [patientIds, setPatientIds] = useState<string[]>([]);
  const [usingDateColumnFilter, setUsingDateColumnFilter] = useState<Moment | undefined>(undefined);

  const patientsJsonApiQuery = useMemo(() => {
    const patientIdsArray = patientIds.filter(Boolean);
    const uniquePatientIds = new Set(patientIdsArray);

    const customParams: CustomParam[] = [...uniquePatientIds].map((id) => {
      return {
        name: "uuid[]",
        value: getUuidFromIri(id),
      };
    });

    return new AppJsonApiQuery({
      customParams,
    });
  }, [patientIds]);

  const { getAll, loading: patientsLoading } = useGetAllControlled(
    patientActionHandler,
    patientsJsonApiQuery
  );

  const [programIds, setProgramIds] = useState<string[]>([]);
  const programJsonApiQuery = useMemo(() => {
    const programIdsArray = programIds.filter(Boolean);
    const uniqueProgramIds = new Set(programIdsArray);

    const customParams: CustomParam[] = [
      ...[...uniqueProgramIds].map((id) => {
        return {
          name: "uuid[]",
          value: getUuidFromIri(id),
        };
      }),
      {
        name: "status",
        value: ProgramStatus.Active,
      },
    ];

    return new AppJsonApiQuery({
      customParams,
      includes: ["programTemplate"],
    });
  }, [programIds]);

  const { getAll: getAllPrograms, loading: programLoading } = useGetAllControlled(
    programActionHandler,
    programJsonApiQuery
  );

  useEffect(() => {
    if (patientIds.length > 0) {
      getAll();
    }
    if (programIds.length > 0) {
      getAllPrograms();
    }
  }, [patientIds, getAll, programIds, getAllPrograms]);

  const createColumnData = useMemo((): TableColumnData<ActivityTableRecord>[] => {
    return [
      getDateColumn(props.type),
      {
        title: ActivityTableColumnTitle.Activity,
        dataIndex: ActivityTableColumnDataIndex.Activity,
      },
      {
        title: ActivityTableColumnTitle.Channels,
        dataIndex: ActivityTableColumnDataIndex.Channels,
        render: (channels) => {
          return renderChannelColumn(channels);
        },
      },
      {
        title: ActivityTableColumnTitle.FirstName,
        dataIndex: ActivityTableColumnDataIndex.FirstName,
        filter: {
          type: ColumnFilterType.Text,
          field: "program.patient.firstName",
          fuzzySearchType: FuzzySearchType.StartWith,
        },
        render: (data) => {
          if (patientsLoading) {
            return <SkeletonInput />;
          }
          return data;
        },
      },
      {
        title: ActivityTableColumnTitle.LastName,
        dataIndex: ActivityTableColumnDataIndex.LastName,
        filter: {
          type: ColumnFilterType.Text,
          field: "program.patient.lastName",
          fuzzySearchType: FuzzySearchType.StartWith,
        },
        render: (data) => {
          if (patientsLoading) {
            return <SkeletonInput />;
          }
          return data;
        },
      },
      {
        title: ActivityTableColumnTitle.Program,
        dataIndex: ActivityTableColumnDataIndex.Program,
        render: (data) => {
          if (programLoading) {
            return <SkeletonInput />;
          }
          return data;
        },
      },
      {
        title: ActivityTableColumnTitle.Action,
        dataIndex: ActivityTableColumnDataIndex.Action,
        render: (idParams: PatientProgramActivityRouteParams) => {
          const patientProgramActivityUrl = `${RouteUrl.Patients}/${idParams.patientId}${RouteUrl.Programs}/${idParams.programId}${RouteUrl.Activities}/${idParams.activityId}`;

          return <Link to={patientProgramActivityUrl}>Idi na Aktivnost</Link>;
        },
      },
    ];
  }, [props.type, patientsLoading, programLoading]);

  const tableData = (activities: ActivityJSONAModel[]): ActivityTableRecord[] => {
    return (
      activities?.map((activity) => {
        const activityDate = moment(activity?.expectedDate);
        const channels = activity?.activityTemplate?.recommendedChannels;
        const activityName = activity?.activityTemplate?.name;
        const activityId = activity?.id;
        const programId = activity?.program?.id || "";
        const patientId = activity?.program?.patient?.id || "";
        const programName = activity?.program?.programTemplate?.name;
        const firstName = activity?.program?.patient?.firstName;
        const lastName = activity?.program?.patient?.lastName;
        return {
          key: activity?.id,
          [ActivityTableColumnDataIndex.Date]: {
            date: activityDate,
            dropoutThreshhold: activity?.program?.programTemplate?.dropoutThreshold,
          },
          [ActivityTableColumnDataIndex.Activity]: activityName,
          [ActivityTableColumnDataIndex.Channels]: channels,
          [ActivityTableColumnDataIndex.FirstName]: firstName,
          [ActivityTableColumnDataIndex.LastName]: lastName,
          [ActivityTableColumnDataIndex.Program]: programName,
          [ActivityTableColumnDataIndex.Action]: {
            activityId: activityId ? getUuidFromIri(activityId) : "",
            programId: programId ? getUuidFromIri(programId) : "",
            patientId: patientId ? getUuidFromIri(patientId) : "",
          },
        };
      }) || []
    );
  };

  const getJsonApiQuery = useCallback(() => {
    const tableType = props.type;
    const todayDateIso = today.format(DateTimeFormat.YearDayMonthTimeISO);
    let customParams: CustomParam[] = [];
    switch (tableType) {
      case ActivityTableType.Expired:
        customParams = [
          {
            name: "expectedDate[strictly_before]",
            value: todayDateIso,
          },
          {
            name: "status",
            value: ActivityStatus.Interactive,
          },
        ];
        break;
      case ActivityTableType.Today:
        customParams = [
          {
            name: "expectedDate[before]",
            value: todayDateIso,
          },
          {
            name: "expectedDate[after]",
            value: todayDateIso,
          },
          {
            name: "status",
            value: ActivityStatus.Interactive,
          },
        ];
        break;
      case ActivityTableType.Future:
        customParams = [
          {
            name: "expectedDate[strictly_after]",
            value: todayDateIso,
          },
          {
            name: "status",
            value: ActivityStatus.Interactive,
          },
        ];
        if (usingDateColumnFilter) {
          customParams = [
            {
              name: "status",
              value: ActivityStatus.Interactive,
            },
            {
              name: "expectedDate",
              value: usingDateColumnFilter.format(DateTimeFormat.YearMonthDayDashed),
            },
          ];
        }
        break;
    }
    return new AppJsonApiQuery({
      customParams,
      includes: ["activityTemplate", "program"],
    });
  }, [usingDateColumnFilter, props.type]);

  const onDateFilterColumnChange = (
    newValue: Moment | null,
    column: TableColumnData<ActivityTableRecord>
  ) => {
    if (Boolean(newValue)) {
      setUsingDateColumnFilter(newValue?.startOf("day") || undefined);
      return;
    }
    setUsingDateColumnFilter(undefined);
  };

  return (
    <Space size={20} direction={"vertical"}>
      <TextContent fontSize={FontSize.Regular20}>{props.title}</TextContent>
      <ResourceTable<ActivityTableRecord, ActivityJSONAModel>
        apiActionHandler={activityActionHandler}
        transformToDataSource={tableData}
        columnData={createColumnData}
        jsonApiQuery={getJsonApiQuery()}
        className={styles.table}
        disableDateFilterPastDates={props.disableDateFilterPastDates}
        onCollectionRender={(collection) => {
          const patientIds = collection.map((value) => value?.program?.patient?.id);
          setPatientIds((data) => {
            if (isEqual(data, patientIds)) {
              return data;
            }
            return patientIds;
          });

          const programIds = collection.map((value) => value?.program?.id);
          setProgramIds((data) => {
            if (isEqual(data, programIds)) {
              return data;
            }
            return programIds;
          });
        }}
        onDateFilterColumnChange={onDateFilterColumnChange}
      />
    </Space>
  );
};
