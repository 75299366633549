import { EntityEndpoint } from "enums/EntityEndpoint";
import { ResourceType } from "enums/ResourceType";
import { AnswerJSONAModel } from "interfaces/jsona-models/AnswerJSONAModel";
import { AnswerSelector } from "selectors/AnswerSelector";
import { AppApiActionHandler } from "./AppApiActionHandler";

class AnswerActionHandler extends AppApiActionHandler<AnswerJSONAModel> {
  constructor() {
    super(ResourceType.Answer, EntityEndpoint.Answer, new AnswerSelector());
  }
}

export const answerActionHandler = new AnswerActionHandler();
