import { CheckOutlined, CloseOutlined } from "@ant-design/icons/lib";
import { Menu } from "antd";
import { ActivityStatus } from "enums/ActivityStatus";
import { RouteUrl } from "enums/RouteUrl";
import { ActivitiesMenuProps } from "features/patients/components/ActivitiesMenu/interfaces/ActivitiesMenuProps";
import { PatientProgramActivityRouteParams } from "features/patients/interfaces/PatientProgramActivityRouteParams";
import { ActivityJSONAModel } from "interfaces/jsona-models/ActivityJSONAModel";
import * as React from "react";
import { FunctionComponent } from "react";
import { Link, useMatch, useNavigate, useParams } from "react-router-dom";
import styles from "./ActivitiesMenu.module.scss";

const MenuItem = Menu.Item;

export const ActivitiesMenu: FunctionComponent<ActivitiesMenuProps> = (props) => {
  const navigate = useNavigate();
  const { activityId } = useParams<PatientProgramActivityRouteParams>();

  const currentRoute = useMatch(RouteUrl.PatientProgramActivity);

  if (!activityId || !currentRoute) {
    navigate(RouteUrl.Patients);
    return null;
  }

  const { pathname } = currentRoute;

  const renderCompletedIcon = (activity: ActivityJSONAModel) => {
    if (activity.status === ActivityStatus.Completed) {
      return <CheckOutlined className={styles["completed-icon"]} />;
    }
    if (activity.status === ActivityStatus.Unsuccessful) {
      return <CloseOutlined className={styles["unsuccessful-icon"]} />;
    }
    return null;
  };

  return (
    <Menu className={styles.menu} mode={"inline"} theme={"light"} selectedKeys={[activityId ?? ""]}>
      {props?.activities?.map((activity) => {
        return (
          <MenuItem key={activity.uuid}>
            <div className={styles["menu-item-wrap"]}>
              <Link to={pathname.replace(activityId, activity.uuid)}>
                <p className={styles["link-paragraph"]}>{`${activity?.activityTemplate?.name}`}</p>
              </Link>
              {renderCompletedIcon(activity)}
            </div>
          </MenuItem>
        );
      })}
    </Menu>
  );
};
