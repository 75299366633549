import { Button, Col, Form, Input, Row } from "antd";
import { Store } from "antd/lib/form/interface";
import { TextContent } from "common/TextContent/TextContent";
import { RouteUrl } from "enums/RouteUrl";
import { ErrorMessage } from "features/authentication/enums/ErrorMessage";
import { ErrorMessageTranslated } from "features/authentication/enums/ErrorMessageTranslated";
import { UserRole } from "features/authentication/enums/UserRole";
import { LoginData } from "features/authentication/interfaces/LoginData";
import { LoginResponse } from "features/authentication/interfaces/LoginResponse";
import { RequestInterface } from "features/authentication/interfaces/RequestInterface";
import { TokenData } from "features/authentication/interfaces/TokenData";
import jwt_decode from "jwt-decode";
import { useAuth } from "locot";
import { UnauthorizedResponse } from "locot/dist/interfaces/LoginResponse";
import * as React from "react";
import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./LoginForm.module.scss";

const InputPassword = Input.Password;

export const LoginForm: FunctionComponent = () => {
  const authContext = useAuth();
  const navigate = useNavigate();

  const [error, setError] = useState<any>(undefined);

  const isAdmin = (tokenData: TokenData) => {
    return tokenData.roles.some((role) => {
      return role === UserRole.Admin;
    });
  };

  const loginAction = (loginData: LoginData) =>
    authContext
      .login({
        username: loginData.email,
        password: loginData.password,
      })
      .then(async (response: LoginResponse | RequestInterface | UnauthorizedResponse) => {
        setError((response as RequestInterface)?.title);
        if ((response as LoginResponse).token !== undefined) {
          const tokenData: TokenData = await jwt_decode((response as LoginResponse).token);
          const isAdminRole = await isAdmin(tokenData);
          if (isAdminRole) {
            navigate(RouteUrl.AdminUsers);
            return;
          }
          navigate(RouteUrl.Home);
        }
        if ((response as UnauthorizedResponse)?.code === 401) {
          if (
            (response as UnauthorizedResponse)?.message &&
            (response as UnauthorizedResponse)?.message === ErrorMessage.InvalidCredentials
          ) {
            setError(ErrorMessageTranslated.InvalidCredentials);
          }
        }
      })
      .catch((reason: { message: string }) => {
        setError(reason?.message);
      });

  const submitForm = (formValues: Store) => {
    loginAction({
      email: formValues.email,
      password: formValues.password,
    })
      .then()
      .catch((response: any) => {
        console.log(response);
      });
  };

  return (
    <div>
      <Form
        layout={"vertical"}
        onFinish={submitForm}
        onChange={() => {
          setError(null);
        }}
      >
        <Form.Item
          label={"Email adresa:"}
          name={"email"}
          rules={[
            {
              required: true,
              message: "Email adresa nije ispravnog formata!",
              type: "email",
            },
          ]}
          validateTrigger={["onBlur", "onSubmit"]}
        >
          <Input data-test={"email-input"} />
        </Form.Item>
        <Form.Item
          label={"Lozinka:"}
          name={"password"}
          rules={[
            {
              required: true,
              message: "Lozinka ne smije biti prazna!",
            },
          ]}
          validateTrigger={["onBlur", "onSubmit"]}
        >
          <InputPassword data-test={"pw-input"} />
        </Form.Item>
        <Button type={"primary"} htmlType={"submit"} style={{ width: "100%" }} data-test={"submit"}>
          Prijavi se
        </Button>
      </Form>
      {error ? <TextContent type={"danger"}>{error}</TextContent> : null}
      <Row justify={"center"}>
        <Col>
          <p
            className={styles["lost-password"]}
            onClick={() => {
              navigate(RouteUrl.ForgotPassword);
            }}
          >
            Zaboravljena lozinka
          </p>
        </Col>
      </Row>
    </div>
  );
};
