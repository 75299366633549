import { JsonApiReducer } from "@bornfight/aardvark";
import { ApiDataState } from "@bornfight/aardvark/dist/interfaces/ApiDataState";
import { BaseAction } from "@bornfight/aardvark/dist/services/ApiActionCreator/interfaces/BaseAction";
import { Action, combineReducers, Reducer } from "@reduxjs/toolkit";
import { RootState } from "./interfaces";
import counterReducer from "features/counter/counterSlice";

const jsonApiReducer = new JsonApiReducer();

const reducers = {
  apiData: (state: ApiDataState | undefined, action: Action) =>
    jsonApiReducer.reduce(state, action as BaseAction),
  counter: counterReducer,
};

export const rootReducer: Reducer<RootState> = combineReducers(reducers);
