import { TextContent } from "common/TextContent/TextContent";
import { StepInstructionProps } from "features/patients/components/Steps/interfaces/StepInstructionProps";
import * as React from "react";
import { FunctionComponent } from "react";

export const StepInstruction: FunctionComponent<StepInstructionProps> = (props) => {
  const { step, stepOrder } = props;
  const { stepTemplate } = step;

  return (
    <div>
      <TextContent strong={true} fontSize={16}>{`${stepOrder}. ${stepTemplate.name}`}</TextContent>
      <TextContent>
        <p>{stepTemplate.instruction?.instructionText}</p>
      </TextContent>
    </div>
  );
};
