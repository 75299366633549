import { apiSaga } from "app/store";
import downloadFile from "downloadjs";

export const fetchReportExport = (fullUrl: string, fileName: string) => {
  apiSaga.apiService.httpAdapter
    .get(fullUrl, { method: "GET", headers: { Accept: "text/csv" } })
    .then((response) => {
      if (response.data) {
        downloadFile(response.data, fileName, "text/csv");
      }
      return response.data;
    });
};
