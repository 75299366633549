import { useGetAll } from "@bornfight/aardvark";
import { Card, Skeleton } from "antd";
import { Space } from "common/Space/Space";
import { TextContent } from "common/TextContent/TextContent";
import { programTemplateActionHandler } from "handlers/ProgramTemplateActionHandler";
import { ProgramTemplateJSONAModel } from "interfaces/jsona-models/ProgramTemplateJSONAModel";
import * as React from "react";
import { FunctionComponent, useMemo } from "react";
import { Link } from "react-router-dom";
import { AppJsonApiQuery } from "utils/AppJsonApiQuery";
import styles from "./ProgramTemplates.module.scss";

export const ProgramTemplates: FunctionComponent = () => {
  const programTemplatesJsonApiQuery = useMemo(() => {
    const includes = ["activityTemplates", "medicines"];
    return new AppJsonApiQuery({
      includes,
      customParams: [
        {
          name: "enabled",
          value: "true",
        },
      ],
    });
  }, []);
  const { collection: programTemplates, loading: programTemplatesLoading } = useGetAll(
    programTemplateActionHandler,
    programTemplatesJsonApiQuery
  );

  const renderProgramTemplates = (programTemplates: ProgramTemplateJSONAModel[]) => {
    if (programTemplatesLoading) {
      return (
        <div
          style={{
            background: "white",
            padding: 20,
          }}
        >
          <Skeleton />
        </div>
      );
    }
    const progTemplatesWithActivityTemplates = programTemplates.filter((programTemplate) => {
      return programTemplate?.activityTemplates?.length > 0;
    });
    return progTemplatesWithActivityTemplates?.map((programTemplate) => {
      // program lasting in days === all offsets combined
      const programDuration = programTemplate?.activityTemplates?.reduce(
        (acc, it) => acc + it.dayOffset,
        0
      );

      let firstActivityTemplateUuid = undefined;
      if (programTemplate && programTemplate?.activityTemplates) {
        firstActivityTemplateUuid = programTemplate?.activityTemplates[0]?.uuid;
      }

      return (
        <Card
          key={programTemplate.uuid}
          title={programTemplate.name}
          extra={
            <Link to={`${programTemplate.uuid}/activity-templates/${firstActivityTemplateUuid}`}>
              Otvori
            </Link>
          }
        >
          <Space size={16} direction={"vertical"}>
            <div>
              <TextContent>
                <p className={styles.subtitle}>Trajanje programa</p>
              </TextContent>
              <TextContent>
                <p className={styles["subtitle-text"]}>{programDuration} dana</p>
              </TextContent>
            </div>
            <div>
              <TextContent>
                <p className={styles.subtitle}>Aktivni pacijenti</p>
              </TextContent>
              <TextContent>
                <p className={styles["subtitle-text"]}>{programTemplate?.activePrograms || 0}</p>
              </TextContent>
            </div>
          </Space>
        </Card>
      );
    });
  };

  return (
    <div className={styles.wrap}>
      <div className={styles["card-wrap"]}>{renderProgramTemplates(programTemplates)}</div>
    </div>
  );
};
