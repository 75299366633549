import { DatePicker as AntdDatePicker } from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import { defaultDateFormats } from "common/DatePicker/defaultDateFormats";
import * as React from "react";

const { RangePicker: AntdRangePicker } = AntdDatePicker;

export const RangePicker = React.forwardRef<React.Component, RangePickerProps>((props, ref) => {
  return <AntdRangePicker format={defaultDateFormats} ref={ref} {...props} />;
});
