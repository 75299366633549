import { CloseOutlined } from "@ant-design/icons/lib";
import { FileCardProps } from "common/FileCard/interfaces/FileCardProps";
import { PreviewConsentModal } from "common/PreviewConsentModal/PreviewConsentModal";
import { TextContent } from "common/TextContent/TextContent";
import * as React from "react";
import { FunctionComponent, useState } from "react";
import styles from "./FileCard.module.scss";

export const FileCard: FunctionComponent<FileCardProps> = (props) => {
  const [previewOpened, setPreviewOpened] = useState<boolean>(false);

  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.left}>
          <div className={styles["img-wrap"]} onClick={() => setPreviewOpened(true)}>
            <img className={styles.img} src={props.imgLink} alt={"GDPR"} />
          </div>
          <TextContent className={styles.name}>{props.imgName}</TextContent>
        </div>
        <CloseOutlined className={styles.right} onClick={() => props.onXClick()} />
      </div>
      <PreviewConsentModal
        visible={previewOpened}
        onClose={setPreviewOpened}
        fileLink={props.imgLink}
        fileName={props.imgName}
      />
    </>
  );
};
