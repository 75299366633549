import { usePatch, usePost } from "@bornfight/aardvark";
import { Button, Form, Input } from "antd";
import { ResourceType } from "enums/ResourceType";
import { AdminAddProgramValues } from "features/admin/components/AdminAddProgram/interfaces/AdminAddProgramValues";
import { AdminAddQuestionnaireFormProps } from "features/admin/components/AdminAddQuestionnaireForm/interfaces/AdminAddQuestionnaireFormProps";
import { questionnaireActionHandler } from "handlers/QuestionnaireActionHandler";
import * as React from "react";
import { FunctionComponent } from "react";

export const AdminAddQuestionnaireForm: FunctionComponent<AdminAddQuestionnaireFormProps> = (
  props
) => {
  const { create: createQuestionnaire, loading: createQuestionnaireLoading } = usePost(
    questionnaireActionHandler
  );

  const { update: editQuestionnaire, loading: editQuestionnaireLoading } = usePatch(
    questionnaireActionHandler
  );

  const getInitialValues = () => {
    if (props.isEditing) {
      return {
        name: props.currentQuestionnaire?.name,
      };
    }
    return undefined;
  };

  const handleEditQuestionnaire = (values: { name: string }) => {
    if (props.currentQuestionnaire) {
      editQuestionnaire(props.currentQuestionnaire.id, {
        model: {
          type: ResourceType.Questionnaire,
          id: props.currentQuestionnaire.id,
          name: values.name,
        },
        includeNames: [],
      })
        .then(() => {
          props.getQuestionnaires();
          props.closeModal();
        })
        .catch((err) => console.log(err));
    }
  };

  const handleSubmit = (values: { name: string }) => {
    if (props.isEditing) {
      handleEditQuestionnaire(values);
      return;
    }
    createQuestionnaire({
      rawData: {
        data: {
          attributes: {
            name: values.name,
          },
        },
      },
    })
      .then(() => {
        props.getQuestionnaires();
        props.closeModal();
      })
      .catch((err) => console.log(err));
  };
  return (
    <Form
      layout={"vertical"}
      onFinish={(values) => {
        handleSubmit(values as unknown as AdminAddProgramValues);
      }}
      initialValues={getInitialValues()}
    >
      <Form.Item
        label={"Ime upitnika"}
        name={"name"}
        rules={[
          {
            required: true,
            message: "Ovo polje je obavezno.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          type={"primary"}
          htmlType={"submit"}
          loading={createQuestionnaireLoading || editQuestionnaireLoading}
        >
          Spremi
        </Button>
      </div>
    </Form>
  );
};
