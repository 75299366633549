import { Button } from "antd";
import { TextContent } from "common/TextContent/TextContent";
import { StepTemplateQuestionnaireProps } from "features/programs/components/StepTemplates/interfaces/StepTemplateQuestionnaireProps";
import * as React from "react";
import { FunctionComponent } from "react";

export const StepTemplateQuestionnaire: FunctionComponent<StepTemplateQuestionnaireProps> = (
  props
) => {
  const { stepTemplate, disabled } = props;

  return (
    <>
      <div>
        <TextContent strong={true} fontSize={16}>{`${stepTemplate?.name}`}</TextContent>
        <TextContent>
          <p>{stepTemplate?.description}</p>
        </TextContent>
        <Button disabled={disabled} type={"primary"}>
          {"Upitnik"}
        </Button>
      </div>
    </>
  );
};
