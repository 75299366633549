import { Space } from "antd";
import { RouteUrl } from "enums/RouteUrl";
import { LoginForm } from "features/authentication/components/LoginForm";
import { UserRole } from "features/authentication/enums/UserRole";
import { TokenData } from "features/authentication/interfaces/TokenData";
import jwt_decode from "jwt-decode";
import * as React from "react";
import { FunctionComponent, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PlivaLogo } from "static/mainLogo.svg";

import styles from "./LoginScreen.module.scss";

export const LoginScreen: FunctionComponent = () => {
  const navigate = useNavigate();
  const isLoggedIn = false;

  const isAdmin = useCallback(() => {
    const accessToken = localStorage.getItem("token");
    let tokenData: TokenData | undefined;
    if (accessToken) {
      tokenData = jwt_decode(accessToken as string);
    }
    return tokenData?.roles?.some((role) => {
      return role === UserRole.Admin;
    });
  }, []);
  useEffect(() => {
    if (isLoggedIn) {
      const isAdminRole = isAdmin();
      if (isAdminRole) {
        navigate(RouteUrl.AdminUsers);
        return;
      }
      navigate(RouteUrl.Home);
    }
  }, [history, isLoggedIn, isAdmin]);

  return (
    <div className={styles.wrap}>
      <div className={styles["content-wrap"]}>
        <Space direction={"vertical"} size={20}>
          <div className={styles["logo-wrap"]} style={{ marginBottom: 7 }}>
            <PlivaLogo />
          </div>
        </Space>
        <div className={styles["form-wrap"]}>
          <LoginForm />
        </div>
      </div>
    </div>
  );
};
