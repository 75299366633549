import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { Button, Col, Input, Row } from "antd";
import { ResourceType } from "../../enums/ResourceType";
import { getIriFromUuid } from "../../utils/getIriFromUuid";
import { usePost } from "@bornfight/aardvark";
import { chatMessageActionHandler } from "../../handlers/ChatMessageActionHandler";
import { ChatMessageJSONAModel } from "../../interfaces/jsona-models/ChatMessageJSONAModel";
import { useDeepCompareEffect } from "use-deep-compare";

const { TextArea } = Input;

interface Props {
  defaultMessage?: string;
  chatUuid: string;
  onSuccessfulMessageSend: (message: ChatMessageJSONAModel) => void;
  disabled?: boolean;
}

const maxCharacterCount = 200;

export const ChatInput = ({
  defaultMessage = "",
  chatUuid,
  onSuccessfulMessageSend,
  disabled,
}: Props) => {
  const [newMessage, setNewMessage] = useState<string>(defaultMessage);

  const isSendDisabled = useMemo(() => {
    return newMessage === undefined || newMessage === "" || newMessage.length > maxCharacterCount;
  }, [newMessage]);

  const {
    create: createMessage,
    record: createdMessage,
    loading: createMessageLoading,
  } = usePost(chatMessageActionHandler);

  /**
   * handle new message creation as calling onSuccessfulMessageSend in then block results in null (stale data in dependency array)
   */
  useDeepCompareEffect(() => {
    if (createdMessage) {
      onSuccessfulMessageSend(createdMessage);
    }
  }, [createdMessage]);

  const handleCreateMessage = useCallback(() => {
    createMessage({
      rawData: {
        data: {
          type: ResourceType.ChatMessage,
          attributes: {
            text: newMessage,
          },
          relationships: {
            chat: {
              type: ResourceType.Chat,
              id: getIriFromUuid("chats", chatUuid),
            },
          },
        },
      },
    }).then(() => {
      // empty message after successful send
      setNewMessage("");
    });
  }, [createMessage, newMessage, chatUuid]);

  return (
    <div>
      <TextArea
        maxLength={maxCharacterCount}
        disabled={createMessageLoading || disabled}
        autoSize={{ minRows: 3 }}
        autoFocus={true}
        onChange={(e) => {
          setNewMessage(e.target.value);
        }}
        value={newMessage}
        placeholder={"Unesite poruku"}
        data-test={"chat-message-input"}
      />
      <Row align={"middle"} justify={"end"} style={{ paddingTop: 12 }}>
        <Col>
          <span
            style={{
              marginRight: 12,
            }}
          >
            <span
              style={{
                color: newMessage.length > maxCharacterCount ? "red" : "",
              }}
            >
              {newMessage.length}
            </span>
            {"/"}
            {maxCharacterCount} znakova
          </span>
        </Col>
        <Col>
          <Button
            onClick={() => {
              handleCreateMessage();
            }}
            disabled={isSendDisabled || disabled}
            type={"primary"}
            data-test={"chat-send-message"}
          >
            Pošalji
          </Button>
        </Col>
      </Row>
    </div>
  );
};
