import { SettingOutlined } from "@ant-design/icons";
import { ExclamationCircleOutlined } from "@ant-design/icons/lib";
import { useDelete, useGetAllControlled, useGetControlled } from "@bornfight/aardvark";
import { Dropdown, Menu, Modal, Table } from "antd";
import { ModalExtended } from "common/ModalExtended/ModalExtended";
import { Space } from "common/Space/Space";
import { TextContent } from "common/TextContent/TextContent";
import { AddMedicine } from "features/patients/components/AddMedicine/AddMedicine";
import { ProgramMedicinesTableColumnDataIndex } from "features/patients/components/ProgramMedicinesTable/enums/ProgramMedicinesTableColumnDataIndex";
import { ProgramMedicinesTableColumnTitle } from "features/patients/components/ProgramMedicinesTable/enums/ProgramMedicinesTableColumnTitle";
import { ProgramMedicinesTableProps } from "features/patients/components/ProgramMedicinesTable/interfaces/ProgramMedicinesTableProps";
import { ProgramMedicinesTableRecord } from "features/patients/components/ProgramMedicinesTable/interfaces/ProgramMedicinesTableRecord";
import { PatientProgramActivityRouteParams } from "features/patients/interfaces/PatientProgramActivityRouteParams";
import { medicineActionHandler } from "handlers/MedicineActionHandler";
import { programActionHandler } from "handlers/ProgramActionHandler";
import { therapyActionHandler } from "handlers/TherapyActionHandler";
import {
  IntakePartOfDay,
  IntakeRegime,
  TherapyJSONAModel,
} from "interfaces/jsona-models/TherapyJSONAModel";
import * as React from "react";
import { FunctionComponent, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDeepCompareEffect, useDeepCompareMemo } from "use-deep-compare";
import { AppJsonApiQuery } from "utils/AppJsonApiQuery";
import { getUuidFromIri } from "utils/getUuidFromIri";
import { removeOverflowOnDropdownClose } from "utils/removeOverflowOnDropdownClose";
import { translateIntakePartOfDay } from "utils/translateIntakePartOfDay";
import { translateIntakeRegime } from "utils/translateIntakeRegime";
import styles from "./ProgramMedicinesTable.module.scss";

const { confirm } = Modal;

const confirmDeleteTherapy = (onOk: () => void) => {
  confirm({
    title: "Želite li zbilja izbrisati terapiju?",
    icon: <ExclamationCircleOutlined />,
    onOk() {
      onOk();
    },
    onCancel() {},
  });
};

export const ProgramMedicinesTable: FunctionComponent<ProgramMedicinesTableProps> = (props) => {
  const params = useParams<PatientProgramActivityRouteParams>();
  const [medicineModalOpened, setAddMedicineModalOpened] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [selectedTherapy, setSelectedTherapy] = useState<TherapyJSONAModel | undefined>(undefined);

  const medicineIds = useMemo(() => {
    if (!props.program || !props?.program?.therapies) {
      return [];
    }
    return props?.program?.therapies.map((therapy) => {
      if (therapy?.medicine?.id) {
        return getUuidFromIri(therapy?.medicine?.id);
      }
      return "";
    });
  }, [props]);

  const medicineQuery = useDeepCompareMemo(() => {
    const customParams = medicineIds.map((id) => {
      return {
        name: "uuid[]",
        value: id,
      };
    });
    return new AppJsonApiQuery({
      customParams,
    });
  }, [medicineIds]);
  const { getAll: getMedicines, loading: getMedicinesLoading } = useGetAllControlled(
    medicineActionHandler,
    medicineQuery
  );
  useDeepCompareEffect(() => {
    if (medicineIds.length === 0) {
      return;
    }
    getMedicines();
  }, [getMedicines, medicineIds]);

  const programId = useMemo(() => {
    return params.programId;
  }, [params.programId]);
  const includes = useMemo(() => {
    return ["activities", "programTemplate", "programComments", "therapies"];
  }, []);
  const { getSingle: getProgram, loading: programLoading } = useGetControlled(
    programActionHandler,
    `/api/programs/${programId}`,
    includes
  );

  const { deleteRecord: deleteTherapy, loading: deleteTherapyLoading } =
    useDelete(therapyActionHandler);

  const renderOptionsColumn = (therapy: TherapyJSONAModel) => {
    const menu = (
      <Menu>
        <Menu.Item
          key={"editSelectedTherapy"}
          onClick={() => {
            setSelectedTherapy(therapy);
            setEditMode(true);
            setAddMedicineModalOpened(true);
          }}
        >
          Uredi
        </Menu.Item>
        <Menu.Item
          key={"delete"}
          onClick={() => {
            const handleDeleteTherapy = async () => {
              const therapyId = therapy.id;
              await deleteTherapy(therapyId);

              await getProgram();
            };
            confirmDeleteTherapy(handleDeleteTherapy);
          }}
        >
          Izbriši
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown overlay={menu} trigger={["click"]} onOpenChange={removeOverflowOnDropdownClose}>
        <div onClick={(e) => e.preventDefault()}>
          <TextContent>
            <span className={styles.cogwheel}>
              <SettingOutlined />
            </span>
          </TextContent>
        </div>
      </Dropdown>
    );
  };

  const nonMainTherapies = useMemo(() => {
    if (props?.program?.therapies) {
      return props?.program?.therapies?.filter((therapy) => {
        return therapy.adherenceTherapy === false;
      });
    }
    return [];
  }, [props]);
  const dataSource: ProgramMedicinesTableRecord[] =
    nonMainTherapies?.map((therapy, index) => {
      return {
        key: therapy.uuid + index,
        [ProgramMedicinesTableColumnDataIndex.ProtectedDrugName]: therapy?.medicine?.productName,
        [ProgramMedicinesTableColumnDataIndex.Type]: therapy?.medicine?.medicineType,
        [ProgramMedicinesTableColumnDataIndex.Inn]: therapy?.medicine?.inn,
        [ProgramMedicinesTableColumnDataIndex.UsageRegime]: therapy?.intakeRegime,
        [ProgramMedicinesTableColumnDataIndex.UsageDayPeriod]: therapy?.intakePartOfDay,
        [ProgramMedicinesTableColumnDataIndex.Option]: therapy,
      };
    }) || [];
  const columns = [
    {
      title: ProgramMedicinesTableColumnTitle.ProtectedDrugName,
      key: ProgramMedicinesTableColumnDataIndex.ProtectedDrugName,
      dataIndex: ProgramMedicinesTableColumnDataIndex.ProtectedDrugName,
    },
    {
      title: ProgramMedicinesTableColumnTitle.Type,
      key: ProgramMedicinesTableColumnDataIndex.Type,
      dataIndex: ProgramMedicinesTableColumnDataIndex.Type,
    },
    {
      title: ProgramMedicinesTableColumnTitle.Inn,
      key: ProgramMedicinesTableColumnDataIndex.Inn,
      dataIndex: ProgramMedicinesTableColumnDataIndex.Inn,
    },
    {
      title: ProgramMedicinesTableColumnTitle.UsageRegime,
      key: ProgramMedicinesTableColumnDataIndex.UsageRegime,
      dataIndex: ProgramMedicinesTableColumnDataIndex.UsageRegime,
      render: (usageRegime: IntakeRegime) => translateIntakeRegime(usageRegime),
    },
    {
      title: ProgramMedicinesTableColumnTitle.UsageDayPeriod,
      key: ProgramMedicinesTableColumnDataIndex.UsageDayPeriod,
      dataIndex: ProgramMedicinesTableColumnDataIndex.UsageDayPeriod,
      render: (intakePartOfDay: IntakePartOfDay) => translateIntakePartOfDay(intakePartOfDay),
    },
    {
      title: ProgramMedicinesTableColumnTitle.Option,
      key: ProgramMedicinesTableColumnDataIndex.Option,
      dataIndex: ProgramMedicinesTableColumnDataIndex.Option,
      render: renderOptionsColumn,
    },
  ];
  return (
    <>
      <div>
        <Space size={10} direction={"vertical"}>
          <div className={styles.header}>
            <TextContent strong={true}>Ostali lijekovi i dodaci prehrani</TextContent>
            <TextContent>
              <span
                className={styles.add}
                onClick={() => setAddMedicineModalOpened(!medicineModalOpened)}
              >
                Dodaj lijek
              </span>
            </TextContent>
          </div>
          <Table
            dataSource={dataSource}
            columns={columns}
            loading={loading || getMedicinesLoading || programLoading || deleteTherapyLoading}
          />
        </Space>
      </div>
      <ModalExtended
        title="Dodaj ostale lijekove i dodatke prehrani"
        open={medicineModalOpened}
        onCancel={() => setAddMedicineModalOpened(false)}
        afterClose={() => {
          setEditMode(false);
          setSelectedTherapy(undefined);
          setAddMedicineModalOpened(false);
          getProgram().then(() => {
            getMedicines();
          });
        }}
      >
        <AddMedicine
          setPatientMedicineTableLoading={setLoading}
          closeModal={setAddMedicineModalOpened}
          addExistingEditMode={editMode}
          therapy={selectedTherapy}
        />
      </ModalExtended>
    </>
  );
};
