import { EntityEndpoint } from "enums/EntityEndpoint";
import { ResourceType } from "enums/ResourceType";
import { AppApiActionHandler } from "./AppApiActionHandler";
import { ChatJSONAModel } from "../interfaces/jsona-models/ChatJSONAModel";
import { ChatSelector } from "../selectors/ChatSelector";

class ChatActionHandler extends AppApiActionHandler<ChatJSONAModel> {
  constructor() {
    super(ResourceType.Chat, EntityEndpoint.Chat, new ChatSelector());
  }
}

export const chatActionHandler = new ChatActionHandler();
