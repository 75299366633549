import { EntityEndpoint } from "enums/EntityEndpoint";
import { ResourceType } from "enums/ResourceType";
import { ConsentJSONAModel } from "interfaces/jsona-models/ConsentJSONAModel";
import { ConsentSelector } from "selectors/ConsentSelector";
import { AppApiActionHandler } from "./AppApiActionHandler";

class ConsentActionHandler extends AppApiActionHandler<ConsentJSONAModel> {
  constructor() {
    super(ResourceType.Consent, EntityEndpoint.Consent, new ConsentSelector());
  }
}

export const consentActionHandler = new ConsentActionHandler();
