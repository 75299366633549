import { usePatch, usePost } from "@bornfight/aardvark";
import { Button, Form, Input } from "antd";
import { ResourceType } from "enums/ResourceType";
import { AdminAddPharmacyFormItemLabel } from "features/admin/components/AdminAddPharmacyForm/enums/AdminAddPharmacyFormItemLabel";
import { AdminAddPharmacyFormItemName } from "features/admin/components/AdminAddPharmacyForm/enums/AdminAddPharmacyFormItemName";
import { AdminAddPharmacyFormProps } from "features/admin/components/AdminAddPharmacyForm/interfaces/AdminAddPharmacyFormProps";
import { AdminAddPharmacyValues } from "features/admin/components/AdminAddPharmacyForm/interfaces/AdminAddPharmacyValues";
import { pharmacyActionHandler } from "handlers/PharmacyActionHandler";
import * as React from "react";
import { FunctionComponent } from "react";

export const AdminAddPharmacyForm: FunctionComponent<AdminAddPharmacyFormProps> = (props) => {
  const { create: createPharmacy, loading: createPharmacyLoading } = usePost(pharmacyActionHandler);
  const { update: editPharmacy, loading: editPharmacyLoading } = usePatch(pharmacyActionHandler);

  const getInitialValues = (): AdminAddPharmacyValues | undefined => {
    if (props.isEditing) {
      return {
        name: props.currentPharmacy?.name || "",
        adress: props.currentPharmacy?.address || "",
        phoneNumber: props.currentPharmacy?.phoneNumber || "",
        email: props.currentPharmacy?.emailAddress || "",
      };
    }
    return undefined;
  };

  const handleEdit = (values: AdminAddPharmacyValues) => {
    if (props.currentPharmacy) {
      editPharmacy(props.currentPharmacy.id, {
        model: {
          type: ResourceType.Pharmacy,
          id: props.currentPharmacy.id,
          name: values[AdminAddPharmacyFormItemName.Name],
          address: values[AdminAddPharmacyFormItemName.Adress],
          phoneNumber: values[AdminAddPharmacyFormItemName.PhoneNumber],
          emailAddress: values[AdminAddPharmacyFormItemName.Email],
        },
        includeNames: [],
      })
        .then(() => {
          props.closeModal();
          props.setPharmacyLoading(false);
        })
        .catch((err) => {
          console.log(err);
          props.setPharmacyLoading(false);
        });
    }
  };

  const handleSubmit = (values: AdminAddPharmacyValues) => {
    props.setPharmacyLoading(true);
    if (props.isEditing) {
      handleEdit(values);
      return;
    }
    createPharmacy({
      rawData: {
        data: {
          attributes: {
            name: values[AdminAddPharmacyFormItemName.Name],
            address: values[AdminAddPharmacyFormItemName.Adress],
            phoneNumber: values[AdminAddPharmacyFormItemName.PhoneNumber],
            emailAddress: values[AdminAddPharmacyFormItemName.Email],
          },
        },
      },
    })
      .then(() => {
        props.closeModal();
        props.setPharmacyLoading(false);
      })
      .catch((err) => {
        console.log(err);
        props.setPharmacyLoading(false);
      });
  };

  return (
    <Form
      layout={"vertical"}
      onFinish={(values) => {
        handleSubmit(values as unknown as AdminAddPharmacyValues);
      }}
      initialValues={getInitialValues()}
    >
      <Form.Item
        label={AdminAddPharmacyFormItemLabel.Name}
        name={AdminAddPharmacyFormItemName.Name}
        rules={[
          {
            required: true,
            message: "Ovo polje je obavezno.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={AdminAddPharmacyFormItemLabel.Adress}
        name={AdminAddPharmacyFormItemName.Adress}
        rules={[
          {
            required: true,
            message: "Ovo polje je obavezno.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={AdminAddPharmacyFormItemLabel.PhoneNumber}
        name={AdminAddPharmacyFormItemName.PhoneNumber}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={AdminAddPharmacyFormItemLabel.Email}
        name={AdminAddPharmacyFormItemName.Email}
        rules={[
          {
            type: "email",
            message: "Pogrešan format email adrese.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          type={"primary"}
          htmlType={"submit"}
          loading={createPharmacyLoading || editPharmacyLoading}
        >
          Spremi
        </Button>
      </div>
    </Form>
  );
};
