import { useGetAllControlled } from "@bornfight/aardvark";
import { Table } from "antd";
import { RangePicker } from "common/RangePicker/RangePicker";
import { DateTimeFormat } from "enums/DateTimeFormat";
import { fetchReportExport } from "features/admin/components/Reports/fetchReportExport";
import { ReportQuestionnairesTableColumnDataIndex } from "features/admin/components/Reports/ReportQuestionnairesTable/enums/ReportQuestionnairesTableColumnDataIndex";
import { ReportQuestionnairesTableColumnTitle } from "features/admin/components/Reports/ReportQuestionnairesTable/enums/ReportQuestionnairesTableColumnTitle";
import { ReportQuestionnairesTableRecord } from "features/admin/components/Reports/ReportQuestionnairesTable/interfaces/ReportQuestionnairesTableRecord";
import { questionnaireActionHandler } from "handlers/QuestionnaireActionHandler";
import { QuestionnaireJSONAModel } from "interfaces/jsona-models/QuestionnaireJSONAModel";
import { Moment } from "moment";
import * as React from "react";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { AppJsonApiQuery } from "utils/AppJsonApiQuery";
import styles from "./ReportQuestionnaireTable.module.scss";

export const ReportQuestionnairesTable: FunctionComponent = () => {
  const [dateRange, setDateRange] = useState<[Moment, Moment] | undefined>(undefined);
  const questionnairesJsonApiQuery = useMemo(() => {
    return new AppJsonApiQuery({ customParams: [] });
  }, []);

  const {
    collection: questionnaires,
    getAll: getQuestionnaires,
    loading: getQuestionnairesLoading,
  } = useGetAllControlled(questionnaireActionHandler, questionnairesJsonApiQuery);

  useEffect(() => {
    // currently admins cannot fetch /questionnaires
    getQuestionnaires();
  }, [getQuestionnaires]);

  const dataSource: ReportQuestionnairesTableRecord[] = questionnaires.map((questionnaire) => {
    return {
      key: questionnaire.id,
      [ReportQuestionnairesTableColumnDataIndex.Questionnaire]: questionnaire.name,
      [ReportQuestionnairesTableColumnDataIndex.Id]: questionnaire.uuid,
      [ReportQuestionnairesTableColumnDataIndex.Options]: questionnaire,
    };
  });
  const columns = [
    {
      title: ReportQuestionnairesTableColumnTitle.Questionnaire,
      dataIndex: ReportQuestionnairesTableColumnDataIndex.Questionnaire,
      key: ReportQuestionnairesTableColumnDataIndex.Questionnaire,
    },
    {
      title: ReportQuestionnairesTableColumnTitle.Options,
      dataIndex: ReportQuestionnairesTableColumnDataIndex.Options,
      key: ReportQuestionnairesTableColumnDataIndex.Options,
      render: (reportData: QuestionnaireJSONAModel) => {
        return (
          <span
            className={dateRange !== undefined ? styles.exportLink : styles.nonLink}
            onClick={() => {
              let reportExportUrl = "";
              let fileName = "";
              if (dateRange) {
                reportExportUrl = createReportExportUrl(reportData.uuid, dateRange);
                fileName = createFileName(reportData.name, dateRange);
              }
              if (reportExportUrl.length > 1) {
                fetchReportExport(reportExportUrl, fileName);
              }
            }}
          >
            Izvezi odgovore
          </span>
        );
      },
    },
  ];

  const createReportExportUrl = (id: string, dateRange: [Moment, Moment]) => {
    const dayBeforeStartDate = dateRange[0]
      .subtract(1, "d")
      .utcOffset(0, false)
      .startOf("d")
      .format(DateTimeFormat.YearMonthDayDashed);

    const dayAfterEndDate = dateRange[1]
      .add(1, "d")
      .utcOffset(0, false)
      .startOf("d")
      .format(DateTimeFormat.YearMonthDayDashed);

    return `questionnaires/${id}/submissions/export?createdAt[strictly_after]=${dayBeforeStartDate}&?createdAt[strictly_before]=${dayAfterEndDate}&success=true`;
  };

  const createFileName = (questionnaireName: string, dateRange: [Moment, Moment]) => {
    const underscoredName = questionnaireName.replace(/\s/g, "_");
    const actualStartDate = dateRange[0].add(1, "d").format(DateTimeFormat.DashedLocalized);
    const actualEndDate = dateRange[1].subtract(1, "d").format(DateTimeFormat.DashedLocalized);

    return `${underscoredName}_${actualStartDate}_${actualEndDate}`;
  };

  return (
    <>
      <div className={styles.tableHeader}>
        <span className={styles.tableTitle}>Izvještaj - upitnici</span>
        <div>
          <RangePicker
            onChange={(value: unknown) => {
              // [Moment, Moment] | null, No exported RangeValue<Moment> from antd
              if (value !== null) {
                setDateRange(value as [Moment, Moment]);
                return;
              }
              setDateRange(undefined);
            }}
          />
        </div>
      </div>
      <Table
        loading={getQuestionnairesLoading}
        dataSource={dataSource}
        columns={columns}
        className={styles.table}
      />
    </>
  );
};
