import { Layout } from "antd";
import { Sidebar } from "common/Sidebar/Sidebar";
import { BasicLayoutProps } from "layouts/interfaces/BasicLayoutProps";
import { FC } from "react";
import * as React from "react";
import styles from "./BasicLayout.module.scss";

const { Header, Content } = Layout;

export const BasicLayout: FC<BasicLayoutProps> = (props) => {
  const { sideMenu, topMenu, content } = props;
  const ContentComponent = content;

  return (
    <Layout className={styles.wrapper}>
      <Sidebar menu={sideMenu} />
      <Layout className={styles.wrapper}>
        <Header className={styles.header}>{topMenu}</Header>
        <Content className={styles["content-wrapper"]}>
          <ContentComponent />
        </Content>
      </Layout>
    </Layout>
  );
};
