import { Spin } from "antd";
import Title from "antd/lib/typography/Title";
import { PageHeaderProps } from "common/PageHeader/interfaces/PageHeaderProps";
import * as React from "react";
import { FunctionComponent } from "react";
import styles from "./PageHeader.module.scss";

export const PageHeader: FunctionComponent<PageHeaderProps> = (props) => {
  return (
    <div className={styles.wrap}>
      {props.loading ? (
        <Spin />
      ) : (
        <>
          <div className={styles["title-wrap"]}>
            <div>{props.leftComponent}</div>
            <Title level={4}>{props.title}</Title>
          </div>
          <div>{props.rightComponent}</div>
        </>
      )}
    </div>
  );
};
