import { ExclamationCircleOutlined } from "@ant-design/icons/lib";
import { usePatch } from "@bornfight/aardvark";
import { Col, Modal, notification, Row } from "antd";
import { TextContent } from "common/TextContent/TextContent";
import { DateTimeFormat } from "enums/DateTimeFormat";
import { ResourceType } from "enums/ResourceType";
import { RouteUrl } from "enums/RouteUrl";
import { programActionHandler } from "handlers/ProgramActionHandler";
import moment from "moment";
import * as React from "react";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ProgramSection.module.scss";
import { ProgramJSONAModel } from "../../../../interfaces/jsona-models/ProgramJSONAModel";

const { confirm } = Modal;
const confirmAbortProgramModal = (onOk: () => void) => {
  confirm({
    title: "Želite li zbilja prekinuti program?",
    icon: <ExclamationCircleOutlined />,
    onOk() {
      onOk();
    },
    onCancel() {},
  });
};

const abortedProgramNotification = () => {
  notification["success"]({
    message: "Program pacijenta je prekinut.",
  });
};

export const ProgramSection: FunctionComponent<{
  program: ProgramJSONAModel | null;
}> = ({ program }) => {
  const navigate = useNavigate();

  const { update: updateProgram } = usePatch(programActionHandler);

  const handleProgramDropout = () => {
    const programId = program?.id || "";
    const data = {
      model: {
        type: ResourceType.Program,
        id: programId,
        dropOut: true,
      },
      includeNames: [],
    };
    if (programId !== "") {
      updateProgram(programId, {
        model: data.model,
        includeNames: data.includeNames,
      }).then(() => {
        abortedProgramNotification();
        navigate(RouteUrl.Activities);
      });
    }
  };

  const programName = program?.programTemplate.name;
  const firstActivity = program?.activities?.find((activity) => {
    return activity.activityOrder === 0;
  });
  const lastActivity = program?.activities?.[program?.activities?.length - 1];
  const programStartDate = moment(firstActivity?.expectedDate).format(
    DateTimeFormat.ShortLocalized
  );
  const programDescription = program?.programTemplate?.description;
  const programDuration = (): string => {
    const first = moment(firstActivity?.expectedDate).startOf("day");
    const last = moment(lastActivity?.expectedDate).startOf("day");

    // daysFromFirstToLastActivity
    const programDurationDays = Math.abs(first.diff(last, "days"));

    let dayWord = "dana";
    if (programDurationDays < 2) {
      dayWord = "dan";
    }
    return `${programDurationDays} ${dayWord}`;
  };
  return (
    <div>
      <div className={styles.header}>
        <Row justify={"space-between"}>
          <Col>
            <TextContent fontSize={16} strong={true}>
              {programName}
            </TextContent>
          </Col>
          <Col>
            <TextContent
              type={"danger"}
              className={styles.link}
              disabled={program?.dropOut === true}
            >
              <span
                onClick={() => {
                  if (program?.dropOut === true) {
                    return;
                  }
                  confirmAbortProgramModal(handleProgramDropout);
                }}
              >
                Prekini program
              </span>
            </TextContent>
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          <Col span={8} className={styles["program-info"]}>
            <TextContent>
              <div>Trajanje programa</div>
              <p>
                <b>{programDuration()}</b>
              </p>
            </TextContent>
            <TextContent>
              <div>Početak programa</div>
              <b>{programStartDate}</b>
            </TextContent>
          </Col>
          <Col span={16} className={styles["program-description"]}>
            <TextContent>
              <div>Opis programa</div>
              <b>{programDescription}</b>
            </TextContent>
          </Col>
        </Row>
      </div>
    </div>
  );
};
