import { Menu, Skeleton } from "antd";
import { RouteUrl } from "enums/RouteUrl";
import { ActivityTemplateMenuProps } from "features/programs/components/ActivityTemplateMenu/interfaces/ActivityTemplateMenuProps";
import * as React from "react";
import { FunctionComponent } from "react";
import { Link, useMatch, useNavigate, useParams } from "react-router-dom";
import styles from "./ActivityTemplateMenu.module.scss";

const MenuItem = Menu.Item;

export const ActivityTemplateMenu: FunctionComponent<ActivityTemplateMenuProps> = (props) => {
  const navigate = useNavigate();
  const params = useParams<{
    programTemplateId: string;
    activityTemplateId: string;
  }>();
  const activityTemplateId = params.activityTemplateId;

  const currentRoute = useMatch(RouteUrl.ProgramTemplate);

  if (!activityTemplateId || !currentRoute) {
    navigate(RouteUrl.ProgramTemplates);
    return null;
  }

  const { pathname } = currentRoute;

  if (props.activityTemplates?.length === 0 || props.activityTemplates === undefined) {
    return <Skeleton />;
  }

  return (
    <Menu
      className={styles.menu}
      mode={"inline"}
      theme={"light"}
      selectedKeys={[activityTemplateId ?? ""]}
    >
      {props?.activityTemplates?.map((activityTemplate) => {
        return (
          <MenuItem key={activityTemplate.uuid}>
            <Link to={pathname.replace(activityTemplateId, activityTemplate.uuid)}>
              {`${activityTemplate?.name}`}
            </Link>
          </MenuItem>
        );
      })}
    </Menu>
  );
};
