import * as React from "react";
import { useEffect, useMemo, useRef } from "react";
import styles from "features/chat/ChatMessageList.module.scss";
import { Empty, List, Tooltip } from "antd";
import cx from "classnames";
import moment from "moment";
import { scrollParentToChild } from "utils/scrollIntoViewInsideDiv";

export interface ChatMessage {
  id?: string;
  text: string;
  createdAt: Date | string;
  type: "inbound" | "outbound";
}

interface Props {
  messages: ChatMessage[];
  maxHeight?: number;
  showBorderBottom?: boolean;
}

const ChatMessageListUnmemoized: React.FunctionComponent<
  React.HTMLAttributes<HTMLElement> & Props
> = ({ messages, maxHeight = 250, showBorderBottom = true }) => {
  const lastEl = useRef<HTMLDivElement | null>();
  const wrapper = useRef(null);
  useEffect(() => {
    const lastElementDom = lastEl?.current;
    const wrapperDom = wrapper?.current;

    if (lastElementDom && wrapperDom) {
      scrollParentToChild(wrapperDom, lastElementDom);
    }
  }, [messages]);

  const wrapperStyles = useMemo(() => {
    return {
      height: maxHeight,
    };
  }, [maxHeight]);

  return (
    <div
      className={cx(styles.background, {
        [styles.noBorderBottom]: !showBorderBottom,
      })}
      style={wrapperStyles}
      ref={wrapper}
    >
      <List
        locale={{
          emptyText: <Empty description={"Nema poruka"} />,
        }}
        bordered={false}
        dataSource={messages}
        renderItem={(item, index) => {
          const momentDate = moment(item.createdAt);
          return (
            <List.Item
              data-test={`message-list-item-${index}`}
              className={cx(styles.listItem, {
                [styles.messageInbound]: item?.type === "inbound",
                [styles.messageOutbound]: item?.type === "outbound",
              })}
            >
              <div
                ref={(ref) => {
                  if (index === messages.length - 1) {
                    lastEl.current = ref;
                  }
                }}
                className={styles.text}
              >
                <span>{item.text}</span>
                <span className={styles.timestamp}>
                  <Tooltip title={momentDate.format("DD.MM.YYYY HH:mm")}>
                    <span>{momentDate.format("HH:mm")}</span>
                  </Tooltip>
                </span>
              </div>
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export const ChatMessageList = React.memo(ChatMessageListUnmemoized);
