import { ArrowLeftOutlined } from "@ant-design/icons/lib";
import { useGet } from "@bornfight/aardvark";
import { Col, Row, Skeleton } from "antd";
import { PageHeader } from "common/PageHeader/PageHeader";
import { PageWrap } from "common/PageWrap/PageWrap";
import { RouteUrl } from "enums/RouteUrl";
import { ProgramSectionWithProps } from "features/patients/components/ProgramSection/ProgramSectionWithProps";
import { ActivityTemplate } from "features/programs/components/ActivityTemplate/ActivityTemplate";
import { ActivityTemplateMenu } from "features/programs/components/ActivityTemplateMenu/ActivityTemplateMenu";
import { ProgramTemplateMedicineTable } from "features/programs/components/ProgramTemplateMedicineTable/ProgramTemplateMedicineTable";
import { programTemplateActionHandler } from "handlers/ProgramTemplateActionHandler";
import * as React from "react";
import { FunctionComponent, useMemo, useState } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import { useParams } from "react-router-dom";

export const SingleProgramScreen: FunctionComponent = () => {
  const navigate = useNavigate();
  const [stepsLoading, setStepsLoading] = useState(false);

  const params = useParams<{ programTemplateId: string }>();

  const programTemplateId = useMemo(() => {
    return params.programTemplateId;
  }, [params.programTemplateId]);

  const programTemplateIncludes = useMemo(() => {
    return ["programs", "activityTemplates", "medicines"];
  }, []);

  const { record: programTemplate } = useGet(
    programTemplateActionHandler,
    `/api/program_templates/${programTemplateId}`,
    programTemplateIncludes
  );

  // program lasting in days === all offsets combined
  const programDuration = programTemplate?.activityTemplates?.reduce(
    (acc, it) => acc + it.dayOffset,
    0
  );

  return (
    <div>
      <PageHeader
        leftComponent={
          <ArrowLeftOutlined
            style={{ fontSize: "26px" }}
            onClick={() => navigate(RouteUrl.ProgramTemplates)}
          />
        }
        title={`Program: ${programTemplate?.name}`}
      />
      <PageWrap>
        <ProgramSectionWithProps
          programDuration={programDuration}
          programName={programTemplate?.name}
          description={programTemplate?.description}
        />
      </PageWrap>
      <PageWrap>
        <ProgramTemplateMedicineTable medicines={programTemplate?.medicines} />
      </PageWrap>
      <PageWrap noLeftPadding={true}>
        <Row>
          <Col span={8}>
            {stepsLoading ? (
              <Skeleton />
            ) : (
              <ActivityTemplateMenu activityTemplates={programTemplate?.activityTemplates} />
            )}
          </Col>
          <Col span={15} offset={1}>
            <ActivityTemplate
              activityTemplates={programTemplate?.activityTemplates}
              setGetStepsLoading={setStepsLoading}
            />
          </Col>
        </Row>
      </PageWrap>
    </div>
  );
};
