import { EntityEndpoint } from "enums/EntityEndpoint";
import { ResourceType } from "enums/ResourceType";
import { PharmacyJSONAModel } from "interfaces/jsona-models/PharmacyJSONAModel";
import { PharmacySelector } from "selectors/PharmacySelector";
import { AppApiActionHandler } from "./AppApiActionHandler";

class PharmacyActionHandler extends AppApiActionHandler<PharmacyJSONAModel> {
  constructor() {
    super(ResourceType.Pharmacy, EntityEndpoint.Pharmacy, new PharmacySelector());
  }
}

export const pharmacyActionHandler = new PharmacyActionHandler();
