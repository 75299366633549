import { useGet, usePatch, usePost } from "@bornfight/aardvark";
import { Button, Col, Row } from "antd";
import { Space } from "common/Space/Space";
import { TextContent } from "common/TextContent/TextContent";
import { ResourceType } from "enums/ResourceType";
import { FinishedConversation } from "features/patients/components/Steps/FinishedConversation";
import { StepConversationProps } from "features/patients/components/Steps/interfaces/StepConversationProps";
import { chatActionHandler } from "handlers/ChatActionHandler";
import { patientActionHandler } from "handlers/PatientActionHandler";
import { stepActionHandler } from "handlers/StepActionHandler";
import * as React from "react";
import { Fragment, FunctionComponent, useCallback, useMemo, useState } from "react";
import { Chat } from "../../../chat/Chat";

export const StepConversation: FunctionComponent<StepConversationProps> = (props) => {
  const { step, stepOrder } = props;
  const { stepTemplate } = step;
  const { record: person } = useGet(patientActionHandler, `${props.patientId}`);

  const mobilePhoneNumber = person?.mobilePhoneNumber || "Nije definiran";
  const telePhoneNumber = person?.landlinePhoneNumber || "Nije definiran";

  const { create, record: chat, loading: chatCreateLoading } = usePost(chatActionHandler);

  const { update: updateStep } = usePatch(stepActionHandler);

  const handleStepComplete = useCallback(() => {
    updateStep(step.id, {
      model: {
        id: step.id,
        type: ResourceType.Step,
        completed: true,
      },
      includeNames: [],
    });
  }, [updateStep, step.id]);

  const handleStartChatClick = useCallback(() => {
    create({
      rawData: {
        data: {
          type: "chat",
          relationships: {
            conversation: {
              id: stepTemplate?.conversation?.id,
              type: ResourceType.Conversation,
            },
            step: {
              id: step.id,
              type: ResourceType.Step,
            },
          },
        },
      },
    }).then(() => {
      setIsChatActive(true);
    });
  }, [stepTemplate, step, create]);

  /**
   * a bit of a hacky solution that relies on current implementation that there can be multiple chats on a step
   * @see https://bornfight.atlassian.net/secure/RapidBoard.jspa?rapidView=31&projectKey=PLIVA&view=planning&selectedIssue=PLIVA-212&epics=visible&issueLimit=100
   * TODO: change this upon refactor to toOne relationship
   */
  const hasExistingChat = useMemo(() => {
    const chatsLength = Array.isArray(step.chats) ? step.chats.length : 0;
    return chatsLength > 0;
  }, [step]);

  const isNewChatReadyForInteractivity = useMemo(
    () => chat && !chatCreateLoading,
    [chat, chatCreateLoading]
  );

  const [isChatActive, setIsChatActive] = useState<boolean>(false);

  const handleChatClose = useCallback(() => {
    setIsChatActive(false);
    handleStepComplete();
  }, [handleStepComplete]);

  const renderNonInteractiveView = () => {
    const chatsLength = step?.chats?.length ?? 0;
    const chatId = (chatsLength > 0 && step?.chats?.[0]?.id) || undefined;

    if (!chatId) {
      return (
        <span data-test={"step-message-no-previous-chat"}>Nema ostvarenog SMS razgovora.</span>
      );
    }

    return <FinishedConversation chatId={chatId} />;
  };

  const renderNewConversationView = () => {
    return (
      <Fragment>
        <Button
          data-test="start-conversation"
          type={"primary"}
          onClick={handleStartChatClick}
          loading={chatCreateLoading}
          disabled={props.disabledActivity}
        >
          Pošalji SMS
        </Button>
        {isNewChatReadyForInteractivity && (
          <Chat
            url={process.env["REACT_APP_PUSHER_URL"] as string}
            isActive={isChatActive}
            defaultMessage={stepTemplate?.conversation?.initialMessage}
            key={chat?.id}
            chatUuid={chat?.uuid as string}
            onChatClose={handleChatClose}
          />
        )}
      </Fragment>
    );
  };

  return (
    <div>
      <TextContent strong={true} fontSize={16}>{`${stepOrder}. ${stepTemplate.name}`}</TextContent>
      <TextContent>
        <p>{stepTemplate.description}</p>
      </TextContent>
      <Space size={12} direction={"vertical"}>
        <Row>
          <Col span={12}>
            <TextContent>
              <p>Broj mobitela</p>
              <p>{mobilePhoneNumber}</p>
            </TextContent>
          </Col>
          <Col span={12}>
            <TextContent>
              <p>Broj telefona</p>
              <p>{telePhoneNumber}</p>
            </TextContent>
          </Col>
        </Row>
        <div>
          {!hasExistingChat && !step.completed
            ? renderNewConversationView()
            : renderNonInteractiveView()}
        </div>
      </Space>
    </div>
  );
};
