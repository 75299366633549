import { usePatch, usePost } from "@bornfight/aardvark";
import { Button, Form, Input } from "antd";
import { ResourceType } from "enums/ResourceType";
import { AdminAddQuestionFormProps } from "features/admin/components/AdminAddQuestionForm/interfaces/AdminAddQuestionFormProps";
import { AdminQuestionnaireRouteParams } from "features/admin/interfaces/AdminQuestionnaireRouteParams";
import { questionActionHandler } from "handlers/QuestionActionHandler";
import * as React from "react";
import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import { getIriFromUuid } from "utils/getIriFromUuid";

export const AdminAddQuestionForm: FunctionComponent<AdminAddQuestionFormProps> = (props) => {
  const params = useParams<AdminQuestionnaireRouteParams>();
  const { create: createQuestion, loading: createQuestionLoading } = usePost(questionActionHandler);
  const { update: editQuestion, loading: editQuestionLoading } = usePatch(questionActionHandler);

  const getInitialValues = () => {
    if (props.isEditing) {
      return { questionText: props.currentQuestion?.text };
    }
    return undefined;
  };

  const handleEditQuestion = (values: { questionText: string }) => {
    if (props.currentQuestion) {
      editQuestion(props.currentQuestion.id, {
        model: {
          type: ResourceType.Question,
          id: props.currentQuestion.id,
          text: values.questionText,
        },
        includeNames: [],
      })
        .then(() => {
          props.getQuestionnaire();
          props.closeModal();
        })
        .catch((err) => console.log(err));
    }
  };

  const handleSubmit = (values: { questionText: string }) => {
    if (props.isEditing) {
      handleEditQuestion(values);
      return;
    }
    const questionnaireIri = getIriFromUuid("questionnaires", params.questionnaireId);
    createQuestion({
      rawData: {
        data: {
          attributes: { text: values.questionText },
          relationships: {
            questionnaires: [
              {
                type: ResourceType.Questionnaire,
                id: questionnaireIri,
              },
            ],
          },
        },
      },
    })
      .then(() => {
        props.getQuestionnaire();
      })
      .catch((err) => console.log(err));
  };
  return (
    <Form
      layout={"vertical"}
      onFinish={(values) => {
        handleSubmit(values as unknown as { questionText: string });
      }}
      initialValues={getInitialValues()}
    >
      <Form.Item
        label={"Pitanje"}
        name={"questionText"}
        rules={[
          {
            required: true,
            message: "Ovo polje je obavezno.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          type={"primary"}
          htmlType={"submit"}
          loading={createQuestionLoading || editQuestionLoading}
        >
          Spremi
        </Button>
      </div>
    </Form>
  );
};
