import { EntityEndpoint } from "enums/EntityEndpoint";
import { ResourceType } from "enums/ResourceType";
import { ActivityJSONAModel } from "interfaces/jsona-models/ActivityJSONAModel";
import { ActivitySelector } from "selectors/ActivitySelector";
import { AppApiActionHandler } from "./AppApiActionHandler";

class ActivityActionHandler extends AppApiActionHandler<ActivityJSONAModel> {
  constructor() {
    super(ResourceType.Activity, EntityEndpoint.Activity, new ActivitySelector());
  }
}

export const activityActionHandler = new ActivityActionHandler();
