import { ArrowLeftOutlined } from "@ant-design/icons/lib";
import { PageHeader } from "common/PageHeader/PageHeader";
import { PageWrap } from "common/PageWrap/PageWrap";
import { ArchivedActivityTable } from "features/activities/components/ArchivedActivityTable";
import * as React from "react";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

export const ArchivedActivitiesScreen: FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <>
      <PageHeader
        title={"Arhivirane aktivnosti"}
        leftComponent={
          <ArrowLeftOutlined style={{ fontSize: "26px" }} onClick={() => navigate(-1)} />
        }
      />
      <PageWrap coverWhole={true}>
        <ArchivedActivityTable />
      </PageWrap>
    </>
  );
};
