import { Button, Col, Form, Input, notification, Row } from "antd";
import { apiSaga } from "app/store";
import { TextContent } from "common/TextContent/TextContent";
import { RouteUrl } from "enums/RouteUrl";
import styles from "features/authentication/components/LoginForm.module.scss";
import * as React from "react";
import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";

const onPasswordResetNotification = () => {
  notification["info"]({
    message: "Zahtjev za promjenu lozinke je uspješno poslan",
  });
  return;
};
const onPasswordResetFailNotification = () => {
  notification["error"]({
    message: "Greška",
    description: "Zahtjev za promjenu lozinke nije poslan. Krivi email je upisan",
  });
  return;
};

export const ForgotPasswordForm: FunctionComponent = () => {
  const [error, setError] = useState<any>();
  const navigate = useNavigate();

  const submitForm = (formValues: { email: string }) => {
    apiSaga.apiService.httpAdapter
      .post(
        `${process.env.REACT_APP_API_URL}users/request-reset`,
        {
          email: `${formValues.email}`,
        },
        {
          headers: {
            "Content-Type": "application/json\n",
            Authorization: null,
          },
        }
      )
      .then(() => {
        onPasswordResetNotification();
        navigate(RouteUrl.Login);
      })
      .catch((error) => {
        console.log(error);
        onPasswordResetFailNotification();
      });
  };

  return (
    <div>
      <Form
        layout={"vertical"}
        onFinish={(values: unknown) => submitForm(values as { email: string })}
        onChange={() => {
          setError(null);
        }}
      >
        <Form.Item
          label={"Email adresa:"}
          name={"email"}
          rules={[
            {
              required: true,
              message: "Email adresa nije ispravnog formata!",
              type: "email",
            },
          ]}
          validateTrigger={["onBlur", "onSubmit"]}
        >
          <Input data-test={"email-input"} />
        </Form.Item>
        <Button type={"primary"} htmlType={"submit"} style={{ width: "100%" }} data-test={"submit"}>
          Zatraži novu lozinku
        </Button>
      </Form>
      {error ? <TextContent type={"danger"}>{error}</TextContent> : null}
      <Row justify={"center"}>
        <Col>
          <p
            className={styles["lost-password"]}
            onClick={() => {
              navigate(RouteUrl.Login);
            }}
          >
            Natrag na prijavu
          </p>
        </Col>
      </Row>
    </div>
  );
};
