export const getUuidFromIri = (iri: string) => {
  /* iri string syntax::
        /api/patients/b657502b-e995-4c69-a8ed-8633a054d63a

        returns only uuid part b657502b-e995-4c69-a8ed-8633a054d63a
    */

  const extractedId = iri.split("/")[3];
  if (extractedId === undefined && process.env.NODE_ENV !== "production") {
    throw Error(
      `Wrong iri format: ${iri}. Iri should look like: /api/entity/uuid, e.g. /api/patients/uuid`
    );
  }
  return extractedId;
};
