import { usePatch, usePost } from "@bornfight/aardvark";
import { Button, Checkbox, Col, Form, Input, InputNumber, Row } from "antd";
import { ResourceType } from "enums/ResourceType";
import { AdminAddActivityTemplateFormItemLabel } from "features/admin/components/AdminAddActivityTemplateForm/enums/AdminAddActivityTemplateFormItemLabel";
import { AdminAddActivityTemplateFormItemName } from "features/admin/components/AdminAddActivityTemplateForm/enums/AdminAddActivityTemplateFormItemName";
import { AdminAddActivityTemplateFormProps } from "features/admin/components/AdminAddActivityTemplateForm/interfaces/AdminAddActivityTemplateFormProps";
import { AdminAddActivityTemplateFormValues } from "features/admin/components/AdminAddActivityTemplateForm/interfaces/AdminAddActivityTemplateFormValues";
import { AdminProgramTemplateRouteParams } from "features/admin/interfaces/AdminProgramTemplateRouteParams";
import { activityTemplateActionHandler } from "handlers/ActivityTemplateActionHandler";
import { RecommendedChannel } from "interfaces/jsona-models/ActivityTemplateJSONAModel";
import * as React from "react";
import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import { getIriFromUuid } from "utils/getIriFromUuid";
import styles from "./AdminAddActivityTemplateForm.module.scss";

export const AdminAddActivityTemplateForm: FunctionComponent<AdminAddActivityTemplateFormProps> = (
  props
) => {
  const params = useParams<AdminProgramTemplateRouteParams>();
  const { create: createActivityTemplate, loading: createActivityTempLoading } = usePost(
    activityTemplateActionHandler
  );
  const { update: editActivityTemplate, loading: editActivityTempLoading } = usePatch(
    activityTemplateActionHandler
  );
  const isEditing = Boolean(props?.editActivityTemplate);

  const translatedChannel = (channel: RecommendedChannel) => {
    let channelName = "";
    switch (channel) {
      case RecommendedChannel.Survey:
        return (channelName = "Upitnik");
      case RecommendedChannel.PhoneCall:
        return (channelName = "Telefonski poziv");
      case RecommendedChannel.Message:
        return (channelName = "SMS");
      case RecommendedChannel.VideoCall:
        return (channelName = "Video poziv");
      case RecommendedChannel.Live:
        return (channelName = "Uživo");
    }
    return channelName;
  };

  const handleEdit = (values: AdminAddActivityTemplateFormValues) => {
    if (props?.editActivityTemplate) {
      editActivityTemplate(props.editActivityTemplate.id, {
        model: {
          type: ResourceType.ActivityTemplate,
          id: props.editActivityTemplate.id,
          name: values[AdminAddActivityTemplateFormItemName.Name],
          dayOffset: values[AdminAddActivityTemplateFormItemName.DaysDelay],
          recommendedChannels: values[AdminAddActivityTemplateFormItemName.RecommendedChannels],
        },
        includeNames: [],
      })
        .then(() => {
          if (props?.getProgramTemplate) {
            props.getProgramTemplate();
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleSubmit = (values: AdminAddActivityTemplateFormValues) => {
    if (isEditing) {
      handleEdit(values);
      return;
    }

    const programTemplateId = getIriFromUuid("program_templates", params?.programTemplateId);

    createActivityTemplate({
      rawData: {
        data: {
          attributes: {
            name: values[AdminAddActivityTemplateFormItemName.Name],
            dayOffset: values[AdminAddActivityTemplateFormItemName.DaysDelay],
            recommendedChannels: values[AdminAddActivityTemplateFormItemName.RecommendedChannels],
          },
          relationships: {
            programTemplate: {
              type: ResourceType.ProgramTemplate,
              id: programTemplateId,
            },
          },
        },
      },
    })
      .then(() => {
        if (props?.getProgramTemplate) {
          props.getProgramTemplate();
        }
      })
      .catch((err) => console.log(err));
  };

  const getInitialValues = (): AdminAddActivityTemplateFormValues | undefined => {
    if (isEditing) {
      return {
        [AdminAddActivityTemplateFormItemName.Name]: props?.editActivityTemplate?.name || "",
        [AdminAddActivityTemplateFormItemName.DaysDelay]:
          props.editActivityTemplate?.dayOffset || 0,
        [AdminAddActivityTemplateFormItemName.RecommendedChannels]:
          props.editActivityTemplate?.recommendedChannels || [],
      };
    }
    return undefined;
  };

  return (
    <Form
      layout={"vertical"}
      onFinish={(values) => {
        handleSubmit(values as AdminAddActivityTemplateFormValues);
      }}
      initialValues={getInitialValues()}
    >
      <Form.Item
        label={AdminAddActivityTemplateFormItemLabel.Name}
        name={AdminAddActivityTemplateFormItemName.Name}
        rules={[
          {
            required: true,
            message: "Ovo polje je obavezno.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={AdminAddActivityTemplateFormItemLabel.DaysDelay}
        name={AdminAddActivityTemplateFormItemName.DaysDelay}
        rules={[
          {
            required: true,
            message: "Ovo polje je obavezno.",
          },
        ]}
      >
        <InputNumber style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        label={AdminAddActivityTemplateFormItemLabel.RecommendedChannels}
        name={AdminAddActivityTemplateFormItemName.RecommendedChannels}
        rules={[
          {
            required: true,
            message: "Ovo polje je obavezno.",
          },
        ]}
      >
        <Checkbox.Group>
          <Row>
            <Col span={24}>
              <Checkbox value={RecommendedChannel.Live}>
                {translatedChannel(RecommendedChannel.Live)}
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value={RecommendedChannel.PhoneCall}>
                {translatedChannel(RecommendedChannel.PhoneCall)}
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value={RecommendedChannel.Message}>
                {translatedChannel(RecommendedChannel.Message)}
              </Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>
      <div className={styles.submitWrap}>
        <Button
          type={"primary"}
          htmlType={"submit"}
          loading={createActivityTempLoading || editActivityTempLoading}
        >
          Spremi
        </Button>
      </div>
    </Form>
  );
};
