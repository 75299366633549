import * as React from "react";
import { ConfigProvider } from "antd";
import hr_HR from "antd/lib/locale-provider/hr_HR";
import moment from "moment";
import "moment/locale/hr";

moment.locale("hr");
export class AppConfigProvider extends React.Component {
  public render() {
    return (
      <ConfigProvider locale={hr_HR}>
        <React.Fragment>{this.props.children}</React.Fragment>
      </ConfigProvider>
    );
  }
}
