import { PageWrap } from "common/PageWrap/PageWrap";
import { AdminProgramTable } from "features/admin/components/AdminProgramTable/AdminProgramTable";
import pageCover from "features/admin/PageCoverStyle.module.scss";
import * as React from "react";
import { FunctionComponent } from "react";

export const AdminProgramsScreen: FunctionComponent = () => {
  return (
    <div className={pageCover.wrap}>
      <PageWrap>
        <AdminProgramTable />
      </PageWrap>
    </div>
  );
};
