import { Button } from "antd";
import { PageHeader } from "common/PageHeader/PageHeader";
import { PageWrap } from "common/PageWrap/PageWrap";
import { RouteUrl } from "enums/RouteUrl";
import { PatientTable } from "features/patients/components/PatientTable/PatientTable";
import * as React from "react";
import { FunctionComponent } from "react";
import { UserOutlined } from "@ant-design/icons/lib";
import { Link } from "react-router-dom";

export const PatientsScreen: FunctionComponent = () => {
  return (
    <div>
      <PageHeader
        title={"Pacijenti"}
        rightComponent={
          <Link to={RouteUrl.NewPatient}>
            <Button data-test={"new-patient-button"} type={"primary"} icon={<UserOutlined />}>
              Novi Pacijent
            </Button>
          </Link>
        }
      />
      <PageWrap coverWhole={true}>
        <PatientTable />
      </PageWrap>
    </div>
  );
};
