import { SideMenu } from "common/SideMenu/SideMenu";
import { UserStrip } from "common/UserStrip/UserStrip";
import { RouteUrl } from "enums/RouteUrl";
import { ActivitiesScreen } from "features/activities/components/ActivitiesScreen";
import { ArchivedActivitiesScreen } from "features/activities/components/ArchivedActivitiesScreen";
import { AdminProgramTemplateScreen } from "features/admin/components/AdminProgramTemplateScreen/AdminProgramTemplateScreen";
import { AdminQuestionnaireSingleScreen } from "features/admin/components/AdminQuestionnaireSingleScreen/AdminQuestionnaireSingleScreen";
import { AdminSideMenu } from "features/admin/components/AdminSideMenu/AdminSideMenu";
import { AdminProgramsScreen } from "features/admin/screens/AdminProgramsScreen";
import { AdminQuestionnairesScreen } from "features/admin/screens/AdminQuestionnairesScreen";
import { AdminReportsScreen } from "features/admin/screens/AdminReportsScreen";
import { AdminUsersScreen } from "features/admin/screens/AdminUsersScreen";
import { ForgotPasswordScreen } from "features/authentication/components/ForgotPasswordScreen";
import { LoginScreen } from "features/authentication/components/LoginScreen";
import { NewPasswordScreen } from "features/authentication/components/NewPasswordScreen";
import { NoMatchScreen } from "features/authentication/components/NoMatchScreen";
import { HomeScreen } from "features/home/components/HomeScreen";
import { NewPatientScreen } from "features/patients/components/NewPatientScreen";
import { PatientProgramActivityScreen } from "features/patients/components/PatientProgramActivityScreen";
import { PatientsScreen } from "features/patients/components/PatientsScreen";
import { SinglePatientScreen } from "features/patients/SinglePatientScreen";
import { ProgramsScreen } from "features/programs/components/ProgramsScreen";
import { SingleProgramScreen } from "features/programs/components/SingleProgramScreen";
import { BasicLayout } from "layouts/BasicLayout";
import * as React from "react";
import { FunctionComponent, useEffect } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { FallbackProvider } from "root/routing/components/FallbackProvider";
import { PrivateRoute } from "root/routing/components/PrivateRoute";

export const RouteView: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") navigate(RouteUrl.Login);
  }, [location]);

  const renderAppRouting = () => {
    return (
      <>
        <Route path={RouteUrl.Login} element={<LoginScreen />} />
        <Route path={RouteUrl.ResetPassword} element={<NewPasswordScreen />} />
        <Route path={RouteUrl.SetPassword} element={<NewPasswordScreen />} />
        <Route path={RouteUrl.ForgotPassword} element={<ForgotPasswordScreen />} />
      </>
    );
  };

  const renderPrivateRouting = () => {
    return (
      <>
        <Route
          path={RouteUrl.Home}
          element={
            <PrivateRoute
              component={() => (
                <BasicLayout sideMenu={<SideMenu />} topMenu={<UserStrip />} content={HomeScreen} />
              )}
            />
          }
        />

        <Route
          path={RouteUrl.Activities}
          element={
            <PrivateRoute
              component={() => (
                <BasicLayout
                  sideMenu={<SideMenu />}
                  topMenu={<UserStrip />}
                  content={ActivitiesScreen}
                />
              )}
            />
          }
        />

        <Route
          path={RouteUrl.ArchivedActivities}
          element={
            <PrivateRoute
              component={() => (
                <BasicLayout
                  sideMenu={<SideMenu />}
                  topMenu={<UserStrip />}
                  content={ArchivedActivitiesScreen}
                />
              )}
            />
          }
        />

        <Route
          path={RouteUrl.Patients}
          element={
            <PrivateRoute
              component={() => (
                <BasicLayout
                  sideMenu={<SideMenu />}
                  topMenu={<UserStrip />}
                  content={PatientsScreen}
                />
              )}
            />
          }
        />

        <Route
          path={RouteUrl.Patient}
          element={
            <PrivateRoute
              component={() => (
                <BasicLayout
                  sideMenu={<SideMenu />}
                  topMenu={<UserStrip />}
                  content={SinglePatientScreen}
                />
              )}
            />
          }
        />

        <Route
          path={RouteUrl.NewPatient}
          element={
            <PrivateRoute
              component={() => (
                <BasicLayout
                  sideMenu={<SideMenu />}
                  topMenu={<UserStrip />}
                  content={NewPatientScreen}
                />
              )}
            />
          }
        />

        <Route
          path={RouteUrl.PatientProgramActivity}
          element={
            <PrivateRoute
              component={() => (
                <BasicLayout
                  sideMenu={<SideMenu />}
                  topMenu={<UserStrip />}
                  content={PatientProgramActivityScreen}
                />
              )}
            />
          }
        />

        <Route
          path={RouteUrl.ProgramTemplates}
          element={
            <PrivateRoute
              component={() => (
                <BasicLayout
                  sideMenu={<SideMenu />}
                  topMenu={<UserStrip />}
                  content={ProgramsScreen}
                />
              )}
            />
          }
        />

        <Route
          path={RouteUrl.ProgramTemplate}
          element={
            <PrivateRoute
              component={() => (
                <BasicLayout
                  sideMenu={<SideMenu />}
                  topMenu={<UserStrip />}
                  content={SingleProgramScreen}
                />
              )}
            />
          }
        />
      </>
    );
  };

  const renderAdminRouting = () => {
    return (
      <>
        <Route
          path={RouteUrl.AdminUsers}
          element={
            <PrivateRoute
              adminRoute={true}
              component={() => (
                <BasicLayout
                  sideMenu={<AdminSideMenu />}
                  topMenu={<UserStrip />}
                  content={AdminUsersScreen}
                />
              )}
            />
          }
        />

        <Route
          path={RouteUrl.AdminPrograms}
          element={
            <PrivateRoute
              adminRoute={true}
              component={() => (
                <BasicLayout
                  sideMenu={<AdminSideMenu />}
                  topMenu={<UserStrip />}
                  content={AdminProgramsScreen}
                />
              )}
            />
          }
        />

        <Route
          path={RouteUrl.AdminProgramTemplate}
          element={
            <PrivateRoute
              adminRoute={true}
              component={() => (
                <BasicLayout
                  sideMenu={<AdminSideMenu />}
                  topMenu={<UserStrip />}
                  content={AdminProgramTemplateScreen}
                />
              )}
            />
          }
        />

        <Route
          path={RouteUrl.AdminQuestionnaires}
          element={
            <PrivateRoute
              adminRoute={true}
              component={() => (
                <BasicLayout
                  sideMenu={<AdminSideMenu />}
                  topMenu={<UserStrip />}
                  content={AdminQuestionnairesScreen}
                />
              )}
            />
          }
        />

        <Route
          path={RouteUrl.AdminQuestionnaireSingle}
          element={
            <PrivateRoute
              adminRoute={true}
              component={() => (
                <BasicLayout
                  sideMenu={<AdminSideMenu />}
                  topMenu={<UserStrip />}
                  content={AdminQuestionnaireSingleScreen}
                />
              )}
            />
          }
        />

        <Route
          path={RouteUrl.AdminReports}
          element={
            <PrivateRoute
              adminRoute={true}
              component={() => (
                <BasicLayout
                  sideMenu={<AdminSideMenu />}
                  topMenu={<UserStrip />}
                  content={AdminReportsScreen}
                />
              )}
            />
          }
        />
      </>
    );
  };

  return (
    <>
      <FallbackProvider>
        <Routes>
          {/* 404 page if non others */}
          <Route path="*" element={<Navigate to={RouteUrl.Page404} />} />
          <Route path={RouteUrl.Page404} element={<NoMatchScreen />} />
          <Route path={"/"}>
            {renderAppRouting()}
            {renderPrivateRouting()}
          </Route>
          <Route path={RouteUrl.AdminDefault}>{renderAdminRouting()}</Route>
        </Routes>
      </FallbackProvider>
    </>
  );
};
