import { SettingOutlined } from "@ant-design/icons/lib";
import { useDelete, useGetAllControlled } from "@bornfight/aardvark";
import { Button, Dropdown, Menu, Table } from "antd";
import { ModalExtended } from "common/ModalExtended/ModalExtended";
import { TextContent } from "common/TextContent/TextContent";
import { StepType } from "enums/entitySpecifics/StepType";
import { AdminActivityStepsTableColumnDataIndex } from "features/admin/components/AdminActivityStepsTable/enums/AdminActivityStepsTableColumnDataIndex";
import { AdminActivityStepsTableColumnTitle } from "features/admin/components/AdminActivityStepsTable/enums/AdminActivityStepsTableColumnTitle";
import { AdminActivityStepsTableProps } from "features/admin/components/AdminActivityStepsTable/interfaces/AdminActivityStepsTableProps";
import { AdminAddStepTemplateForm } from "features/admin/components/AdminAddStepTemplateForm/AdminAddStepTemplateForm";
import { stepTemplateActionHandler } from "handlers/StepTemplateActionHandler";
import { StepTemplateJSONAModel } from "interfaces/jsona-models/StepTemplateJSONAModel";
import * as React from "react";
import { FunctionComponent, useMemo, useState } from "react";
import { useDeepCompareEffect, useDeepCompareMemo } from "use-deep-compare";
import { AppJsonApiQuery } from "utils/AppJsonApiQuery";
import { getUuidFromIri } from "utils/getUuidFromIri";
import { removeOverflowOnDropdownClose } from "utils/removeOverflowOnDropdownClose";
import styles from "./AdminActivityStepsTable.module.scss";

export const AdminActivityStepsTable: FunctionComponent<AdminActivityStepsTableProps> = (props) => {
  const [newStepVisible, setNewStepVisible] = useState<boolean>(false);
  const [selectedStepTemplate, setSelectedStepTemplate] = useState<
    StepTemplateJSONAModel | undefined
  >(undefined);
  const { deleteRecord: deleteStepTemplate } = useDelete(stepTemplateActionHandler);

  const stepTemplateIds = useMemo(() => {
    return props?.stepTemplates?.map((stepTemplate) => {
      return getUuidFromIri(stepTemplate?.id);
    });
  }, [props]);

  const stepTemplateQuery = useDeepCompareMemo(() => {
    const customParams = stepTemplateIds?.map((id) => {
      return {
        name: "uuid[]",
        value: id,
      };
    });
    return new AppJsonApiQuery({
      customParams,
      includes: ["questionnaire", "instruction", "conversation"],
    });
  }, [stepTemplateIds]);
  const { getAll: getAllStepTemplates, loading: stepTemplateLoading } = useGetAllControlled(
    stepTemplateActionHandler,
    stepTemplateQuery
  );
  useDeepCompareEffect(() => {
    if (stepTemplateIds?.length === 0) {
      return;
    }
    getAllStepTemplates();
  }, [getAllStepTemplates, stepTemplateIds]);

  const handleDeleteStepTemplate = (id: string) => {
    deleteStepTemplate(id)
      .then(() => {
        if (props.getProgramTemplate) {
          props.getProgramTemplate();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderTranslatedStepType = (stepType: StepType) => {
    switch (stepType) {
      case StepType.Conversation:
        return "Poruka";
      case StepType.Questionnaire:
        return "Upitnik";
      case StepType.Instruction:
        return "Instrukcija";
    }
  };

  const renderOptionsColumn = (optionsData: StepTemplateJSONAModel) => {
    const menu = (
      <Menu>
        <Menu.Item
          key={"editStep"}
          onClick={async () => {
            await setSelectedStepTemplate(optionsData);
            await setNewStepVisible(true);
          }}
        >
          Uredi
        </Menu.Item>
        <Menu.Item
          key={"deleteStep"}
          onClick={() => {
            handleDeleteStepTemplate(optionsData.id);
          }}
        >
          Izbriši
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        disabled={props.programEnabled}
        onOpenChange={removeOverflowOnDropdownClose}
      >
        <div onClick={(e) => e.preventDefault()} className={styles.menuItemWidth}>
          <TextContent>
            <span className={props.programEnabled ? undefined : styles.cogwheel}>
              <SettingOutlined />
            </span>
          </TextContent>
        </div>
      </Dropdown>
    );
  };

  const dataSource = props?.stepTemplates?.map((step, index) => {
    return {
      key: step.uuid,
      [AdminActivityStepsTableColumnDataIndex.Step]: index + 1,
      [AdminActivityStepsTableColumnDataIndex.StepType]: renderTranslatedStepType(step.stepType),
      [AdminActivityStepsTableColumnDataIndex.StepName]: step.name,
      [AdminActivityStepsTableColumnDataIndex.Options]: step,
    };
  });
  const columns = [
    {
      title: AdminActivityStepsTableColumnTitle.Step,
      dataIndex: AdminActivityStepsTableColumnDataIndex.Step,
      key: AdminActivityStepsTableColumnDataIndex.Step,
    },
    {
      title: AdminActivityStepsTableColumnTitle.StepType,
      dataIndex: AdminActivityStepsTableColumnDataIndex.StepType,
      key: AdminActivityStepsTableColumnDataIndex.StepType,
    },
    {
      title: AdminActivityStepsTableColumnTitle.StepName,
      dataIndex: AdminActivityStepsTableColumnDataIndex.StepName,
      key: AdminActivityStepsTableColumnDataIndex.StepName,
    },
    {
      title: AdminActivityStepsTableColumnTitle.Options,
      dataIndex: AdminActivityStepsTableColumnDataIndex.Options,
      key: AdminActivityStepsTableColumnDataIndex.Options,
      render: (optionsData: StepTemplateJSONAModel) => {
        return renderOptionsColumn(optionsData);
      },
    },
  ];
  return (
    <>
      <div className={styles.tableHeader}>
        <span className={styles.tableTitle}>{props.title}</span>{" "}
        <Button
          type={"primary"}
          onClick={() => setNewStepVisible(true)}
          disabled={props.programEnabled}
        >
          Dodaj korak
        </Button>
      </div>
      <Table
        pagination={false}
        loading={stepTemplateLoading}
        columns={columns}
        dataSource={dataSource}
      />
      <ModalExtended
        title="Novi korak"
        key={selectedStepTemplate?.uuid}
        open={newStepVisible}
        onCancel={() => {
          setNewStepVisible(false);
          setSelectedStepTemplate(undefined);
        }}
      >
        <AdminAddStepTemplateForm
          editStepTemplate={selectedStepTemplate}
          currentActivityTemplate={props.currentActivityTemplate}
          closeModal={() => setNewStepVisible(false)}
          getProgramTemplate={props.getProgramTemplate}
        />
      </ModalExtended>
    </>
  );
};
