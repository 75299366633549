import { Col, Row } from "antd";
import { TextContent } from "common/TextContent/TextContent";
import { ProgramSectionWithPropsProps } from "features/patients/components/ProgramSection/interfaces/ProgramSectionWithPropsProps";
import * as React from "react";
import { FunctionComponent } from "react";
import styles from "./ProgramSection.module.scss";

export const ProgramSectionWithProps: FunctionComponent<ProgramSectionWithPropsProps> = (props) => {
  return (
    <div>
      <div className={styles.header}>
        <Row justify={"space-between"}>
          <Col>
            <TextContent fontSize={16} strong={true}>
              {props.programName}
            </TextContent>
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          <Col span={8} className={styles["program-info"]}>
            <TextContent>
              <div>Trajanje programa</div>
              <p>
                <b>{props.programDuration} dana</b>
              </p>
            </TextContent>
          </Col>
          <Col span={16} className={styles["program-description"]}>
            <TextContent>
              <div>Opis programa</div>
              <b>{props.description || "Nema opisa programa"}</b>
            </TextContent>
          </Col>
        </Row>
      </div>
    </div>
  );
};
