import { Menu } from "antd";
import styles from "common/SideMenu/SideMenu.module.scss";
import { AdminSideMenuItem } from "features/admin/components/AdminSideMenu/enums/AdminSideMenuItem";
import { AdminSideMenuItemUrl } from "features/admin/components/AdminSideMenu/enums/AdminSideMenuItemUrl";
import * as React from "react";
import { FunctionComponent } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as SideMenuLogo } from "static/sideMenuLogo.svg";

export const AdminSideMenu: FunctionComponent = () => {
  const pathName = useLocation().pathname;
  return (
    <>
      <div className={styles["logo-wrap"]}>
        <SideMenuLogo />
      </div>

      <Menu mode={"inline"} theme={"dark"} selectedKeys={[pathName]}>
        <Menu.Item key={AdminSideMenuItemUrl.Users}>
          <Link to={AdminSideMenuItemUrl.Users}>{AdminSideMenuItem.Users}</Link>
        </Menu.Item>
        <Menu.Item key={AdminSideMenuItemUrl.Programs}>
          <Link to={AdminSideMenuItemUrl.Programs}>{AdminSideMenuItem.Programs}</Link>
        </Menu.Item>
        <Menu.Item key={AdminSideMenuItemUrl.Questionnaires}>
          <Link to={AdminSideMenuItemUrl.Questionnaires}>{AdminSideMenuItem.Questionnaires}</Link>
        </Menu.Item>
        <Menu.Item key={AdminSideMenuItemUrl.Reports}>
          <Link to={AdminSideMenuItemUrl.Reports}>{AdminSideMenuItem.Reports}</Link>
        </Menu.Item>
      </Menu>
    </>
  );
};
