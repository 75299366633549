import { PageWrap } from "common/PageWrap/PageWrap";
import { AdminPharmaciesTable } from "features/admin/components/AdminPharmaciesTable/AdminPharmaciesTable";
import { AdminAdminsTable } from "features/admin/components/AdminAdminsTable/AdminAdminsTable";
import { AdminPharmacistsTable } from "features/admin/components/AdminPharmacistsTable/AdminPharmacistsTable";
import pageCover from "features/admin/PageCoverStyle.module.scss";
import * as React from "react";
import { FunctionComponent } from "react";

export const AdminUsersScreen: FunctionComponent = () => {
  return (
    <div className={pageCover.wrap}>
      <PageWrap>
        <AdminAdminsTable />
      </PageWrap>
      <PageWrap>
        <AdminPharmacistsTable />
      </PageWrap>
      <PageWrap>
        <AdminPharmaciesTable />
      </PageWrap>
    </div>
  );
};
