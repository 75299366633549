import { TextContent } from "common/TextContent/TextContent";
import { StepTemplateInstructionProps } from "features/programs/components/StepTemplates/interfaces/StepTemplateInstructionProps";
import * as React from "react";
import { FunctionComponent } from "react";

export const StepTemplateInstruction: FunctionComponent<StepTemplateInstructionProps> = (props) => {
  const { stepTemplate } = props;

  return (
    <div>
      <TextContent strong={true} fontSize={16}>{`${stepTemplate?.name}`}</TextContent>
      <TextContent>
        <p>{stepTemplate?.instruction?.instructionText}</p>
      </TextContent>
    </div>
  );
};
