import { SettingOutlined } from "@ant-design/icons/lib";
import { useDelete, useGetAllControlled } from "@bornfight/aardvark";
import { Button, Dropdown, Menu, Table } from "antd";
import { ModalExtended } from "common/ModalExtended/ModalExtended";
import { TextContent } from "common/TextContent/TextContent";
import { AdminAddQuestionnaireForm } from "features/admin/components/AdminAddQuestionnaireForm/AdminAddQuestionnaireForm";
import { AdminQuestionnaireTableColumnDataIndex } from "features/admin/components/AdminQuestionnaireTable/enums/AdminQuestionnaireTableColumnDataIndex";
import { AdminQuestionnaireTableColumnTitle } from "features/admin/components/AdminQuestionnaireTable/enums/AdminQuestionnaireTableColumnTitle";
import { AdminQuestionnaireTableRecord } from "features/admin/components/AdminQuestionnaireTable/interfaces/AdminQuestionnaireTableRecord";
import { questionnaireActionHandler } from "handlers/QuestionnaireActionHandler";
import { QuestionnaireJSONAModel } from "interfaces/jsona-models/QuestionnaireJSONAModel";
import * as React from "react";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { AppJsonApiQuery } from "utils/AppJsonApiQuery";
import { removeOverflowOnDropdownClose } from "utils/removeOverflowOnDropdownClose";
import styles from "./AdminQuestionnaireTable.module.scss";

export const AdminQuestionnaireTable: FunctionComponent = () => {
  const [addQuestionnaireVisible, setAddQuestionnaireVisible] = useState<boolean>(false);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [currentQuestionnaire, setCurrentQuestionnaire] = useState<
    QuestionnaireJSONAModel | undefined
  >(undefined);

  const questionnairesJsonApiQuery = useMemo(() => {
    const includes = ["questions", "stepTemplates"];
    return new AppJsonApiQuery({
      includes,
      customParams: [],
    });
  }, []);

  let {
    collection: questionnaires,
    loading: questionnairesLoading,
    getAll: getAllQuestionnaires,
  } = useGetAllControlled(questionnaireActionHandler, questionnairesJsonApiQuery);
  useEffect(() => {
    getAllQuestionnaires();
  }, [getAllQuestionnaires]);

  const { deleteRecord: deleteQuestionnaire, loading: deleteQuestionnaireLoading } = useDelete(
    questionnaireActionHandler
  );

  const handleDeleteQuestionnaire = (questionnaireId: string) => {
    deleteQuestionnaire(questionnaireId)
      .then(() => {
        getAllQuestionnaires();
      })
      .catch((err) => console.log(err));
  };

  const renderOptionsColumn = (optionsData: QuestionnaireJSONAModel) => {
    const menu = (
      <Menu>
        <Menu.Item
          disabled={optionsData.partOfProgram}
          key={"edit"}
          onClick={async () => {
            await setIsEditing(true);
            await setCurrentQuestionnaire(optionsData);
            setAddQuestionnaireVisible(true);
          }}
        >
          Uredi
        </Menu.Item>
        <Menu.Item
          disabled={optionsData.partOfProgram}
          key={"deactivate"}
          onClick={() => {
            handleDeleteQuestionnaire(optionsData.id);
          }}
        >
          Izbriši
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown overlay={menu} trigger={["click"]} onOpenChange={removeOverflowOnDropdownClose}>
        <div onClick={(e) => e.preventDefault()} className={styles.menuItemWidth}>
          <TextContent>
            <span className={styles.cogwheel}>
              <SettingOutlined />
            </span>
          </TextContent>
        </div>
      </Dropdown>
    );
  };

  const dataSource: AdminQuestionnaireTableRecord[] = questionnaires.map((questionnaire) => {
    return {
      key: questionnaire.id,
      [AdminQuestionnaireTableColumnDataIndex.Name]: questionnaire.name,
      [AdminQuestionnaireTableColumnDataIndex.Link]: questionnaire.uuid,
      [AdminQuestionnaireTableColumnDataIndex.Options]: questionnaire,
    };
  });
  const columns = [
    {
      title: AdminQuestionnaireTableColumnTitle.Name,
      dataIndex: AdminQuestionnaireTableColumnDataIndex.Name,
      key: AdminQuestionnaireTableColumnDataIndex.Name,
    },
    {
      title: AdminQuestionnaireTableColumnTitle.Link,
      dataIndex: AdminQuestionnaireTableColumnDataIndex.Link,
      key: AdminQuestionnaireTableColumnDataIndex.Link,
      render: (questionnaireUuid: string) => {
        return <Link to={`/admin/questionnaires/${questionnaireUuid}`}>Idi na Upitnik</Link>;
      },
    },
    {
      title: AdminQuestionnaireTableColumnTitle.Options,
      dataIndex: AdminQuestionnaireTableColumnDataIndex.Options,
      key: AdminQuestionnaireTableColumnDataIndex.Options,
      render: (optionsData: QuestionnaireJSONAModel) => {
        return renderOptionsColumn(optionsData);
      },
    },
  ];

  return (
    <>
      <div className={styles.tableHeader}>
        <span className={styles.tableTitle}>Upitnici programa</span>
        <Button type={"primary"} onClick={() => setAddQuestionnaireVisible(true)}>
          Dodaj novi
        </Button>
      </div>
      <Table
        loading={questionnairesLoading || deleteQuestionnaireLoading}
        dataSource={dataSource}
        columns={columns}
        className={styles.table}
      />
      <ModalExtended
        title="Dodaj novi upitnik"
        open={addQuestionnaireVisible}
        onCancel={() => {
          setIsEditing(false);
          setCurrentQuestionnaire(undefined);
          setAddQuestionnaireVisible(false);
        }}
      >
        <AdminAddQuestionnaireForm
          closeModal={() => setAddQuestionnaireVisible(false)}
          getQuestionnaires={getAllQuestionnaires}
          currentQuestionnaire={currentQuestionnaire}
          isEditing={isEditing}
        />
      </ModalExtended>
    </>
  );
};
