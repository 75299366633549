import { JSONAModel } from "@bornfight/aardvark/dist/interfaces/JSONAModel";
import { useGetAll } from "@bornfight/aardvark";
import React from "react";
import { ResourceCollectionProviderProps } from "./ResourceCollectionProviderOwnProps";

export interface InjectedWithResourceCollectionStateProps<T extends JSONAModel = JSONAModel> {
  collection: T[];
  loading: boolean;
  count?: number;
}

export const ResourceCollectionProvider: React.FunctionComponent<
  {
    render: (props: InjectedWithResourceCollectionStateProps<any>) => React.ReactNode;
  } & ResourceCollectionProviderProps
> = ({ render, apiActionHandler, jsonApiQuery }) => {
  const getAll = useGetAll(apiActionHandler, jsonApiQuery);
  return <>{render(getAll)}</>;
};
