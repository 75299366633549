import { apiSaga } from "app/store";
import { RouteUrl } from "enums/RouteUrl";
import { ActivitiesMeta } from "interfaces/ActivitiesMeta";
import { PatientsMeta } from "interfaces/PatientsMeta";
import * as React from "react";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { HomeCard } from "./HomeCard";
import styles from "./HomeScreen.module.scss";
import { IconCard } from "./IconCard";
import { TextImageBlock } from "./TextImageBlock";

export const HomeScreen: FunctionComponent = () => {
  const [patientsMeta, setPatientsMeta] = useState<PatientsMeta | undefined>(undefined);
  const [activitiesMeta, setActivitiesMeta] = useState<ActivitiesMeta | undefined>(undefined);
  const [activitiesLoading, setActivitiesLoading] = useState<boolean>(false);
  const [patientsLoading, setPatientsLoading] = useState<boolean>(false);

  const getPatientsMeta = useCallback(() => {
    setPatientsLoading(true);
    return apiSaga.apiService.httpAdapter
      .get(`${process.env.REACT_APP_API_URL}patients/dashboard`)
      .then((response) => {
        const meta = response?.data?.data?.[0] as PatientsMeta;
        setPatientsMeta(meta);
        setPatientsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setPatientsLoading(false);
      });
  }, []);
  const getActivitiesMeta = useCallback(() => {
    setActivitiesLoading(true);
    return apiSaga.apiService.httpAdapter
      .get(`${process.env.REACT_APP_API_URL}activities/dashboard`)
      .then((response) => {
        const meta = response?.data?.data?.[0] as ActivitiesMeta;
        setActivitiesMeta(meta);
        setActivitiesLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setActivitiesLoading(false);
      });
  }, []);

  useEffect(() => {
    getPatientsMeta();
    getActivitiesMeta();
  }, [getPatientsMeta, getActivitiesMeta]);

  return (
    <div>
      <div className={styles.wrap}>
        <TextImageBlock
          title={"Pliva Adherencija"}
          subtitle={"Svrha i cilj programa"}
          text={
            "Redovita i pravilna primjena lijekova važan je dio Vašeg liječenja. Kako bi lijek najbolje djelovao potrebno ga je uzimati u određeno vrijeme, na pravilan način i dovoljno dugo. Kako bismo Vam pomogli u primjeni Vaših lijekova, provodimo program kroz koji bismo Vam pružali potrebne informacije i podršku. Kroz program bismo Vas savjetovali i kontaktirali tijekom nekoliko sljedećih mjeseci na način koji Vama najbolje odgovara."
          }
        />
        <IconCard text={"Dodaj pacijenta"} url={RouteUrl.NewPatient} />
        <div className={styles.otherCardsWrap}>
          <div className={styles.marginWrap}>
            <HomeCard
              loading={activitiesLoading}
              title={"Aktivnosti"}
              extra={{ url: RouteUrl.Activities }}
              content={[
                {
                  title: "Danas",
                  value: `${activitiesMeta?.todayActivities}`,
                },
                {
                  title: "Prekoračeno",
                  value: `${activitiesMeta?.exceededActivities}`,
                },
              ]}
            />
          </div>
          <HomeCard
            loading={patientsLoading}
            title={"Pacijenti"}
            extra={{ url: RouteUrl.Patients }}
            content={[
              {
                title: "Aktivnih pacijenata",
                value: `${patientsMeta?.activePatients}`,
              },
              {
                title: "Ukupno",
                value: `${patientsMeta?.allPatients}`,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};
