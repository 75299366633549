import { usePatch, usePost } from "@bornfight/aardvark";
import { Button, Form, Input } from "antd";
import { ErrorMessage } from "common/ErrorMessage/ErrorMessage";
import { TextContent } from "common/TextContent/TextContent";
import { ResourceType } from "enums/ResourceType";
import { AdminAddAdminFormItemLabel } from "features/admin/components/AdminAddAdminForm/enums/AdminAddAdminFormItemLabel";
import { AdminAddAdminFormItemName } from "features/admin/components/AdminAddAdminForm/enums/AdminAddAdminFormItemName";
import { AdminAddAdminFormProps } from "features/admin/components/AdminAddAdminForm/interfaces/AdminAddAdminFormProps";
import { AdminAddAdminFormValues } from "features/admin/components/AdminAddAdminForm/interfaces/AdminAddAdminFormValues";
import { AdminAddPharmacistFormItemName } from "features/admin/components/AdminAddPharmacistForm/enums/AdminAddPharmacistFormItemName";
import { administratorActionHandler } from "handlers/AdministratorActionHandler";
import { RequestError } from "interfaces/RequestError";
import * as React from "react";
import { FunctionComponent, useState } from "react";

export const AdminAddAdminForm: FunctionComponent<AdminAddAdminFormProps> = (props) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const { create: createAdmin, loading: createAdminLoading } = usePost(administratorActionHandler);
  const { update: editAdmin, loading: editAdminLoading } = usePatch(administratorActionHandler);
  const getInitialValues = (): AdminAddAdminFormValues | undefined => {
    if (props.isEditing) {
      return {
        [AdminAddAdminFormItemName.FirstName]: props.currentAdmin?.firstName || "",
        [AdminAddAdminFormItemName.LastName]: props.currentAdmin?.lastName || "",
        [AdminAddAdminFormItemName.Email]: props.currentAdmin?.email || "",
      };
    }
    return undefined;
  };

  const handleEdit = (values: AdminAddAdminFormValues) => {
    if (props.currentAdmin) {
      editAdmin(props.currentAdmin.id, {
        model: {
          type: ResourceType.Administrator,
          id: props.currentAdmin.id,
          email: values[AdminAddPharmacistFormItemName.Email],
          firstName: values[AdminAddPharmacistFormItemName.FirstName],
          lastName: values[AdminAddPharmacistFormItemName.LastName],
          username: values[AdminAddAdminFormItemName.Email],
        },
        includeNames: [],
      })
        .then(() => {
          props.setAdminLoading(false);
          props.closeModal();
        })
        .catch((err) => {
          props.setAdminLoading(false);
          console.log(err);
        });
    }
  };

  const handleSubmit = (values: AdminAddAdminFormValues) => {
    props.setAdminLoading(true);
    if (props.isEditing) {
      handleEdit(values);
      return;
    }
    createAdmin({
      rawData: {
        data: {
          attributes: {
            email: values[AdminAddAdminFormItemName.Email],
            firstName: values[AdminAddAdminFormItemName.FirstName],
            lastName: values[AdminAddAdminFormItemName.LastName],
            username: values[AdminAddAdminFormItemName.Email],
          },
        },
      },
    })
      .then((response) => {
        props.setAdminLoading(false);
        props.closeModal();
      })
      .catch((err: any) => {
        const errorData = err.response.data as RequestError;
        props.setAdminLoading(false);

        const hasSameEmailError = errorData?.violations?.some((violation) => {
          return violation?.propertyPath === "email";
        });
        if (hasSameEmailError) {
          setError("Email se već koristi!");
          return;
        }
        if (errorData.title === "An error occurred") {
          setError("Administrator nije uspješno kreiran!");
        }
      });
  };

  return (
    <Form
      layout={"vertical"}
      onFinish={(values) => {
        handleSubmit(values as unknown as AdminAddAdminFormValues);
      }}
      initialValues={getInitialValues()}
    >
      <Form.Item
        label={AdminAddAdminFormItemLabel.FirstName}
        name={AdminAddAdminFormItemName.FirstName}
        rules={[
          {
            required: true,
            message: "Ovo polje je obavezno.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={AdminAddAdminFormItemLabel.LastName}
        name={AdminAddAdminFormItemName.LastName}
        rules={[
          {
            required: true,
            message: "Ovo polje je obavezno.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={AdminAddAdminFormItemLabel.Email}
        name={AdminAddAdminFormItemName.Email}
        rules={[
          {
            required: true,
            message: "Ovo polje je obavezno.",
            type: "email",
          },
        ]}
      >
        <Input disabled={props.isEditing} />
      </Form.Item>
      {props.isEditing ? null : (
        <TextContent>
          Klikom na “Kreiraj korisnički račun” korisniku će biti poslan email s uputama za
          postavljanje lozinke te će mu biti kreiran korisnički račun.
        </TextContent>
      )}
      {error ? (
        <div style={{ marginTop: 10 }}>
          <ErrorMessage error={error} />
        </div>
      ) : null}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 10,
        }}
      >
        <Button
          type={"primary"}
          htmlType={"submit"}
          loading={createAdminLoading || editAdminLoading}
        >
          {props.isEditing ? "Uredi administratora" : "Kreiraj korisnički račun"}
        </Button>
      </div>
    </Form>
  );
};
