import * as React from "react";
import { useCallback, useMemo } from "react";
import { Button, Col, Popconfirm, Row, Space } from "antd";
import { usePatch } from "@bornfight/aardvark";
import { ResourceType } from "enums/ResourceType";
import { chatActionHandler } from "handlers/ChatActionHandler";
import { getIriFromUuid } from "utils/getIriFromUuid";
import { ChatJSONAModel } from "interfaces/jsona-models/ChatJSONAModel";
import { ChatStatus } from "enums/entitySpecifics/ChatStatus";

interface Props {
  sessionActive: boolean;
  onEndSessionClick: () => void;
  chatUuid: string;
  onChatClose: () => void;
}

const baseModel: Pick<ChatJSONAModel, "type"> = {
  type: ResourceType.Chat,
};

export const ChatFooter: React.FC<Props> = ({
  onEndSessionClick,
  sessionActive,
  chatUuid,
  onChatClose,
}) => {
  const { update: updateChatSuccess, loading: updateChatSuccessLoading } =
    usePatch(chatActionHandler);
  const { update: updateChatFailure, loading: updateChatFailureLoading } =
    usePatch(chatActionHandler);

  const chatId = useMemo(() => getIriFromUuid("chats", chatUuid), [chatUuid]);

  const handleChatSuccess = useCallback(() => {
    const data = {
      model: {
        ...baseModel,
        id: chatId,
        status: ChatStatus.Successful,
      },
      includeNames: [],
    };

    updateChatSuccess(chatId, data).then(() => {
      onChatClose();
    });
  }, [updateChatSuccess, chatId, onChatClose]);

  const handleChatFailure = useCallback(() => {
    const data = {
      model: {
        ...baseModel,
        id: chatId,
        status: ChatStatus.Failed,
      },
      includeNames: [],
    };
    updateChatFailure(chatId, data).then(() => {
      onChatClose();
    });
  }, [updateChatFailure, chatId, onChatClose]);

  if (!sessionActive) {
    return (
      <Row align={"middle"} justify={"space-between"}>
        <Col>
          <span>Kakav je ishod ove sesije?</span>
        </Col>
        <Col>
          <Space>
            <Button
              loading={updateChatFailureLoading}
              disabled={updateChatSuccessLoading}
              data-test={"chat-unsuccessful"}
              onClick={handleChatFailure}
            >
              Neuspješan
            </Button>
            <Button
              loading={updateChatSuccessLoading}
              disabled={updateChatFailureLoading}
              data-test={"chat-successful"}
              onClick={handleChatSuccess}
              type={"primary"}
            >
              Uspješan
            </Button>
          </Space>
        </Col>
      </Row>
    );
  }

  return (
    <Row justify={"start"}>
      <Popconfirm
        title="Jeste li sigurni da želite završiti sesiju?"
        onConfirm={onEndSessionClick}
        okText={<span data-test={"end-chat-session-popover-yes"}>Da</span>}
        cancelText="Ne"
      >
        <Button data-test={"end-chat-session"}>Završi sesiju</Button>
      </Popconfirm>
    </Row>
  );
};
