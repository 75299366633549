import { JsonApiQuery, JsonApiQueryConfig } from "@bornfight/aardvark";

export class AppJsonApiQuery extends JsonApiQuery {
  constructor(config: JsonApiQueryConfig) {
    super({
      ...config,
      pageNumberKeyName: "pageNumber",
      pageSizeKeyName: "pageSize",
    });
  }
}
