import { Space as AntdSpace } from "antd";
import { SpaceProps } from "antd/lib/space";
import * as React from "react";
import { FunctionComponent } from "react";

const spaceComponentStyle = { width: "100%" };
export const Space: FunctionComponent<SpaceProps> = (props) => {
  const { children, ...spaceProps } = props;

  return (
    <AntdSpace {...spaceProps} style={spaceComponentStyle}>
      {props.children}
    </AntdSpace>
  );
};
