import { Table } from "antd";
import { TextContent } from "common/TextContent/TextContent";
import { ProgramTemplateMedicineTableColummDataIndex } from "features/programs/components/ProgramTemplateMedicineTable/enums/ProgramTemplateMedicineTableColummDataIndex";
import { ProgramTemplateMedicineTableColummTitle } from "features/programs/components/ProgramTemplateMedicineTable/enums/ProgramTemplateMedicineTableColummTitle";
import { ProgramTemplateMedicineTableProps } from "features/programs/components/ProgramTemplateMedicineTable/interfaces/ProgramTemplateMedicineTableProps";
import * as React from "react";
import { FunctionComponent } from "react";

export const ProgramTemplateMedicineTable: FunctionComponent<ProgramTemplateMedicineTableProps> = (
  props
) => {
  const dataSource = props.medicines?.map((medicine) => {
    return {
      key: medicine.uuid,
      [ProgramTemplateMedicineTableColummDataIndex.ProtectedDrugName]: medicine.productName,
      [ProgramTemplateMedicineTableColummDataIndex.Type]: medicine.medicineType,
      [ProgramTemplateMedicineTableColummDataIndex.Inn]: medicine.inn,
    };
  });
  const columns = [
    {
      title: ProgramTemplateMedicineTableColummTitle.ProtectedDrugName,
      dataIndex: ProgramTemplateMedicineTableColummDataIndex.ProtectedDrugName,
      key: ProgramTemplateMedicineTableColummDataIndex.ProtectedDrugName,
    },
    {
      title: ProgramTemplateMedicineTableColummTitle.Type,
      dataIndex: ProgramTemplateMedicineTableColummDataIndex.Type,
      key: ProgramTemplateMedicineTableColummDataIndex.Type,
    },
    {
      title: ProgramTemplateMedicineTableColummTitle.Inn,
      dataIndex: ProgramTemplateMedicineTableColummDataIndex.Inn,
      key: ProgramTemplateMedicineTableColummDataIndex.Inn,
    },
  ];
  return (
    <div>
      <TextContent fontSize={16} strong={true}>
        <p>Popis lijekova za praćenje u programu</p>
      </TextContent>
      <Table dataSource={dataSource} columns={columns} />
    </div>
  );
};
