import { ArrowLeftOutlined } from "@ant-design/icons/lib";
import { Form, notification } from "antd";
import { UploadProps } from "antd/lib/upload";
import { apiSaga } from "app/store";
import { PageHeader } from "common/PageHeader/PageHeader";
import { PageWrap } from "common/PageWrap/PageWrap";
import { TextContent } from "common/TextContent/TextContent";
import { ResourceType } from "enums/ResourceType";
import { RouteUrl } from "enums/RouteUrl";
import { PatientGeneralInfo } from "features/patients/components/PatientGeneralInfo/PatientGeneralInfo";
import { PatientHealthInfo } from "features/patients/components/PatientHealthInfo/PatientHealthInfo";
import { PatientFormError } from "features/patients/enums/PatientFormError";
import { PatientFormField } from "features/patients/enums/PatientFormField";
import { PatientFormValues } from "features/patients/enums/PatientFormValues";
import styles from "features/patients/SinglePatientScreen.module.scss";
import { RequestError } from "interfaces/RequestError";
import moment from "moment";
import * as React from "react";
import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";

const patientCreateSuccessNotification = () => {
  notification["success"]({
    message: "Novi pacijent je uspješno spremljen.",
  });
};
const patientCreateErrorNotification = (description: string = "") => {
  notification["error"]({
    message: "Pacijent nije napravljen.",
    description,
  });
};

export const NewPatientScreen: FunctionComponent = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [createPatientLoading, setPatientLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<any[]>([]);

  const uploadProps: UploadProps = {
    beforeUpload: (file: any) => {
      setFileList([...fileList, file]);
      return false;
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);

      setFileList(newFileList);
    },
    maxCount: 1,
  };

  const handleCreatePatient = async (values: PatientFormValues) => {
    setPatientLoading(true);
    const formData = new FormData();
    formData.append("file", values[PatientFormField.GdprConsent].fileList[0].originFileObj);
    // create consent
    const newConsentPromise = await apiSaga.apiService.httpAdapter
      .post(`${process.env.REACT_APP_API_URL}consents`, formData, {
        headers: { "Content-Type": undefined },
      })
      .then((response) => {
        const responseConsentUuid = response.data.data.id;
        Promise.resolve(responseConsentUuid);
        return responseConsentUuid;
      })
      .catch(() => {
        setPatientLoading(false);
        return;
      });
    // create patientHealthData
    const newPatientHealthDataPromise = await apiSaga.apiService.httpAdapter
      .post(`${process.env.REACT_APP_API_URL}patient_health_datas`, {
        data: {
          attributes: {
            alcoholConsumption: values[PatientFormField.Alcohol],
            cigaretteConsumption: values[PatientFormField.Smoking],
            allergies: values[PatientFormField.Allergies],
            diagnosis: values[PatientFormField.DiseasesDiagnosis],
          },
        },
      })
      .then((response) => {
        const responsePatientHealthDataUuid = response.data.data.id;
        Promise.resolve(responsePatientHealthDataUuid);
        return responsePatientHealthDataUuid;
      })
      .catch(() => {
        setPatientLoading(false);
        return;
      });
    const createdConsentUuid = newConsentPromise;
    const patientHealthDataUuid = newPatientHealthDataPromise;

    const newPatientPromise = await apiSaga.apiService.httpAdapter
      .post(`${process.env.REACT_APP_API_URL}patients`, {
        data: {
          attributes: {
            firstName: values[PatientFormField.FirstName],
            lastName: values[PatientFormField.LastName],
            mobilePhoneNumber: values[PatientFormField.MobilePhoneNumber],
            landlinePhoneNumber: values[PatientFormField.TelephoneNumber],
            dateOfBirth: values[PatientFormField.Birthday],
            gender: values[PatientFormField.Gender],
            height: values[PatientFormField.Height],
            weight: values[PatientFormField.Weight],
          },
          relationships: {
            patientHealthData: {
              type: ResourceType.PatientHealthData,
              id: patientHealthDataUuid,
            },
            consent: {
              type: ResourceType.Consent,
              id: createdConsentUuid,
            },
          },
        },
      })
      .then((response) => {
        setPatientLoading(false);
        patientCreateSuccessNotification();

        const newPatientId = response.data.data.attributes.uuid;
        navigate(`${RouteUrl.Patients}/${newPatientId}`);
      })
      .catch((err) => {
        setPatientLoading(false);
        checkErrorAndShowNotification(err);
      });

    Promise.all([newConsentPromise, newPatientHealthDataPromise, newPatientPromise]).then(() => {
      setPatientLoading(false);
    });
  };

  const checkErrorAndShowNotification = (error: any) => {
    const mobilePhoneError =
      (error?.response?.data as RequestError)?.violations?.[0]?.propertyPath ===
      PatientFormField.MobilePhoneNumber;

    if (mobilePhoneError && error.response.data.detail === PatientFormError.UniquePhoneNumber) {
      patientCreateErrorNotification("Broj telefona mora biti jedinstven");
      return;
    }
    if (mobilePhoneError) {
      patientCreateErrorNotification(
        "Broj telefona je krivog formata. Format broja mora biti +3859 s dodatnih 7-9 znamenaka"
      );
      return;
    }
    patientCreateErrorNotification();
    return;
  };

  return (
    <div>
      <PageHeader
        title={"Novi pacijent"}
        leftComponent={
          <ArrowLeftOutlined style={{ fontSize: "26px" }} onClick={() => navigate(-1)} />
        }
      />
      <PageWrap coverWhole={true}>
        <Form
          form={form}
          className={styles["patient-info-wrap"]}
          layout={"vertical"}
          onFinish={async (values) => {
            await handleCreatePatient(values as PatientFormValues);
            form.resetFields();
            setFileList([]);
          }}
          initialValues={{
            [PatientFormField.Smoking]: null,
            [PatientFormField.Allergies]: "",
            [PatientFormField.DiseasesDiagnosis]: "",
            [PatientFormField.Alcohol]: null,
            [PatientFormField.MobilePhoneNumber]: "+3859",
            // set this date to fix firefox datepicker & to consider
            // timezones so the year remains correct
            [PatientFormField.Birthday]: moment("2000-01-02"),
            [PatientFormField.GdprConsent]: {
              file: {},
              fileList: [],
            },
          }}
        >
          <div className={styles["patient-general-info-wrap"]}>
            <TextContent fontSize={20}>
              <p className={styles.title}>Podaci novog pacijenta</p>
            </TextContent>
            <PatientGeneralInfo
              patient={null}
              createPatientLoading={createPatientLoading}
              fileList={fileList}
              uploadProps={uploadProps}
            />
          </div>
          <div className={styles["patient-health-info-wrap"]}>
            <PatientHealthInfo patient={null} />
          </div>
        </Form>
      </PageWrap>
    </div>
  );
};
