import { useGetAllControlled, useGetControlled, usePatch, usePost } from "@bornfight/aardvark";
import { Button, Form, Input, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { StepType } from "enums/entitySpecifics/StepType";
import { ResourceType } from "enums/ResourceType";
import styles from "features/admin/components/AdminAddActivityTemplateForm/AdminAddActivityTemplateForm.module.scss";
import { AdminAddStepTemplateFormItemLabel } from "features/admin/components/AdminAddStepTemplateForm/enums/AdminAddStepTemplateFormItemLabel";
import { AdminAddStepTemplateFormItemName } from "features/admin/components/AdminAddStepTemplateForm/enums/AdminAddStepTemplateFormItemName";
import { AdminAddStepTemplateFormProps } from "features/admin/components/AdminAddStepTemplateForm/interfaces/AdminAddStepTemplateFormProps";
import { AdminAddStepTemplateFormValues } from "features/admin/components/AdminAddStepTemplateForm/interfaces/AdminAddStepTemplateFormValues";
import { conversationActionHandler } from "handlers/ConversationActionHandler";
import { instructionActionHandler } from "handlers/InstructionActionHandler";
import { questionnaireActionHandler } from "handlers/QuestionnaireActionHandler";
import { stepTemplateActionHandler } from "handlers/StepTemplateActionHandler";
import { StepTemplateJSONAModel } from "interfaces/jsona-models/StepTemplateJSONAModel";
import * as React from "react";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { AppJsonApiQuery } from "utils/AppJsonApiQuery";
import { removeOverflowOnDropdownClose } from "utils/removeOverflowOnDropdownClose";

export const AdminAddStepTemplateForm: FunctionComponent<AdminAddStepTemplateFormProps> = (
  props
) => {
  // must match form's initial state
  const [currentStepType, setCurrentStepType] = useState<StepType>(StepType.Instruction);
  const isEditing = Boolean(props.editStepTemplate);
  const questionnaireQuery = useMemo(() => {
    return new AppJsonApiQuery({ includes: ["questions"] });
  }, []);
  const {
    getAll: getAllQuestionnaires,
    loading: getAllQuestionnairesLoading,
    collection: questionnaires,
  } = useGetAllControlled(questionnaireActionHandler, questionnaireQuery);

  const questionnaireIncludes = useMemo(() => {
    return ["questionnaire", "instruction", "conversation"];
  }, []);

  const { getSingle: getStepTemplate, loading: getStepTemplateLoading } = useGetControlled(
    stepTemplateActionHandler,
    props?.editStepTemplate?.id || "-",
    questionnaireIncludes
  );

  useEffect(() => {
    getAllQuestionnaires();
    if (isEditing) {
      getStepTemplate();
    }
  }, [getAllQuestionnaires, getStepTemplate, isEditing]);

  const { update: editStepTemplate, loading: editStepTempLoading } =
    usePatch(stepTemplateActionHandler);
  const { create: createStepTemplate, loading: createStepTempLoading } =
    usePost(stepTemplateActionHandler);
  const { create: createConversation, loading: createConversationLoading } =
    usePost(conversationActionHandler);
  const { create: createInstruction, loading: createInstructionLoading } =
    usePost(instructionActionHandler);

  const getStepTypeOptions = () => {
    const stepTypes = [StepType.Instruction, StepType.Conversation, StepType.Questionnaire];
    const translateStepType = (stepType: StepType) => {
      switch (stepType) {
        case StepType.Conversation:
          return "Poruka";
        case StepType.Questionnaire:
          return "Upitnik";
        case StepType.Instruction:
          return "Instrukcija";
      }
    };
    return stepTypes.map((stepType, index) => {
      return (
        <Select.Option key={index} value={stepType}>
          {translateStepType(stepType)}
        </Select.Option>
      );
    });
  };

  const getQuestionnaireOptions = () => {
    const questionnairesWithQuestions = questionnaires?.filter((questionnaire) => {
      return questionnaire?.questions?.length > 0;
    });
    const questionnairesWithQuestionsAndAnswers = questionnairesWithQuestions?.filter(
      (questionnaire) => {
        const doesQuestionWithNoAnswersExist = questionnaire?.questions?.some((question) => {
          return question?.answers?.length < 1 || question.answers === undefined;
        });
        return !doesQuestionWithNoAnswersExist;
      }
    );
    return questionnairesWithQuestionsAndAnswers?.map((questionnaire, index) => {
      return (
        <Select.Option key={index} value={questionnaire.id}>
          {questionnaire.name}
        </Select.Option>
      );
    });
  };

  const getInitialValues = (): AdminAddStepTemplateFormValues => {
    if (isEditing && props.editStepTemplate) {
      return {
        [AdminAddStepTemplateFormItemName.Type]: props.editStepTemplate.stepType,
        [AdminAddStepTemplateFormItemName.Name]: props.editStepTemplate.name,
        [AdminAddStepTemplateFormItemName.Description]: props.editStepTemplate.description,
        [AdminAddStepTemplateFormItemName.MessageText]:
          props.editStepTemplate.conversation?.initialMessage,
        [AdminAddStepTemplateFormItemName.QuestionnaireId]:
          props.editStepTemplate.questionnaire?.id,
      };
    }
    return {
      [AdminAddStepTemplateFormItemName.Type]: StepType.Instruction,
      [AdminAddStepTemplateFormItemName.Name]: "",
      [AdminAddStepTemplateFormItemName.Description]: "",
      [AdminAddStepTemplateFormItemName.MessageText]: undefined,
      [AdminAddStepTemplateFormItemName.QuestionnaireId]: undefined,
    };
  };

  const handleCreateStepTemplate = ({
    stepType,
    name,
    description,
    activityTemplateId,
    questionnaireId,
  }: {
    stepType: StepType;
    name: string;
    description: string;
    activityTemplateId: string;
    questionnaireId: string | undefined;
  }) => {
    if (questionnaireId) {
      return createStepTemplate({
        rawData: {
          data: {
            attributes: {
              stepType,
              name,
              description,
            },
            relationships: {
              activityTemplate: {
                type: ResourceType.ActivityTemplate,
                id: activityTemplateId,
              },
              questionnaire: {
                type: ResourceType.Questionnaire,
                id: questionnaireId,
              },
            },
          },
        },
      });
    }
    return createStepTemplate({
      rawData: {
        data: {
          attributes: {
            stepType,
            name,
            description,
          },
          relationships: {
            activityTemplate: {
              type: ResourceType.ActivityTemplate,
              id: activityTemplateId,
            },
          },
        },
      },
    });
  };
  const createConversationAndStepTemplate = (values: AdminAddStepTemplateFormValues) => {
    handleCreateStepTemplate({
      stepType: values[AdminAddStepTemplateFormItemName.Type],
      name: values[AdminAddStepTemplateFormItemName.Name],
      description: values[AdminAddStepTemplateFormItemName.Description],
      activityTemplateId: props.currentActivityTemplate.id,
      questionnaireId: undefined,
    })
      .then((response: StepTemplateJSONAModel) => {
        createConversation({
          rawData: {
            data: {
              attributes: {
                initialMessage: values[AdminAddStepTemplateFormItemName.MessageText],
              },
              relationships: {
                stepTemplates: [
                  {
                    type: ResourceType.StepTemplate,
                    id: response.id,
                  },
                ],
              },
            },
          },
        });
      })
      .then(() => {
        if (props.getProgramTemplate) {
          props.getProgramTemplate();
        }
        props.closeModal();
      })
      .catch((err) => console.log(err));
  };
  const createQuestionnaireAndStepTemplate = (values: AdminAddStepTemplateFormValues) => {
    handleCreateStepTemplate({
      stepType: values[AdminAddStepTemplateFormItemName.Type],
      name: values[AdminAddStepTemplateFormItemName.Name],
      description: values[AdminAddStepTemplateFormItemName.Description],
      activityTemplateId: props.currentActivityTemplate.id,
      questionnaireId: values[AdminAddStepTemplateFormItemName.QuestionnaireId],
    })
      .then(() => {
        if (props.getProgramTemplate) {
          props.getProgramTemplate();
        }
        props.closeModal();
      })
      .catch((err) => console.log(err));
  };
  const createInstructionAndStepTemplate = (values: AdminAddStepTemplateFormValues) => {
    handleCreateStepTemplate({
      stepType: values[AdminAddStepTemplateFormItemName.Type],
      name: values[AdminAddStepTemplateFormItemName.Name],
      description: values[AdminAddStepTemplateFormItemName.Description],
      activityTemplateId: props.currentActivityTemplate.id,
      questionnaireId: undefined,
    })
      .then((response: StepTemplateJSONAModel) => {
        createInstruction({
          rawData: {
            data: {
              attributes: {
                instructionText: values[AdminAddStepTemplateFormItemName.Description],
              },
              relationships: {
                stepTemplates: [
                  {
                    type: ResourceType.StepTemplate,
                    id: response.id,
                  },
                ],
              },
            },
          },
        });
      })
      .then(() => {
        if (props.getProgramTemplate) {
          props.getProgramTemplate();
        }
        props.closeModal();
      })
      .catch((err) => console.log(err));
  };

  const handleEditStepTemplate = (values: AdminAddStepTemplateFormValues) => {
    if (props.editStepTemplate) {
      editStepTemplate(props.editStepTemplate.id, {
        model: {
          type: ResourceType.ActivityTemplate,
          id: props.editStepTemplate.id,
          name: values[AdminAddStepTemplateFormItemName.Name],
          description: values[AdminAddStepTemplateFormItemName.Description],
        },
        includeNames: [],
      }).then(() => {
        props?.closeModal();
      });
    }
  };

  const handleSubmit = (values: AdminAddStepTemplateFormValues) => {
    if (isEditing) {
      handleEditStepTemplate(values);
      return;
    }
    switch (values[AdminAddStepTemplateFormItemName.Type]) {
      case StepType.Conversation:
        createConversationAndStepTemplate(values);
        break;
      case StepType.Questionnaire:
        createQuestionnaireAndStepTemplate(values);
        break;
      case StepType.Instruction:
        createInstructionAndStepTemplate(values);
        break;
    }
  };

  const renderExtraFormField = () => {
    switch (currentStepType) {
      case StepType.Conversation:
        return (
          <Form.Item
            label={AdminAddStepTemplateFormItemLabel.MessageText}
            name={AdminAddStepTemplateFormItemName.MessageText}
            rules={[
              {
                required: isEditing ? false : true,
                message: "Ovo polje je obavezno.",
                min: 1,
              },
            ]}
          >
            <TextArea rows={4} disabled={isEditing} maxLength={200} showCount={true} />
          </Form.Item>
        );
      case StepType.Questionnaire:
        return (
          <Form.Item
            label={AdminAddStepTemplateFormItemLabel.QuestionnaireId}
            name={AdminAddStepTemplateFormItemName.QuestionnaireId}
            rules={[
              {
                required: isEditing ? false : true,
                message: "Ovo polje je obavezno.",
                min: 1,
              },
            ]}
          >
            <Select
              onDropdownVisibleChange={removeOverflowOnDropdownClose}
              disabled={isEditing || getStepTemplateLoading}
              loading={getAllQuestionnairesLoading || getStepTemplateLoading}
            >
              {getQuestionnaireOptions()}
            </Select>
          </Form.Item>
        );
      case StepType.Instruction:
        return null;
    }
  };

  return (
    <Form
      layout={"vertical"}
      onFinish={(values) => {
        handleSubmit(values as AdminAddStepTemplateFormValues);
      }}
      initialValues={getInitialValues()}
      onValuesChange={(value) => {
        if (value && value.type) {
          setCurrentStepType(value.type);
        }
      }}
    >
      <Form.Item
        label={AdminAddStepTemplateFormItemLabel.Type}
        name={AdminAddStepTemplateFormItemName.Type}
        rules={[
          {
            required: true,
            message: "Ovo polje je obavezno.",
          },
        ]}
      >
        <Select
          disabled={isEditing}
          loading={getStepTemplateLoading}
          onDropdownVisibleChange={removeOverflowOnDropdownClose}
        >
          {getStepTypeOptions()}
        </Select>
      </Form.Item>
      <Form.Item
        label={AdminAddStepTemplateFormItemLabel.Name}
        name={AdminAddStepTemplateFormItemName.Name}
        rules={[
          {
            required: true,
            message: "Ovo polje je obavezno.",
            min: 1,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={AdminAddStepTemplateFormItemLabel.Description}
        name={AdminAddStepTemplateFormItemName.Description}
        rules={[
          {
            required: true,
            message: "Ovo polje je obavezno.",
            min: 1,
          },
        ]}
      >
        <TextArea rows={6} />
      </Form.Item>
      {renderExtraFormField()}
      <div className={styles.submitWrap}>
        <Button
          type={"primary"}
          htmlType={"submit"}
          loading={
            createStepTempLoading ||
            createInstructionLoading ||
            createConversationLoading ||
            editStepTempLoading
          }
        >
          Spremi
        </Button>
      </div>
    </Form>
  );
};
