import { ExclamationCircleOutlined, SettingOutlined } from "@ant-design/icons/lib";
import { FuzzySearchType, usePatch } from "@bornfight/aardvark";
import { Button, Dropdown, Menu, Modal, Skeleton } from "antd";
import { ModalExtended } from "common/ModalExtended/ModalExtended";
import { ColumnFilterType } from "common/ResourceTable/enums/ColumnFilterType";
import { TableColumnData } from "common/ResourceTable/interfaces/TableColumnData";
import { ResourceTable } from "common/ResourceTable/ResourceTable";
import { TextContent } from "common/TextContent/TextContent";
import { ResourceType } from "enums/ResourceType";
import { AdminAddPharmacistForm } from "features/admin/components/AdminAddPharmacistForm/AdminAddPharmacistForm";
import styles from "features/admin/components/AdminPharmacistsTable/AdminPharmacistsTable.module.scss";
import { AdminPharmacistsTableColumnDataIndex } from "features/admin/components/AdminPharmacistsTable/enums/AdminPharmacistsTableColumnDataIndex";
import { AdminPharmacistsTableColumnTitle } from "features/admin/components/AdminPharmacistsTable/enums/AdminPharmacistsTableColumnTitle";
import { AdminPharmacistsTableRecord } from "features/admin/components/AdminPharmacistsTable/interfaces/AdminPharmacistsTableRecord";
import { pharmacistActionHandler } from "handlers/PharmacistActionHandler";
import { PharmacistJSONAModel } from "interfaces/jsona-models/PharmacistJSONAModel";
import * as React from "react";
import { FunctionComponent, useMemo, useState } from "react";
import { AppJsonApiQuery } from "utils/AppJsonApiQuery";
import { removeOverflowOnDropdownClose } from "utils/removeOverflowOnDropdownClose";

const { confirm } = Modal;
const confirmDeactivatePharmacistModal = (onOk: () => void, pharmacistFullName: string) => {
  confirm({
    title: `Želite li zbilja deaktivirati ljekarnika: ${pharmacistFullName} ?`,
    icon: <ExclamationCircleOutlined />,
    onOk() {
      onOk();
    },
  });
};
export const AdminPharmacistsTable: FunctionComponent = () => {
  const [addPharmacistVisible, setAddPharmacistVisible] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [currentPharmacist, setCurrentPharmacist] = useState<PharmacistJSONAModel | undefined>(
    undefined
  );
  const [pharmacistsLoading, setPharmacistsLoading] = useState<boolean>(false);
  const { update: editPharmacist, loading: editPharmacistLoading } =
    usePatch(pharmacistActionHandler);
  const pharmacistJsonApiQuery = useMemo(() => {
    return new AppJsonApiQuery({});
  }, []);

  const renderOptionsColumn = (optionsData: PharmacistJSONAModel) => {
    const disabled = !optionsData.active;

    const menu = (
      <Menu>
        <Menu.Item
          disabled={disabled}
          key={"editPharmacist"}
          onClick={async () => {
            await setIsEditing(true);
            await setCurrentPharmacist(optionsData);
            setAddPharmacistVisible(true);
          }}
        >
          Uredi
        </Menu.Item>
        <Menu.Item
          key={"deactivatePharmacist"}
          disabled={disabled}
          onClick={() => {
            const handleEditPharmacist = () => {
              editPharmacist(optionsData.id, {
                model: {
                  type: ResourceType.Pharmacist,
                  id: optionsData.id,
                  active: false,
                },
                includeNames: [],
              });
            };
            confirmDeactivatePharmacistModal(
              handleEditPharmacist,
              `${optionsData.firstName} ${optionsData.lastName}`
            );
          }}
        >
          Deaktiviraj
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown overlay={menu} trigger={["click"]} onOpenChange={removeOverflowOnDropdownClose}>
        <div onClick={(e) => e.preventDefault()} className={styles.menuItemWidth}>
          <TextContent>
            <span className={styles.cogwheel}>
              <SettingOutlined />
            </span>
          </TextContent>
        </div>
      </Dropdown>
    );
  };

  const dataSource = (collection: PharmacistJSONAModel[]): AdminPharmacistsTableRecord[] => {
    return (
      collection.map((pharmacist, index) => {
        return {
          key: pharmacist.id,
          [AdminPharmacistsTableColumnDataIndex.FirstName]: pharmacist.firstName,
          [AdminPharmacistsTableColumnDataIndex.LastName]: pharmacist.lastName,
          [AdminPharmacistsTableColumnDataIndex.Email]: pharmacist.email,
          [AdminPharmacistsTableColumnDataIndex.Active]: pharmacist.active,
          [AdminPharmacistsTableColumnDataIndex.Options]: pharmacist,
        };
      }) || []
    );
  };
  const columns: TableColumnData<AdminPharmacistsTableRecord>[] = [
    {
      title: AdminPharmacistsTableColumnTitle.FirstName,
      dataIndex: AdminPharmacistsTableColumnDataIndex.FirstName,
      key: AdminPharmacistsTableColumnDataIndex.FirstName,
      filter: {
        type: ColumnFilterType.Text,
        field: "firstName",
        fuzzySearchType: FuzzySearchType.StartWith,
      },
    },
    {
      title: AdminPharmacistsTableColumnTitle.LastName,
      dataIndex: AdminPharmacistsTableColumnDataIndex.LastName,
      key: AdminPharmacistsTableColumnDataIndex.LastName,
      filter: {
        type: ColumnFilterType.Text,
        field: "lastName",
        fuzzySearchType: FuzzySearchType.StartWith,
      },
    },
    {
      title: AdminPharmacistsTableColumnTitle.Email,
      dataIndex: AdminPharmacistsTableColumnDataIndex.Email,
      key: AdminPharmacistsTableColumnDataIndex.Email,
    },
    {
      title: AdminPharmacistsTableColumnTitle.Active,
      dataIndex: AdminPharmacistsTableColumnDataIndex.Active,
      key: AdminPharmacistsTableColumnDataIndex.Active,
      render: (active: boolean) => {
        if (active) {
          return <span>Da</span>;
        }
        return <span>Ne</span>;
      },
    },
    {
      title: AdminPharmacistsTableColumnTitle.Options,
      dataIndex: AdminPharmacistsTableColumnDataIndex.Options,
      key: AdminPharmacistsTableColumnDataIndex.Options,
      render: (optionsData: PharmacistJSONAModel) => {
        return renderOptionsColumn(optionsData);
      },
    },
  ];
  return (
    <>
      <div className={styles.tableHeader}>
        <span className={styles.tableTitle}>Ljekarnici</span>
        <Button type={"primary"} onClick={() => setAddPharmacistVisible(true)}>
          Novi ljekarnik
        </Button>
      </div>
      {pharmacistsLoading || editPharmacistLoading ? (
        <Skeleton />
      ) : (
        <ResourceTable<AdminPharmacistsTableRecord, PharmacistJSONAModel>
          columnData={columns}
          transformToDataSource={dataSource}
          className={styles.table}
          apiActionHandler={pharmacistActionHandler}
          jsonApiQuery={pharmacistJsonApiQuery}
        />
      )}

      <ModalExtended
        title={isEditing ? "Uredi Ljekarnika" : "Dodaj novog Ljekarnika"}
        open={addPharmacistVisible}
        onCancel={() => {
          setIsEditing(false);
          setCurrentPharmacist(undefined);
          setAddPharmacistVisible(false);
        }}
        width={700}
      >
        <AdminAddPharmacistForm
          closeModal={() => setAddPharmacistVisible(false)}
          currentPharmacist={currentPharmacist}
          isEditing={isEditing}
          setPharmacistsLoading={setPharmacistsLoading}
        />
      </ModalExtended>
    </>
  );
};
