import { Modal } from "antd";
import { PreviewConsentModalProps } from "common/PreviewConsentModal/interfaces/PreviewConsentModalProps";
import * as React from "react";
import { FunctionComponent } from "react";
import styles from "./PreviewConsentModal.module.scss";

export const PreviewConsentModal: FunctionComponent<PreviewConsentModalProps> = (props) => {
  return (
    <Modal
      title={props.fileName}
      open={props.visible}
      onCancel={() => props.onClose(false)}
      maskClosable={false}
      afterClose={() => document.body.style.removeProperty("overflow")}
      destroyOnClose={true}
      footer={null}
      width={1000}
    >
      <img className={styles.wrap} src={props.fileLink} alt={"GDPR"} />
    </Modal>
  );
};
