import { EntityEndpoint } from "enums/EntityEndpoint";
import { ResourceType } from "enums/ResourceType";
import { AdministratorJSONAModel } from "interfaces/jsona-models/AdministratorJSONAModel";
import { AdministratorSelector } from "selectors/AdministratorSelector";
import { AppApiActionHandler } from "./AppApiActionHandler";

class AdministratorActionHandler extends AppApiActionHandler<AdministratorJSONAModel> {
  constructor() {
    super(ResourceType.Administrator, EntityEndpoint.Administrator, new AdministratorSelector());
  }
}

export const administratorActionHandler = new AdministratorActionHandler();
