/**
 * Endpoint enum keys must be singular!
 */
export enum EntityEndpoint {
  Activity = "/activities/",
  Patient = "/patients/",
  Program = "/programs/",
  ProgramTemplate = "/program_templates/",
  ProgramComment = "/program_comments/",
  Step = "/steps/",
  StepTemplate = "/step_templates/",
  Question = "/questions/",
  Answer = "/answers/",
  Questionnaire = "/questionnaires/",
  Chat = "/chats/",
  ChatMessage = "/chat_messages/",
  Consent = "/consents/",
  Conversation = "/conversations/",
  Therapy = "/therapies/",
  Medicine = "/medicines/",
  User = "/users/",
  ActivityTemplate = "/activity_templates/",
  Instruction = "/instructions/",
  Pharmacy = "/pharmacies/",
  Administrator = "/administrators/",
  Pharmacist = "/pharmacists/",
}
