import { CardWrapProps } from "common/CardWrap/interfaces/CardWrapProps";
import * as React from "react";
import { FunctionComponent } from "react";
import styles from "./CardWrap.module.scss";

export const CardWrap: FunctionComponent<CardWrapProps> = (props) => {
  let className = styles.wrap;
  if (props.shortMarginLeftTop) {
    className = `${styles.wrap} ${styles["short-margin-left-top"]}`;
  }
  if (props.shortMarginRightTop) {
    className = `${styles.wrap} ${styles["short-margin-right-top"]}`;
  }
  if (props.noPadding) {
    className = `${styles.wrap} ${styles["no-padding"]}`;
  }
  if (props.activityWrap) {
    className = `${styles.activityWrap}`;
  }

  return <div className={className}>{props.children}</div>;
};
