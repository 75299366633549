import { Menu } from "antd";
import { SideMenuItemName } from "common/SideMenu/enums/SideMenuItemName";
import { SideMenuItemUrl } from "common/SideMenu/enums/SideMenuItemUrl";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./SideMenu.module.scss";
import { ReactComponent as SideMenuLogo } from "static/sideMenuLogo.svg";

export const SideMenu: React.FC = () => {
  // wrapping <Menu.Item>'s in <></>, crashes the app
  const pathName = useLocation().pathname;
  return (
    <>
      <div className={styles["logo-wrap"]}>
        <SideMenuLogo />
      </div>

      <Menu className={styles.menu} mode={"inline"} theme={"dark"} selectedKeys={[pathName]}>
        <Menu.Item key={SideMenuItemUrl.Home}>
          <Link to={SideMenuItemUrl.Home} data-test={"home"}>
            {SideMenuItemName.Home}
          </Link>
        </Menu.Item>
        <Menu.Item key={SideMenuItemUrl.Activities}>
          <Link to={SideMenuItemUrl.Activities} data-test={"activities"}>
            {SideMenuItemName.Activities}
          </Link>
        </Menu.Item>
        <Menu.Item key={SideMenuItemUrl.Patients}>
          <Link to={SideMenuItemUrl.Patients} data-test={"patients"}>
            {SideMenuItemName.Patients}
          </Link>
        </Menu.Item>
        <Menu.Item key={SideMenuItemUrl.ProgramTemplates}>
          <Link to={SideMenuItemUrl.ProgramTemplates} data-test={"programs"}>
            {SideMenuItemName.ProgramTemplates}
          </Link>
        </Menu.Item>
      </Menu>
    </>
  );
};
