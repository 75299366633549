import { all, fork } from "redux-saga/effects";
import { Saga } from "@bornfight/aardvark/dist/interfaces/Saga";

export function createRootSaga(sagas: Saga[] = []) {
  const forkedSagas = sagas.map((saga) => {
    return fork(saga.run);
  });

  // We `fork()` these tasks so they execute in the background.
  const allSagas = [...forkedSagas];

  return function* rootSaga() {
    yield all(allSagas);
  };
}
