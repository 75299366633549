import { EntityEndpoint } from "enums/EntityEndpoint";
import { ResourceType } from "enums/ResourceType";
import { MedicineJSONAModel } from "interfaces/jsona-models/MedicineJSONAModel";
import { MedicineSelector } from "selectors/MedicineSelector";
import { AppApiActionHandler } from "./AppApiActionHandler";

class MedicineActionHandler extends AppApiActionHandler<MedicineJSONAModel> {
  constructor() {
    super(ResourceType.Medicine, EntityEndpoint.Medicine, new MedicineSelector());
  }
}

export const medicineActionHandler = new MedicineActionHandler();
