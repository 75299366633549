import { useGetAllControlled, useGetControlled, usePost } from "@bornfight/aardvark";
import { Button, Form, Row, Select } from "antd";
import { Store } from "antd/lib/form/interface";
import { DatePicker } from "common/DatePicker/DatePicker";
import { ResourceType } from "enums/ResourceType";
import { AddProgramFormFieldLabel } from "features/patients/components/AddProgramForm/enums/AddProgramFormFieldLabel";
import { AddProgramFormFieldName } from "features/patients/components/AddProgramForm/enums/AddProgramFormFieldName";
import { AddProgramFormProps } from "features/patients/components/AddProgramForm/interfaces/AddProgramFormProps";
import { AddProgramFormValues } from "features/patients/components/AddProgramForm/interfaces/AddProgramFormValues";
import { medicineActionHandler } from "handlers/MedicineActionHandler";
import { patientActionHandler } from "handlers/PatientActionHandler";
import { programActionHandler } from "handlers/ProgramActionHandler";
import { therapyActionHandler } from "handlers/TherapyActionHandler";
import { MedicineJSONAModel } from "interfaces/jsona-models/MedicineJSONAModel";
import { ProgramTemplateJSONAModel } from "interfaces/jsona-models/ProgramTemplateJSONAModel";
import moment from "moment";
import * as React from "react";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDeepCompareMemo } from "use-deep-compare";
import { AppJsonApiQuery } from "utils/AppJsonApiQuery";
import { removeOverflowOnDropdownClose } from "utils/removeOverflowOnDropdownClose";
import useDebounce from "utils/useDebounce";

export const AddProgramForm: FunctionComponent<AddProgramFormProps> = (props) => {
  const params = useParams<{ patientId: string }>();
  const [medicineName, setMedicineName] = useState<string>("");
  const { create: createTherapy, loading: createTherapyLoading } = usePost(therapyActionHandler);
  const { create: createProgram, loading: createProgramLoading } = usePost(programActionHandler);
  const [createLoading, setCreateLoading] = useState<boolean>(false);

  const debouncedSearchTerm = useDebounce(medicineName, 400);

  const customParams = useDeepCompareMemo(() => {
    return [
      { name: "productName", value: debouncedSearchTerm },
      { name: "exists[programTemplates]", value: "true" },
      { name: "programTemplates.enabled", value: "true" },
    ];
  }, [debouncedSearchTerm]);

  const medicineQuery = useDeepCompareMemo(() => {
    return new AppJsonApiQuery({
      customParams,
      includes: ["programTemplates"],
    });
  }, [debouncedSearchTerm]);

  const { getAll, collection, loading } = useGetAllControlled(medicineActionHandler, medicineQuery);

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 2) {
      getAll();
    }
  }, [getAll, debouncedSearchTerm]);
  const handleGetMedicines = (searchValue: string) => {
    setMedicineName(searchValue);
  };

  // get patient.programs
  const patientId = useMemo(() => {
    return params.patientId;
  }, [params]);

  const patientIncludes = useMemo(() => {
    return ["programs"];
  }, []);

  const { getSingle: getPatient, loading: patientLoading } = useGetControlled(
    patientActionHandler,
    `/api/patients/${patientId}`,
    patientIncludes
  );

  const renderMedicineOrHealthSupplementSelectItems = (medicines: MedicineJSONAModel[]) => {
    return medicines.map((medicine, index) => {
      return (
        <Select.Option key={medicine.id} value={medicine.id} title={medicine.productName}>
          {medicine.productName}
        </Select.Option>
      );
    });
  };

  const renderProgramTemplateSelectItems = (medicines: MedicineJSONAModel[]) => {
    const progTemplates = new Set<ProgramTemplateJSONAModel>();

    medicines?.forEach((medicine) => {
      return medicine?.programTemplates?.forEach((progTemplate) => {
        return progTemplates.add(progTemplate);
      });
    });

    const programTemplatesCollection = Array.from(progTemplates).filter((progTemplate) => {
      return progTemplate.enabled;
    });

    return programTemplatesCollection.map((progTemplate) => {
      return (
        <Select.Option key={progTemplate.id} value={progTemplate.id} title={progTemplate?.name}>
          {progTemplate?.name}
        </Select.Option>
      );
    });
  };

  const handleSubmit = (values: Store) => {
    setCreateLoading(true);
    createTherapy({
      rawData: {
        data: {
          attributes: {
            adherenceTherapy: true,
          },
          relationships: {
            medicine: {
              type: ResourceType.Medicine,
              id: (values as AddProgramFormValues)[AddProgramFormFieldName.Medicine],
            },
          },
        },
      },
    })
      .then((response) => {
        const newTherapyId = response?.id;
        return createProgram({
          rawData: {
            data: {
              attributes: {
                startDate: (values as AddProgramFormValues)[AddProgramFormFieldName.StartDate],
              },
              relationships: {
                programTemplate: {
                  type: ResourceType.ProgramTemplate,
                  id: (values as AddProgramFormValues)[AddProgramFormFieldName.ProgramTemplate],
                },
                patient: {
                  type: ResourceType.Patient,
                  id: props?.patient?.id,
                },
                therapies: [
                  {
                    type: ResourceType.Therapy,
                    id: newTherapyId,
                  },
                ],
              },
            },
          },
        });
      })
      .then(() => {
        getPatient().then(() => {
          if (props.closeModal) {
            props.closeModal();
          }
          setCreateLoading(false);
        });
      })
      .catch(() => setCreateLoading(false));
  };
  return (
    <Form onFinish={handleSubmit} layout={"vertical"}>
      <Form.Item
        label={AddProgramFormFieldLabel.Medicine}
        name={AddProgramFormFieldName.Medicine}
        rules={[{ required: true, message: "Ovo polje je obavezno." }]}
      >
        <Select
          onDropdownVisibleChange={removeOverflowOnDropdownClose}
          // @ts-ignore scroll container fix, has type errors
          getPopupContainer={() => {
            if (document.getElementById("addExistingMedicineForm")) {
              return document.getElementById("addExistingMedicineForm");
            }
            return document.body;
          }}
          optionFilterProp={"title"}
          showSearch={true}
          onSearch={handleGetMedicines}
          loading={loading}
          showAction={["focus", "click"]}
        >
          {renderMedicineOrHealthSupplementSelectItems(collection)}
        </Select>
      </Form.Item>
      <Form.Item
        label={AddProgramFormFieldLabel.ProgramTemplate}
        name={AddProgramFormFieldName.ProgramTemplate}
        rules={[{ required: true, message: "Ovo polje je obavezno." }]}
      >
        <Select optionFilterProp={"title"} onDropdownVisibleChange={removeOverflowOnDropdownClose}>
          {renderProgramTemplateSelectItems(collection)}
        </Select>
      </Form.Item>
      <Form.Item
        label={AddProgramFormFieldLabel.StartDate}
        name={AddProgramFormFieldName.StartDate}
        rules={[{ required: true, message: "Ovo polje je obavezno." }]}
      >
        <DatePicker
          placeholder={"Odaberi datum"}
          disabledDate={(current) => {
            return current < moment().startOf("day");
          }}
        />
      </Form.Item>

      <Row justify={"end"} style={{ marginTop: 22 }}>
        <Button
          data-test={"submit-patient-program-button"}
          htmlType={"submit"}
          type={"primary"}
          loading={createTherapyLoading || createProgramLoading || patientLoading || createLoading}
        >
          Dodijeli program
        </Button>
      </Row>
    </Form>
  );
};
