import { PageWrapProps } from "common/PageWrap/interfaces/PageWrapProps";
import { FunctionComponent } from "react";
import * as React from "react";
import styles from "./PageWrap.module.scss";

export const PageWrap: FunctionComponent<PageWrapProps> = (props) => {
  let className = styles.wrap;
  if (props.coverWhole) {
    className = `${styles.wrap} ${styles.cover}`;
  }
  if (props.noLeftPadding) {
    className = `${styles["no-left-padding-wrap"]}`;
  }
  if (props.coverWhole && props.noLeftPadding) {
    className = `${styles["no-left-padding-wrap"]} ${styles.cover}`;
  }
  return <div className={className}>{props.children}</div>;
};
