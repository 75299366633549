import { RouteUrl } from "enums/RouteUrl";
import { UserRole } from "features/authentication/enums/UserRole";
import { TokenData } from "features/authentication/interfaces/TokenData";
import jwt_decode from "jwt-decode";
import { useAuth } from "locot";
import React, { useCallback, useEffect } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";

export interface PrivateRouteProps {
  component: React.ComponentType;
  adminRoute?: boolean;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...props }) => {
  const authData = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const isAdmin = useCallback(() => {
    const accessToken = localStorage.getItem("token");
    let tokenData: TokenData | undefined;
    if (accessToken) {
      tokenData = jwt_decode(accessToken as string);
    }
    return tokenData?.roles?.some((role) => {
      return role === UserRole.Admin;
    });
  }, []);

  useEffect(() => {
    const isAdminRole = isAdmin();
    if (isAdminRole && !props.adminRoute) {
      navigate(RouteUrl.AdminUsers);
      return;
    }
    if (isAdminRole) {
      if (location.pathname === RouteUrl.Login) {
        navigate(RouteUrl.AdminUsers);
        return;
      }
    }
    if (props.adminRoute) {
      if (!isAdminRole && authData.isLoggedIn) {
        navigate(RouteUrl.Home);
        return;
      }
    }
  }, [authData, history, isAdmin, props.adminRoute, location.pathname]);

  const componentProps = { ...props, isAdmin };

  return authData.isLoggedIn ? (
    <Component {...componentProps} />
  ) : (
    <Navigate to={{ pathname: "/login" }} />
  );
};
