import { EntityEndpoint } from "enums/EntityEndpoint";
import { ResourceType } from "enums/ResourceType";
import { AppApiActionHandler } from "./AppApiActionHandler";
import { ConversationSelector } from "../selectors/ConversationSelector";
import { ConversationJSONAModel } from "../interfaces/jsona-models/ConversationJSONAModel";

class ConversationActionHandler extends AppApiActionHandler<ConversationJSONAModel> {
  constructor() {
    super(ResourceType.Conversation, EntityEndpoint.Conversation, new ConversationSelector());
  }
}

export const conversationActionHandler = new ConversationActionHandler();
