import { ExclamationCircleOutlined, SettingOutlined } from "@ant-design/icons/lib";
import { FuzzySearchType, usePatch } from "@bornfight/aardvark";
import { Button, Dropdown, Menu, Modal, Spin } from "antd";
import { ModalExtended } from "common/ModalExtended/ModalExtended";
import { ColumnFilterType } from "common/ResourceTable/enums/ColumnFilterType";
import { TableColumnData } from "common/ResourceTable/interfaces/TableColumnData";
import { ResourceTable } from "common/ResourceTable/ResourceTable";
import { Space } from "common/Space/Space";
import { TextContent } from "common/TextContent/TextContent";
import { ResourceType } from "enums/ResourceType";
import { AdminAddPharmacyForm } from "features/admin/components/AdminAddPharmacyForm/AdminAddPharmacyForm";
import { AdminPharmaciesTableColumnDataIndex } from "features/admin/components/AdminPharmaciesTable/enums/AdminPharmaciesTableColumnDataIndex";
import { AdminPharmaciesTableColumnTitle } from "features/admin/components/AdminPharmaciesTable/enums/AdminPharmaciesTableColumnTitle";
import { AdminPharmaciesTableRecord } from "features/admin/components/AdminPharmaciesTable/interfaces/AdminPharmaciesTableRecord";
import { pharmacyActionHandler } from "handlers/PharmacyActionHandler";
import { PharmacyJSONAModel } from "interfaces/jsona-models/PharmacyJSONAModel";
import * as React from "react";
import { FunctionComponent, useMemo, useState } from "react";
import { AppJsonApiQuery } from "utils/AppJsonApiQuery";
import { removeOverflowOnDropdownClose } from "utils/removeOverflowOnDropdownClose";
import styles from "./AdminPharmaciesTable.module.scss";

const { confirm } = Modal;
const confirmChangePharmacyActiveModal = (
  onOk: () => void,
  pharmacyNameAndAddress: string,
  activate: boolean
) => {
  const title = activate ? (
    `Želite li zbilja aktivirati ljekarnu: ${pharmacyNameAndAddress} ?`
  ) : (
    <>
      <Space direction={"vertical"} size={12}>
        <div>
          Jeste li sigurni da želite deaktivirati ljekarnu
          {pharmacyNameAndAddress} ?{" "}
        </div>
        <div>Svi farmaceuti koji su vezani na ljekarnu će također biti deaktivirani.</div>
      </Space>
    </>
  );

  confirm({
    title,
    icon: <ExclamationCircleOutlined />,
    onOk() {
      onOk();
    },
    onCancel() {},
  });
};

export const AdminPharmaciesTable: FunctionComponent = () => {
  const [addPharmacyVisible, setAddPharmacyVisible] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [pharmacyLoading, setPharmacyLoading] = useState<boolean>(false);
  const [currentPharmacy, setCurrentPharmacy] = useState<PharmacyJSONAModel | undefined>(undefined);
  const { update: editPharmacy, loading: editPharmacyLoading } = usePatch(pharmacyActionHandler);

  const pharmaciesJsonApiQuery = useMemo(() => {
    return new AppJsonApiQuery({});
  }, []);

  const renderOptionsColumn = (optionsData: PharmacyJSONAModel) => {
    const menu = (
      <Menu>
        <Menu.Item
          key={"editPharmacy"}
          disabled={optionsData.active}
          onClick={async () => {
            await setIsEditing(true);
            await setCurrentPharmacy(optionsData);
            setAddPharmacyVisible(true);
          }}
        >
          Uredi
        </Menu.Item>
        {optionsData.active ? (
          <Menu.Item
            key={"deactivatePharmacy"}
            onClick={() => {
              const handleDeactivatePharmacy = () => {
                editPharmacy(optionsData.id, {
                  model: {
                    type: ResourceType.Pharmacy,
                    id: optionsData.id,
                    active: false,
                  },
                  includeNames: [],
                });
              };
              const pharmacyNameAndAddress = `${optionsData.name} ${optionsData.address}`;
              confirmChangePharmacyActiveModal(
                handleDeactivatePharmacy,
                pharmacyNameAndAddress,
                false
              );
            }}
          >
            Deaktiviraj
          </Menu.Item>
        ) : (
          <Menu.Item
            key={"activatePharmacy"}
            onClick={() => {
              const handleDeactivatePharmacy = () => {
                editPharmacy(optionsData.id, {
                  model: {
                    type: ResourceType.Pharmacy,
                    id: optionsData.id,
                    active: true,
                  },
                  includeNames: [],
                });
              };
              const pharmacyNameAndAddress = `${optionsData.name} ${optionsData.address}`;
              confirmChangePharmacyActiveModal(
                handleDeactivatePharmacy,
                pharmacyNameAndAddress,
                true
              );
            }}
          >
            Aktiviraj
          </Menu.Item>
        )}
      </Menu>
    );
    return (
      <Dropdown overlay={menu} trigger={["click"]} onOpenChange={removeOverflowOnDropdownClose}>
        <div onClick={(e) => e.preventDefault()} className={styles.menuItemWidth}>
          <TextContent>
            <span className={styles.cogwheel}>
              <SettingOutlined />
            </span>
          </TextContent>
        </div>
      </Dropdown>
    );
  };

  const dataSource = (collection: PharmacyJSONAModel[]): AdminPharmaciesTableRecord[] => {
    return (
      collection.map((pharmacy, index) => {
        return {
          key: pharmacy.id,
          [AdminPharmaciesTableColumnDataIndex.Name]: pharmacy.name,
          [AdminPharmaciesTableColumnDataIndex.Adress]: pharmacy.address,
          [AdminPharmaciesTableColumnDataIndex.Active]: pharmacy.active,
          [AdminPharmaciesTableColumnDataIndex.Options]: pharmacy,
        };
      }) || []
    );
  };
  const columns: TableColumnData<AdminPharmaciesTableRecord>[] = [
    {
      title: AdminPharmaciesTableColumnTitle.Name,
      dataIndex: AdminPharmaciesTableColumnDataIndex.Name,
      key: AdminPharmaciesTableColumnDataIndex.Name,
      filter: {
        type: ColumnFilterType.Text,
        field: "name",
        fuzzySearchType: FuzzySearchType.StartWith,
      },
    },
    {
      title: AdminPharmaciesTableColumnTitle.Adress,
      dataIndex: AdminPharmaciesTableColumnDataIndex.Adress,
      key: AdminPharmaciesTableColumnDataIndex.Adress,
      filter: {
        type: ColumnFilterType.Text,
        field: "address",
        fuzzySearchType: FuzzySearchType.StartWith,
      },
    },
    {
      title: AdminPharmaciesTableColumnTitle.Active,
      dataIndex: AdminPharmaciesTableColumnDataIndex.Active,
      key: AdminPharmaciesTableColumnDataIndex.Active,
      render: (active: boolean) => {
        if (active) {
          return <span>Da</span>;
        }
        return <span>Ne</span>;
      },
    },
    {
      title: AdminPharmaciesTableColumnTitle.Options,
      dataIndex: AdminPharmaciesTableColumnDataIndex.Options,
      key: AdminPharmaciesTableColumnDataIndex.Options,
      render: (optionsData: PharmacyJSONAModel) => {
        return renderOptionsColumn(optionsData);
      },
    },
  ];
  return (
    <>
      <div className={styles.tableHeader}>
        <span className={styles.tableTitle}>Ljekarne</span>
        <Button type={"primary"} onClick={() => setAddPharmacyVisible(true)}>
          Nova ljekarna
        </Button>
      </div>
      {pharmacyLoading || editPharmacyLoading ? (
        <Spin />
      ) : (
        <ResourceTable<AdminPharmaciesTableRecord, PharmacyJSONAModel>
          columnData={columns}
          transformToDataSource={dataSource}
          className={styles.table}
          apiActionHandler={pharmacyActionHandler}
          jsonApiQuery={pharmaciesJsonApiQuery}
        />
      )}
      <ModalExtended
        title={isEditing ? "Uredi Ljekarnu" : "Dodaj novu ljekarnu"}
        open={addPharmacyVisible}
        onCancel={() => {
          setAddPharmacyVisible(false);
        }}
        width={500}
      >
        <AdminAddPharmacyForm
          closeModal={() => setAddPharmacyVisible(false)}
          isEditing={isEditing}
          setPharmacyLoading={setPharmacyLoading}
          currentPharmacy={currentPharmacy}
        />
      </ModalExtended>
    </>
  );
};
