import { EntityEndpoint } from "enums/EntityEndpoint";
import { ResourceType } from "enums/ResourceType";
import { InstructionJSONAModel } from "interfaces/jsona-models/InstructionJSONAModel";
import { InstructionSelector } from "selectors/InstructionSelector";
import { AppApiActionHandler } from "./AppApiActionHandler";

class InstructionActionHandler extends AppApiActionHandler<InstructionJSONAModel> {
  constructor() {
    super(ResourceType.Instruction, EntityEndpoint.Instruction, new InstructionSelector());
  }
}

export const instructionActionHandler = new InstructionActionHandler();
