import { ApiActionHandler, BaseApiSelector } from "@bornfight/aardvark";
import { JSONAModel } from "@bornfight/aardvark/dist/interfaces/JSONAModel";
import { ResourceType } from "../enums/ResourceType";
import { EntityEndpoint } from "../enums/EntityEndpoint";
import { getUuidFromIri } from "../utils/getUuidFromIri";

export class AppApiActionHandler<T extends JSONAModel> extends ApiActionHandler<T> {
  constructor(resourceType: ResourceType, endpoint: EntityEndpoint, selector: BaseApiSelector<T>) {
    super(resourceType, endpoint, selector, (id) => {
      return getUuidFromIri(id);
    });
  }
}
