import { Space } from "antd";
import { TextContent } from "common/TextContent/TextContent";
import { ForgotPasswordForm } from "features/authentication/components/ForgotPasswordForm";
import styles from "features/authentication/components/ForgotPasswordScreen.module.scss";
import * as React from "react";
import { FunctionComponent } from "react";
import { ReactComponent as PlivaLogo } from "static/mainLogo.svg";

export const ForgotPasswordScreen: FunctionComponent = () => {
  return (
    <div className={styles.wrap}>
      <div className={styles["content-wrap"]}>
        <Space direction={"vertical"} size={20}>
          <div className={styles["logo-wrap"]}>
            <PlivaLogo />
          </div>
          <div className={styles["center-text"]}>
            <TextContent className={styles["message-title"]}>Zatraži novu lozinku</TextContent>
            <TextContent className={styles["message-description"]}>
              Unesite Email adresu koji koristite za prijavu na sustav
            </TextContent>
          </div>
        </Space>
        <div className={styles["form-wrap"]}>
          <ForgotPasswordForm />
        </div>
      </div>
    </div>
  );
};
