import { ArrowLeftOutlined } from "@ant-design/icons/lib";
import { useGetAllControlled, useGetControlled } from "@bornfight/aardvark";
import { Skeleton } from "antd";
import { CardWrap } from "common/CardWrap/CardWrap";
import { PageHeader } from "common/PageHeader/PageHeader";
import { RouteUrl } from "enums/RouteUrl";
import { AdminQuestionsTable } from "features/admin/components/AdminQuestionsTable/AdminQuestionsTable";
import { AdminQuestionnaireRouteParams } from "features/admin/interfaces/AdminQuestionnaireRouteParams";
import pageCover from "features/admin/PageCoverStyle.module.scss";
import { questionActionHandler } from "handlers/QuestionActionHandler";
import { questionnaireActionHandler } from "handlers/QuestionnaireActionHandler";
import * as React from "react";
import { FunctionComponent, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDeepCompareEffect, useDeepCompareMemo } from "use-deep-compare";
import { AppJsonApiQuery } from "utils/AppJsonApiQuery";
import { getUuidFromIri } from "utils/getUuidFromIri";

export const AdminQuestionnaireSingleScreen: FunctionComponent = () => {
  const navigate = useNavigate();
  const params = useParams<AdminQuestionnaireRouteParams>();

  const questionnaireId = useMemo(() => {
    return params.questionnaireId;
  }, [params.questionnaireId]);
  const questionnaireIncludes = useMemo(() => {
    return ["questions", "stepTemplates"];
  }, []);

  let {
    record: questionnaire,
    loading: questionnaireLoading,
    getSingle: getQuestionnaire,
  } = useGetControlled(
    questionnaireActionHandler,
    `/api/questionnaires/${questionnaireId}`,
    questionnaireIncludes
  );

  useEffect(() => {
    getQuestionnaire();
  }, [getQuestionnaire]);

  const questionIds = useMemo(() => {
    if (!questionnaire) {
      return [];
    }
    return questionnaire?.questions?.map((question) => {
      return getUuidFromIri(question?.id);
    });
  }, [questionnaire]);
  const questionsQuery = useDeepCompareMemo(() => {
    const customParams = questionIds?.map((id) => {
      return {
        name: "uuid[]",
        value: id,
      };
    });
    return new AppJsonApiQuery({
      customParams,
      includes: ["answers", "questionnaire"],
    });
  }, [questionIds]);
  const { getAll: getQuestionsAndAnswers, loading: getQuestionsAndAnswersLoading } =
    useGetAllControlled(questionActionHandler, questionsQuery);
  useDeepCompareEffect(() => {
    if (questionIds === undefined || questionIds?.length === 0) {
      return;
    }
    getQuestionsAndAnswers();
  }, [getQuestionsAndAnswers, questionIds]);

  return (
    <div className={pageCover.full}>
      <PageHeader
        loading={questionnaireLoading}
        title={`${questionnaire?.name}`}
        leftComponent={
          <ArrowLeftOutlined
            style={{ fontSize: "26px" }}
            onClick={() => navigate(RouteUrl.AdminQuestionnaires)}
          />
        }
      />
      <CardWrap>
        {getQuestionsAndAnswersLoading || questionnaireLoading ? (
          <Skeleton />
        ) : (
          <AdminQuestionsTable
            questions={questionnaire?.questions}
            getQuestionnaire={getQuestionnaire}
            partOfProgram={questionnaire?.partOfProgram}
          />
        )}
      </CardWrap>
    </div>
  );
};
