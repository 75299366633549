import { ChatMessage } from "features/chat/ChatMessageList";
import { ChatMessageJSONAModel } from "interfaces/jsona-models/ChatMessageJSONAModel";
import { EventSourceMessage } from "features/chat/interfaces/EventSourceMessage";

export class ChatMessageMapper {
  public fromChatMessageJSONAModel(chatMessage: ChatMessageJSONAModel): ChatMessage {
    return {
      createdAt: new Date(chatMessage.createdAt),
      type: chatMessage.isIncoming ? "inbound" : "outbound",
      text: chatMessage.text,
      id: chatMessage.id,
    };
  }

  public fromEventSourceChatMessageData(
    eventSourceMessageData: EventSourceMessage["data"]
  ): ChatMessage {
    return {
      createdAt: new Date(eventSourceMessageData.createdAt),
      type: "inbound",
      text: eventSourceMessageData.text,
    };
  }
}
