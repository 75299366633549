import { useGetControlled } from "@bornfight/aardvark";
import { Button, Form, Radio, Skeleton } from "antd";
import { Store } from "antd/lib/form/interface";
import { apiSaga } from "app/store";
import { TextContent } from "common/TextContent/TextContent";
import { ResourceType } from "enums/ResourceType";
import { QuestionAnswer } from "features/patients/components/Questionnaire/interfaces/QuestionAnswer";
import { QuestionnaireProps } from "features/patients/components/Questionnaire/interfaces/QuestionnaireProps";
import { stepActionHandler } from "handlers/StepActionHandler";
import { AnswerJSONAModel } from "interfaces/jsona-models/AnswerJSONAModel";
import { QuestionJSONAModel } from "interfaces/jsona-models/QuestionJSONAModel";
import * as React from "react";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import styles from "./Questionnaire.module.scss";

export const Questionnaire: FunctionComponent<QuestionnaireProps> = (props) => {
  const { data: questionnaire, completed, stepUuid } = props;
  const [questionAnswers, setQuestionAnswers] = useState<QuestionAnswer[]>([]);
  const [postLoading, setPostLoading] = useState<boolean>(false);

  const stepIncludes = useMemo(() => {
    return ["questionnaireSubmission"];
  }, []);

  const { getSingle: getStep, loading: stepLoading } = useGetControlled(
    stepActionHandler,
    `/api/steps/${stepUuid}`,
    stepIncludes
  );

  useEffect(() => {
    getStep();
  }, [getStep]);

  useEffect(() => {
    if (questionnaire && !completed) {
      const initialQuestionAnswers: QuestionAnswer[] = questionnaire.questions.map((question) => {
        return {
          questionId: question.id,
          answerId: "",
        };
      });
      setQuestionAnswers(initialQuestionAnswers);
    }
    if (questionnaire && completed) {
      const initialQuestionAnswers: QuestionAnswer[] | undefined =
        props?.step?.questionnaireSubmission?.questionnaire?.questions?.map((question, index) => {
          const currentAnswerId = props?.step?.questionnaireSubmission?.answers?.[index]?.id;
          return {
            questionId: question.id,
            answerId: currentAnswerId || "",
          };
        });
      setQuestionAnswers(initialQuestionAnswers as QuestionAnswer[]);
    }
  }, [questionnaire, completed, props]);

  if (!questionnaire) {
    return <div>No data</div>;
  }

  const updateQuestionAnswer = (questionId: string, answerId: string) => {
    let newQuestionAnswersCollection = [...questionAnswers];

    const objectToUpdateIndex = newQuestionAnswersCollection.findIndex((questionAnswer) => {
      return questionAnswer.questionId === questionId;
    });

    newQuestionAnswersCollection[objectToUpdateIndex] = {
      ...newQuestionAnswersCollection[objectToUpdateIndex],
      answerId,
    };

    setQuestionAnswers(newQuestionAnswersCollection);
  };

  const questionAndAnswerRender = (questions: QuestionJSONAModel[] | undefined) => {
    if (stepLoading) {
      return <Skeleton />;
    }
    if (questions) {
      return questions.map((question) => {
        return (
          <div key={question.id} className={styles["question-answer-wrap"]}>
            <div className={styles["question-answer-left"]}>
              <TextContent fontSize={14} className={styles.question}>
                <p>{question.text}</p>
              </TextContent>
              {renderAnswers(question.id, question.answers)}
            </div>
            <div className={styles["question-answer-right"]}>{renderQuestionTip(question)}</div>
          </div>
        );
      });
    }
    return null;
  };

  const renderAnswers = (questionId: string, answers: AnswerJSONAModel[]) => {
    const answersRender = answers.map((answer) => {
      return (
        <Radio key={answer.id} className={styles.answer} value={answer.id}>
          <p className={styles["answer-text"]}>{answer.text}</p>
        </Radio>
      );
    });
    return (
      <Form.Item
        key={questionId}
        name={`${questionId}`}
        rules={[
          {
            required: true,
            message: "Pitanje nije odgovoreno!",
            type: "string",
          },
        ]}
      >
        <Radio.Group
          disabled={completed}
          onChange={(e) => {
            const answerId = e.target.value;
            updateQuestionAnswer(questionId, answerId);
          }}
        >
          {answersRender}
        </Radio.Group>
      </Form.Item>
    );
  };

  const renderQuestionTip = (question: QuestionJSONAModel) => {
    const currentQuestionAnswer = questionAnswers?.find((questionAnswer) => {
      return questionAnswer?.questionId === question?.id;
    });
    if (currentQuestionAnswer && currentQuestionAnswer.answerId.length > 0) {
      const selectedAnswer = question.answers.find((answer) => {
        return answer.id === currentQuestionAnswer.answerId;
      });
      if (selectedAnswer && selectedAnswer?.explanation?.length > 0) {
        return (
          <div className={styles["tip-wrap"]}>
            <TextContent className={styles["tip-title"]}>Uputa</TextContent>
            <TextContent className={styles["tip-description"]}>
              {selectedAnswer.explanation}
            </TextContent>
          </div>
        );
      }
    }
    return null;
  };

  const renderTitle = () => {
    if (questionnaire.questions.length === 1) {
      return `${questionnaire.name} (${questionnaire.questions.length} pitanje)`;
    }
    return `${questionnaire.name} (${questionnaire.questions.length} pitanja)`;
  };

  const handlePatchStep = () => {
    setPostLoading(true);
    return apiSaga.apiService.httpAdapter
      .patch(`${process.env.REACT_APP_API_URL}steps/${stepUuid}`, {
        data: {
          attributes: {
            completed: true,
          },
        },
      })
      .then(() => {
        setPostLoading(false);
        props.closeModal();
      });
  };

  const handleSaveQuestionnaire = (answerIds: string[]) => {
    const answerJsonaModelCollection = answerIds.map((answerId) => {
      return {
        type: ResourceType.Answer,
        id: answerId,
      };
    });
    setPostLoading(true);
    return apiSaga.apiService.httpAdapter
      .post(`${process.env.REACT_APP_API_URL}questionnaire_submissions`, {
        data: {
          relationships: {
            questionnaire: {
              type: ResourceType.Questionnaire,
              id: questionnaire.id,
            },
            answers: answerJsonaModelCollection,
            step: {
              type: ResourceType.Step,
              id: `/api/steps/${stepUuid}`,
            },
          },
        },
      })
      .then(() => {
        handlePatchStep()
          .then(() => {
            if (props.getActivities) {
              props.getActivities();
            }
            setPostLoading(false);
          })
          .catch(() => setPostLoading(false));
      })
      .catch(() => {
        setPostLoading(false);
      });
  };

  const submitForm = (values: Store) => {
    const answerIds = Object.values(values);
    handleSaveQuestionnaire(answerIds);
  };

  const getFormInitialValues = () => {
    if (props.step && questionAnswers && questionAnswers.length > 0) {
      let objectQuestionAnswers: { [key: string]: string } = {};
      if (questionAnswers.length > 0) {
        for (let i = 0; i < questionAnswers.length; i++) {
          objectQuestionAnswers[questionAnswers[i].questionId] = questionAnswers[i].answerId;
        }
      }

      return objectQuestionAnswers;
    }
    return undefined;
  };

  return (
    <div>
      <TextContent fontSize={20}>{renderTitle()}</TextContent>
      <div className={styles["qa-wrap"]}>
        <div className={styles["question-wrap"]}>
          {getFormInitialValues() ? (
            <Form onFinish={submitForm} layout={"vertical"} initialValues={getFormInitialValues()}>
              {questionAndAnswerRender(questionnaire.questions)}
              {stepLoading ? null : (
                <div className={styles["submit-wrap"]}>
                  <Button
                    loading={postLoading}
                    className={styles.submit}
                    type={"primary"}
                    htmlType={"submit"}
                    data-test={"submit"}
                    disabled={completed}
                  >
                    Predaj upitnik
                  </Button>
                </div>
              )}
            </Form>
          ) : (
            <div>
              <TextContent fontSize={14}>
                Upitnik nije ispunjen u sklopu predviđene aktivnosti
              </TextContent>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
