import { Form, Radio } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { PatientHealthInfoProps } from "features/patients/components/PatientHealthInfo/interfaces/PatientHealthInfoProps";
import { PatientFormField } from "features/patients/enums/PatientFormField";
import { PatientFormFieldName } from "features/patients/enums/PatientFormFieldName";
import * as React from "react";
import { FunctionComponent } from "react";

export const PatientHealthInfo: FunctionComponent<PatientHealthInfoProps> = (props) => {
  return (
    <div>
      <Form.Item label={PatientFormFieldName.Smoking} name={PatientFormField.Smoking}>
        <Radio.Group>
          <Radio value={true}>Da</Radio>
          <Radio value={false}>Ne</Radio>
          <Radio value={null}>Nije poznato</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label={PatientFormFieldName.Alcohol} name={PatientFormField.Alcohol}>
        <Radio.Group>
          <Radio value={true}>Da</Radio>
          <Radio value={false}>Ne</Radio>
          <Radio value={null}>Nije poznato</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={PatientFormFieldName.Allergies}
        name={PatientFormField.Allergies}
        rules={[
          {
            max: 10000,
            message: "Ne smije imati više od 10000 znakova",
          },
        ]}
      >
        <TextArea rows={3} />
      </Form.Item>
      <Form.Item
        label={PatientFormFieldName.DiseasesDiagnosis}
        name={PatientFormField.DiseasesDiagnosis}
        rules={[
          {
            max: 10000,
            message: "Ne smije imati više od 10000 znakova",
          },
        ]}
      >
        <TextArea rows={10} />
      </Form.Item>
    </div>
  );
};
