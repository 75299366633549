import * as React from "react";
import styles from "./SkeletonInput.module.scss";
import { Skeleton } from "antd";

interface Props {}

export const SkeletonInput: React.FunctionComponent<React.HTMLAttributes<HTMLElement> & Props> = ({
  children,
  className = "",
  ...rest
}) => {
  return <Skeleton.Input className={styles.skeleton} active={true} size={"small"} />;
};
