import { Button, Form, Input, notification } from "antd";
import { apiSaga } from "app/store";
import { TextContent } from "common/TextContent/TextContent";
import { RouteUrl } from "enums/RouteUrl";
import { NewPasswordFormData } from "features/authentication/interfaces/NewPasswordFormData";
import { NewPasswordFormItemName } from "features/authentication/interfaces/NewPasswordFormItemName";
import { NewPasswordFormProps } from "features/authentication/interfaces/NewPasswordFormProps";
import * as React from "react";
import { FunctionComponent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const InputPassword = Input.Password;

// special characters are "balkan's characters"
const atleast8Charaters_capitalLetter_allowedSpecialChars: RegExp = new RegExp(
  "^(?=.*?[A-Z\u0161\u0111\u010D\u0107\u017E])(?=.*?[0-9]).{8,}$"
);

export const NewPasswordForm: FunctionComponent<NewPasswordFormProps> = (props) => {
  const navigate = useNavigate();
  const resetTokenQuery = useLocation().search;
  const resetToken = resetTokenQuery.split("=")[1];

  const [error, setError] = useState<any>();

  const onPasswordChangeNotification = () => {
    if (props.isSetPassword) {
      notification["info"]({
        message: "Lozinka je uspješno stvorena",
      });
      return;
    }
    notification["info"]({
      message: "Lozinka je uspješno promijenjena",
    });
    return;
  };

  const submitResetPasword = (formValues: NewPasswordFormData) => {
    apiSaga.apiService.httpAdapter
      .post(
        `${process.env.REACT_APP_API_URL}users/reset`,
        {
          password: `${formValues[NewPasswordFormItemName.AgainPassword]}`,
          resetToken: resetToken,
        },
        {
          headers: {
            "Content-Type": "application/json\n",
            Authorization: null,
          },
        }
      )
      .then(() => {
        onPasswordChangeNotification();
        navigate(RouteUrl.Login);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Form
        layout={"vertical"}
        onFinish={(values: unknown) => submitResetPasword(values as NewPasswordFormData)}
        onChange={() => {
          setError(null);
        }}
      >
        <Form.Item
          label={props.isSetPassword ? "Lozinka" : "Nova lozinka:"}
          name={NewPasswordFormItemName.NewPassword}
          rules={[
            {
              required: true,
              message: "Unesite minimalno 8 znakova, jedno veliko slovo, broj i specijalni znak",
              type: "string",
              pattern: atleast8Charaters_capitalLetter_allowedSpecialChars,
            },
          ]}
          validateTrigger={["onBlur", "onSubmit"]}
        >
          <InputPassword data-test={"pw-input"} />
        </Form.Item>
        <Form.Item
          label={props.isSetPassword ? "Ponovi lozinku" : "Lozinka:"}
          name={NewPasswordFormItemName.AgainPassword}
          dependencies={["newPassword"]}
          rules={[
            {
              required: true,
              message: "Molimo da potvrdite lozinku!",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("Lozinke se ne podudaraju!");
              },
            }),
          ]}
          validateTrigger={["onBlur", "onSubmit", "onChange"]}
        >
          <InputPassword data-test={"pw-again-input"} />
        </Form.Item>
        <Button type={"primary"} htmlType={"submit"} style={{ width: "100%" }} data-test={"submit"}>
          {props.isSetPassword ? "Kreiraj lozinku" : "Spremi lozinku"}
        </Button>
      </Form>
      {error ? <TextContent type={"danger"}>{error}</TextContent> : null}
    </div>
  );
};
