import { Button, Checkbox, Divider, Radio } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Space } from "common/Space/Space";
import { TextContent } from "common/TextContent/TextContent";
import { ChannelType } from "enums/entitySpecifics/ChannelType";
import { ActivityTemplateFooterProps } from "features/programs/components/ActivityTemplateFooter/interfaces/ActivityTemplateFooterProps";
import * as React from "react";
import { FunctionComponent } from "react";

export const ActivityTemplateFooter: FunctionComponent<ActivityTemplateFooterProps> = (props) => {
  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  return (
    <div>
      <Divider />
      <TextContent strong={true} fontSize={16}>
        Ishod aktivnosti
      </TextContent>
      <TextContent>
        <p>Označi kanal koji je korišten u ovoj Aktivnosti</p>
      </TextContent>
      <Space size={16} direction={"vertical"}>
        <Radio.Group disabled={props.disabledActivity}>
          <Radio value={ChannelType.Live} style={radioStyle}>
            Uživo
          </Radio>
          <Radio value={ChannelType.PhoneCall} style={radioStyle}>
            Telefon
          </Radio>
          <Radio value={ChannelType.Message} style={radioStyle}>
            Poruka
          </Radio>
        </Radio.Group>
        <div>
          <TextContent>
            <div>Bilješka</div>
          </TextContent>
          <Space size={16} direction={"vertical"}>
            <TextArea disabled={props.disabledActivity} rows={4} />
            <Checkbox disabled={props.disabledActivity}>
              Bilješka sadrži opis sumnje na nuspojavu ili posebne situacije (npr. uzimanje lijeka
              tijekom trudnoće ili dojenja, predoziranje, zloupotreba, pogrešna upotreba,
              medikacijska pogreška, nedjelotvornost lijeka)
            </Checkbox>
            <>
              <Button disabled={props.disabledActivity} type={"primary"}>
                Označi aktivnost uspješnom
              </Button>
              <Button disabled={props.disabledActivity} type={"link"}>
                Označi aktivnost neuspješnom
              </Button>
            </>
          </Space>
        </div>
      </Space>
    </div>
  );
};
