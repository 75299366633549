import { Table } from "antd";
import { RangePicker } from "common/RangePicker/RangePicker";
import { DateTimeFormat } from "enums/DateTimeFormat";
import { fetchReportExport } from "features/admin/components/Reports/fetchReportExport";
import { ReportsTableColumnDataIndex } from "features/admin/components/Reports/ReportsTable/enums/ReportsTableColumnDataIndex";
import { ReportsTableColumnTitle } from "features/admin/components/Reports/ReportsTable/enums/ReportsTableColumnTitle";
import { ReportUrlPrefix } from "features/admin/components/Reports/ReportsTable/enums/ReportUrlPrefix";
import { ReportsTableRecord } from "features/admin/components/Reports/ReportsTable/interfaces/ReportsTableRecord";
import { Moment } from "moment";
import * as React from "react";
import { FunctionComponent, useState } from "react";
import styles from "./ReportsTable.module.scss";

export const ReportsTable: FunctionComponent = () => {
  const [dateRange, setDateRange] = useState<[Moment, Moment] | undefined>(undefined);
  const reportsCollection = [
    {
      name: "Izvještaj - aktivnosti",
      urlPrefix: ReportUrlPrefix.Activities,
    },
    {
      name: "Izvještaj - programi",
      urlPrefix: ReportUrlPrefix.Programs,
    },
    {
      name: "Izvještaj - bilješke i nuspojave",
      urlPrefix: ReportUrlPrefix.ProgramComments,
    },
  ];
  const dataSource: Array<Omit<ReportsTableRecord, "urlPrefix">> = reportsCollection.map(
    (report) => {
      return {
        key: report.name,
        [ReportsTableColumnDataIndex.Report]: report.name,
        [ReportsTableColumnDataIndex.Options]: report,
      };
    }
  );
  const columns = [
    {
      title: ReportsTableColumnTitle.Report,
      dataIndex: ReportsTableColumnDataIndex.Report,
      key: ReportsTableColumnDataIndex.Report,
    },
    {
      title: ReportsTableColumnTitle.Options,
      dataIndex: ReportsTableColumnDataIndex.Options,
      key: ReportsTableColumnDataIndex.Options,
      render: (reportData: ReportsTableRecord) => {
        return (
          <span
            className={dateRange !== undefined ? styles.exportLink : styles.nonLink}
            onClick={() => {
              let reportExportUrl = "";
              let fileName = "";
              if (dateRange) {
                reportExportUrl = createReportExportUrl(reportData.urlPrefix, dateRange);
                fileName = createFileName(reportData.urlPrefix, dateRange);
              }
              if (reportExportUrl.length > 1) {
                fetchReportExport(reportExportUrl, fileName);
              }
            }}
          >
            Izvezi izvještaj
          </span>
        );
      },
    },
  ];

  const createReportExportUrl = (urlPrefix: ReportUrlPrefix, dateRange: [Moment, Moment]) => {
    const dayBeforeStartDate = dateRange[0]
      .subtract(1, "d")
      .utcOffset(0, false)
      .startOf("d")
      .format(DateTimeFormat.YearMonthDayDashed);
    const dayAfterEndDate = dateRange[1]
      .add(1, "d")
      .utcOffset(0, false)
      .startOf("d")
      .format(DateTimeFormat.YearMonthDayDashed);

    return `${urlPrefix}?actualDate[strictly_after]=${dayBeforeStartDate}&?actualDate[strictly_before]=${dayAfterEndDate}&success=true`;
  };

  const createFileName = (urlPrefix: ReportUrlPrefix, dateRange: [Moment, Moment]) => {
    let translatedName = "";
    switch (urlPrefix) {
      case ReportUrlPrefix.Activities:
        translatedName = "Izvjestaj_aktivnosti_";
        break;
      case ReportUrlPrefix.Programs:
        translatedName = "Izvjestaj_programa_";
        break;
      case ReportUrlPrefix.ProgramComments:
        translatedName = "Izvjestaj_biljeski_";
        break;
    }

    const actualStartDate = dateRange[0].add(1, "d").format(DateTimeFormat.DashedLocalized);
    const actualEndDate = dateRange[1].subtract(1, "d").format(DateTimeFormat.DashedLocalized);

    return `${translatedName}${actualStartDate}_${actualEndDate}`;
  };

  return (
    <>
      <div className={styles.tableHeader}>
        <span className={styles.tableTitle}>Izvještaj - općeniti</span>
        <div>
          <RangePicker
            onChange={(value: unknown) => {
              // [Moment, Moment] | null, No exported RangeValue<Moment> from antd
              if (value !== null) {
                setDateRange(value as [Moment, Moment]);
                return;
              }
              setDateRange(undefined);
            }}
          />
        </div>
      </div>
      <Table
        pagination={false}
        loading={false}
        dataSource={dataSource}
        columns={columns}
        className={styles.table}
      />
    </>
  );
};
