import { useGetAllControlled } from "@bornfight/aardvark";
import { Col, Row, Skeleton } from "antd";
import { ActivitiesMenu } from "features/patients/components/ActivitiesMenu/ActivitiesMenu";
import { Activity } from "features/patients/components/Activity/Activity";
import { activityActionHandler } from "handlers/ActivityActionHandler";
import * as React from "react";
import { FunctionComponent, useMemo } from "react";
import { AppJsonApiQuery } from "utils/AppJsonApiQuery";
import { useDeepCompareEffect, useDeepCompareMemo } from "use-deep-compare";
import { ProgramJSONAModel } from "interfaces/jsona-models/ProgramJSONAModel";

export const Activities: FunctionComponent<{
  program: ProgramJSONAModel | null;
}> = ({ program }) => {
  const activities = useMemo(() => {
    return program?.activities;
  }, [program]);

  const activityIdsToFetch: string[] = useMemo(() => {
    return (
      activities?.map((activity) => {
        return activity.uuid;
      }) || []
    );
  }, [activities]);

  const activityJsonApiQuery = useDeepCompareMemo(() => {
    const customParams = activityIdsToFetch.filter(Boolean).map((id) => {
      return {
        name: "uuid[]",
        value: id,
      };
    });
    return new AppJsonApiQuery({
      customParams,
      includes: ["activityTemplate", "steps"],
    });
  }, [activityIdsToFetch]);

  const {
    collection,
    getAll,
    loading: getActivitesLoading,
  } = useGetAllControlled(activityActionHandler, activityJsonApiQuery);

  useDeepCompareEffect(() => {
    if (activityIdsToFetch.length === 0) {
      return;
    }
    getAll();
  }, [activityIdsToFetch, activityJsonApiQuery, getAll]);

  if (program === null) {
    return <div></div>;
  }
  if (program?.dropOut === true) {
    return <div>Program je prekinut</div>;
  }

  return (
    <Row>
      {getActivitesLoading ? (
        <Skeleton />
      ) : (
        <>
          <Col span={8}>
            <ActivitiesMenu activities={collection} />
          </Col>
          <Col span={15} offset={1}>
            <Activity activities={collection} getActivities={getAll} />
          </Col>
        </>
      )}
    </Row>
  );
};
