import { usePatch, usePost } from "@bornfight/aardvark";
import { Button, Form, Input } from "antd";
import { ResourceType } from "enums/ResourceType";
import { AdminAddAnswerFormProps } from "features/admin/components/AdminAddAnswerForm/interfaces/AdminAddAnswerFormProps";
import { answerActionHandler } from "handlers/AnswerActionHandler";
import * as React from "react";
import { FunctionComponent } from "react";

export const AdminAddAnswerForm: FunctionComponent<AdminAddAnswerFormProps> = (props) => {
  const { create: createAnswer, loading: createAnswerLoading } = usePost(answerActionHandler);
  const { update: editAnswer, loading: editAnswerLoading } = usePatch(answerActionHandler);
  const getInitialValues = () => {
    if (props.isEditing) {
      return {
        answerText: props.currentAnswer?.text,
        answerExplanation: props.currentAnswer?.explanation,
      };
    }
    return undefined;
  };

  const handleEditAnswer = (values: {
    answerText: string;
    answerExplanation: string | undefined;
  }) => {
    if (props.currentAnswer) {
      editAnswer(props.currentAnswer.id, {
        model: {
          type: ResourceType.Answer,
          id: props.currentAnswer.id,
          text: values.answerText,
          explanation: values.answerExplanation || "",
        },
        includeNames: [],
      })
        .then(() => {
          props.getQuestionAnswers();
          props.closeModal();
        })
        .catch((err) => console.log(err));
    }
  };

  const handleSubmit = (values: { answerText: string; answerExplanation: string | undefined }) => {
    if (props.isEditing) {
      handleEditAnswer(values);
      return;
    }
    createAnswer({
      rawData: {
        data: {
          attributes: {
            text: values.answerText,
            explanation: values.answerExplanation || "",
          },
          relationships: {
            question: {
              type: ResourceType.Question,
              id: props.currentQuestion.id,
            },
          },
        },
      },
    })
      .then(async () => {
        await props.getQuestionAnswers();
        props.closeModal();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Form
      layout={"vertical"}
      onFinish={(values) => {
        handleSubmit(
          values as unknown as {
            answerText: string;
            answerExplanation: string | undefined;
          }
        );
      }}
      initialValues={getInitialValues()}
    >
      <Form.Item
        label={"Odgovor"}
        name={"answerText"}
        rules={[
          {
            required: true,
            message: "Ovo polje je obavezno.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label={"Pojašnjenje"} name={"answerExplanation"}>
        <Input />
      </Form.Item>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          type={"primary"}
          htmlType={"submit"}
          loading={createAnswerLoading || editAnswerLoading}
        >
          Spremi
        </Button>
      </div>
    </Form>
  );
};
