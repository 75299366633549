import { Col, Row } from "antd";
import { DatePicker } from "common/DatePicker/DatePicker";
import { TextContent } from "common/TextContent/TextContent";
import { ActivityTemplateHeaderProps } from "features/programs/components/ActivityTemplateHeader/interfaces/ActivityTemplateHeaderProps";
import { RecommendedChannel } from "interfaces/jsona-models/ActivityTemplateJSONAModel";
import moment from "moment";
import * as React from "react";
import { FunctionComponent } from "react";

export const ActivityTemplateHeader: FunctionComponent<ActivityTemplateHeaderProps> = (props) => {
  const renderChannelColumn = (channels: RecommendedChannel[] | undefined) => {
    if (channels === undefined) {
      return;
    }
    const translatedChannels = channels.map((channel) => {
      let channelName = "";
      switch (channel) {
        case RecommendedChannel.Survey:
          return (channelName = "Upitnik");
        case RecommendedChannel.PhoneCall:
          return (channelName = "Telefonski poziv");
        case RecommendedChannel.Message:
          return (channelName = "SMS");
        case RecommendedChannel.VideoCall:
          return (channelName = "Video poziv");
        case RecommendedChannel.Live:
          return (channelName = "Uživo");
      }
      return channelName;
    });
    const channelSpans = translatedChannels.map((translatedChannel, index, arr) => {
      if (arr.length - 1 === index) {
        // if last item in array don't show comma
        return <span key={index}>{translatedChannel}</span>;
      } else {
        return <span key={index}>{translatedChannel}, </span>;
      }
    });

    return <div>{channelSpans}</div>;
  };

  return (
    <>
      <Row>
        <TextContent strong={true} fontSize={16}>
          <p>{props.activityTemplate?.name}</p>
        </TextContent>
      </Row>
      <Row>
        <Col span={12}>
          <TextContent>
            <div>Datum aktivnosti</div>
          </TextContent>
          <DatePicker
            disabled={true}
            disabledDate={(current) => {
              return current < moment().startOf("day");
            }}
            placeholder={""}
          />
        </Col>
        <Col span={12}>
          <TextContent>
            <div>Predloženi kanali aktivnosti</div>
            <div>{renderChannelColumn(props?.activityTemplate?.recommendedChannels)}</div>
          </TextContent>
        </Col>
      </Row>
    </>
  );
};
