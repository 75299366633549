import { EntityEndpoint } from "enums/EntityEndpoint";
import { ResourceType } from "enums/ResourceType";
import { TherapyJSONAModel } from "interfaces/jsona-models/TherapyJSONAModel";
import { TherapySelector } from "selectors/TherapySelector";
import { AppApiActionHandler } from "./AppApiActionHandler";

class TherapyActionHandler extends AppApiActionHandler<TherapyJSONAModel> {
  constructor() {
    super(ResourceType.Therapy, EntityEndpoint.Therapy, new TherapySelector());
  }
}

export const therapyActionHandler = new TherapyActionHandler();
