import { SearchOutlined } from "@ant-design/icons/lib";
import { Button, Input, InputRef } from "antd";
import * as React from "react";

interface Props {
  dataIndex: string;
  title: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPressEnter: () => void;
  onClear: () => void;
}

export const TextFilter = React.forwardRef<InputRef, Props>((props, ref) => {
  return (
    <div style={{ padding: 8, display: "block" }}>
      <Input
        ref={ref}
        data-test={`resource-table-filter-input-${props.dataIndex}`}
        placeholder={`Pretraži ${props.title}`}
        value={props.value}
        onChange={props.onChange}
        onPressEnter={props.onPressEnter}
        style={{
          width: 188,
          marginBottom: 8,
          display: "block",
        }}
      />
      <Button
        data-test={`resource-table-filter-input-${props.dataIndex}-search`}
        type="primary"
        onClick={props.onPressEnter}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Traži
      </Button>
      <Button
        data-test={`resource-table-filter-input-${props.dataIndex}-clear`}
        onClick={props.onClear}
        size="small"
        style={{ width: 90 }}
      >
        Obriši
      </Button>
    </div>
  );
});
