import { Button, Col, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Space } from "common/Space/Space";
import { TextContent } from "common/TextContent/TextContent";
import { StepTemplateConversationProps } from "features/programs/components/StepTemplates/interfaces/StepTemplateConversationProps";
import * as React from "react";
import { FunctionComponent } from "react";

export const StepTemplateConversation: FunctionComponent<StepTemplateConversationProps> = (
  props
) => {
  const { stepTemplate } = props;

  const disabled = props.disabled;

  const renderMessageSection = () => {
    return (
      <div>
        <TextContent>
          <div>Tekst poruke:</div>
        </TextContent>
        <Space size={16} direction={"vertical"}>
          <TextArea disabled={disabled} rows={4} />
          <Button type={"primary"} disabled={disabled}>
            Pošalji poruku
          </Button>
        </Space>
      </div>
    );
  };

  return (
    <div>
      <TextContent strong={true} fontSize={16}>{`${stepTemplate?.name}`}</TextContent>
      <TextContent>
        <p>{stepTemplate?.description}</p>
      </TextContent>
      <Space size={12} direction={"vertical"}>
        <Row>
          <Col span={12}>
            <TextContent>
              <p>Broj mobitela</p>
              <p>+385...</p>
            </TextContent>
          </Col>
          <Col span={12}>
            <TextContent>
              <p>Broj telefona</p>
              <p>123456789...</p>
            </TextContent>
          </Col>
        </Row>
        {renderMessageSection()}
      </Space>
    </div>
  );
};
