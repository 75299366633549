import { EntityEndpoint } from "enums/EntityEndpoint";
import { ResourceType } from "enums/ResourceType";
import { PatientJSONAModel } from "interfaces/jsona-models/PatientJSONAModel";
import { PatientSelector } from "selectors/PatientSelector";
import { AppApiActionHandler } from "./AppApiActionHandler";

class PatientActionHandler extends AppApiActionHandler<PatientJSONAModel> {
  constructor() {
    super(ResourceType.Patient, EntityEndpoint.Patient, new PatientSelector());
  }
}

export const patientActionHandler = new PatientActionHandler();
