import { EntityEndpoint } from "enums/EntityEndpoint";
import { ResourceType } from "enums/ResourceType";
import { QuestionJSONAModel } from "interfaces/jsona-models/QuestionJSONAModel";
import { QuestionSelector } from "selectors/QuestionSelector";
import { AppApiActionHandler } from "./AppApiActionHandler";

class QuestionActionHandler extends AppApiActionHandler<QuestionJSONAModel> {
  constructor() {
    super(ResourceType.Question, EntityEndpoint.Question, new QuestionSelector());
  }
}

export const questionActionHandler = new QuestionActionHandler();
