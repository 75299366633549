import { useGetAllControlled } from "@bornfight/aardvark";
import { Divider, Skeleton } from "antd";
import { StepWrap } from "common/StepWrap/StepWrap";
import { StepType } from "enums/entitySpecifics/StepType";
import { ActivityTemplateProps } from "features/programs/components/ActivityTemplate/interfaces/ActivityTemplateProps";
import { ActivityTemplateFooter } from "features/programs/components/ActivityTemplateFooter/ActivityTemplateFooter";
import { ActivityTemplateHeader } from "features/programs/components/ActivityTemplateHeader/ActivityTemplateHeader";
import { StepTemplateInstruction } from "features/programs/components/StepTemplates/StepTemplateInstruction";
import { StepTemplateConversation } from "features/programs/components/StepTemplates/StepTemplateConversation";
import { StepTemplateQuestionnaire } from "features/programs/components/StepTemplates/StepTemplateQuestionnaire";
import { stepTemplateActionHandler } from "handlers/StepTemplateActionHandler";
import { ActivityTemplateJSONAModel } from "interfaces/jsona-models/ActivityTemplateJSONAModel";
import { StepTemplateJSONAModel } from "interfaces/jsona-models/StepTemplateJSONAModel";
import * as React from "react";
import { FunctionComponent, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { AppJsonApiQuery } from "utils/AppJsonApiQuery";
import { getUuidFromIri } from "utils/getUuidFromIri";

export const ActivityTemplate: FunctionComponent<ActivityTemplateProps> = (props) => {
  const params = useParams<{
    programTemplateId: string;
    activityTemplateId: string;
  }>();

  // rerender on url change
  useEffect(() => {}, [params.activityTemplateId]);

  const activityTemplateId = params.activityTemplateId;

  const currentActivityTemplate: ActivityTemplateJSONAModel | undefined = useMemo(() => {
    return props?.activityTemplates?.find((activityTemplate) => {
      return activityTemplate.uuid === activityTemplateId;
    });
  }, [activityTemplateId, props]);

  const stepTemplateIds = useMemo(() => {
    if (!currentActivityTemplate) {
      return [];
    }
    return currentActivityTemplate?.stepTemplates.map((stepTemplate) => {
      return getUuidFromIri(stepTemplate?.id);
    });
  }, [currentActivityTemplate]);

  const stepTemplateQuery = useMemo(() => {
    const customParams = stepTemplateIds.map((id) => {
      return {
        name: "uuid[]",
        value: id,
      };
    });
    return new AppJsonApiQuery({
      customParams,
      includes: ["conversation", "questionnaire", "instruction"],
    });
  }, [stepTemplateIds]);

  const { loading, getAll } = useGetAllControlled(stepTemplateActionHandler, stepTemplateQuery);

  useEffect(() => {
    if (props.setGetStepsLoading) {
      props.setGetStepsLoading(loading);
    }
  }, [props, loading]);

  useEffect(() => {
    getAll();
  }, [getAll]);

  if (loading) {
    return <Skeleton />;
  }

  const stepsRenderer = (stepTemplates?: StepTemplateJSONAModel[]) => {
    return stepTemplates?.map((stepTemplate, index) => {
      const stepProps = {
        stepTemplate: stepTemplate,
        disabled: true,
      };
      let componentToRender;
      switch (stepTemplate.stepType) {
        case StepType.Conversation:
          return (componentToRender = (
            <StepWrap key={index}>
              <StepTemplateConversation {...stepProps} />
            </StepWrap>
          ));
        case StepType.Questionnaire:
          return (componentToRender = (
            <StepWrap key={index}>
              <StepTemplateQuestionnaire {...stepProps} />
            </StepWrap>
          ));
        case StepType.Instruction:
          return (componentToRender = (
            <StepWrap key={index}>
              <StepTemplateInstruction {...stepProps} />
            </StepWrap>
          ));
      }
      return componentToRender;
    });
  };

  return (
    <div>
      <ActivityTemplateHeader activityTemplate={currentActivityTemplate} disabledActivity={true} />
      <Divider />
      {stepsRenderer(currentActivityTemplate?.stepTemplates)}
      <ActivityTemplateFooter activityTemplate={currentActivityTemplate} disabledActivity={true} />
    </div>
  );
};
