import { Layout } from "antd";
import { SiderBreakpoint } from "common/Sidebar/enums/SiderBreakpoint";
import { SidebarProps } from "common/Sidebar/interfaces/SidebarProps";
import { FunctionComponent } from "react";
import * as React from "react";

const { Sider } = Layout;

export const Sidebar: FunctionComponent<SidebarProps> = (props) => {
  return (
    <Sider width={160} breakpoint={SiderBreakpoint.Large} collapsible={false}>
      {props.menu}
    </Sider>
  );
};
