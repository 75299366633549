import { Modal } from "antd";
import { ModalExtendedProps } from "common/ModalExtended/interfaces/ModalExtendedProps";
import * as React from "react";
import { FunctionComponent } from "react";

export const ModalExtended: FunctionComponent<ModalExtendedProps> = (props) => {
  const { children, ...restProps } = props;
  return (
    <Modal
      width={380}
      keyboard={false}
      maskClosable={false}
      destroyOnClose={true}
      afterClose={() => document.body.style.removeProperty("overflow")}
      footer={null}
      {...restProps}
    >
      {children}
    </Modal>
  );
};
