import { ArrowLeftOutlined } from "@ant-design/icons/lib";
import { useGet } from "@bornfight/aardvark";
import { Form, notification, Skeleton } from "antd";
import { UploadProps } from "antd/lib/upload";
import { apiSaga } from "app/store";
import { PageHeader } from "common/PageHeader/PageHeader";
import { PageWrap } from "common/PageWrap/PageWrap";
import { TextContent } from "common/TextContent/TextContent";
import { RouteUrl } from "enums/RouteUrl";
import { PatientGeneralInfo } from "features/patients/components/PatientGeneralInfo/PatientGeneralInfo";
import { PatientHealthInfo } from "features/patients/components/PatientHealthInfo/PatientHealthInfo";
import { PatientMenuItem } from "features/patients/components/PatientMenu/enums/PatientMenuItem";
import { PatientMenu } from "features/patients/components/PatientMenu/PatientMenu";
import { PatientProgramInfo } from "features/patients/components/PatientProgramInfo/PatientProgramInfo";
import { PatientFormField } from "features/patients/enums/PatientFormField";
import { PatientFormValues } from "features/patients/enums/PatientFormValues";
import { SinglePatientScreenLocationState } from "features/patients/enums/SinglePatientScreenLocationState";
import { patientActionHandler } from "handlers/PatientActionHandler";
import moment, { Moment } from "moment";
import * as React from "react";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./SinglePatientScreen.module.scss";

const onEditPatientNotification = () => {
  notification["info"]({
    message: "Podaci o pacijentu su uspješno spremljeni.",
  });
  return;
};

export const SinglePatientScreen: FunctionComponent = () => {
  const navigate = useNavigate();
  const locationState = useLocation().state as SinglePatientScreenLocationState;

  const params = useParams<{ patientId: string }>();
  const [selectedKey, setSelectedKey] = useState<PatientMenuItem>(PatientMenuItem.Info);
  const [patchLoading, setPatchLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<any[]>([]);

  const uploadProps: UploadProps = {
    beforeUpload: (file: any) => {
      setFileList([...fileList, file]);
      return false;
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);

      setFileList(newFileList);
    },
    maxCount: 1,
  };

  const patientId = useMemo(() => {
    return params.patientId;
  }, [params]);

  const includes = useMemo(() => {
    return ["consent", "patientHealthData"];
  }, []);

  const { record: patient, loading } = useGet(
    patientActionHandler,
    `/api/patients/${patientId}`,
    includes
  );

  useEffect(() => {}, [patient]);

  const renderContentBySelectedMenuItem = () => {
    switch (selectedKey) {
      case PatientMenuItem.Info:
        return patientInfoRender();
      case PatientMenuItem.Programs:
        return programInfoRender();
    }
  };

  const getInitialValues = (): PatientFormValues => {
    let birthday: string | Moment = "";
    if (patient?.dateOfBirth) {
      birthday = moment(patient?.dateOfBirth);
    }
    return {
      [PatientFormField.FirstName]: patient?.firstName || "",
      [PatientFormField.LastName]: patient?.lastName || "",
      [PatientFormField.MobilePhoneNumber]: patient?.mobilePhoneNumber || "",
      [PatientFormField.TelephoneNumber]: patient?.landlinePhoneNumber || "",
      [PatientFormField.Birthday]: birthday,
      [PatientFormField.Gender]: patient?.gender || undefined,
      [PatientFormField.Height]: patient?.height,
      [PatientFormField.Weight]: patient?.weight,
      [PatientFormField.Smoking]: patient?.patientHealthData?.cigaretteConsumption,
      [PatientFormField.Alcohol]: patient?.patientHealthData?.alcoholConsumption,
      [PatientFormField.Allergies]: patient?.patientHealthData.allergies,
      [PatientFormField.DiseasesDiagnosis]: patient?.patientHealthData.diagnosis,
      [PatientFormField.GdprConsent]: { file: {}, fileList: [] },
    };
  };

  const handleEditPatient = (values: PatientFormValues) => {
    setPatchLoading(true);
    const editPatientPromise = apiSaga.apiService.httpAdapter.patch(
      `${process.env.REACT_APP_API_URL}patients/${patientId}`,
      {
        data: {
          attributes: {
            firstName: values[PatientFormField.FirstName],
            lastName: values[PatientFormField.LastName],
            mobilePhoneNumber: values[PatientFormField.MobilePhoneNumber],
            landlinePhoneNumber: values[PatientFormField.TelephoneNumber],
            dateOfBirth: values[PatientFormField.Birthday],
            gender: values[PatientFormField.Gender],
            height: values[PatientFormField.Height],
            weight: values[PatientFormField.Weight],
          },
        },
      }
    );
    const editPatientHealthDataPromise = apiSaga.apiService.httpAdapter.patch(
      `${process.env.REACT_APP_API_URL}patient_health_datas/${patient?.patientHealthData.uuid}`,
      {
        data: {
          attributes: {
            alcoholConsumption: values[PatientFormField.Alcohol],
            cigaretteConsumption: values[PatientFormField.Smoking],
            allergies: values[PatientFormField.Allergies],
            diagnosis: values[PatientFormField.DiseasesDiagnosis],
          },
        },
      }
    );

    Promise.all([editPatientPromise, editPatientHealthDataPromise]).then(() => {
      setPatchLoading(false);
      onEditPatientNotification();
    });
  };

  const patientInfoRender = () => {
    if (loading || patient === null) {
      return <Skeleton />;
    }
    return (
      <Form
        className={styles["patient-info-wrap"]}
        layout={"vertical"}
        onFinish={(values) => handleEditPatient(values as PatientFormValues)}
        initialValues={getInitialValues()}
      >
        <div className={styles["patient-general-info-wrap"]}>
          <TextContent fontSize={20}>
            <p className={styles.title}>Podaci pacijenta</p>
          </TextContent>
          <PatientGeneralInfo
            editPatient={true}
            patient={patient}
            patchLoading={patchLoading}
            uploadProps={uploadProps}
            fileList={fileList}
          />
        </div>
        <div className={styles["patient-health-info-wrap"]}>
          <PatientHealthInfo patient={patient} />
        </div>
      </Form>
    );
  };
  const programInfoRender = () => {
    return (
      <div className={styles["patient-info-wrap"]}>
        <PatientProgramInfo />
      </div>
    );
  };

  return (
    <div>
      <PageHeader
        title={`${patient?.firstName} ${patient?.lastName}`}
        leftComponent={
          <ArrowLeftOutlined
            style={{ fontSize: "26px" }}
            onClick={() =>
              navigate(RouteUrl.Patients, {
                state: { patientTablePage: locationState?.patientTablePage },
              })
            }
          />
        }
      />
      <PageWrap coverWhole={true} noLeftPadding={true}>
        <div className={styles.wrap}>
          <div className={styles["menu-wrap"]}>
            <PatientMenu selectedKey={selectedKey} setSelectedKey={setSelectedKey} />
          </div>
          {renderContentBySelectedMenuItem()}
        </div>
      </PageWrap>
    </div>
  );
};
