import * as React from "react";
import { Card } from "antd";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import styles from "./HomeCard.module.scss";
import { TextContent } from "../../../common/TextContent/TextContent";
import { HomeCardProps } from "../interfaces/HomeCardProps";

export const HomeCard: FunctionComponent<HomeCardProps> = (props) => {
  const contentData = props.content.map((content) => {
    return (
      <div key={content.title}>
        <TextContent fontSize={14}>
          <p className={styles.subtitle}>{content.title}</p>
        </TextContent>
        <TextContent strong={true} fontSize={30}>
          <span className={styles.value}>{content.value}</span>
        </TextContent>
      </div>
    );
  });
  return (
    <Card
      loading={props.loading}
      className={styles.card}
      title={props.title}
      extra={<Link to={props.extra.url}>{props.extra?.label || "Pregledaj sve"}</Link>}
    >
      {contentData}
    </Card>
  );
};
