import { Col, Divider, Row } from "antd";
import { TextContent } from "common/TextContent/TextContent";
import { DateTimeFormat } from "enums/DateTimeFormat";
import { ProgramCommentJSONAModel } from "interfaces/jsona-models/ProgramCommentJSONAModel";
import moment from "moment";
import * as React from "react";
import { FunctionComponent } from "react";
import styles from "./Comments.module.scss";
import { ProgramJSONAModel } from "../../../../interfaces/jsona-models/ProgramJSONAModel";

export const Comments: FunctionComponent<{
  program: ProgramJSONAModel | null;
}> = ({ program }) => {
  const comments: ProgramCommentJSONAModel[] = program?.programComments || [];

  const renderComments = (comments: ProgramCommentJSONAModel[]) => {
    return comments.map((comment) => {
      const isSideEffectSent = comment.sideEffect;

      return (
        <div key={comment.id} data-test={"comment-block"}>
          <Row justify={"space-between"}>
            <Col>
              <TextContent>
                <p>{moment(comment.createdAt).format(DateTimeFormat.ShortLocalized)}</p>
              </TextContent>
            </Col>
            {isSideEffectSent ? (
              <Col>
                <TextContent>
                  <p className={styles.green}>Prijavljeno</p>
                </TextContent>
              </Col>
            ) : null}
          </Row>
          <Row>
            <Col>
              <TextContent strong={true}>{comment.activityTitle}</TextContent>
            </Col>
          </Row>
          <Row>
            <TextContent>{comment.text}</TextContent>
          </Row>
          <Divider />
        </div>
      );
    });
  };
  return (
    <div className={styles.wrap}>
      <TextContent strong={true} fontSize={16}>
        {comments.length > 0 ? "Povijest bilješki aktivnosti" : "Nema bilješki"}
      </TextContent>
      {renderComments(comments)}
    </div>
  );
};
