import { JSONAModel } from "@bornfight/aardvark/dist/interfaces/JSONAModel";
import { ActivityJSONAModel } from "interfaces/jsona-models/ActivityJSONAModel";
import { PatientJSONAModel } from "interfaces/jsona-models/PatientJSONAModel";
import { ProgramCommentJSONAModel } from "interfaces/jsona-models/ProgramCommentJSONAModel";
import { ProgramTemplateJSONAModel } from "interfaces/jsona-models/ProgramTemplateJSONAModel";
import { TherapyJSONAModel } from "interfaces/jsona-models/TherapyJSONAModel";
import { UuidAttribute } from "interfaces/UuidAttribute";

export type ProgramJSONAModel = JSONAModel<Program, Relationships>;

export enum ProgramStatus {
  Active = "active",
  Inactive = "inactive",
  Completed = "completed",
}

export interface Program extends UuidAttribute {
  dropOut: boolean;
  status: ProgramStatus;
}

interface Relationships {
  patient: PatientJSONAModel;
  programTemplate: ProgramTemplateJSONAModel;
  activities: ActivityJSONAModel[];
  programComments: ProgramCommentJSONAModel[];
  therapies: TherapyJSONAModel[];
}
