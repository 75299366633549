import { FuzzySearchType, useGetAllControlled } from "@bornfight/aardvark";
import { ColumnFilterType } from "common/ResourceTable/enums/ColumnFilterType";
import { TableColumnData } from "common/ResourceTable/interfaces/TableColumnData";
import { ResourceTable } from "common/ResourceTable/ResourceTable";
import { Space } from "common/Space/Space";
import { TextContent } from "common/TextContent/TextContent";
import { ActivityStatus } from "enums/ActivityStatus";
import { DateTimeFormat } from "enums/DateTimeFormat";
import { ArchivedActivityTableColumnDataIndex } from "features/activities/enums/ArchivedActivityTableColumnDataIndex";
import { ArchivedActivityTableColumnTitle } from "features/activities/enums/ArchivedActivityTableColumnTitle";
import { ActivityState } from "features/activities/interfaces/ActivityState";
import { ArchivedActivityTableRecord } from "features/activities/interfaces/ArchivedActivityTableRecord";
import { activityActionHandler } from "handlers/ActivityActionHandler";
import { patientActionHandler } from "handlers/PatientActionHandler";
import { programActionHandler } from "handlers/ProgramActionHandler";
import { ActivityJSONAModel } from "interfaces/jsona-models/ActivityJSONAModel";
import { RecommendedChannel } from "interfaces/jsona-models/ActivityTemplateJSONAModel";
import isEqual from "lodash.isequal";
import moment, { Moment } from "moment";
import * as React from "react";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { SkeletonInput } from "common/SkeletonInput/SkeletonInput";
import { AppJsonApiQuery } from "utils/AppJsonApiQuery";
import styles from "./ArchivedActivityTable.module.scss";
import { CustomParam } from "@bornfight/aardvark/dist/services/JsonApiQuery/interfaces/CustomParam";
import { getUuidFromIri } from "utils/getUuidFromIri";

const translateActivityStatus = (status: ActivityStatus) => {
  switch (status) {
    case ActivityStatus.Completed:
      return "Završeno";
    case ActivityStatus.Interactive:
      return "Interaktivno";
    case ActivityStatus.Scheduled:
      return "Zakazano";
    case ActivityStatus.Unsuccessful:
      return "Neuspješno završeno";
    case ActivityStatus.Unknown:
      return "Nepoznato";
    case ActivityStatus.DroppedOut:
      return "Ručni drop out";
    case ActivityStatus.AutomaticDroppedOut:
      return "Automatski drop out";
  }
};

const renderDateColumn = (dateString: Moment) => {
  const currentActivityDate = moment(dateString).startOf("day");
  return <TextContent>{currentActivityDate.format(DateTimeFormat.ShortLocalized)}</TextContent>;
};

const renderChannelColumn = (channels: RecommendedChannel[]) => {
  if (channels === undefined) {
    return;
  }
  const translatedChannels = channels.map((channel) => {
    let channelName = "";
    switch (channel) {
      case RecommendedChannel.Survey:
        return (channelName = "Upitnik");
      case RecommendedChannel.PhoneCall:
        return (channelName = "Telefonski poziv");
      case RecommendedChannel.Message:
        return (channelName = "SMS");
      case RecommendedChannel.VideoCall:
        return (channelName = "Video poziv");
      case RecommendedChannel.Live:
        return (channelName = "Uživo");
    }
    return channelName;
  });
  const channelSpans = translatedChannels.map((translatedChannel, index, arr) => {
    if (arr.length - 1 === index) {
      // if last item in array don't show comma
      return <span key={index}>{translatedChannel}</span>;
    } else {
      return <span key={index}>{translatedChannel}, </span>;
    }
  });

  return <div>{channelSpans}</div>;
};

const renderStateColumn = (activityState: ActivityState) => {
  const translatedStatus = translateActivityStatus(activityState.status);
  if (activityState.status === ActivityStatus.Completed) {
    return <span className={styles["sucessful-state"]}>{translatedStatus}</span>;
  }
  if (activityState.status === ActivityStatus.Unsuccessful) {
    return <span className={styles["cancelled-state"]}>{translatedStatus}</span>;
  }
  return <span>{translatedStatus}</span>;
};

export const ArchivedActivityTable: FunctionComponent = (props) => {
  const [patientIds, setPatientIds] = useState<string[]>([]);
  const patientsJsonApiQuery = useMemo(() => {
    const patientIdsArray = patientIds.filter(Boolean);
    const uniquePatientIds = new Set(patientIdsArray);

    const customParams: CustomParam[] = [...uniquePatientIds].map((id) => {
      return {
        name: "uuid[]",
        value: getUuidFromIri(id),
      };
    });

    return new AppJsonApiQuery({
      customParams,
    });
  }, [patientIds]);

  const { getAll, loading: patientsLoading } = useGetAllControlled(
    patientActionHandler,
    patientsJsonApiQuery
  );

  const [programIds, setProgramIds] = useState<string[]>([]);
  const programJsonApiQuery = useMemo(() => {
    const programIdsArray = programIds.filter(Boolean);
    const uniqueProgramIds = new Set(programIdsArray);

    const customParams: CustomParam[] = [...uniqueProgramIds].map((id) => {
      return {
        name: "uuid[]",
        value: getUuidFromIri(id),
      };
    });

    return new AppJsonApiQuery({
      customParams,
      includes: ["programTemplate"],
    });
  }, [programIds]);

  const getAllPrograms = useGetAllControlled(programActionHandler, programJsonApiQuery).getAll;
  const programLoading = useGetAllControlled(programActionHandler, programJsonApiQuery).loading;

  useEffect(() => {
    if (patientIds.length > 0) {
      getAll();
    }
    if (programIds.length > 0) {
      getAllPrograms();
    }
  }, [patientIds, getAll, programIds, getAllPrograms]);

  const createColumnData = useMemo((): TableColumnData<ArchivedActivityTableRecord>[] => {
    return [
      {
        title: ArchivedActivityTableColumnTitle.Date,
        dataIndex: ArchivedActivityTableColumnDataIndex.Date,
        filter: {
          type: ColumnFilterType.Date,
          field: "expectedDate",
        },
        render: (dateString: Moment) => {
          return renderDateColumn(dateString);
        },
      },
      {
        title: ArchivedActivityTableColumnTitle.Activity,
        dataIndex: ArchivedActivityTableColumnDataIndex.Activity,
      },
      {
        title: ArchivedActivityTableColumnTitle.Channels,
        dataIndex: ArchivedActivityTableColumnDataIndex.Channels,
        render: (channels) => {
          return renderChannelColumn(channels);
        },
      },
      {
        title: ArchivedActivityTableColumnTitle.FirstName,
        dataIndex: ArchivedActivityTableColumnDataIndex.FirstName,
        filter: {
          type: ColumnFilterType.Text,
          field: "program.patient.firstName",
          fuzzySearchType: FuzzySearchType.StartWith,
        },
        render: (data) => {
          if (patientsLoading) {
            return <SkeletonInput />;
          }
          return data;
        },
      },
      {
        title: ArchivedActivityTableColumnTitle.LastName,
        dataIndex: ArchivedActivityTableColumnDataIndex.LastName,
        filter: {
          type: ColumnFilterType.Text,
          field: "program.patient.lastName",
          fuzzySearchType: FuzzySearchType.StartWith,
        },
        render: (data) => {
          if (patientsLoading) {
            return <SkeletonInput />;
          }
          return data;
        },
      },
      {
        title: ArchivedActivityTableColumnTitle.Program,
        dataIndex: ArchivedActivityTableColumnDataIndex.Program,
        render: (data) => {
          if (programLoading) {
            return <SkeletonInput />;
          }
          return data;
        },
      },
      {
        title: ArchivedActivityTableColumnTitle.State,
        dataIndex: ArchivedActivityTableColumnDataIndex.State,
        render: (activityState: ActivityState) => {
          return renderStateColumn(activityState);
        },
      },
    ];
  }, [patientsLoading, programLoading]);
  const tableData = (activities: ActivityJSONAModel[]): ArchivedActivityTableRecord[] => {
    return activities.map((activity, index) => {
      const activityDate = moment(activity.actualDate);

      const channels = activity.activityTemplate.recommendedChannels;
      const activityName = activity.activityTemplate.name;
      const programName = activity.program.programTemplate.name;
      const firstName = activity.program.patient.firstName;
      const lastName = activity.program.patient.lastName;
      return {
        key: activity.id,
        [ArchivedActivityTableColumnDataIndex.Date]: activityDate,
        [ArchivedActivityTableColumnDataIndex.Activity]: activityName,
        [ArchivedActivityTableColumnDataIndex.Channels]: channels,
        [ArchivedActivityTableColumnDataIndex.FirstName]: firstName,
        [ArchivedActivityTableColumnDataIndex.LastName]: lastName,
        [ArchivedActivityTableColumnDataIndex.Program]: programName,
        [ArchivedActivityTableColumnDataIndex.State]: {
          status: activity.status,
          sucessful: activity.success,
        },
      };
    });
  };
  const getJsonApiQuery = useMemo(() => {
    return new AppJsonApiQuery({
      includes: ["activityTemplate", "program"],
      customParams: [
        {
          name: "status[]",
          value: ActivityStatus.Completed,
        },
        {
          name: "status[]",
          value: ActivityStatus.Unsuccessful,
        },
        {
          name: "status[]",
          value: ActivityStatus.DroppedOut,
        },
        {
          name: "status[]",
          value: ActivityStatus.AutomaticDroppedOut,
        },
      ],
    });
  }, []);

  return (
    <Space size={20} direction={"vertical"}>
      <ResourceTable<ArchivedActivityTableRecord, ActivityJSONAModel>
        apiActionHandler={activityActionHandler}
        transformToDataSource={tableData}
        columnData={createColumnData}
        jsonApiQuery={getJsonApiQuery}
        className={styles.table}
        onCollectionRender={(collection) => {
          const patientIds = collection.map((value) => value?.program?.patient?.id);
          setPatientIds((data) => {
            if (isEqual(data, patientIds)) {
              return data;
            }
            return patientIds;
          });

          const programIds = collection.map((value) => value?.program?.id);
          setProgramIds((data) => {
            if (isEqual(data, programIds)) {
              return data;
            }
            return programIds;
          });
        }}
      />
    </Space>
  );
};
