import { IntakePartOfDay } from "interfaces/jsona-models/TherapyJSONAModel";

export const translateIntakePartOfDay = (intakePartOfDay: IntakePartOfDay) => {
  switch (intakePartOfDay) {
    case IntakePartOfDay.MorningEmptyStomach:
      return "Ujutro na tašte";
    case IntakePartOfDay.Morning:
      return "Ujutro";
    case IntakePartOfDay.Afternoon:
      return "Poslijepodne";
    case IntakePartOfDay.Night:
      return "Navečer";
    case IntakePartOfDay.NonApplicable:
      return "Nije primjenjivo";
  }
};
