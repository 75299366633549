import { UploadOutlined } from "@ant-design/icons/lib";
import { useDelete } from "@bornfight/aardvark";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Select,
  Skeleton,
  Upload,
} from "antd";
import { RuleObject } from "antd/lib/form";
import { NamePath, StoreValue } from "antd/lib/form/interface";
import { ModalFuncProps } from "antd/lib/modal";
import { apiSaga } from "app/store";
import { DatePicker } from "common/DatePicker/DatePicker";
import { FileCard } from "common/FileCard/FileCard";
import { Space } from "common/Space/Space";
import { TextContent } from "common/TextContent/TextContent";
import { DateTimeFormat } from "enums/DateTimeFormat";
import { RouteUrl } from "enums/RouteUrl";
import { AllowedGDPRFileType } from "features/patients/components/PatientGeneralInfo/enums/AllowedGDPRFileType";
import { PatientGeneralInfoProps } from "features/patients/components/PatientGeneralInfo/interfaces/PatientGeneralInfoProps";
import { PatientFormField } from "features/patients/enums/PatientFormField";
import { PatientFormFieldName } from "features/patients/enums/PatientFormFieldName";
import { PatientFormGender } from "features/patients/enums/PatientFormGender";
import moment, { Moment } from "moment";
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { removeOverflowOnDropdownClose } from "utils/removeOverflowOnDropdownClose";
import { consentActionHandler } from "../../../../handlers/ConsentActionHandler";
import styles from "./PatientGeneralInfo.module.scss";

const onGDPRConsentDeleteNotification = () => {
  notification["info"]({
    message: "GDPR privola je uspješno izbrisana",
  });
};

export const PatientGeneralInfo: FunctionComponent<PatientGeneralInfoProps> = (props) => {
  const navigate = useNavigate();
  const [modal, contextHolder] = Modal.useModal();
  const { deleteRecord, loading } = useDelete(consentActionHandler);
  const [image, setImage] = useState<Blob>();

  useEffect(() => {
    const getImage = async () => {
      await apiSaga.apiService.httpAdapter
        .get(
          `${process.env.REACT_APP_API_URL}consent/file/${props.patient?.consent.id
            .split("/")
            .pop()}`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          setImage(response.data);
        });
    };

    getImage();
  }, []);

  const deleteConsent = () => {
    if (props?.patient?.consent?.id) {
      deleteRecord(props?.patient?.consent?.id).then(() => {
        onGDPRConsentDeleteNotification();
        navigate(RouteUrl.Patients);
      });
    }
  };

  const confirmModalConfig: ModalFuncProps = {
    title: "Jeste li sigurni da želite ukloniti GDPR privolu pacijenta?",
    content: (
      <div>
        <TextContent>Uklanjanjem GDPR privole više</TextContent>
        <TextContent type={"danger"}>
          {" "}
          neće biti moguće pristupiti pacijentu te se njegov program prekida.{" "}
        </TextContent>
      </div>
    ),
    onOk: () => {
      deleteConsent();
    },
  };

  const validateGDPRUpload = (
    rule: RuleObject,
    value: StoreValue,
    getFieldValue: (name: NamePath) => any
  ) => {
    const file = value.file;
    const fileSizeInMB = Number((file.size / (1024 * 1024)).toFixed(3));

    const isFileTypeAllowed =
      file.type === AllowedGDPRFileType.JPG ||
      file.type === AllowedGDPRFileType.JPEG ||
      file.type === AllowedGDPRFileType.PNG;

    const doesFileExistAndIsItSingular =
      value.fileList.length === 1 && getFieldValue(PatientFormField.GdprConsent) === value;

    const isPassingValidation =
      doesFileExistAndIsItSingular && isFileTypeAllowed && fileSizeInMB < 5;

    // validation pass
    if (isPassingValidation) {
      return Promise.resolve();
    }

    // validation errors
    if (fileSizeInMB > 5) {
      return Promise.reject("Dokaz privole mora biti manji od 5MB");
    }
    if (!isFileTypeAllowed) {
      return Promise.reject("Dokaz privole mora biti slikovna datoteka (.jpg ili .png)");
    }
    if (value.fileList.length > 1) {
      return Promise.reject("Moguće je učitati samo jednu privolu");
    }
    return Promise.reject("Molimo učitajte dokaz privole");
  };

  const uploadFileFormItem = () => {
    if (props.editPatient) {
      const imgLink = image && URL.createObjectURL(image);
      const imgName = `${props.patient?.firstName}${props.patient?.lastName}Privola`;
      return (
        <>
          <div>
            <Space size={10} direction={"vertical"}>
              <TextContent strong={true}>GDPR Privola</TextContent>

              {props.patient?.consent ? (
                <FileCard
                  imgLink={imgLink || ""}
                  imgName={imgName}
                  onXClick={() => modal.confirm(confirmModalConfig)}
                />
              ) : (
                <TextContent>Povučena</TextContent>
              )}
            </Space>
          </div>
        </>
      );
    }
    return (
      <Form.Item
        label={PatientFormFieldName.GdprConsent}
        name={PatientFormField.GdprConsent}
        rules={[
          {
            required: true,
            message: "Molimo učitajte dokaz privole",
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              return validateGDPRUpload(rule, value, getFieldValue);
            },
          }),
        ]}
      >
        <Upload {...props.uploadProps}>
          <Button htmlType={"button"} data-test={"upload-gdpr-button"}>
            <UploadOutlined /> Odaberi datoteku...
          </Button>
        </Upload>
      </Form.Item>
    );
  };
  return (
    <div className={styles.wrap}>
      {loading ? (
        <Skeleton />
      ) : (
        <>
          {uploadFileFormItem()}
          <TextContent>
            <p className={styles["personal-info-title"]}>
              <b>Osobni podaci</b>
            </p>
          </TextContent>
          <Form.Item
            label={PatientFormFieldName.FirstName}
            name={PatientFormField.FirstName}
            rules={[{ required: true, message: "Molimo unesite ime." }]}
          >
            <Input placeholder={"Unesi ime"} />
          </Form.Item>
          <Form.Item
            label={PatientFormFieldName.LastName}
            name={PatientFormField.LastName}
            rules={[
              {
                required: true,
                message: "Molimo unesite prezime.",
              },
            ]}
          >
            <Input placeholder={"Unesi prezime"} />
          </Form.Item>
          <Row justify={"space-between"}>
            <Col span={11}>
              <Form.Item
                label={PatientFormFieldName.MobilePhoneNumber}
                name={PatientFormField.MobilePhoneNumber}
                validateTrigger={"onBlur"}
                rules={[
                  {
                    required: true,
                    pattern: /^[+]\d{11,13}$/,
                    message: "Format broja mora biti +3859 s dodatnih 7-9 znamenaka",
                  },
                ]}
              >
                <Input placeholder={"+385.."} />
              </Form.Item>
              <Form.Item
                label={PatientFormFieldName.Birthday}
                name={PatientFormField.Birthday}
                rules={[
                  {
                    required: true,
                    message: "Molimo unesite godinu rođenja.",
                  },
                ]}
              >
                <DatePicker
                  placeholder={"Unesi godinu"}
                  disabledDate={(current) => {
                    return current > moment().endOf("day");
                  }}
                  picker={"year"}
                  // the following two props will disable typing input
                  format={(selected: Moment) => {
                    return selected.startOf("year").add(1, "d").format(DateTimeFormat.Year);
                  }}
                  inputReadOnly={true}
                />
              </Form.Item>
              <Form.Item label={PatientFormFieldName.Height} name={PatientFormField.Height}>
                <InputNumber placeholder={"cm"} />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label={PatientFormFieldName.TelephoneNumber}
                name={PatientFormField.TelephoneNumber}
              >
                <Input placeholder={"npr. 012345632"} />
              </Form.Item>
              <Form.Item
                label={PatientFormFieldName.Gender}
                name={PatientFormField.Gender}
                rules={[
                  {
                    required: true,
                    message: "Molimo unesite spol pacijenta.",
                  },
                ]}
              >
                <Select onDropdownVisibleChange={removeOverflowOnDropdownClose}>
                  <Select.Option value={PatientFormGender.Male}>Muško</Select.Option>
                  <Select.Option value={PatientFormGender.Female}>Žensko</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label={PatientFormFieldName.Weight} name={PatientFormField.Weight}>
                <InputNumber placeholder={"kg"} />
              </Form.Item>
            </Col>
            <Button
              data-test={"save-patient-button"}
              className={styles.submit}
              htmlType={"submit"}
              type={"primary"}
              loading={props.createPatientLoading || props.patchLoading}
            >
              {props.editPatient ? "Spremi promjene" : "Spremi Novog Pacijenta"}
            </Button>
          </Row>
        </>
      )}
      {contextHolder}
    </div>
  );
};
