import { SettingOutlined } from "@ant-design/icons/lib";
import { useDelete } from "@bornfight/aardvark";
import { Button, Dropdown, Menu, Table } from "antd";
import { ModalExtended } from "common/ModalExtended/ModalExtended";
import { TextContent } from "common/TextContent/TextContent";
import { AdminActivityStepsTable } from "features/admin/components/AdminActivityStepsTable/AdminActivityStepsTable";
import { AdminAddActivityTemplateForm } from "features/admin/components/AdminAddActivityTemplateForm/AdminAddActivityTemplateForm";
import { AdminProgramActivityTemplateTableColumnDataIndex } from "features/admin/components/AdminProgramActivityTemplateTable/enums/AdminProgramActivityTemplateTableColumnDataIndex";
import { AdminProgramActivityTemplateTableColumnTitle } from "features/admin/components/AdminProgramActivityTemplateTable/enums/AdminProgramActivityTemplateTableColumnTitle";
import { AdminProgramActivitiesTableRecord } from "features/admin/components/AdminProgramActivityTemplateTable/interfaces/AdminProgramActivitiesTableRecord";
import { AdminProgramActivityTemplatesTableProps } from "features/admin/components/AdminProgramActivityTemplateTable/interfaces/AdminProgramActivityTemplatesTableProps";
import { activityTemplateActionHandler } from "handlers/ActivityTemplateActionHandler";
import {
  ActivityTemplateJSONAModel,
  RecommendedChannel,
} from "interfaces/jsona-models/ActivityTemplateJSONAModel";
import * as React from "react";
import { FunctionComponent, useState } from "react";
import { removeOverflowOnDropdownClose } from "utils/removeOverflowOnDropdownClose";
import styles from "./AdminProgramActivityTemplatesTable.module.scss";

export const AdminProgramActivityTemplatesTable: FunctionComponent<
  AdminProgramActivityTemplatesTableProps
> = (props) => {
  const [newActivityVisible, setNewActivityVisible] = useState<boolean>(false);
  const [selectedActivityTemplate, setSelectedActivityTemplate] = useState<
    ActivityTemplateJSONAModel | undefined
  >(undefined);
  const { deleteRecord: deleteActivityTemplate } = useDelete(activityTemplateActionHandler);

  const translatedChannels = (channels: RecommendedChannel[]) => {
    const channelsArray = channels?.map((channel) => {
      let channelName = "";
      switch (channel) {
        case RecommendedChannel.Survey:
          return (channelName = "Upitnik");
        case RecommendedChannel.PhoneCall:
          return (channelName = "Telefonski poziv");
        case RecommendedChannel.Message:
          return (channelName = "SMS");
        case RecommendedChannel.VideoCall:
          return (channelName = "Video poziv");
        case RecommendedChannel.Live:
          return (channelName = "Uživo");
      }
      return channelName;
    });
    return channelsArray?.map((translatedChannel, index, arr) => {
      if (arr.length - 1 === index) {
        // if last item in array don't show comma
        return <span key={index}>{translatedChannel}</span>;
      } else {
        return <span key={index}>{translatedChannel}, </span>;
      }
    });
  };

  const handleDelete = (id: string) => {
    deleteActivityTemplate(id)
      .then(() => {
        if (props.getProgramTemplate) {
          props.getProgramTemplate();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderOptionsColumn = (optionsData: ActivityTemplateJSONAModel) => {
    const menu = (
      <Menu>
        <Menu.Item
          key={"editActivityTemplate"}
          onClick={async () => {
            await setSelectedActivityTemplate(optionsData);
            await setNewActivityVisible(true);
          }}
        >
          Uredi
        </Menu.Item>
        <Menu.Item
          key={"deleteActivityTemplate"}
          onClick={() => {
            handleDelete(optionsData.id);
          }}
        >
          Izbriši
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        disabled={props.programEnabled}
        onOpenChange={removeOverflowOnDropdownClose}
      >
        <div onClick={(e) => e.preventDefault()} className={styles.menuItemWidth}>
          <TextContent>
            <span className={props.programEnabled ? undefined : styles.cogwheel}>
              <SettingOutlined />
            </span>
          </TextContent>
        </div>
      </Dropdown>
    );
  };

  const dataSource: AdminProgramActivitiesTableRecord[] = props?.activityTemplates?.map(
    (activityTemplate, index) => {
      return {
        key: activityTemplate.id,
        [AdminProgramActivityTemplateTableColumnDataIndex.OrdinalNumber]: index + 1,
        [AdminProgramActivityTemplateTableColumnDataIndex.Name]: activityTemplate.name,
        [AdminProgramActivityTemplateTableColumnDataIndex.DaysDelay]: activityTemplate.dayOffset,
        [AdminProgramActivityTemplateTableColumnDataIndex.RecommendedChannel]: translatedChannels(
          activityTemplate.recommendedChannels
        ),
        [AdminProgramActivityTemplateTableColumnDataIndex.Options]: activityTemplate,
      };
    }
  );
  const columns = [
    {
      title: AdminProgramActivityTemplateTableColumnTitle.OrdinalNumber,
      dataIndex: AdminProgramActivityTemplateTableColumnDataIndex.OrdinalNumber,
      key: AdminProgramActivityTemplateTableColumnDataIndex.OrdinalNumber,
    },
    {
      title: AdminProgramActivityTemplateTableColumnTitle.Name,
      dataIndex: AdminProgramActivityTemplateTableColumnDataIndex.Name,
      key: AdminProgramActivityTemplateTableColumnDataIndex.Name,
    },
    {
      title: AdminProgramActivityTemplateTableColumnTitle.DaysDelay,
      dataIndex: AdminProgramActivityTemplateTableColumnDataIndex.DaysDelay,
      key: AdminProgramActivityTemplateTableColumnDataIndex.DaysDelay,
    },
    {
      title: AdminProgramActivityTemplateTableColumnTitle.RecommendedChannel,
      dataIndex: AdminProgramActivityTemplateTableColumnDataIndex.RecommendedChannel,
      key: AdminProgramActivityTemplateTableColumnDataIndex.RecommendedChannel,
    },
    {
      title: AdminProgramActivityTemplateTableColumnTitle.Options,
      dataIndex: AdminProgramActivityTemplateTableColumnDataIndex.Options,
      key: AdminProgramActivityTemplateTableColumnDataIndex.Options,
      render: (optionsData: ActivityTemplateJSONAModel) => {
        return renderOptionsColumn(optionsData);
      },
    },
  ];
  return (
    <>
      <div className={styles.tableHeader}>
        <span className={styles.tableTitle}>Aktivnosti i koraci programa</span>{" "}
        <Button
          disabled={props.programEnabled}
          type={"primary"}
          onClick={() => setNewActivityVisible(true)}
        >
          Dodaj aktivnost
        </Button>
      </div>
      <Table
        pagination={false}
        columns={columns}
        dataSource={dataSource}
        className={styles.table}
        expandedRowRender={(row: AdminProgramActivitiesTableRecord) => {
          return (
            <AdminActivityStepsTable
              currentActivityTemplate={
                row[AdminProgramActivityTemplateTableColumnDataIndex.Options]
              }
              programEnabled={props.programEnabled}
              title={`Koraci aktivnosti: ${
                row[AdminProgramActivityTemplateTableColumnDataIndex.Name]
              }`}
              stepTemplates={
                row[AdminProgramActivityTemplateTableColumnDataIndex.Options]?.stepTemplates
              }
              getProgramTemplate={props.getProgramTemplate}
            />
          );
        }}
      />
      <ModalExtended
        title="Nova aktivnost"
        open={newActivityVisible}
        onCancel={() => {
          setNewActivityVisible(false);
          setSelectedActivityTemplate(undefined);
        }}
      >
        <AdminAddActivityTemplateForm
          getProgramTemplate={props.getProgramTemplate}
          editActivityTemplate={selectedActivityTemplate}
        />
      </ModalExtended>
    </>
  );
};
