import { useGetControlled, usePatch } from "@bornfight/aardvark";
import { Button, Checkbox, Divider, notification, Radio } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { apiSaga } from "app/store";
import { Space } from "common/Space/Space";
import { TextContent } from "common/TextContent/TextContent";
import { ActivityStatus } from "enums/ActivityStatus";
import { ChannelType } from "enums/entitySpecifics/ChannelType";
import { ResourceType } from "enums/ResourceType";
import { ActivityFooterProps } from "features/patients/components/Activity/interfaces/ActivityFooterProps";
import { PatientProgramActivityRouteParams } from "features/patients/interfaces/PatientProgramActivityRouteParams";
import { activityActionHandler } from "handlers/ActivityActionHandler";
import { programActionHandler } from "handlers/ProgramActionHandler";
import * as React from "react";
import { ChangeEvent, FunctionComponent, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./ActivityFooter.module.scss";

const allActivitiesCompletionNotification = () => {
  notification["success"]({
    message: "Program je završen!",
    description: "Sve aktivnosti unutar ovog programa su izvršene.",
  });
};

const getTranslatedChannel = (channel: ChannelType) => {
  switch (channel) {
    case ChannelType.Live:
      return "Uživo";
    case ChannelType.PhoneCall:
      return "Telefonom";
    case ChannelType.Message:
      return "Porukom";
  }
};

export const ActivityFooter: FunctionComponent<ActivityFooterProps> = (props) => {
  const activity = props.activity;
  const params = useParams<PatientProgramActivityRouteParams>();
  const { update: updateActivity } = usePatch(activityActionHandler);

  const [selectedChannel, setSelectedChannel] = useState<ChannelType>(ChannelType.Live);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [comment, setComment] = useState<string>("");
  const [shouldSendSideEffect, setShouldSendSideEffect] = useState<boolean>(false);
  const [activityEndLoading, setActivityEndLoading] = useState<boolean>(false);

  const programId = useMemo(() => {
    return params.programId;
  }, [params.programId]);
  const includes = useMemo(() => {
    return ["programComments"];
  }, []);

  const { getSingle: getProgram } = useGetControlled(
    programActionHandler,
    `/api/programs/${programId}`,
    includes
  );

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  const disabled = Boolean(activity?.actualDate) || props.disabledActivity;
  const completed = activity?.success;

  const handleCommentCreate = () => {
    return apiSaga.apiService.httpAdapter.post(`${process.env.REACT_APP_API_URL}program_comments`, {
      data: {
        attributes: {
          text: comment,
          sideEffect: shouldSendSideEffect,
          activityTitle: activity?.activityTemplate.name,
        },
        relationships: {
          program: {
            type: ResourceType.Program,
            id: `/api/programs/${programId}`,
          },
        },
      },
    });
  };
  if (activity?.status === ActivityStatus.Scheduled) {
    return null;
  }

  if (!completed && disabled) {
    return (
      <div>
        <Divider />
        <TextContent strong={true} fontSize={16}>
          Ishod aktivnosti
        </TextContent>
        <TextContent type={"danger"}>
          <p>{`Aktivnost je neuspješno izvršena.`}</p>
        </TextContent>
      </div>
    );
  }

  if (completed) {
    return (
      <div>
        <Divider />
        <TextContent strong={true} fontSize={16}>
          Ishod aktivnosti
        </TextContent>
        <TextContent>
          <p className={styles.completed}>
            {`Aktivnost je uspješno izvršena ${getTranslatedChannel(
              (activity?.actualChannel as ChannelType) || ""
            )}.`}
          </p>
        </TextContent>
      </div>
    );
  }

  const handleSuccessAction = () => {
    setActivityEndLoading(true);
    const activityId = activity?.id || "";
    const data = {
      model: {
        type: ResourceType.Activity,
        id: activityId,
        success: true,
        actualChannel: selectedChannel,
      },
      includeNames: ["actualChannel"],
    };
    if (activityId !== "") {
      if (comment.length > 0) {
        handleCommentCreate()
          .then(() => {
            getProgram();
          })
          .catch(() => setActivityEndLoading(false));
      }
      updateActivity(activityId, {
        model: data.model,
        includeNames: data.includeNames,
      })
        .then(() => {
          setActivityEndLoading(false);
          props.getActivities && props.getActivities();
        })
        .catch(() => setActivityEndLoading(false));
    }
    if (props.isLastActivity) {
      allActivitiesCompletionNotification();
    }
  };
  const handleUnsuccessfulAction = () => {
    setActivityEndLoading(true);
    const activityId = activity?.id || "";
    const data = {
      model: {
        type: ResourceType.Activity,
        id: activityId,
        success: false,
        actualChannel: selectedChannel,
      },
      includeNames: ["actualChannel"],
    };
    if (activityId !== "") {
      if (comment.length > 0) {
        handleCommentCreate()
          .then(() => {
            getProgram();
          })
          .catch(() => setActivityEndLoading(false));
      }
      updateActivity(activityId, {
        model: data.model,
        includeNames: data.includeNames,
      })
        .then(() => {
          props.getActivities && props.getActivities();
          setActivityEndLoading(false);
        })
        .catch(() => setActivityEndLoading(false));
    }
    if (props.isLastActivity) {
      allActivitiesCompletionNotification();
    }
  };

  return (
    <div>
      <Divider />
      <TextContent strong={true} fontSize={16}>
        Ishod aktivnosti
      </TextContent>
      <TextContent>
        <p>Označi kanal koji je korišten u ovoj Aktivnosti</p>
      </TextContent>
      <Space size={16} direction={"vertical"}>
        <Radio.Group
          disabled={disabled}
          onChange={(e) => {
            const channelType = e.target.value;
            setSelectedChannel(channelType);
          }}
          value={selectedChannel}
        >
          <Radio value={ChannelType.Live} style={radioStyle} data-test={"activity-radio-live"}>
            Uživo
          </Radio>
          <Radio
            value={ChannelType.PhoneCall}
            style={radioStyle}
            data-test={"activity-radio-phonecall"}
          >
            Telefon
          </Radio>
          <Radio
            value={ChannelType.Message}
            style={radioStyle}
            data-test={"activity-radio-message"}
          >
            Poruka
          </Radio>
        </Radio.Group>
        <div>
          <TextContent>
            <div>Bilješka</div>
          </TextContent>
          <Space size={16} direction={"vertical"}>
            <TextArea
              disabled={disabled}
              rows={4}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                const newNote = e.target.value;
                setComment(newNote);
              }}
            />
            <Checkbox
              disabled={disabled}
              onChange={() => {
                setShouldSendSideEffect(!shouldSendSideEffect);
              }}
            >
              Bilješka sadrži opis sumnje na nuspojavu ili posebne situacije (npr. uzimanje lijeka
              tijekom trudnoće ili dojenja, predoziranje, zloupotreba, pogrešna upotreba,
              medikacijska pogreška, nedjelotvornost lijeka)
            </Checkbox>
            {disabled ? null : (
              <>
                <Button
                  data-test={"activity-success-button"}
                  className={styles["success-button"]}
                  disabled={disabled}
                  loading={activityEndLoading}
                  type={"primary"}
                  onClick={() => {
                    handleSuccessAction();
                  }}
                >
                  Označi aktivnost uspješnom
                </Button>
                <Button
                  data-test={"activity-unsuccess-button"}
                  type={"link"}
                  style={{ color: "#ed2733" }}
                  loading={activityEndLoading}
                  onClick={() => {
                    handleUnsuccessfulAction();
                  }}
                >
                  Označi aktivnost neuspješnom
                </Button>
              </>
            )}
          </Space>
        </div>
      </Space>
    </div>
  );
};
