import { useGetAllControlled, usePatch, usePost } from "@bornfight/aardvark";
import { Button, Form, Input, Select } from "antd";
import { ErrorMessage } from "common/ErrorMessage/ErrorMessage";
import { TextContent } from "common/TextContent/TextContent";
import { ResourceType } from "enums/ResourceType";
import { AdminAddPharmacistFormItemLabel } from "features/admin/components/AdminAddPharmacistForm/enums/AdminAddPharmacistFormItemLabel";
import { AdminAddPharmacistFormItemName } from "features/admin/components/AdminAddPharmacistForm/enums/AdminAddPharmacistFormItemName";
import { AdminAddPharmacistFormProps } from "features/admin/components/AdminAddPharmacistForm/interfaces/AdminAddPharmacistFormProps";
import { AdminAddPharmacistFormValues } from "features/admin/components/AdminAddPharmacistForm/interfaces/AdminAddPharmacistFormValues";
import { pharmacistActionHandler } from "handlers/PharmacistActionHandler";
import { pharmacyActionHandler } from "handlers/PharmacyActionHandler";
import { RequestError } from "interfaces/RequestError";
import * as React from "react";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { AppJsonApiQuery } from "utils/AppJsonApiQuery";
import { removeOverflowOnDropdownClose } from "utils/removeOverflowOnDropdownClose";

export const AdminAddPharmacistForm: FunctionComponent<AdminAddPharmacistFormProps> = (props) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const { create: createPharmacist, loading: createPharmacistLoading } =
    usePost(pharmacistActionHandler);
  const { update: editPharmacist, loading: editPharmacistLoading } =
    usePatch(pharmacistActionHandler);

  const pharmacyQuery = useMemo(() => {
    return new AppJsonApiQuery({});
  }, []);
  const {
    getAll: getPharmacies,
    collection: pharmacies,
    loading: pharmaciesLoading,
  } = useGetAllControlled(pharmacyActionHandler, pharmacyQuery);

  useEffect(() => {
    getPharmacies();
  }, [getPharmacies]);

  const getPharmacyOptions = useMemo(() => {
    return pharmacies?.map((pharmacy) => {
      return (
        <Select.Option
          key={pharmacy.id}
          value={pharmacy.id}
        >{`${pharmacy.name}, ${pharmacy.address}`}</Select.Option>
      );
    });
  }, [pharmacies]);

  const getInitialValues = (): AdminAddPharmacistFormValues | undefined => {
    if (props.isEditing) {
      return {
        [AdminAddPharmacistFormItemName.FirstName]: props.currentPharmacist?.firstName || "",
        [AdminAddPharmacistFormItemName.LastName]: props.currentPharmacist?.lastName || "",
        [AdminAddPharmacistFormItemName.Email]: props.currentPharmacist?.email || "",
        [AdminAddPharmacistFormItemName.Pharmacy]: props.currentPharmacist?.pharmacy.id || "",
      };
    }
    return undefined;
  };

  const handleEdit = (values: AdminAddPharmacistFormValues) => {
    if (props.currentPharmacist) {
      editPharmacist(props.currentPharmacist.id, {
        model: {
          type: ResourceType.Pharmacist,
          id: props.currentPharmacist.id,
          email: values[AdminAddPharmacistFormItemName.Email],
          firstName: values[AdminAddPharmacistFormItemName.FirstName],
          lastName: values[AdminAddPharmacistFormItemName.LastName],
          username: values[AdminAddPharmacistFormItemName.Email],
        },
        includeNames: [],
      })
        .then(() => {
          props.setPharmacistsLoading(false);
          props.closeModal();
        })
        .catch((err) => {
          props.setPharmacistsLoading(false);
          console.log(err);
        });
    }
  };

  const handleSubmit = (values: AdminAddPharmacistFormValues) => {
    props.setPharmacistsLoading(true);
    setError(undefined);
    if (props.isEditing) {
      handleEdit(values);
      return;
    }
    createPharmacist({
      rawData: {
        data: {
          attributes: {
            email: values[AdminAddPharmacistFormItemName.Email],
            firstName: values[AdminAddPharmacistFormItemName.FirstName],
            lastName: values[AdminAddPharmacistFormItemName.LastName],
            username: values[AdminAddPharmacistFormItemName.Email],
          },
          relationships: {
            pharmacy: {
              type: ResourceType.Pharmacy,
              id: values[AdminAddPharmacistFormItemName.Pharmacy],
            },
          },
        },
      },
    })
      .then((response) => {
        props.setPharmacistsLoading(false);
        props.closeModal();
      })
      .catch((err: any) => {
        const errorData = err.response.data as RequestError;
        props.setPharmacistsLoading(false);
        const hasSameEmailError = errorData?.violations?.some((violation) => {
          return violation?.propertyPath === "email";
        });
        if (hasSameEmailError) {
          setError("Email se već koristi!");
          return;
        }
        if (errorData.title === "An error occurred") {
          setError("Ljekarnik nije uspješno kreiran!");
        }
      });
  };

  return (
    <Form
      layout={"vertical"}
      onFinish={(values) => {
        handleSubmit(values as unknown as AdminAddPharmacistFormValues);
      }}
      initialValues={getInitialValues()}
    >
      <Form.Item
        label={AdminAddPharmacistFormItemLabel.FirstName}
        name={AdminAddPharmacistFormItemName.FirstName}
        rules={[
          {
            required: true,
            message: "Ovo polje je obavezno.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={AdminAddPharmacistFormItemLabel.LastName}
        name={AdminAddPharmacistFormItemName.LastName}
        rules={[
          {
            required: true,
            message: "Ovo polje je obavezno.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={AdminAddPharmacistFormItemLabel.Email}
        name={AdminAddPharmacistFormItemName.Email}
        rules={[
          {
            required: true,
            message: "Ovo polje je obavezno.",
            type: "email",
          },
        ]}
      >
        <Input disabled={props.isEditing} />
      </Form.Item>
      <Form.Item
        label={AdminAddPharmacistFormItemLabel.Pharmacy}
        name={AdminAddPharmacistFormItemName.Pharmacy}
        rules={[
          {
            required: true,
            message: "Ovo polje je obavezno.",
          },
        ]}
      >
        <Select
          loading={pharmaciesLoading}
          disabled={pharmaciesLoading || props.isEditing}
          onDropdownVisibleChange={removeOverflowOnDropdownClose}
        >
          {getPharmacyOptions}
        </Select>
      </Form.Item>
      {props.isEditing ? null : (
        <TextContent>
          Klikom na “Kreiraj korisnički račun” korisniku će biti poslan email s uputama za
          postavljanje lozinke te će mu biti kreiran korisnički račun.
        </TextContent>
      )}
      <br />
      {error ? <ErrorMessage error={error} /> : null}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          type={"primary"}
          htmlType={"submit"}
          loading={createPharmacistLoading || editPharmacistLoading}
        >
          {props.isEditing ? "Uredi ljekarnika" : "Kreiraj korisnički račun"}
        </Button>
      </div>
    </Form>
  );
};
