import { useGet, useGetAllControlled } from "@bornfight/aardvark";
import { Button, Card, Empty, Skeleton } from "antd";
import { ModalExtended } from "common/ModalExtended/ModalExtended";
import { Space } from "common/Space/Space";
import { TextContent } from "common/TextContent/TextContent";
import { ActivityStatus } from "enums/ActivityStatus";
import { DateTimeFormat } from "enums/DateTimeFormat";
import { RouteUrl } from "enums/RouteUrl";
import { AddProgramForm } from "features/patients/components/AddProgramForm/AddProgramForm";
import { patientActionHandler } from "handlers/PatientActionHandler";
import { programActionHandler } from "handlers/ProgramActionHandler";
import { therapyActionHandler } from "handlers/TherapyActionHandler";
import { ProgramJSONAModel, ProgramStatus } from "interfaces/jsona-models/ProgramJSONAModel";
import moment from "moment";
import * as React from "react";
import { FunctionComponent, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDeepCompareEffect, useDeepCompareMemo } from "use-deep-compare";
import { AppJsonApiQuery } from "utils/AppJsonApiQuery";
import { getIriFromUuid } from "utils/getIriFromUuid";
import styles from "./PatientProgramInfo.module.scss";
import { getUuidFromIri } from "utils/getUuidFromIri";

export const PatientProgramInfo: FunctionComponent = () => {
  const navigate = useNavigate();
  const params = useParams<{ patientId: string }>();
  const [addProgramModalOpened, setAddProgramModalOpened] = useState<boolean>(false);

  const patientId = useMemo(() => {
    return params.patientId;
  }, [params]);

  const patientIncludes = useMemo(() => {
    return ["programs"];
  }, []);

  const { record: patient, loading } = useGet(
    patientActionHandler,
    `/api/patients/${patientId}`,
    patientIncludes
  );

  // get program.programTemplate, program.activites, program.therapies
  const programUuids = useMemo(() => {
    let uuids =
      patient?.programs?.map((program) => {
        return program?.uuid;
      }) || [];
    if (uuids?.some((programId) => programId === undefined)) {
      uuids = [];
    }
    return uuids;
  }, [patient]);
  const programJsonApiQuery = useDeepCompareMemo(() => {
    const customParams = programUuids.map((progUuid) => {
      return { name: "uuid[]", value: progUuid };
    });
    return new AppJsonApiQuery({
      customParams,
      includes: ["programTemplate", "activities", "therapies"],
    });
  }, [programUuids]);

  const { getAll: getAllPrograms, loading: programsLoading } = useGetAllControlled(
    programActionHandler,
    programJsonApiQuery
  );

  useDeepCompareEffect(() => {
    if (programUuids.length === 0) {
      return;
    }
    getAllPrograms();
  }, [getAllPrograms, programUuids]);

  // get therapies.medicine
  const therapyIds = useMemo(() => {
    let ids =
      patient?.programs
        ?.map((program) => {
          return program?.therapies?.map((therapy) => {
            return therapy?.id;
          });
        })
        .flat() || [];
    ids.forEach((id) => {
      return getIriFromUuid("therapies", id);
    });
    if (ids.some((therapyId) => therapyId === undefined)) {
      ids = [];
    }
    return ids;
  }, [patient]);
  const therapyJsonApiQuery = useDeepCompareMemo(() => {
    const customParams = therapyIds.map((id) => {
      return {
        name: "uuid[]",
        value: getUuidFromIri(id),
      };
    });
    return new AppJsonApiQuery({
      customParams,
      includes: ["medicine"],
    });
  }, [therapyIds]);

  const { getAll: getAllTherapies, loading: therapiesLoading } = useGetAllControlled(
    therapyActionHandler,
    therapyJsonApiQuery
  );

  useDeepCompareEffect(() => {
    if (therapyIds.length === 0) {
      return;
    }
    getAllTherapies();
  }, [getAllTherapies]);

  const activePrograms: ProgramJSONAModel[] = useMemo(() => {
    return (
      patient?.programs?.filter((program) => {
        return program?.status === ProgramStatus.Active;
      }) || []
    );
  }, [patient]);
  const inactivePrograms: ProgramJSONAModel[] = useMemo(() => {
    return (
      patient?.programs?.filter((program) => {
        return (
          program?.status === ProgramStatus.Inactive || program?.status === ProgramStatus.Completed
        );
      }) || []
    );
  }, [patient]);

  const addProgramModal = (
    <ModalExtended
      title="Dodjela programa"
      open={addProgramModalOpened}
      onCancel={() => setAddProgramModalOpened(false)}
    >
      <AddProgramForm patient={patient} closeModal={() => setAddProgramModalOpened(false)} />
    </ModalExtended>
  );
  const renderEmptyState = () => {
    return (
      <div className={styles.emptyWrap}>
        {programsLoading || loading || therapiesLoading ? (
          <Skeleton />
        ) : (
          <>
            <TextContent fontSize={20}>
              <p>Programi</p>
            </TextContent>
            <div className={styles.emptyOuterWrap}>
              <div className={styles.emptyInnerWrap}>
                <Empty description={"Pacijent još nema programa."} />
                <Button
                  data-test={"add-patient-program-button"}
                  className={styles.emptyAddButton}
                  type={"primary"}
                  onClick={() => setAddProgramModalOpened(true)}
                >
                  Dodijeli program
                </Button>
              </div>
            </div>
          </>
        )}

        {addProgramModal}
      </div>
    );
  };

  if (activePrograms.length === 0 && inactivePrograms.length === 0) {
    return renderEmptyState();
  }

  const renderActivePrograms = () => {
    const currentPatientUuid = patient?.uuid;
    if (activePrograms.length === 0) {
      return <TextContent>Nema aktivnih programa</TextContent>;
    }
    return activePrograms.map((program: ProgramJSONAModel) => {
      const currentProgramUuid = program.uuid;
      // next interactive activity or first activity if none are interactive
      const currentActivityUuid =
        program?.activities?.find((activity) => {
          return activity.status === ActivityStatus.Interactive;
        })?.uuid || program?.activities?.[0].uuid;

      const patientProgramActivityUrl = `${RouteUrl.Patients}/${currentPatientUuid}${RouteUrl.Programs}/${currentProgramUuid}${RouteUrl.Activities}/${currentActivityUuid}`;

      const activitesCompleted = program?.activities?.filter((activity) => {
        return activity.success;
      }).length;
      const nextActivityExpectedDateString = program?.activities?.filter((activity) => {
        return activity?.status === ActivityStatus.Interactive;
      })?.[0]?.expectedDate;
      const nextActivityExpectedDate = () => {
        if (nextActivityExpectedDateString) {
          return moment(nextActivityExpectedDateString).format(DateTimeFormat.ShortLocalized);
        }
        return "Sve aktivnosti su završene";
      };

      const mainTherapy = program?.therapies?.find((therapy) => {
        return therapy?.adherenceTherapy;
      });
      let mainMedicine = "";
      if (mainTherapy && mainTherapy?.medicine) {
        mainMedicine = mainTherapy?.medicine?.productName;
      }

      return (
        <Card
          key={program.id}
          title={program?.programTemplate?.name}
          extra={
            <span
              className={styles.link}
              onClick={() => {
                document.body.style.removeProperty("overflow");
                navigate(patientProgramActivityUrl);
              }}
            >
              Otvori
            </span>
          }
        >
          <Space size={20} direction={"vertical"}>
            <div>
              <TextContent>
                <p className={styles.subtitle}>Lijek</p>
              </TextContent>
              <TextContent>{mainMedicine}</TextContent>
            </div>
            <div>
              <TextContent>
                <p className={styles.subtitle}>Izvršeno aktivnosti</p>
              </TextContent>
              <TextContent>
                <p
                  className={styles["subtitle-text"]}
                >{`${activitesCompleted}/${program?.activities?.length}`}</p>
              </TextContent>
            </div>
            <div>
              <TextContent>
                <p className={styles.subtitle}>Datum slijedeće aktivnosti</p>
              </TextContent>
              <TextContent>
                <p className={styles["subtitle-text"]}>{nextActivityExpectedDate()}</p>
              </TextContent>
            </div>
          </Space>
        </Card>
      );
    });
  };

  const renderInactivePrograms = () => {
    const currentPatientUuid = patient?.uuid;
    if (inactivePrograms.length === 0) {
      return <TextContent>Nema neaktivnih programa</TextContent>;
    }
    return inactivePrograms.map((program: ProgramJSONAModel) => {
      const currentProgramUuid = program.uuid;
      // next interactive activity or first activity if none are interactive
      const firstActivityUuid = program?.activities?.[0].uuid;
      const patientProgramActivityUrl = `${RouteUrl.Patients}/${currentPatientUuid}${RouteUrl.Programs}/${currentProgramUuid}${RouteUrl.Activities}/${firstActivityUuid}`;

      const activitesCompleted = program?.activities?.filter((activity) => {
        return activity.success;
      }).length;
      const mainTherapy = program?.therapies?.find((therapy) => {
        return therapy?.adherenceTherapy;
      });
      let mainMedicine = "";
      if (mainTherapy && mainTherapy?.medicine) {
        mainMedicine = mainTherapy?.medicine?.productName;
      }

      const renderProgramOutcome = () => {
        const lastActivity = program?.activities?.[program.activities.length - 1];
        const programSucessful = lastActivity && lastActivity?.success === true;
        const disabled = Boolean(lastActivity?.actualDate);

        if (programSucessful) {
          return (
            <TextContent>
              <span className={styles["successful-program"]}>Uspješno</span>
            </TextContent>
          );
        }
        if (!programSucessful && disabled) {
          return <TextContent type={"danger"}>Neuspješno</TextContent>;
        }
        if (program?.dropOut) {
          return <TextContent type={"danger"}>Otkazano</TextContent>;
        }
      };

      return (
        <Card
          key={program.id}
          title={program?.programTemplate?.name}
          extra={<Link to={patientProgramActivityUrl}>Otvori</Link>}
        >
          <Space size={20} direction={"vertical"}>
            <div>
              <TextContent>
                <p className={styles.subtitle}>Lijek</p>
              </TextContent>
              <TextContent>
                <p className={styles["subtitle-text"]}>{mainMedicine}</p>
              </TextContent>
            </div>
            <div>
              <TextContent>
                <p className={styles.subtitle}>Izvršeno aktivnosti</p>
              </TextContent>
              <TextContent>
                <p
                  className={styles["subtitle-text"]}
                >{`${activitesCompleted}/${program?.activities?.length}`}</p>
              </TextContent>
            </div>
            <div>
              <TextContent>
                <p className={styles.subtitle}>Provedba programa</p>
              </TextContent>
              <TextContent>{renderProgramOutcome()}</TextContent>
            </div>
          </Space>
        </Card>
      );
    });
  };

  return (
    <div className={styles["patient-program-info-wrap"]}>
      {loading || therapiesLoading || programsLoading ? (
        <Skeleton />
      ) : (
        <>
          <div className={styles["header-wrap"]}>
            <TextContent fontSize={20}>
              <p>Programi</p>
            </TextContent>
            <Button
              type={"primary"}
              onClick={() => setAddProgramModalOpened(true)}
              disabled={activePrograms.length > 0}
            >
              Dodijeli program
            </Button>
          </div>
          <TextContent fontSize={16} strong={true}>
            <p>Aktivni</p>
          </TextContent>
          <div className={styles["active-programs-wrap"]}>{renderActivePrograms()}</div>
          <TextContent fontSize={16} strong={true}>
            <p className={styles["non-active-programs-title"]}>Neaktivni</p>
          </TextContent>
          <div className={styles["non-active-programs-wrap"]}>{renderInactivePrograms()}</div>
          {addProgramModal}
        </>
      )}
    </div>
  );
};
