import { FuzzySearchType, useGetAllControlled } from "@bornfight/aardvark";
import { CustomParam } from "@bornfight/aardvark/dist/services/JsonApiQuery/interfaces/CustomParam";
import { Spin } from "antd";
import { ColumnFilterType } from "common/ResourceTable/enums/ColumnFilterType";
import { TableColumnData } from "common/ResourceTable/interfaces/TableColumnData";
import { ResourceTable } from "common/ResourceTable/ResourceTable";
import { DateTimeFormat } from "enums/DateTimeFormat";
import { RouteUrl } from "enums/RouteUrl";
import { PatientTableColumnDataIndex } from "features/patients/components/PatientTable/enums/PatientTableColumnDataIndex";
import { PatientTableColumnTitle } from "features/patients/components/PatientTable/enums/PatientTableColumnTitle";
import { PatientTableRecord } from "features/patients/components/PatientTable/interfaces/PatientTableRecord";
import { SinglePatientScreenLocationState } from "features/patients/enums/SinglePatientScreenLocationState";
import { patientActionHandler } from "handlers/PatientActionHandler";
import { programActionHandler } from "handlers/ProgramActionHandler";
import { PatientJSONAModel } from "interfaces/jsona-models/PatientJSONAModel";
import { ProgramJSONAModel, ProgramStatus } from "interfaces/jsona-models/ProgramJSONAModel";
import isEqual from "lodash.isequal";
import moment from "moment";
import * as React from "react";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppJsonApiQuery } from "utils/AppJsonApiQuery";
import { getUuidFromIri } from "utils/getUuidFromIri";
import styles from "./PatientTable.module.scss";

export const PatientTable: FunctionComponent = () => {
  const navigate = useNavigate();
  const locationState = useLocation().state as SinglePatientScreenLocationState;

  const [currentTablePage, setCurrentTablePage] = useState<number>(1);

  const getJsonApiQuery = useMemo(() => {
    const includes = ["patientHealthData", "consent", "patient"];
    return new AppJsonApiQuery({
      includes,
    });
  }, []);
  const [programIds, setProgramIds] = useState<string[]>([]);
  const programJsonApiQuery = useMemo(() => {
    const customParams: CustomParam[] = [
      ...programIds.filter(Boolean).map((id) => {
        return {
          name: "uuid[]",
          value: getUuidFromIri(id),
        };
      }),
      {
        name: "status",
        value: ProgramStatus.Active,
      },
    ];

    return new AppJsonApiQuery({
      customParams,
      paginationConfig: {
        pageSize: 50,
        pageNumber: 1,
      },
      includes: ["programTemplate"],
    });
  }, [programIds]);
  const { getAll: getAllPrograms, loading } = useGetAllControlled(
    programActionHandler,
    programJsonApiQuery
  );
  useEffect(() => {
    if (programIds.length > 0) {
      getAllPrograms();
    }
  }, [programIds, getAllPrograms]);

  const createColumnData = useMemo((): TableColumnData<PatientTableRecord>[] => {
    return [
      {
        title: PatientTableColumnTitle.FirstName,
        dataIndex: PatientTableColumnDataIndex.FirstName,
        filter: {
          type: ColumnFilterType.Text,
          field: "firstName",
          fuzzySearchType: FuzzySearchType.StartWith,
        },
      },
      {
        title: PatientTableColumnTitle.LastName,
        dataIndex: PatientTableColumnDataIndex.LastName,
        filter: {
          type: ColumnFilterType.Text,
          field: "lastName",
          fuzzySearchType: FuzzySearchType.StartWith,
        },
      },
      {
        title: PatientTableColumnTitle.Birthday,
        dataIndex: PatientTableColumnDataIndex.Birthday,
        filter: {
          type: ColumnFilterType.Year,
          field: "dateOfBirth",
        },
      },
      {
        title: PatientTableColumnTitle.ProgramName,
        dataIndex: PatientTableColumnDataIndex.ProgramName,
        render: (programs: ProgramJSONAModel[] | undefined) => {
          const activeProgram = programs?.find((program) => {
            return program.status === ProgramStatus.Active;
          });
          if (activeProgram) {
            return <span>{activeProgram?.programTemplate?.name}</span>;
          }
          return <span>Nema programa u tijeku</span>;
        },
      },
      {
        title: PatientTableColumnTitle.GDPR,
        dataIndex: PatientTableColumnDataIndex.GDPR,
        render: (confirmedGDPR: boolean) => {
          if (confirmedGDPR) {
            return "Da";
          }
          return "Povučen";
        },
      },
      {
        title: PatientTableColumnTitle.Action,
        dataIndex: PatientTableColumnDataIndex.Action,
        render: ({ uuid, disabled }: { uuid: string; disabled: boolean }) => {
          const patientUrl = `${RouteUrl.Patients}/${uuid}`;
          if (disabled) {
            return <span> Otvori profil</span>;
          }
          return (
            <span
              className={styles.link}
              onClick={() => {
                navigate(patientUrl, {
                  state: {
                    patientTablePage: currentTablePage,
                  } as SinglePatientScreenLocationState,
                });
              }}
            >
              Otvori profil
            </span>
          );
        },
      },
    ];
  }, [currentTablePage, history]);

  const tableData = (patients: PatientJSONAModel[]): PatientTableRecord[] => {
    return patients.map((patient) => {
      const birthday = moment(patient.dateOfBirth).format(DateTimeFormat.Year);

      return {
        key: patient.id,
        [PatientTableColumnDataIndex.FirstName]: patient.firstName,
        [PatientTableColumnDataIndex.LastName]: patient.lastName,
        [PatientTableColumnDataIndex.Birthday]: birthday,
        [PatientTableColumnDataIndex.ProgramName]: patient.programs,
        [PatientTableColumnDataIndex.GDPR]: Boolean(patient?.consent?.uuid),
        [PatientTableColumnDataIndex.Action]: {
          uuid: patient.uuid,
          disabled: patient.firstName === "Anonymous" && patient.lastName === "Anonymous",
        },
      };
    });
  };

  return (
    <Spin spinning={loading}>
      <ResourceTable<PatientTableRecord, PatientJSONAModel>
        apiActionHandler={patientActionHandler}
        jsonApiQuery={getJsonApiQuery}
        transformToDataSource={tableData}
        onChange={(pageData) => pageData.current && setCurrentTablePage(pageData.current)}
        currentPage={locationState?.patientTablePage}
        columnData={createColumnData}
        onCollectionRender={(collection) => {
          const programIds = collection
            .map((value) => {
              return value?.programs?.map((program) => {
                return program.id;
              });
            })
            .flat();
          setProgramIds((data) => {
            if (isEqual(data, programIds)) {
              return data;
            }
            return programIds;
          });
        }}
      />
    </Spin>
  );
};
