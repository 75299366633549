import { useGetAllControlled, usePost } from "@bornfight/aardvark";
import { Button, Form, Select } from "antd";
import { apiSaga } from "app/store";
import { TextContent } from "common/TextContent/TextContent";
import { ResourceType } from "enums/ResourceType";
import { AddExistingMedicineFormProps } from "features/patients/components/AddMedicine/components/interfaces/AddExistingMedicineFormProps";
import { AddExistingMedicineFormValues } from "features/patients/components/AddMedicine/components/interfaces/AddExistingMedicineFormValues";
import { intakePartOfDayCollection } from "features/patients/components/AddMedicine/constants/IntakePartOfDayCollection";
import { intakeRegimeCollection } from "features/patients/components/AddMedicine/constants/IntakeRegimeCollection";
import { AddExistingMedicineFieldLabel } from "features/patients/components/AddMedicine/enums/AddExistingMedicineFieldLabel";
import { AddExistingMedicineFieldName } from "features/patients/components/AddMedicine/enums/AddExistingMedicineFieldName";
import { MedicineSelectOption } from "features/patients/components/AddMedicine/enums/MedicineSelectOption";
import { PatientProgramActivityRouteParams } from "features/patients/interfaces/PatientProgramActivityRouteParams";
import { medicineActionHandler } from "handlers/MedicineActionHandler";
import { therapyActionHandler } from "handlers/TherapyActionHandler";
import { MedicineJSONAModel } from "interfaces/jsona-models/MedicineJSONAModel";
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDeepCompareMemo } from "use-deep-compare";
import { AppJsonApiQuery } from "utils/AppJsonApiQuery";
import { removeOverflowOnDropdownClose } from "utils/removeOverflowOnDropdownClose";
import { translateIntakePartOfDay } from "utils/translateIntakePartOfDay";
import { translateIntakeRegime } from "utils/translateIntakeRegime";
import useDebounce from "utils/useDebounce";
import styles from "./AddExistingMedicineForm.module.scss";

export const AddExistingMedicineForm: FunctionComponent<AddExistingMedicineFormProps> = (props) => {
  const editMode = Boolean(props?.therapy?.medicine?.id);
  const params = useParams<PatientProgramActivityRouteParams>();
  const [medicineName, setMedicineName] = useState<string>("");
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const { create: createTherapy } = usePost(therapyActionHandler);

  const debouncedSearchTerm = useDebounce(medicineName, 400);

  const customParams = useDeepCompareMemo(() => {
    return [{ name: "productName", value: debouncedSearchTerm }];
  }, [debouncedSearchTerm]);

  const medicineQuery = useDeepCompareMemo(() => {
    return new AppJsonApiQuery({
      customParams,
    });
  }, [debouncedSearchTerm]);

  const { getAll, collection, loading } = useGetAllControlled(medicineActionHandler, medicineQuery);

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 2) {
      getAll();
    }
  }, [getAll, debouncedSearchTerm]);
  const handleGetMedicines = (searchValue: string) => {
    setMedicineName(searchValue);
  };

  const renderMedicineOrHealthSupplementSelectItems = (medicines: MedicineJSONAModel[]) => {
    let medicineOptions = medicines.map((medicine, index) => {
      return (
        <Select.Option key={medicine.id} value={medicine.id} title={medicine.productName}>
          {medicine.productName}
        </Select.Option>
      );
    });

    const handMedicineEntryOption = (
      <Select.Option value={MedicineSelectOption.HandMedicineEntry}>
        <TextContent>
          <p
            onClick={() => {
              props.setViewAddForm(true);
            }}
            className={styles["hand-entry"]}
          >
            Ručni unos lijeka
          </p>
        </TextContent>
      </Select.Option>
    );

    if (props?.therapy?.medicine) {
      const defaultMedicine = props?.therapy?.medicine;
      const defaultValueMedicineOption = (
        <Select.Option
          key={defaultMedicine.id}
          value={defaultMedicine.id}
          title={defaultMedicine.productName}
        >
          {defaultMedicine.productName}
        </Select.Option>
      );
      medicineOptions = [...medicineOptions, defaultValueMedicineOption];
    }
    medicineOptions = [...medicineOptions, handMedicineEntryOption];
    return medicineOptions;
  };

  const renderUsageDayPeriod = () => {
    return intakePartOfDayCollection.map((intakePartOfDay, index) => {
      const translatedIntakePart = translateIntakePartOfDay(intakePartOfDay);

      return (
        <Select.Option key={index} value={intakePartOfDay}>
          {translatedIntakePart}
        </Select.Option>
      );
    });
  };

  const renderIntakeRegimentOptions = () => {
    return intakeRegimeCollection.map((intakeRegime, index) => {
      const translatedIntakeRegime = translateIntakeRegime(intakeRegime);

      return (
        <Select.Option key={index} value={intakeRegime}>
          {translatedIntakeRegime}
        </Select.Option>
      );
    });
  };

  const handleFormSubmit = (values: AddExistingMedicineFormValues) => {
    props.setPatientMedicineTableLoading(true);
    setButtonLoading(true);

    if (editMode) {
      handleUpdateExistingMedicine(values);
      return;
    }
    handleAddExistingMedicine(values);
  };

  const handleUpdateExistingMedicine = (values: AddExistingMedicineFormValues) => {
    const therapyUuid = props?.therapy?.uuid;
    if (therapyUuid) {
      apiSaga.apiService.httpAdapter
        .patch(`${process.env.REACT_APP_API_URL}therapies/${therapyUuid}`, {
          data: {
            attributes: {
              intakeRegime: values[AddExistingMedicineFieldName.IntakeRegime],
              intakePartOfDay: values[AddExistingMedicineFieldName.IntakePartOfDay],
            },
          },
        })
        .then(() => {
          props.setPatientMedicineTableLoading(false);
          setButtonLoading(false);
          props.closeModal(false);
        })
        .catch(() => {
          props.setPatientMedicineTableLoading(false);
          setButtonLoading(false);
          props.closeModal(false);
        });
    }
  };

  const handleAddExistingMedicine = (values: AddExistingMedicineFormValues) => {
    const medicineId = values[AddExistingMedicineFieldName.MedicineOrHealthSupplement];
    const programId = `api/programs/${params.programId}`;
    createTherapy({
      rawData: {
        data: {
          attributes: {
            intakeRegime: values[AddExistingMedicineFieldName.IntakeRegime],
            intakePartOfDay: values[AddExistingMedicineFieldName.IntakePartOfDay],
            adherenceTherapy: false,
          },
          relationships: {
            medicine: {
              type: ResourceType.Medicine,
              id: medicineId,
            },
            program: {
              type: ResourceType.Program,
              id: programId,
            },
          },
        },
      },
    })
      .then(() => {
        props.setPatientMedicineTableLoading(false);
        props.closeModal(false);
      })
      .catch((error) => {
        console.log(error);
        props.setPatientMedicineTableLoading(false);
        props.closeModal(false);
      });
  };

  const popupContainerFixation = () => {
    if (document.getElementById("addExistingMedicineForm")) {
      return document.getElementById("addExistingMedicineForm");
    }
    return document.body;
  };

  return (
    <div id={"addExistingMedicineForm"}>
      <Form
        layout={"vertical"}
        onFinish={(values) => {
          handleFormSubmit(values as AddExistingMedicineFormValues);
        }}
        initialValues={
          // add inital values if passed therapy (passed if clicked on edit)
          editMode
            ? {
                [AddExistingMedicineFieldName.MedicineOrHealthSupplement]:
                  props?.therapy?.medicine?.id,
                [AddExistingMedicineFieldName.IntakeRegime]: props?.therapy?.intakeRegime,
                [AddExistingMedicineFieldName.IntakePartOfDay]: props?.therapy?.intakePartOfDay,
              }
            : undefined
        }
      >
        <Form.Item
          label={AddExistingMedicineFieldLabel.MedicineOrHealthSupplement}
          name={AddExistingMedicineFieldName.MedicineOrHealthSupplement}
          rules={[{ required: true, message: "Obavezno polje" }]}
        >
          <Select
            onDropdownVisibleChange={removeOverflowOnDropdownClose}
            // @ts-ignore scroll container fix, has type errors
            getPopupContainer={popupContainerFixation}
            disabled={editMode}
            optionFilterProp={"title"}
            showSearch={true}
            onSearch={handleGetMedicines}
            loading={loading}
            showAction={["focus", "click"]}
          >
            {renderMedicineOrHealthSupplementSelectItems(collection)}
          </Select>
        </Form.Item>
        <Form.Item
          label={AddExistingMedicineFieldLabel.IntakeRegime}
          name={AddExistingMedicineFieldName.IntakeRegime}
          rules={[{ required: true, message: "Obavezno polje" }]}
        >
          <Select
            onDropdownVisibleChange={removeOverflowOnDropdownClose}
            // @ts-ignore scroll container fix, has type errors
            getPopupContainer={popupContainerFixation}
          >
            {renderIntakeRegimentOptions()}
          </Select>
        </Form.Item>
        <Form.Item
          label={AddExistingMedicineFieldLabel.IntakePartOfDay}
          name={AddExistingMedicineFieldName.IntakePartOfDay}
          rules={[{ required: true, message: "Obavezno polje" }]}
        >
          <Select
            onDropdownVisibleChange={removeOverflowOnDropdownClose}
            // @ts-ignore scroll container fix, has type errors
            getPopupContainer={popupContainerFixation}
          >
            {renderUsageDayPeriod()}
          </Select>
        </Form.Item>
        <div className={styles["button-wrap"]}>
          <Button htmlType={"submit"} type={"primary"} loading={buttonLoading}>
            {editMode ? "Uredi" : "Dodaj"}
          </Button>
        </div>
      </Form>
    </div>
  );
};
