import { ArrowLeftOutlined } from "@ant-design/icons/lib";
import { useGetControlled } from "@bornfight/aardvark";
import { Skeleton } from "antd";
import { CardWrap } from "common/CardWrap/CardWrap";
import { PageHeader } from "common/PageHeader/PageHeader";
import { RouteUrl } from "enums/RouteUrl";
import { AdminProgramActivityTemplatesTable } from "features/admin/components/AdminProgramActivityTemplateTable/AdminProgramActivityTemplatesTable";
import { AdminProgramTable } from "features/admin/components/AdminProgramTable/AdminProgramTable";
import { AdminProgramTemplateRouteParams } from "features/admin/interfaces/AdminProgramTemplateRouteParams";
import pageCover from "features/admin/PageCoverStyle.module.scss";
import { ProgramTemplateMedicineTable } from "features/programs/components/ProgramTemplateMedicineTable/ProgramTemplateMedicineTable";
import { programTemplateActionHandler } from "handlers/ProgramTemplateActionHandler";
import * as React from "react";
import { FunctionComponent, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const AdminProgramTemplateScreen: FunctionComponent = () => {
  const navigate = useNavigate();
  const params = useParams<AdminProgramTemplateRouteParams>();
  const programTemplateId = useMemo(() => {
    return params.programTemplateId;
  }, [params.programTemplateId]);

  const progTemplateIncludes = useMemo(() => {
    return ["activityTemplates", "medicines"];
  }, []);
  const {
    record: programTemplate,
    loading: progTemplateLoading,
    getSingle: getProgramTemplate,
  } = useGetControlled(
    programTemplateActionHandler,
    `/api/program_templates/${programTemplateId}`,
    progTemplateIncludes
  );
  useEffect(() => {
    getProgramTemplate();
  }, [getProgramTemplate]);

  return (
    <div className={pageCover.full}>
      <PageHeader
        loading={progTemplateLoading}
        title={`${programTemplate?.name}`}
        leftComponent={
          <ArrowLeftOutlined
            style={{ fontSize: "26px" }}
            onClick={() => navigate(RouteUrl.AdminPrograms)}
          />
        }
      />
      <CardWrap>
        {progTemplateLoading ? (
          <Skeleton />
        ) : (
          <AdminProgramTable
            title={"Program praćenja terapije"}
            preventDataFetch={true}
            providedProgTemplateData={programTemplate}
            showLink={false}
          />
        )}
      </CardWrap>
      <CardWrap>
        {progTemplateLoading ? (
          <Skeleton />
        ) : (
          <AdminProgramActivityTemplatesTable
            activityTemplates={programTemplate?.activityTemplates}
            getProgramTemplate={getProgramTemplate}
            programEnabled={programTemplate?.enabled}
          />
        )}
      </CardWrap>
      <CardWrap>
        <ProgramTemplateMedicineTable medicines={programTemplate?.medicines} />
      </CardWrap>
    </div>
  );
};
