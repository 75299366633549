import { EntityEndpoint } from "enums/EntityEndpoint";
import { ResourceType } from "enums/ResourceType";
import { StepJSONAModel } from "interfaces/jsona-models/StepJSONAModel";
import { StepSelector } from "selectors/StepSelector";
import { AppApiActionHandler } from "./AppApiActionHandler";

class StepActionHandler extends AppApiActionHandler<StepJSONAModel> {
  constructor() {
    super(ResourceType.Step, EntityEndpoint.Step, new StepSelector());
  }
}

export const stepActionHandler = new StepActionHandler();
