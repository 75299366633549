import { JSONAModel } from "@bornfight/aardvark/dist/interfaces/JSONAModel";
import { MedicineJSONAModel } from "interfaces/jsona-models/MedicineJSONAModel";
import { UuidAttribute } from "interfaces/UuidAttribute";

export type TherapyJSONAModel = JSONAModel<Therapy, Relationships>;

// matches backend enums
export enum IntakePartOfDay {
  MorningEmptyStomach = "morning_empty_stomach",
  Morning = "morning",
  Afternoon = "afternoon",
  Night = "night",
  NonApplicable = "non_applicable",
}

// matches backend enums
export enum IntakeRegime {
  OncePerDay = "1_x_1",
  TwicePerDay = "2_x_1",
  ThreeTimesPerDay = "3_x_1",
  OncePerWeek = "1_x_week",
  OncePerMonth = "1_x_month",
  AsNeeded = "as_needed",
}

interface Therapy extends UuidAttribute {
  adherenceTherapy: boolean;
  intakePartOfDay: IntakePartOfDay;
  intakeRegime: IntakeRegime;
}

interface Relationships {
  medicine: MedicineJSONAModel;
}
