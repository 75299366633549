import { Button, Result } from "antd";
import { RouteUrl } from "enums/RouteUrl";
import * as React from "react";
import { FunctionComponent } from "react";
import styles from "./NoMatchScreen.module.scss";
import { useNavigate } from "react-router-dom";

export const NoMatchScreen: FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.wrap}>
      <div className={styles["inner-wrap"]}>
        <Result
          title="404"
          subTitle="Žao nam je, stranica koju ste posjetili ne postoji."
          extra={
            <Button
              type="primary"
              // returns user to /login, then redirects to /home or /admin/users
              onClick={() => navigate(RouteUrl.Login)}
            >
              Povratak na početnu
            </Button>
          }
        />
      </div>
    </div>
  );
};
