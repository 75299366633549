import { IntakeRegime } from "interfaces/jsona-models/TherapyJSONAModel";

export const translateIntakeRegime = (intakeRegime: IntakeRegime) => {
  switch (intakeRegime) {
    case IntakeRegime.OncePerDay:
      return "Jedanput na dan";
    case IntakeRegime.TwicePerDay:
      return "Dvaput na dan";
    case IntakeRegime.ThreeTimesPerDay:
      return "Triput na dan";
    case IntakeRegime.OncePerWeek:
      return "Jedanput na tjedan";
    case IntakeRegime.OncePerMonth:
      return "Jedanput na mjesec";
    case IntakeRegime.AsNeeded:
      return "Po potrebi";
  }
};
