export enum RouteUrl {
  Home = "/home",
  Activities = "/activities",
  ArchivedActivities = "/archived-activities",
  Login = "/login",
  Register = "/register",
  Refresh = "/refresh",
  Patients = "/patients",
  Patient = "/patients/:patientId",
  NewPatient = "/new-patient/",
  PatientProgramActivity = "/patients/:patientId/programs/:programId/activities/:activityId",
  ProgramTemplates = "/program-templates",
  ProgramTemplate = "/program-templates/:programTemplateId/activity-templates/:activityTemplateId",
  Reports = "/reports",
  Programs = "/programs",
  Therapy = "/therapies",

  // you can't have query string in route anymore
  // e.g. http://localhost:3000/reset-password/?token=aaaaa
  // https://stackoverflow.com/questions/45731522/question-mark-inside-react-router-path
  ResetPassword = "/reset-password",
  SetPassword = "/set-password",
  ForgotPassword = "/forgot-password",

  Page404 = "/404",

  // admin routes, values should match those in AdminSideMenuItemUrl
  AdminDefault = "/admin",
  AdminUsers = "/admin/users",
  AdminPrograms = "/admin/programs",
  AdminQuestionnaires = "/admin/questionnaires",
  AdminQuestionnaireSingle = "/admin/questionnaires/:questionnaireId",
  AdminReports = "/admin/reports",
  AdminProgramTemplate = "/admin/program-templates/:programTemplateId",
}
