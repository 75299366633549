import { useGet } from "@bornfight/aardvark";
import { CardWrap } from "common/CardWrap/CardWrap";
import { PageHeader } from "common/PageHeader/PageHeader";
import { Activities } from "features/patients/components/Activities/Activities";
import { Comments } from "features/patients/components/Comments/Comments";
import styles from "features/patients/components/PatientProgramActivityScreen.module.scss";
import { ProgramMainMedicinesTable } from "features/patients/components/ProgramMainMedicinesTable/ProgramMainMedicinesTable";
import { ProgramMedicinesTable } from "features/patients/components/ProgramMedicinesTable/ProgramMedicinesTable";
import { ProgramSection } from "features/patients/components/ProgramSection/ProgramSection";
import { patientActionHandler } from "handlers/PatientActionHandler";
import * as React from "react";
import { FunctionComponent, useMemo } from "react";
import { useParams } from "react-router-dom";
import { programActionHandler } from "../../../handlers/ProgramActionHandler";
import { PatientProgramActivityRouteParams } from "../interfaces/PatientProgramActivityRouteParams";

export const PatientProgramActivityScreen: FunctionComponent = () => {
  const params = useParams<PatientProgramActivityRouteParams>();
  const patientId = useMemo(() => {
    return params.patientId;
  }, [params.patientId]);

  const { record: person, loading } = useGet(patientActionHandler, `/api/patients/${patientId}`);

  const programId = useMemo(() => {
    return params.programId;
  }, [params.programId]);
  const includes = useMemo(() => {
    return ["activities", "programTemplate", "programComments", "therapies"];
  }, []);
  const { record: program } = useGet(programActionHandler, `/api/programs/${programId}`, includes);

  return (
    <div>
      <PageHeader loading={loading} title={`${person?.firstName} ${person?.lastName}`} />
      <CardWrap noPadding={true}>
        <ProgramSection program={program} />
      </CardWrap>
      <CardWrap>
        <ProgramMainMedicinesTable program={program} />
      </CardWrap>
      <CardWrap>
        <ProgramMedicinesTable program={program} />
      </CardWrap>
      <div className={styles.row}>
        <div className={styles["col-interactions"]}>
          <CardWrap shortMarginRightTop={true} activityWrap={true}>
            <Activities program={program} />
          </CardWrap>
        </div>
        <div className={styles["col-comments"]}>
          <CardWrap shortMarginLeftTop={true}>
            <Comments program={program} />
          </CardWrap>
        </div>
      </div>
    </div>
  );
};
