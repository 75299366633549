import { Space } from "antd";
import { TextContent } from "common/TextContent/TextContent";
import { NewPasswordForm } from "features/authentication/components/NewPasswordForm";
import * as React from "react";
import { FunctionComponent } from "react";
import { useMatch } from "react-router-dom";
import { ReactComponent as PlivaLogo } from "static/mainLogo.svg";
import styles from "./NewPasswordScreen.module.scss";

export const NewPasswordScreen: FunctionComponent = () => {
  const isSetPassword = useMatch("/set-password/")?.pathname;

  return (
    <div className={styles.wrap}>
      <div className={styles["content-wrap"]}>
        <Space direction={"vertical"} size={20}>
          <div className={styles["logo-wrap"]}>
            <PlivaLogo />
          </div>
          <div className={styles["center-text"]}>
            <TextContent className={styles["message-title"]}>
              {isSetPassword ? "Molimo kreirajte lozinku." : "Molimo kreirajte novu lozinku."}
            </TextContent>
            <TextContent className={styles["message-description"]}>
              {isSetPassword
                ? "Molimo kreirajte lozinku. Upamtite ili zapišite lozinku na sigurno mjesto kako biste ju mogli koristiti za prijavu na svoj račun."
                : "Molimo kreirajte novu lozinku. Upamtite ili zapišite lozinku na sigurno mjesto kako biste ju mogli koristiti za prijavu na svoj račun."}
            </TextContent>
          </div>
        </Space>
        <div className={styles["form-wrap"]}>
          <NewPasswordForm isSetPassword={Boolean(isSetPassword)} />
        </div>
      </div>
    </div>
  );
};
